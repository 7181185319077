import { all, call, fork, put, takeEvery } from "redux-saga/effects";
import axios from "axios";
import { loadStripe } from "@stripe/stripe-js";

import { setAlert } from "../Alerts/actions";

import baseDomain, {
  addTestStepOneSubmitReq,
  addTestStepTwoSubmitReq,
  addTestStepThreeSubmitReq,
  fileUploadReq,
  stripePaymentReq,
  getPaymentPackageReq,
} from "../../constants/apiRoutes";

import {
  ADD_TEST_STEP_ONE_SUBMIT,
  ADD_TEST_STEP_TWO_SUBMIT,
  ADD_TEST_STEP_TWO_WITH_STORE_SUBMIT,
  ADD_TEST_STEP_THREE_SUBMIT,
  GET_PAYMENT_PACKAGE,
} from "../actions";

import {
  addTestStepOneSubmitSuccess,
  addTestStepOneSubmitError,
  addTestStepTwoSubmitSuccess,
  addTestStepTwoSubmitError,
  addTestStepTwoWithStoreSubmitSuccess,
  addTestStepTwoWithStoreSubmitError,
  addTestStepThreeSubmitSuccess,
  addTestStepThreeSubmitError,
  getPaymentPackageSuccess,
  getPaymentPackageError,
} from "./actions";

const stripePromise = loadStripe("pk_test_oyPcOVlesYZfLavrPiTza7Mi00fqzdXtj1");

const addTestStepOneSubmitAPI = async data => {
  return axios.post(`${baseDomain}${addTestStepOneSubmitReq}`, data);
};

const addTestStepTwoSubmitAPI = async data => {
  return axios.post(`${baseDomain}${addTestStepTwoSubmitReq}`, data);
};

const addTestStepThreeSubmitAPI = async data => {
  return axios.post(`${baseDomain}${addTestStepThreeSubmitReq}`, data);
};

const getPaymentPackageAPI = async () => {
  return axios.get(`${baseDomain}${getPaymentPackageReq}`);
};

const fileUploadAPI = async (file, type) => {
  return axios.post(`${baseDomain}${fileUploadReq}/${type}`, file);
};

const stripePaymentAPI = async projectId => {
  return axios.get(`${baseDomain}${stripePaymentReq}/${projectId}`);
};

function* addTestStepOneSubmit({ payload }) {
  try {
    const { data } = yield call(addTestStepOneSubmitAPI, payload);
    yield put(addTestStepOneSubmitSuccess(data.data));
  } catch (error) {
    yield put(addTestStepOneSubmitError(error));
    yield put(setAlert({ message: error.response.data.message, type: "ERROR" }));
  }
}

function* addTestStepTwoSubmit({ payload, appLogo }) {
  try {
    const fileData = new FormData();
    fileData.append("file", appLogo);
    const { data } = yield call(fileUploadAPI, fileData, "PROJECT_ICON");
    const res = yield call(addTestStepTwoSubmitAPI, { ...payload, logo: data.data });
    yield put(addTestStepTwoSubmitSuccess(res.data.data));
  } catch (error) {
    yield put(addTestStepTwoSubmitError(error));
    yield put(setAlert({ message: error.response.data.message, type: "ERROR" }));
  }
}

function* addTestStepTwoWithStoreSubmit({ payload }) {
  try {
    const res = yield call(addTestStepTwoSubmitAPI, payload);
    yield put(addTestStepTwoWithStoreSubmitSuccess(res.data.data));
  } catch (error) {
    yield put(addTestStepTwoWithStoreSubmitError(error));
    yield put(setAlert({ message: error.response.data.message, type: "ERROR" }));
  }
}

function* addTestStepThreeSubmit({ payload, formFiles, history }) {
  try {
    let fileJson = {};
    if (formFiles.knownBug) {
      const knownBugForm = new FormData();
      knownBugForm.append("file", formFiles.knownBug);
      const knownBugReq = yield call(fileUploadAPI, knownBugForm, "BUG_LIST");
      fileJson = { ...fileJson, knownBug: knownBugReq.data.data };
    }
    if (formFiles.file) {
      const fileForm = new FormData();
      fileForm.append("file", formFiles.file);
      const fileReq = yield call(fileUploadAPI, fileForm, "OTHER");
      fileJson = { ...fileJson, file: fileReq.data.data };
    }
    const { data } = yield call(addTestStepThreeSubmitAPI, { ...payload, ...fileJson });
    const stripe = yield stripePromise;
    const paymentRes = yield call(stripePaymentAPI, payload.projectId);
    const result = yield stripe.redirectToCheckout({
      sessionId: paymentRes.data.data,
    });
    if (result.error) {
    }
    yield put(addTestStepThreeSubmitSuccess({ projectID: data.data, stripeID: paymentRes.data.data }));
  } catch (error) {
    yield put(addTestStepThreeSubmitError(error));
    yield put(setAlert({ message: (error.response && error.response.data && error.response.data.message) || "Error", type: "ERROR" }));
    history.push("/dashboard/home");
  }
}

function* getPaymentPackage() {
  try {
    const { data } = yield call(getPaymentPackageAPI);
    yield put(getPaymentPackageSuccess(data.data));
  } catch (error) {
    yield put(getPaymentPackageError(error));
    yield put(setAlert({ message: error.response.data.message, type: "ERROR" }));
  }
}

export function* watchaddTestStepOneSubmit() {
  yield takeEvery(ADD_TEST_STEP_ONE_SUBMIT, addTestStepOneSubmit);
}
export function* watchaddTestStepTwoSubmit() {
  yield takeEvery(ADD_TEST_STEP_TWO_SUBMIT, addTestStepTwoSubmit);
}
export function* watchaddTestStepTwoWithStoreSubmit() {
  yield takeEvery(ADD_TEST_STEP_TWO_WITH_STORE_SUBMIT, addTestStepTwoWithStoreSubmit);
}
export function* watchAddTestStepThreeSubmit() {
  yield takeEvery(ADD_TEST_STEP_THREE_SUBMIT, addTestStepThreeSubmit);
}
export function* watchGetPaymentPackage() {
  yield takeEvery(GET_PAYMENT_PACKAGE, getPaymentPackage);
}

export default function* rootSaga() {
  yield all([
    fork(watchaddTestStepOneSubmit),
    fork(watchaddTestStepTwoSubmit),
    fork(watchAddTestStepThreeSubmit),
    fork(watchGetPaymentPackage),
    fork(watchaddTestStepTwoWithStoreSubmit),
  ]);
}
