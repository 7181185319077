import React from "react";
import { Link } from "react-router-dom";

import { getFullDateTime } from "../../../../utils/getDateTime";
import NoDataMessage from "../../NoDataMessage";
import settings from "../../../../constants/settings";

import "../style.scss";

// const tableHeadingArray = ["Name", "Submission Time", "Priority", "Approval", "View"];

const AnalysisTable = ({ tableContentArray, projectID, role }) => {
  return (
    <div className="dashboard-table">
      <div className="analysis-table">
        {tableContentArray.length === 0 ? (
          <NoDataMessage message={settings.analysis_table.message} />
        ) : (
          <table>
            <tr>
              {settings.analysis_table.tableHeadingArray.map((head, i) => (
                <th key={i}>{head}</th>
              ))}
            </tr>
            {tableContentArray.map(({ summary, createAt, earning, priority, status, id }, i) => (
              <tr key={i}>
                <td>
                  <div className="notification-invit">
                    <Link className="inherit blue-color bugName" to={role === "TESTER" ? `/dashboard/test-taken/projects/active/${projectID}/bug/${id}` : `/dashboard/projects/projectlist/${projectID}/bugs/${id}/details`}>
                      <div> {summary}</div>
                    </Link>
                  </div>
                </td>
                <td className="remove">{getFullDateTime(createAt)}</td>
                <td className="remove">{earning ? earning : "-"}</td>
                <td className="remove">{priority}</td>
                <td approval={status} className="remove">{status}</td>
                {/* <td>
                  <Link className="inherit blue-color" to={role === "TESTER" ? `/dashboard/test-taken/projects/active/${projectID}/bug/${id}`: `dashboard/projects/projectlist/${projectID}/bugs/${id}/details`}>
                    {settings.analysis_table.view}
                  </Link>
                </td> */}
              </tr>
            ))}
          </table>
        )}
      </div>
    </div>
  );
};

export default AnalysisTable;
