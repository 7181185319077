export const SearchIcon = ({ color, size = "19px" }) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 0 19 19">
    <g>
      <g>
        <path
          fill={color}
          d="M18.884 17.765l-5.519-5.519a7.536 7.536 0 1 0-1.119 1.119l5.519 5.519a.4.4 0 0 0 .56 0l.56-.56a.4.4 0 0 0-.001-.559zM7.521 13.458a5.938 5.938 0 1 1 5.938-5.938 5.944 5.944 0 0 1-5.938 5.938z"
        />
      </g>
    </g>
  </svg>
);

export const CloseSign = (
  <svg xmlns="http://www.w3.org/2000/svg" width="19px" height="19px" fill="#333" viewBox="0 0 36 36">
    <path d="M28.5 9.62L26.38 7.5 18 15.88 9.62 7.5 7.5 9.62 15.88 18 7.5 26.38l2.12 2.12L18 20.12l8.38 8.38 2.12-2.12L20.12 18z" />
  </svg>
);

export const Star = (
  <svg height="28.6px" viewBox="0 -10 511.98685 511" width="28.6px" fill="#ffc107" xmlns="http://www.w3.org/2000/svg">
    <path d="m510.652344 185.902344c-3.351563-10.367188-12.546875-17.730469-23.425782-18.710938l-147.773437-13.417968-58.433594-136.769532c-4.308593-10.023437-14.121093-16.511718-25.023437-16.511718s-20.714844 6.488281-25.023438 16.535156l-58.433594 136.746094-147.796874 13.417968c-10.859376 1.003906-20.03125 8.34375-23.402344 18.710938-3.371094 10.367187-.257813 21.738281 7.957031 28.90625l111.699219 97.960937-32.9375 145.089844c-2.410156 10.667969 1.730468 21.695313 10.582031 28.09375 4.757813 3.4375 10.324219 5.1875 15.9375 5.1875 4.839844 0 9.640625-1.304687 13.949219-3.882813l127.46875-76.183593 127.421875 76.183593c9.324219 5.609376 21.078125 5.097657 29.910156-1.304687 8.855469-6.417969 12.992187-17.449219 10.582031-28.09375l-32.9375-145.089844 111.699219-97.941406c8.214844-7.1875 11.351563-18.539063 7.980469-28.925781zm0 0" />
  </svg>
);

export const EarningNavIcon = (
  <svg style={{ marginLeft: "-0.4rem", verticalAlign:"middle" }} xmlns="http://www.w3.org/2000/svg" width="36" height="36" fill="#000000" stroke="#000000" strokeWidth="1" viewBox="0 0 11.809 23.186">
    <path
      id="Path_2250"
      data-name="Path 2250"
      d="M196.932,133.21c-2.1-1.049-4.079-2.04-4.079-3.788s1.917-2.56,3.7-2.56,3.7.8,3.7,2.56a.853.853,0,1,0,1.707,0c0-2.5-2.172-3.939-4.551-4.216v-1.473a.853.853,0,0,0-1.707,0v1.473c-2.379.277-4.551,1.713-4.551,4.216,0,2.8,2.669,4.137,5.023,5.314,2.1,1.049,4.079,2.04,4.079,3.788s-1.917,2.56-3.7,2.56-3.7-.8-3.7-2.56a.853.853,0,0,0-1.707,0c0,2.5,2.172,3.939,4.551,4.216v1.473a.853.853,0,0,0,1.707,0V142.74c2.379-.277,4.551-1.713,4.551-4.216C201.955,135.721,199.286,134.387,196.932,133.21Z"
      transform="translate(-190.646 -122.38)"
    />
  </svg>
);

export const MessageSquareIcon = (
  <svg xmlns="http://www.w3.org/2000/svg" width="19.984" height="19.984" viewBox="0 0 19.984 19.984">
    <g id="message-square" transform="translate(-0.555 -0.555)" opacity="1">
      <path
        id="message-square-2"
        data-name="message-square"
        d="M21.984,15.656a2.109,2.109,0,0,1-2.109,2.109H7.219L3,21.984V5.109A2.109,2.109,0,0,1,5.109,3H19.875a2.109,2.109,0,0,1,2.109,2.109Z"
        transform="translate(-1.945 -1.945)"
        fill="none"
        stroke="#000"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
      />
    </g>
  </svg>
);

export const HistoryIcon = (
  <svg xmlns="http://www.w3.org/2000/svg" width="23.642" height="20.687" viewBox="0 0 23.642 20.687">
    <g id="_2089679" data-name="2089679" transform="translate(0 -34.445)">
      <path
        id="Path_683"
        data-name="Path 683"
        d="M275.483,172.228l0,5.169a.738.738,0,0,0,.216.522l3.7,3.7,1.045-1.045-3.481-3.481,0-4.863Z"
        transform="translate(-263.663 -131.872)"
        fill="#777"
      />
      <path
        id="Path_684"
        data-name="Path 684"
        d="M13.3,34.445A10.351,10.351,0,0,0,3.073,43.311H0L3.694,47l3.694-3.694H4.566a8.851,8.851,0,1,1,1.741,6.931l-1.165.909A10.344,10.344,0,1,0,13.3,34.445Z"
        fill="#777"
      />
    </g>
  </svg>
);

export const ManageClientsIcon = (
  <svg style={{ marginLeft: "-0.3rem" }} xmlns="http://www.w3.org/2000/svg" width="36" height="36" viewBox="0 0 18.834 18.549">
    <g id="_1605401" data-name="1605401" transform="translate(-0.001 -0.5)">
      <path
        id="Path_700"
        data-name="Path 700"
        d="M18.39,224.466a1.706,1.706,0,0,0-1.052-.441,1.506,1.506,0,0,0-1.157.441l-2.094,2.072a1.39,1.39,0,0,0-.142-.2,1.358,1.358,0,0,0-1.061-.474H9.953a6.012,6.012,0,0,1-1.6-.213,3.944,3.944,0,0,0-3.679.778,5.35,5.35,0,0,0-.958,1.028l-.526-.51a.374.374,0,0,0-.259-.105h0a.374.374,0,0,0-.266.114L.1,229.607a.371.371,0,0,0,.011.525l4.322,4.115a.37.37,0,0,0,.521-.01l2.531-2.6a.373.373,0,0,0-.007-.527l-.22-.214.5-.057a8.019,8.019,0,0,1,.923-.053h4.46a3.349,3.349,0,0,0,1.83-.471l.012-.008a2.47,2.47,0,0,0,.473-.429l3.006-3.536a1.294,1.294,0,0,0-.08-1.879ZM4.684,233.458.9,229.852l2.04-2.118L6.7,231.388Zm13.222-7.593L14.9,229.4a1.732,1.732,0,0,1-.325.3,2.635,2.635,0,0,1-1.425.351H8.69a8.749,8.749,0,0,0-1.007.058l-1.109.124-2.322-2.253A4.621,4.621,0,0,1,5.14,227a3.189,3.189,0,0,1,3.013-.638,6.753,6.753,0,0,0,1.8.24h2.932a.669.669,0,0,1,.667.726.8.8,0,0,1-.173.514.63.63,0,0,1-.505.212H10.321a.371.371,0,0,0,0,.741h2.552a1.374,1.374,0,0,0,1.07-.473,1.522,1.522,0,0,0,.349-.944l2.412-2.387a.867.867,0,0,1,1.185.02.558.558,0,0,1,.018.854Zm0,0"
        transform="translate(0 -215.3)"
        fill="#777"
      />
      <path
        id="Path_701"
        data-name="Path 701"
        d="M229.969,333.507a.37.37,0,1,0-.721.169l0,.015a.371.371,0,1,0,.721-.169Zm0,0"
        transform="translate(-220.804 -320.482)"
        fill="#777"
      />
      <path
        id="Path_702"
        data-name="Path 702"
        d="M44.588,8.317H48.2a.892.892,0,0,0,.527-.172.892.892,0,0,0,.527.172h3.61a.892.892,0,0,0,.527-.172.892.892,0,0,0,.526.172h3.61a.9.9,0,0,0,.9-.9V5.736A1.943,1.943,0,0,0,57.365,4a2.137,2.137,0,1,0-3.291,0,1.941,1.941,0,0,0-.686.588A1.937,1.937,0,0,0,52.7,4a2.137,2.137,0,1,0-3.291,0,1.937,1.937,0,0,0-.686.588A1.939,1.939,0,0,0,48.038,4a2.137,2.137,0,1,0-3.291,0,1.943,1.943,0,0,0-1.057,1.737V7.42a.9.9,0,0,0,.9.9ZM55.72,1.241a1.4,1.4,0,1,1-1.4,1.4A1.4,1.4,0,0,1,55.72,1.241Zm-.963,3.3a2.13,2.13,0,0,0,1.925,0,1.2,1.2,0,0,1,1,1.192V7.42a.156.156,0,0,1-.156.156h-3.61a.156.156,0,0,1-.156-.156V5.736a1.2,1.2,0,0,1,1-1.192Zm-3.7-3.3a1.4,1.4,0,1,1-1.4,1.4A1.4,1.4,0,0,1,51.056,1.241Zm-.962,3.3a2.13,2.13,0,0,0,1.925,0,1.2,1.2,0,0,1,1,1.192V7.42a.156.156,0,0,1-.156.156h-3.61A.156.156,0,0,1,49.1,7.42V5.736a1.2,1.2,0,0,1,1-1.192Zm-3.7-3.3a1.4,1.4,0,1,1-1.4,1.4A1.4,1.4,0,0,1,46.393,1.241Zm-1.961,4.5a1.2,1.2,0,0,1,1-1.192,2.13,2.13,0,0,0,1.925,0,1.2,1.2,0,0,1,1,1.192V7.42a.156.156,0,0,1-.156.156h-3.61a.156.156,0,0,1-.156-.156Zm0,0"
        transform="translate(-42.083)"
        fill="#777"
      />
    </g>
  </svg>
);

export const ManageTestersIcon = (
  <svg style={{ marginLeft: "-0.3rem" }} xmlns="http://www.w3.org/2000/svg" width="36" height="36" viewBox="0 0 19.412 19.477">
    <g id="_1086581" data-name="1086581" transform="translate(-0.144 0.194)">
      <path
        id="Path_695"
        data-name="Path 695"
        d="M.355,1.818,1.621,4.034a.316.316,0,0,0,.2.15L3,4.479,7.052,8.531,7.5,8.083,3.384,3.968a.316.316,0,0,0-.147-.083L2.1,3.6,1.024,1.713,1.949.788,3.837,1.868,4.122,3a.316.316,0,0,0,.083.147L8.32,7.265l.448-.448L4.716,2.766l-.3-1.181a.317.317,0,0,0-.15-.2L2.055.121a.317.317,0,0,0-.38.051L.409,1.438a.317.317,0,0,0-.054.38Zm0,0"
        transform="translate(-0.039 -0.077)"
        fill="#000"
        stroke="#000"
        strokeWidth="0.24"
      />
      <path
        id="Path_697"
        data-name="Path 697"
        d="M51.19,398.989a.316.316,0,0,0-.271-.154h-1.9a.316.316,0,0,0-.271.154l-.95,1.583a.316.316,0,0,0,0,.326l.95,1.583a.317.317,0,0,0,.271.154h1.9a.317.317,0,0,0,.271-.154l.95-1.583a.317.317,0,0,0,0-.326ZM50.739,402H49.2l-.76-1.266.76-1.266h1.541l.76,1.266Zm0,0"
        transform="translate(-45.897 -385.54)"
        fill="#000"
        stroke="#000"
        strokeWidth="0.24"
      />
      <path
        id="Path_698"
        data-name="Path 698"
        d="M15.467,7.6a3.785,3.785,0,0,0,3.682-4.716.316.316,0,0,0-.531-.148L16.648,4.7l-1.563-.521-.521-1.563L16.534.648a.317.317,0,0,0-.148-.531A3.787,3.787,0,0,0,11.669,3.8a3.715,3.715,0,0,0,.077.742L4.814,11.473a3.734,3.734,0,0,0-.742-.077,3.8,3.8,0,1,0,3.8,3.8,3.725,3.725,0,0,0-.077-.742l1.66-1.66.726.726a.317.317,0,0,0,.448,0l.158-.158a.355.355,0,0,1,.5.5h0l-.158.158a.316.316,0,0,0,0,.448L14.9,18.244a2.555,2.555,0,1,0,3.629-3.6l-.016-.016-3.776-3.776a.316.316,0,0,0-.448,0l-.158.158a.355.355,0,0,1-.5-.5h0l.158-.158a.316.316,0,0,0,0-.448l-.726-.726,1.66-1.66a3.725,3.725,0,0,0,.742.077ZM16.711,18.36a1.923,1.923,0,0,1-.673-.123l2.472-2.472a1.92,1.92,0,0,1-1.8,2.6Zm-3.586-8.227a.988.988,0,0,0,1.393,1.391l3.552,3.555c.039.039.075.079.11.121l-2.706,2.706c-.042-.035-.082-.071-.121-.11L11.8,14.242A.988.988,0,0,0,10.4,12.851L9.9,12.344l2.721-2.717ZM14.4,6.949,7.223,14.129a.317.317,0,0,0-.084.3,3.186,3.186,0,1,1-2.3-2.3.319.319,0,0,0,.3-.083l7.179-7.179a.316.316,0,0,0,.083-.3A3.15,3.15,0,0,1,15.647.639l-1.67,1.67a.316.316,0,0,0-.077.324l.633,1.9a.317.317,0,0,0,.2.2l1.9.633a.317.317,0,0,0,.324-.076l1.67-1.67c0,.06.006.12.006.18A3.149,3.149,0,0,1,14.7,6.866a.317.317,0,0,0-.3.083h0Zm0,0"
        transform="translate(0 0)"
        fill="#000"
        stroke="#000"
        strokeWidth="0.24"
      />
      <path
        id="Path_699"
        data-name="Path 699"
        d="M386.5,385.79l-.448.448-3.165-3.165.448-.448Zm0,0"
        transform="translate(-369.862 -369.87)"
        fill="#000"
        stroke="#000"
        strokeWidth="0.24"
      />
    </g>
  </svg>
);

export const manageBugReports = (
  <svg style={{ marginLeft: "-0.5rem" }} xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 22.167 27.208">
    <g id="file-text" transform="translate(-0.26 -0.26)">
      <path
        id="Path_441"
        data-name="Path 441"
        d="M16.6,2H6.521A2.521,2.521,0,0,0,4,4.521V24.687a2.521,2.521,0,0,0,2.521,2.521H21.646a2.521,2.521,0,0,0,2.521-2.521V9.563Z"
        transform="translate(-2.74 -0.74)"
        fill="none"
        stroke="#000"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
      />
      <path
        id="Path_442"
        data-name="Path 442"
        d="M14,2V9.563h7.562"
        transform="translate(-0.135 -0.74)"
        fill="none"
        stroke="#000"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
      />
      <line
        id="Line_6"
        data-name="Line 6"
        x1="10"
        transform="translate(6 15)"
        fill="none"
        stroke="#000"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
      />
      <line
        id="Line_7"
        data-name="Line 7"
        x1="10"
        transform="translate(6 20)"
        fill="none"
        stroke="#000"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
      />
      <path
        id="Path_443"
        data-name="Path 443"
        d="M10.521,9H8"
        transform="translate(-1.698 1.083)"
        fill="none"
        stroke="#000"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
      />
    </g>
  </svg>
);

export const eyeSign = (
  <path d="M12.01 20c-5.065 0-9.586-4.211-12.01-8.424 2.418-4.103 6.943-7.576 12.01-7.576 5.135 0 9.635 3.453 11.999 7.564-2.241 4.43-6.726 8.436-11.999 8.436zm-10.842-8.416c.843 1.331 5.018 7.416 10.842 7.416 6.305 0 10.112-6.103 10.851-7.405-.772-1.198-4.606-6.595-10.851-6.595-6.116 0-10.025 5.355-10.842 6.584zm10.832-4.584c2.76 0 5 2.24 5 5s-2.24 5-5 5-5-2.24-5-5 2.24-5 5-5zm0 1c2.208 0 4 1.792 4 4s-1.792 4-4 4-4-1.792-4-4 1.792-4 4-4z" />
);

export const deleteBin = (<svg xmlns="http://www.w3.org/2000/svg" data-name="Layer 1" viewBox="0 0 64 64">
  <path fill="none" stroke="#fb3a3a" stroke-miterlimit="10" stroke-width="4" d="M49,62H15a3,3,0,0,1-3-3V10H52V59A3,3,0,0,1,49,62Z" />
  <line x1="6" x2="58" y1="10" y2="10" fill="none" stroke="#fb3a3a" stroke-miterlimit="10" stroke-width="4" />
  <path fill="none" stroke="#fb3a3a" stroke-miterlimit="10" stroke-width="4" d="M38,2H26a2,2,0,0,0-2,2v6H40V4A2,2,0,0,0,38,2Z" />
  <line x1="24" x2="24" y1="20" y2="52" fill="none" stroke="#fb3a3a" stroke-miterlimit="10" stroke-width="4" />
  <line x1="32" x2="32" y1="20" y2="52" fill="none" stroke="#fb3a3a" stroke-miterlimit="10" stroke-width="4" />
  <line x1="40" x2="40" y1="20" y2="52" fill="none" stroke="#fb3a3a" stroke-miterlimit="10" stroke-width="4" />
</svg>);

export const editBadge = (
  <svg xmlns="http://www.w3.org/2000/svg" fill="#247CD3" width="24" height="24" viewBox="0 0 24 24"><path fill="none" d="M0 0h24v24H0V0z" /><path d="M3 17.46v3.04c0 .28.22.5.5.5h3.04c.13 0 .26-.05.35-.15L17.81 9.94l-3.75-3.75L3.15 17.1c-.1.1-.15.22-.15.36zM20.71 7.04c.39-.39.39-1.02 0-1.41l-2.34-2.34c-.39-.39-1.02-.39-1.41 0l-1.83 1.83 3.75 3.75 1.83-1.83z" /></svg>
)

export const manageBadges = (
  <svg style={{ marginLeft: "-0.6rem" }} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100.3 100.3"><path d="M72 56.1c-0.2-0.5-0.8-0.9-1.4-0.9H56.6L50.5 43c-0.3-0.5-0.8-0.8-1.3-0.8s-1.1 0.3-1.3 0.8l-6.1 12.2H28.2c-0.6 0-1.1 0.3-1.4 0.9s-0.2 1.2 0.2 1.6l10 11.8 -3.1 13.7c-0.1 0.6 0.1 1.2 0.5 1.5 0.5 0.4 1.1 0.4 1.6 0.2l13.1-6.5 13.1 6.5c0.2 0.1 0.4 0.2 0.7 0.2 0.3 0 0.7-0.1 0.9-0.3 0.5-0.4 0.7-0.9 0.5-1.5l-3.1-13.7 10.5-11.8C72.1 57.3 72.2 56.7 72 56.1zM58.5 68.1c-0.3 0.4-0.4 0.9-0.3 1.3L60.8 80.8l-10.9-5.4c-0.2-0.1-0.4-0.2-0.7-0.2s-0.5 0.1-0.7 0.2L37.6 80.8l2.6-11.3c0.1-0.5 0-0.9-0.3-1.3l-8.4-9.9h11.2c0.6 0 1.1-0.3 1.3-0.8l5.1-10.4 5.1 10.4c0.3 0.5 0.8 0.8 1.3 0.8h11.6L58.5 68.1z" /><path d="M51 31.1V24.3h16.5c0.8 0 1.5-0.7 1.5-1.5v-16c0-0.8-0.7-1.5-1.5-1.5h-35c-0.8 0-1.5 0.7-1.5 1.5v16c0 0.8 0.7 1.5 1.5 1.5H48v6.8C30.3 31.9 16.1 46.6 16.1 64.5c0 18.5 15 33.5 33.5 33.5s33.5-15 33.5-33.5C83.1 46.5 68.8 31.8 51 31.1zM34 8.3h32v13H34V8.3zM49.6 95c-16.8 0-30.5-13.7-30.5-30.5 0-16.5 13.2-30 29.6-30.5 0.4 0 1.1 0 1.5 0 16.5 0.3 29.9 13.9 29.9 30.5C80.1 81.3 66.4 95 49.6 95z" /></svg>
)

export const managetestimonial = (
  <svg style={{ marginLeft: "-0.5rem" }} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 186.3 186.3"><path d="M183.8 168H2.5c-1.4 0-2.5-1.1-2.5-2.5V96.2c0-1.4 1.1-2.5 2.5-2.5h127.7l38.4-74c0.6-1.2 2.1-1.7 3.4-1.1l10 5.2c0.6 0.3 1 0.8 1.2 1.5 0.2 0.6 0.1 1.3-0.2 1.9l-34.5 66.5h35.4c1.4 0 2.5 1.1 2.5 2.5v69.3C186.3 166.9 185.2 168 183.8 168zM5 163h176.3V98.7h-35.4l-12.6 24.2c-0.1 0.3-0.3 0.5-0.5 0.8l-15.8 18.3c-0.8 0.9-2 1.1-3 0.6 -1-0.5-1.6-1.7-1.3-2.8l5.8-23.3c0-0.2 0.1-0.5 0.2-0.7l8.8-17H5V163zM122.6 120.5l-2.5 10.3 6.9-8L122.6 120.5zM127.1 117.2l2.8 1.4 47.5-91.6 -5.5-2.9 -37.8 72.8c0 0.1-0.1 0.2-0.1 0.3l-9.6 18.5L127.1 117.2zM74.2 148.5H20.8c-1.4 0-2.5-1.1-2.5-2.5s1.1-2.5 2.5-2.5h53.3c1.4 0 2.5 1.1 2.5 2.5S75.5 148.5 74.2 148.5zM74.2 132.9H20.8c-1.4 0-2.5-1.1-2.5-2.5s1.1-2.5 2.5-2.5h53.3c1.4 0 2.5 1.1 2.5 2.5S75.5 132.9 74.2 132.9zM74.2 117.3H20.8c-1.4 0-2.5-1.1-2.5-2.5s1.1-2.5 2.5-2.5h53.3c1.4 0 2.5 1.1 2.5 2.5S75.5 117.3 74.2 117.3z" fill="#000002"/></svg>
);

export const googleLogo = (
  <svg style={{verticalAlign:"middle",background:"white", borderRadius:"50%"}} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24">
  <g transform="matrix(1, 0, 0, 1, 27.009001, -39.238998)">
    <path fill="#4285F4" d="M -3.264 51.509 C -3.264 50.719 -3.334 49.969 -3.454 49.239 L -14.754 49.239 L -14.754 53.749 L -8.284 53.749 C -8.574 55.229 -9.424 56.479 -10.684 57.329 L -10.684 60.329 L -6.824 60.329 C -4.564 58.239 -3.264 55.159 -3.264 51.509 Z"/>
    <path fill="#34A853" d="M -14.754 63.239 C -11.514 63.239 -8.804 62.159 -6.824 60.329 L -10.684 57.329 C -11.764 58.049 -13.134 58.489 -14.754 58.489 C -17.884 58.489 -20.534 56.379 -21.484 53.529 L -25.464 53.529 L -25.464 56.619 C -23.494 60.539 -19.444 63.239 -14.754 63.239 Z"/>
    <path fill="#FBBC05" d="M -21.484 53.529 C -21.734 52.809 -21.864 52.039 -21.864 51.239 C -21.864 50.439 -21.724 49.669 -21.484 48.949 L -21.484 45.859 L -25.464 45.859 C -26.284 47.479 -26.754 49.299 -26.754 51.239 C -26.754 53.179 -26.284 54.999 -25.464 56.619 L -21.484 53.529 Z"/>
    <path fill="#EA4335" d="M -14.754 43.989 C -12.984 43.989 -11.404 44.599 -10.154 45.789 L -6.734 42.369 C -8.804 40.429 -11.514 39.239 -14.754 39.239 C -19.444 39.239 -23.494 41.939 -25.464 45.859 L -21.484 48.949 C -20.534 46.099 -17.884 43.989 -14.754 43.989 Z"/>
  </g>
</svg>
)

export const linkedInLogo = (
<svg style={{verticalAlign:"middle"}} xmlns="http://www.w3.org/2000/svg" width="28" height="28" viewBox="0 0 100 100" fill="#ffffff">
  <path d="M87.877 5.608H11.174c-3.673 0-6.65 2.886-6.65 6.448v77.101c0 3.562 2.978 6.451 6.65 6.451h76.703c3.673 0 6.646-2.89 6.646-6.451V12.056c0-3.561-2.973-6.448-6.646-6.448zM31.809 80.944H18.211V40.31h13.598v40.634zm-6.798-46.185h-.091c-4.56 0-7.516-3.119-7.516-7.023 0-3.983 3.043-7.017 7.693-7.017 4.651 0 7.512 3.033 7.602 7.017 0 3.905-2.95 7.023-7.688 7.023zm55.816 46.185H67.233v-21.74c0-5.464-1.97-9.191-6.886-9.191-3.761 0-5.993 2.515-6.973 4.942-.364.868-.453 2.08-.453 3.292v22.696H39.329s.178-36.823 0-40.634h13.593v5.761c1.805-2.768 5.029-6.717 12.249-6.717 8.947 0 15.656 5.804 15.656 18.291v23.3zM52.834 46.199c.024-.038.056-.084.088-.128v.128h-.088z"/>
  </svg>
)

export const linkedInLogoBlue = (
<svg style={{verticalAlign:"middle"}} xmlns="http://www.w3.org/2000/svg" width="28" height="28" viewBox="0 0 100 100" fill="#0A66C2" >
  <path d="M87.877 5.608H11.174c-3.673 0-6.65 2.886-6.65 6.448v77.101c0 3.562 2.978 6.451 6.65 6.451h76.703c3.673 0 6.646-2.89 6.646-6.451V12.056c0-3.561-2.973-6.448-6.646-6.448zM31.809 80.944H18.211V40.31h13.598v40.634zm-6.798-46.185h-.091c-4.56 0-7.516-3.119-7.516-7.023 0-3.983 3.043-7.017 7.693-7.017 4.651 0 7.512 3.033 7.602 7.017 0 3.905-2.95 7.023-7.688 7.023zm55.816 46.185H67.233v-21.74c0-5.464-1.97-9.191-6.886-9.191-3.761 0-5.993 2.515-6.973 4.942-.364.868-.453 2.08-.453 3.292v22.696H39.329s.178-36.823 0-40.634h13.593v5.761c1.805-2.768 5.029-6.717 12.249-6.717 8.947 0 15.656 5.804 15.656 18.291v23.3zM52.834 46.199c.024-.038.056-.084.088-.128v.128h-.088z"/>
  </svg>
  )

export const reviewBugs = (
<svg style={{marginLeft:"-0.25rem",verticalAlign:"middle"}} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1000 1000" >
  <path d="M235.3 305h300.4c13.8 0 25 11.2 25 25s-11.2 25-25 25H235.3c-13.8 0-25-11.2-25-25s11.2-25 25-25zM235.3 455.2h300.4c13.8 0 25 11.2 25 25s-11.2 25-25 25H235.3c-13.8 0-25-11.2-25-25s11.2-25 25-25zM490.2 605.4H235.3c-13.8 0-25 11.2-25 25s11.2 25 25 25h221.4c10-17.5 21.1-34.2 33.5-50z"/>
  <path d="M411.4 856.2H151.7c-50.6 0-91.7-43.2-91.7-96.4V201.1c0-53.1 41.1-96.4 91.7-96.4h466.8c50.6 0 91.7 43.2 91.7 96.4v411.8c16.3-3.3 33-5.6 50-6.7V201.1c0-80.9-63.5-146.4-141.7-146.4H151.7C73.5 54.7 10 120.2 10 201.1v558.7c0 80.9 63.5 146.4 141.7 146.4h266.4c-3.3-16.3-5.6-33-6.7-50z"/>
  <path d="M982.6 589c-9.8-9.8-25.7-9.8-35.5 0L651.4 884.7 527.1 760.4c-9.8-9.8-25.7-9.8-35.5 0s-9.8 25.7 0 35.5l142 142c9.8 9.8 25.7 9.8 35.5 0l313.5-313.5c9.9-9.7 9.9-25.6 0-35.4z"/>
  </svg>
)  

