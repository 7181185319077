import React, { useEffect } from "react";
import { connect } from "react-redux";

import { getAdminSeedDashboard, getAdminDashboardStats } from "../../../redux/AdminDashboard/actions";
import { setDashboardRoute } from "../../../redux/Routing/actions";
import Loader from "../../Loader";

import AdminCard from "./Components/Cards";

import DashboardHeading from "../../Common/DashboardHeading";
import Img1 from "../../../assets/icons/adminIcons/1.png";
import Img2 from "../../../assets/icons/adminIcons/2.png";
import Img3 from "../../../assets/icons/adminIcons/3.png";
import Img4 from "../../../assets/icons/adminIcons/4.png";
import Img5 from "../../../assets/icons/adminIcons/5.png";
// import Img6 from "../../../assets/icons/adminIcons/6.png";
import Img7 from "../../../assets/icons/adminIcons/7.png";
import Img8 from "../../../assets/icons/adminIcons/8.png";
import Img9 from "../../../assets/icons/adminIcons/9.png";
import Img10 from "../../../assets/icons/adminIcons/10.png";
import Img11 from "../../../assets/icons/adminIcons/11.png";

import "./style.scss";

const HomeAdmin = ({ setDashboardRoute, componentID, getAdminSeedDashboard, getAdminDashboardStats, adminDashboardStats, isLoading }) => {
  useEffect(() => {
    setDashboardRoute(componentID);
    getAdminSeedDashboard();
    getAdminDashboardStats();
    // eslint-disable-next-line
  }, []);

  return (
    <div className="adminHome">
      <DashboardHeading headingStrong={"Home"} showBtn={false} />
      <div className="cardContainer">
        {isLoading ? (
          <Loader />
        ) : (
          <>
            <AdminCard
              heading={"ALL TESTERS"}
              icon={Img4}
              number={adminDashboardStats.totalTesters}
              percent={adminDashboardStats?.testersDiff || 0}
              color={adminDashboardStats.testersDiff >= 0 ? "green" : "red"}
            />
            <AdminCard
              heading={"TOTAL BUGS ADDED"}
              icon={Img1}
              number={adminDashboardStats.totalBugs}
              percent={adminDashboardStats?.bugDiff || 0}
              color={adminDashboardStats.bugDiff >= 0 ? "green" : "red"}
            />
            <AdminCard
              heading={"BUGS APPROVED"}
              icon={Img2}
              number={adminDashboardStats.totalApprovedBugs}
              percent={adminDashboardStats?.bugApprovedDiff  || 0}
              color={adminDashboardStats.bugApprovedDiff >= 0 ? "green" : "red"}
            />
            <AdminCard
              heading={"BUGS DECLINED"}
              icon={Img3}
              number={adminDashboardStats.totalRejectedBugs || 0}
              percent={adminDashboardStats?.bugRejectedBugsDif || 0}
              color={adminDashboardStats.bugRejectedBugsDiff >= 0 ? "green" : "red"}
            />

            <AdminCard
              heading={"TOTAL REVENUE"}
              icon={Img10}
              number={adminDashboardStats.totalPaymentRevenue}
              percent={adminDashboardStats?.paymentRevenueDiff || 0}
              color={adminDashboardStats.paymentRevenueDiff >= 0 ? "green" : "red"}
            />
            <AdminCard
              heading={"TOTAL PAYMENT DUE"}
              icon={Img5}
              number={adminDashboardStats?.totalPaymentDue?.toFixed(2) || 0}
              percent={adminDashboardStats?.paymentdueDiff || 0}
              color={adminDashboardStats.paymentdueDiff >= 0 ? "green" : "red"}
            />
            <AdminCard
              heading={"TOTAL PAYMENT REQUEST"}
              icon={Img11}
              number={adminDashboardStats.totalPaymentRequest}
              percent={adminDashboardStats?.paymentRequestsDiff || 0}
              color={adminDashboardStats.paymentRequestsDiff >= 0 ? "green" : "red"}
            />

            <AdminCard
              heading={"MANAGE ALL CLIENTS"}
              icon={Img7}
              number={adminDashboardStats.totalClients}
              percent={adminDashboardStats?.clientsDiff || 0}
              color={adminDashboardStats.clientsDiff >= 0 ? "green" : "red"}
            />
            <AdminCard
              heading={"TOTAL PROJECTS"}
              icon={Img8}
              number={adminDashboardStats.totalProjects}
              percent={adminDashboardStats?.projectsDiff || 0}
              color={adminDashboardStats.projectsDiff >= 0 ? "green" : "red"}
            />
            <AdminCard
              heading={"TOTAL ACTIVE PROJECTS"}
              icon={Img9}
              number={`${adminDashboardStats.totalActiveProjects} Active`}
              percent={adminDashboardStats?.acttiveProjectsDiff || 0}
              color={adminDashboardStats.acttiveProjectsDiff >= 0 ? "green" : "red"}
            />
          </>
        )}
      </div>
    </div>
  );
};

const mapStateToProps = ({ adminDashboard }) => ({
  adminDashboardStats: adminDashboard.adminDashboardStats,
  isLoading: adminDashboard.isLoading,
});

export default connect(mapStateToProps, { setDashboardRoute, getAdminSeedDashboard, getAdminDashboardStats })(HomeAdmin);
