import React from "react";
//import DashTester from "../../../assets/images/DashboardTester.png";
//import DashClient from "../../../assets/images/DashboardClient.png";
import TestCreation from "../../../assets/icons/888034.png";
import TestExecution from "../../../assets/icons/945529.png";
import TestResults from "../../../assets/icons/944053.png";
//import EarnMoney from "../../../assets/icons/money.png";
//import TimeFlexibility from "../../../assets/icons/XMLID_1031_.png";
//import LatestFeatures from "../../../assets/icons/new-product.png"
import { useHistory } from "react-router-dom";

import "./style.scss";

function AdditionalInfoLanding({ data = {} }) {
  const history = useHistory();
  return (
    <div className="additionalinfo bg-light">
      <div className="home_additionalinfo_flex_first">
        <div className="home_additionalinfo_first_div">
          <h4>{data.headingA}</h4>
          {/* <div className="home_additionalinfo_second_div">{data.descriptionA}</div> */}
          <div className="howItWorks" >
            <div className="howItWorks-item" >
              <div>
                <img src={TestCreation} alt="Upload your app" />
              </div>
              <div>
                <h4>Upload App</h4>
                <p>Upload your mobile app or provide your website’s URL. We distribute it securely to the right testers.</p>
              </div>
            </div>
            <div className="howItWorks-item" >
              <div>
                <img src={TestExecution} alt="Upload your app" />
              </div>
              <div>
                <h4>Start a Test Run</h4>
                <p>Schedule the test. The first results will arrive in as soon as 1 hour.</p>
              </div>
            </div>
            <div className="howItWorks-item" >
              <div>
                <img src={TestResults} alt="Upload your app" />
              </div>
              <div>
                <h4>View Bug Reports</h4>
                <p>Review detailed bug reports with screenshots and videos. Drill into user insights and reviews.</p>
              </div>
            </div>
          </div>
          <div className="glb-btn home_additionalinfo_third_div" onClick={() => history.push("/signup/setup")}>
            {data.btnText}
          </div>
        </div>
        {/* <div className="home_additionalinfo_fourth_div">
          <img src={DashClient} alt="Tester Dashboard" />
        </div> */}
      </div>
      {/* <div className="home_additionalinfo_flex_second">
       
      </div> */}
    </div>
  );
}

export default AdditionalInfoLanding;
