import React, { useEffect } from "react";
import { connect } from "react-redux";
import exportFromJSON from "export-from-json";

import { setDashboardRoute } from "../../../redux/Routing/actions";
import { getAdminAllEarnings ,getFinanceReport } from "../../../redux/AdminDashboard/actions";

import DashboardHeading from "../../Common/DashboardHeading";
import ManageAllEarningsTable from "../../Common/DashboardTables/ManageAllEarnings"

const ManageEarnings = ({ setDashboardRoute, componentID, getAdminAllEarnings, allEarnings ,getFinanceReport ,financeReportData }) => {

  useEffect(() => {
    setDashboardRoute(componentID);
    getAdminAllEarnings();
    getFinanceReport();
    // eslint-disable-next-line
  }, []);

  const exportFinanceEarning = () => {
    let finalData = [];

    financeReportData.forEach(item=>{

      finalData.push({
          name:item.name || "",
          id:item.id || "",
         //  bank details added
         accountNumber:item?.bankDetails[0]?.accountNumber || "" ,
         IFSC:item?.bankDetails[0]?.bankDetail['IFSC'] || "" ,
         MICR:item?.bankDetails[0]?.bankDetail['MICR'] || "" ,
         Bank:item?.bankDetails[0]?.bankDetail['BANK'] || "" ,
         BRANCH:item?.bankDetails[0]?.bankDetail['BRANCH'] || "" ,
         Bank_City:item?.bankDetails[0]?.bankDetail['CITY'] || "" ,
       //   Bank details over
        City : item?.city?.name || "" ,
        country: item?.country?.name || "" ,
        "paid(USD)": item?.paid ?? item?.paid,
        pendingPayment: item?.pendingPayment ?? item.pendingPayment ,
        PanCard : item?.panCard ? item?.panCard : ""
      })
  })
    
    const fileName = "Finance Report";
    const exportType = "xls";
     const data = finalData;

     exportFromJSON({ data, fileName, exportType });
  }

  return (
    <div className="allTesters">
      <DashboardHeading headingStrong={"Manage All Earnings"} showBtn={false} />
      {financeReportData.length >0 &&
        <div className="buttonContainer">
        <button onClick={exportFinanceEarning} className='glb-btn glb-action-btn'>
          Export Finance Data
          </button>
      </div>
      }
      <ManageAllEarningsTable manageEarnings={allEarnings}/>
    </div>
  );
};
const mapStateToProps = ({ adminDashboard }) => ({
  allEarnings: adminDashboard.adminAllEarnings,
  isLoading: adminDashboard.isLoading,
  financeReportData:adminDashboard.financeReportData
});

export default connect(mapStateToProps, { getAdminAllEarnings, setDashboardRoute ,getFinanceReport })(ManageEarnings);