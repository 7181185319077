import React from "react";

// import Loader from "../../Loader";

import PlatformPicker from "../../Common/PlatformPicker";

import "./style.scss";
const SelectDevice = ({ setPlatformId, platformId, setPlatformFilter, platformFilter, nextStep }) => {
  const handleContinue = () => {
    // if (platformId === "") setPlatformId(platformFilter.id);
    nextStep();
  };
  return (
    <div className="device-select-container">
      <PlatformPicker
        setPlatform={setPlatformId}
        platformId={platformId}
        setPlatformFilter={platform => setPlatformFilter(platform)}
        platformFilter={platformFilter}
      />
      <button
        className={!platformFilter.id && platformId === "" && !platformId ? "next-step-btn disabled" : "next-step-btn"}
        onClick={!platformFilter.id && platformId === "" ? null : () => handleContinue()}
      >
        Select a platform and continue
      </button>
    </div>
  );
};

export default SelectDevice;
