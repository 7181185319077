import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { useHistory } from "react-router-dom";
import axios from "axios";

import { setDashboardRoute } from "../../../redux/Routing/actions";
import { changePassword, updateProfile, updateProfileWithoutImage, deleteTesterAccount, removeProfilePicture } from "../../../redux/TesterDashboard/actions";
import { generateReferralCode } from "../../../redux/auth/actions";
import baseDomain, { checkIFSCReq, getUserBanksReq } from "../../../constants/apiRoutes";
import { getStaticCountries, getStaticStates } from "../../../redux/staticData/actions";

import DashboardHeading from "../../Common/DashboardHeading";
import DashboardSubHeading from "../../Common/DashboardSubHeading";
import Dropdown from "../../Common/Dropdown";
import TesterBankTable from "../../Common/DashboardTables/TesterBankTable";
import BadgeDetailsTable from "../../Common/DashboardTables/BadgeDetailsTable";
import settings from "../../../constants/settings";
import ProfilePic from "../../../assets/images/profilePic.jpg";
import { PasswordViewer } from "../../../utils/passwordVisibilityToggle";

import { setAlert } from "../../../redux/Alerts/actions";
import "./style.scss";
import "../../SignupClient/style.scss";
import Loader from "../../Loader";
import Modal from "../Earnings/Components/TransferAmount/Modal";
import { eyeSign } from "../../../assets/icons/icons";
import Testimonials from "./Testimonials";

const Settings = ({
  setDashboardRoute,
  componentID,
  changePassword,
  getStaticCountries,
  countriesList,
  statesList,
  getStaticStates,
  updateProfile,
  updateProfileWithoutImage,
  user,
  setAlert,
  role,
  generateReferralCode,
  removeProfilePicture,
}) => {
  const [passwordReset, setPasswordReset] = useState({
    oldPassword: "",
    newPassword: "",
    newDupPassword: "",
  });
  const history = useHistory();
  const [isLoading, setIsLoading] = useState(true);
  const [testerBanks, setTesterBanks] = useState([]);
  const [isAccountMatch, setIsAccountMatch] = useState(true);
  const [showModal, setShowModal] = useState(false);
  const [ifscData, setIfscData] = useState({});
  const [userImage, setUserImage] = useState(user.profilePic || ProfilePic);
  const [imageChanged, setImageChanged] = useState(false);
  const [currentUserImg, setCurrentUserImg] = useState(userImage);
  const [isAdmin, setIsAdmin] = useState(false);
  const [isTooltip, setTooltip] = useState(false);
  // const [checkTelegramBox, setCheckTelegramBox] = useState(false);
  const [form, setForm] = useState({
    name: user.name,
    domain: user.position,
    email: user.email,
    phone: user.phone,
    pancard: user.pancard,
    country: user.country.id,
    state: user.state && user.state.id,
    age: user.age,
    gender:user.gender
  });
  const [formData, setFormData] = useState({
    name: "",
    accountNumber: "",
    accountNumberRep: "",
    ifsc: "",
  });
  const [showpassword, setShowPassword] = useState(false);
  const [shownewpassword, setShowNewPassword] = useState(false);
  const [showCnfpassword, setShowCnfPassword] = useState(false);
  const { name, accountNumber, ifsc, accountNumberRep } = formData;

  const [changeCountry, setChangeCountry] = useState(false);
  const [disable,setDisable]=useState(true);

  const [step, setStep] = useState(0);

  const { oldPassword, newPassword, newDupPassword } = passwordReset;

  useEffect(() => {
    if (role === "TESTER" || role === "REVIEWER") getInitailData();
    role === "ADMIN" && setIsAdmin(true);
    // eslint-disable-next-line
  }, []);

  const viewProfile = () => setStep(0);

  const changePasswordBtn = () => setStep(1);

  const addBankDetails = () => setStep(2);

  const giveTestimonial = () => setStep(3);

  const badgeDetails = () => setStep(4);

  const handleGeneratingReferralCode = userId => {
    userId ? generateReferralCode(userId) : setAlert({ message: "userId incomplete", type: "ERROR" });
  };
  const handleClipBoardCopy = () => {
    const codeToCopy = document.getElementById("codeToCopy");
    codeToCopy.focus();
    codeToCopy.select();
    document.execCommand("Copy");
    codeToCopy.blur();
  };

  const getInitailData = async () => {
    setIsLoading(true);
    try {
      const { data } = await axios.get(`${baseDomain}${getUserBanksReq}`);
      setTesterBanks(data.data);
      setIsLoading(false);
    } catch (error) {
      setAlert({ message: error.response.data.message || "Unable to transfer amount try later", type: "ERROR" });
      history.push("/dashboard/earning/total");
      setIsLoading(false);
    }
  };

  const handelSubmit = async e => {
    e.preventDefault();
    if (ifsc.length === 11) {
      if (formData.accountNumber === formData.accountNumberRep) {
        try {
          const { data } = await axios.get(`${baseDomain}${checkIFSCReq}/${ifsc}`);
          setIfscData(data.data);
          setShowModal(true);
        } catch (error) {
          setAlert({ message: error.response.data.message || settings.transferamount.error1, type: "ERROR" });
        }
      } else {
        setAlert({ message: "Account Number does not match", type: "ERROR" });
      }
    } else {
      setAlert({ message: settings.transferamount.warning1, type: "WARNING" });
    }
  };

  const handelChange = e => {
    const { name, value } = e.target;
    if (name === "ifsc")
      setFormData({
        ...formData,
        [name]: value.toUpperCase(),
      });
    else if ((name === "accountNumber" || name === "accountNumberRep") && value === " ")
      return setAlert({ message: "Account Number can't have space", type: "WARNING" });
    else
      setFormData({
        ...formData,
        [name]: value,
      });
  };

  useEffect(() => {
    setDashboardRoute(componentID);
    getStaticCountries();
    // eslint-disable-next-line
  }, []);

  const handelChangePassword = e => {
    e.preventDefault();
    if (newPassword !== newDupPassword) setAlert({ message: "New Password and Confirm Password did not match.", type: "WARNING" });
    else if (newPassword.length < 8) setAlert({ message: "Password must have atleast 8 characters.", type: "WARNING" });
    else changePassword(passwordReset);
  };

  const handelUpdateProfile = e => {
    e.preventDefault();
    if (form.state === "") setAlert({ message: "Please select a state.", type: "WARNING" });
    else if (form.name === "") setAlert({ message: "Please enter a Name.", type: "WARNING" });
    else if (form.domain === "") setAlert({ message: "Please enter a Domain.", type: "WARNING" });
    else if (form.email === "") setAlert({ message: "Please enter a Email Id.", type: "WARNING" });
    else if (form.phone === "") setAlert({ message: "Please enter a Mobile No.", type: "WARNING" });
    else if(form.age === "") setAlert({ message: "Please enter your age", type: "WARNING" });
    else if(form.gender === "") setAlert({ message: "Please choose your gender", type: "WARNING" });
    else imageChanged ? updateProfile(form, userImage) : updateProfileWithoutImage(form);
  };

  const handlePasswordChange = e => {
    setPasswordReset({
      ...passwordReset,
      [e.target.name]: e.target.value,
    });
  };

  const myfunc = e => {
    setForm({ ...form, country: e.target.value, state: "" });
    getStaticStates(e.target.value);
    setChangeCountry(true);
  };

  const handleImg = e => {
    const reader = new FileReader();
    reader.onload = () => {
      if (reader.readyState === 2) {
        setCurrentUserImg(reader.result);
        setUserImage(e.target.files[0]);
        setImageChanged(true);
      }
    };
    reader.readAsDataURL(e.target.files[0]);
  };

  const handleTooltip = () => {
    setTooltip(true);
    setTimeout(() => {
      setTooltip(false);
    }, 1000);
  };

  const tooltipStyle = {
    visibility: "visible",
  };

  useEffect(() => {
    setCurrentUserImg(user.profilePic);
  }, [user.profilePic]);

  const handleRemoveProfilePicture = () => {
    removeProfilePicture(user.userId);
    setCurrentUserImg(null);
  };

  return (
    <div className="client-dash-settings">
      <div className="settings-leaderboard">
        <DashboardHeading heading={settings.testersettings.heading1.light} headingStrong={settings.testersettings.heading1.bold} showBtn={false} />
      </div>
      <div className="tab-buttons" style={{ display: "flex", gap: "20px" }}>
        <button className={step === 0 ? "view-profile border" : "view-profile"} onClick={viewProfile}>
          View Profile
        </button>
        <button className={step === 1 ? "change-password border" : "change-password"} onClick={changePasswordBtn}>
          Change Password
        </button>
        {(role === "TESTER" || role === "REVIEWER") && (
          <button className={step === 2 ? "add-bank-details border" : "add-bank-details"} onClick={addBankDetails}>
            View Bank Details
          </button>
        )}
        {(role === "TESTER" || role === "REVIEWER") && (
          <button className={step === 3 ? "give-testimonial border" : "give-testimonial"} onClick={giveTestimonial}>
            Give Testimonial
          </button>
        )}
       { (role === "TESTER" || role === "REVIEWER") && ( <button className={step === 4 ? "badge-details border" : "badge-details"} onClick={badgeDetails}>
          Badge Details
        </button> )}
      </div>

      {/* Profile Settings  */}

      {step === 0 && (
        <div className="settings-leaderboard">
          <DashboardHeading heading={settings.testersettings.heading5.bold} showBtn={false} />
        </div>
      )}
      {step === 0 && role === "TESTER" && (
        <div id="referral-code" className="settings-leaderboard" style={{ display: "inline-block" }}>
          {!isAdmin && !user?.refferalCode ? (
            <button
              className="genReferr"
              onClick={() => {
                handleGeneratingReferralCode(user.userId);
              }}
            >
              Generate Referral Code
            </button>
          ) : (
            <h5 style={{ textAlign: "left" }}> Referral Code</h5>
          )}

          {user?.refferalCode && (
            <div id="userReferralCode">
              <input id="codeToCopy" value={user.refferalCode} />
              <span
                className="copyIcon"
                onClick={() => {
                  handleClipBoardCopy(user.refferalCode);
                }}
              >
                <img onClick={handleTooltip} src="https://img.icons8.com/ios-glyphs/30/000000/copy.png" alt="copyCode" />
                <div style={isTooltip ? tooltipStyle : {}} className="tooltip-text">
                  Referral code copied!
                </div>
              </span>
            </div>
          )}
        </div>
      )}
      {step === 0 && (
        <form onSubmit={handelUpdateProfile}>
          <div className="change-password-container">
            <label>{settings.testersettings.profileimg}</label>
            <div className="form-group img-field">
              <div className="img-circle">
                <img alt="user" src={currentUserImg ? currentUserImg : ProfilePic} width="100px" height="100px" style={{ borderRadius: "50%" }} />
                {currentUserImg && (
                  <div className="removeProfilePicture" onClick={handleRemoveProfilePicture}>
                    DELETE
                  </div>
                )}
              </div>
              <br />
              <label htmlFor="user-image" className="editProfilePicture">
                EDIT
              </label>

              <input type="file" id="user-image" className="image-input" onChange={handleImg} accept="image/*" name="file" />
              <small className={"hide"}></small>
              <br />
              <br />
              <label style={user?.refferalCode ? { visibility: "visible" } : { visibility: "hidden" }} className="user-image-label">
                {user?.refferalCode ? user?.refferalCode : "User ID"}
              </label>
            </div>

            <div className="field1">
              <div className="input-field">
                <label>Your Name</label>
                <input type="name" width="big" value={form.name} onChange={e => setForm({ ...form, name: e.target.value })} />
              </div>
            </div>

            <div className="field1">
              <div className="input-field">
                <label>Domain</label>
                <input type="name" width="big" value={form.domain} onChange={e => setForm({ ...form, domain: e.target.value })} />
              </div>
            </div>

            <div className="field1">
              <div className="input-field">
                <label>Email Id</label>
                <input type="name" width="big" value={form.email} onChange={e => setForm({ ...form, email: e.target.value })} />
              </div>
            </div>

            <div className="field1">
              <div className="input-field">
                <label>Mobile No.</label>
                <input type="number" width="big" value={form.phone} onChange={e => setForm({ ...form, phone: e.target.value })} />
              </div>
            </div>

            <div className="field1">
              <div className="input-field">
                <label>Pancard No.</label>
                <input type="text" width="big" value={form.pancard} onChange={e => setForm({ ...form, pancard: e.target.value })} />
              </div>
            </div>

            <div className="same-row">
              <div className="country-list field1">
                <div className="input-field">
                  <label>Country</label>
                  <select
                    className="input-select"
                    id="countryList"
                    value={form.country}
                    onChange={(e, country) => myfunc(e, country)}
                    name="country"
                    disabled={countriesList.length === 0}
                  >
                    {user.country ? <option value={user.country.id}>{user.country.name}</option> : <option value="">Choose Country</option>}
                    {countriesList &&
                      countriesList.map((cont, i) => (
                        <option value={cont.id} key={i}>
                          {cont.name}
                        </option>
                      ))}
                  </select>
                </div>
              </div>
              {user.state && (
                <div className="country-list field1">
                  <div className="input-field">
                    <label>State</label>
                    <select
                      className="input-select"
                      id="stateList"
                      value={form.state}
                      onChange={e => setForm({ ...form, state: e.target.value })}
                      name="state"
                      disabled={countriesList.length === 0}
                    >
                      {!changeCountry && user.state ? <option value={user.state.id}>{user.state.name}</option> : <option value={null}>Choose State</option>}
                      {statesList &&
                        statesList.map((cont, i) => (
                          <option value={cont.id} key={i}>
                            {cont.name}
                          </option>
                        ))}
                    </select>
                  </div>
                </div>
              )}            
            </div>
           <div className="same-row" >
           {
                user && (
                  <div className="field1" >
                    <div className="input-field">
                    <label>Age</label>
                    <input type="number"  value={form.age} onChange={e => setForm({ ...form, age: e.target.value })} />
                  </div>
                  </div>
                )
              }
                         {
                user && (
                  <div className=" country-list field1" >
                    <div className="input-field">
                    <label>Gender</label>
                    <select value={form.gender} className="input-select" name="gender" required onClick={()=>{setDisable(false)}} onChange={e => setForm({...form,gender:e.target.value})}>
                    <option value="" disabled={disable ? false:true} >Select Gender</option>
                    <option value="Male" >Male</option>
                    <option value="Female" >Female</option>
                    <option value="Other" >Other</option>
           </select>
                  </div>
                  </div>
                )
              }
           </div>
            <button className="glb-btn submit-btn" type="submit">
              Save Profile
            </button>
          </div>
        </form>
      )}

      {/* Password Change Settings */}

      {step === 1 && (
        <div className="settings-leaderboard-1">
          <DashboardSubHeading heading={settings.testersettings.change_password.heading2} showBtn={false} />
        </div>
      )}
      {step === 1 && (
        <form onSubmit={handelChangePassword}>
          <div className="change-password-container">
            <div className="settings-change-password">
              <div className="input-field" id="old-password">
                <label>{settings.testersettings.change_password.currpassword}</label>
                <input
                  type={showpassword ? "text" : "password"}
                  width="big"
                  required
                  value={oldPassword}
                  name="oldPassword"
                  onChange={e => handlePasswordChange(e)}
                />
                <svg
                  onMouseOver={() => {
                    PasswordViewer(showpassword, setShowPassword);
                  }}
                  onMouseOut={() => {
                    PasswordViewer(showpassword, setShowPassword);
                  }}
                  onTouchStart={() => {
                    PasswordViewer(showpassword, setShowPassword);
                  }}
                  onTouchEnd={() => {
                    PasswordViewer(showpassword, setShowPassword);
                  }}
                  className="password-show-hide"
                  fill={showpassword ? "#646464" : "#bbbbbb"}
                  width="24"
                  height="24"
                  xmlns="http://www.w3.org/2000/svg"
                  fill-rule="evenodd"
                >
                  {eyeSign}
                </svg>
              </div>
            </div>
            <div className="same-row">
              <div className="field1">
                <div className="input-field">
                  <label>{settings.testersettings.change_password.newpassword}</label>
                  <input type={shownewpassword ? "text" : "password"} width="small" required value={newPassword} name="newPassword" onChange={handlePasswordChange} />
                  <svg
                    onMouseOver={() => {
                      PasswordViewer(shownewpassword, setShowNewPassword);
                    }}
                    onMouseOut={() => {
                      PasswordViewer(shownewpassword, setShowNewPassword);
                    }}
                    onTouchStart={() => {
                      PasswordViewer(shownewpassword, setShowNewPassword);
                    }}
                    onTouchEnd={() => {
                      PasswordViewer(shownewpassword, setShowNewPassword);
                    }}
                    className="password-show-hide"
                    fill={shownewpassword ? "#646464" : "#bbbbbb"}
                    width="24"
                    height="24"
                    xmlns="http://www.w3.org/2000/svg"
                    fill-rule="evenodd"
                  >
                    {eyeSign}
                  </svg>
                </div>
              </div>
              <div className="field2">
                <div className="input-field">
                  <label>{settings.testersettings.change_password.repeatpassword}</label>
                  <input
                    type={showCnfpassword ? "text" : "password"}
                    width="small"
                    required
                    value={newDupPassword}
                    name="newDupPassword"
                    onChange={e => handlePasswordChange(e)}
                  />
                  <svg
                    onMouseOver={() => {
                      PasswordViewer(showCnfpassword, setShowCnfPassword);
                    }}
                    onMouseOut={() => {
                      PasswordViewer(showCnfpassword, setShowCnfPassword);
                    }}
                    onTouchStart={() => {
                      PasswordViewer(showCnfpassword, setShowCnfPassword);
                    }}
                    onTouchEnd={() => {
                      PasswordViewer(showCnfpassword, setShowCnfPassword);
                    }}
                    className="password-show-hide"
                    fill={showCnfpassword ? "#646464" : "#bbbbbb"}
                    width="24"
                    height="24"
                    xmlns="http://www.w3.org/2000/svg"
                    fill-rule="evenodd"
                  >
                    {eyeSign}
                  </svg>
                </div>
              </div>
            </div>

            <button className="glb-btn submit-btn" type="submit">
              {settings.testersettings.change_password.btnText1}
            </button>
          </div>
        </form>
      )}
      <div className="LangNTimezone">
        <div className="same-row-1">
          <DashboardSubHeading heading={settings.testersettings.heading3} showBtn={false} />
          <Dropdown placeholder={settings.testersettings.placeholder} OptionListArray={settings.testersettings.LanguageList} />
        </div>
        <br />
        <div className="same-row-1">
          <DashboardSubHeading heading={settings.testersettings.heading4} showBtn={false} />
          <Dropdown placeholder={settings.testersettings.placeholder} OptionListArray={settings.testersettings.TimezoneList} />
        </div>
      </div>

      <div className="savaBtn">
        <button className="glb-btn" type="submit">
          {settings.testersettings.btnText2}
        </button>
      </div>

      {/* Bank Details */}
      {step === 2 && (role === "TESTER" || role === "REVIEWER") && (
        <>
          <Modal
            setAlert={setAlert}
            showModal={showModal}
            ifscData={ifscData}
            userDetails={formData}
            closeModal={() => {
              setShowModal(false);
              getInitailData();
              setFormData({
                name: "",
                accountNumber: "",
                accountNumberRep: "",
                ifsc: "",
              });
            }}
          />

          <div className="tester-bank-table">
            <DashboardSubHeading heading={settings.transferamount.heading2.light} headingStrong={settings.transferamount.heading2.bold} showBtn={false} />
            {isLoading ? (
              <Loader />
            ) : (
              <div className="card">
                <TesterBankTable bankDetailsArray={testerBanks} getInitailData={getInitailData} deleteBankTable={deleteTesterAccount} showItem={true} />
              </div>
            )}
          </div>

          <form onSubmit={handelSubmit}>
            <DashboardSubHeading heading={settings.transferamount.bank_details.heading3} headingStrong={""} showBtn={false} />
            <div className="details">
              <div className="bank-name">
                <div className="label-error">
                  <label>{settings.transferamount.bank_details.account}</label>
                  <small className={!isAccountMatch ? "show-error" : "hide"}>Account Number is not matched</small>
                </div>
                <input
                  className="bank"
                  type="password"
                  placeholder={settings.transferamount.bank_details.account_no}
                  name="accountNumber"
                  value={accountNumber}
                  onChange={handelChange}
                  onBlur={() => {
                    if (accountNumberRep && accountNumberRep.length !== 0) {
                      if (accountNumber !== accountNumberRep) setIsAccountMatch(false);
                      else setIsAccountMatch(true);
                    }
                  }}
                  required
                />
              </div>
              <div className="bank-name">
                <label>Confirm Account Number</label>
                <input
                  className="bank"
                  type="text"
                  placeholder={settings.transferamount.bank_details.account_no}
                  name="accountNumberRep"
                  value={accountNumberRep}
                  onChange={handelChange}
                  onBlur={() => {
                    if (accountNumber !== accountNumberRep) setIsAccountMatch(false);
                    else setIsAccountMatch(true);
                  }}
                  required
                />
              </div>

              <div className="ifsc-code">
                <label>{settings.transferamount.bank_details.ifsc}</label>
                <input
                  className="bank"
                  type="text"
                  placeholder={settings.transferamount.bank_details.ifsc_plcholder}
                  name="ifsc"
                  value={ifsc}
                  onChange={handelChange}
                  required
                />
              </div>
              <div className="add-name">
                <label>{settings.transferamount.bank_details.name}</label>
                <input
                  className="name"
                  type="text"
                  placeholder={settings.transferamount.bank_details.placeholder}
                  name="name"
                  value={name}
                  onChange={handelChange}
                  required
                />
              </div>
            </div>

            <button className="glb-btn submit-button" type="submit">
              {settings.transferamount.btnText}
            </button>
          </form>
        </>
      )}
      <br />
      {step === 3 && role === "TESTER" && (
        <div>
          <DashboardSubHeading heading={settings.testimonials.heading1} headingStrong={""} showBtn={false} />
          <Testimonials />
        </div>
      )}
      {step === 4 && (
        <div className="settings-leaderboard-1">
          <DashboardSubHeading heading="Badge Details" showBtn={false} />
          <div style={{display:"flex",alignItems:"flex-start",gap:"30px",marginTop:"30px"}}>
            <div style={{width:"110px",display:"flex",flexDirection:"column",alignItems:"center",justifyContent:"center"}}>
              <label>Current Badge</label>
              <div style={{marginTop:"20px"}}>
                {user.badge?.currentBadge?.logo ?
                (<img src={user.badge?.currentBadge?.logo} alt={user.badge?.currentBadge?.name} width="60px" height="60px" />)
                : "No Badge"}
              </div>
            </div>
            <div style={{width:"110px",display:"flex",flexDirection:"column",alignItems:"center",justifyContent:"center"}}>
              <label>Current Score</label>
              <div style={{marginTop:"50px"}}>
                {user.badge ? user.badge.currentScore : "0"}
              </div>
            </div>
          </div>
          {isLoading ? (
              <Loader />
            ) : (
              <div className="card" style={{marginTop:"30px"}}>
                <BadgeDetailsTable tableContentArray={user.badgeHistory}/>
              </div>
            )}

<div className="pointsDescription" >
            <h2>How to earn points ?</h2>
          <ul>
            <li><div>REFER_AND_EARN:</div> <div>Refer your friends to join Qantily Community and earn <b>10 Points</b>.</div></li> 
            <br/>
            <li><div>BUG_REPORTED:</div> <div>On every bug report you'll earn <b>1 Point</b>.</div></li>
            <br/>
            <li><div>BUG_APPROVED:</div> <div>On every bug approval you'll earn <b>3 Points</b>.</div></li>
            <br/>
            <li><div>PROFILE_COMPLETION:</div> <div>Add your Bank Details and Complete your profile in the profie setting section and <b>10 Points</b> will be added into your account.</div></li>
            <br/>
            <li><div>TESTIMONIAL:</div> <div>We value our testers and we appreciate your time, do leave a testimonial &#40;which is under settings section&#41; and get <b>5 Points</b>.</div></li>
            <br/>
            <li><div>TURN_AROUND_TIME_4_HOURS:</div> <div>Report a bug within first 4 hours after project being published and earn <b>10 Points</b></div></li>
            <br/>
            <li><div>TURN_AROUND_TIME_24_HOURS:</div> <div>Report a bug within first 24 hours after project being published and earn <b>5 Points</b></div></li>
            <br/>
            <li><div>TURN_AROUND_TIME_4_HOURS_MULTIPLIER:</div> <div>Report a bug within first 4 hours after project being published and at the time of approval on the basis of the bug Severity, amount of your bug will be <b>2x</b>.</div></li>
            <br/>
            <li><div>TURN_AROUND_TIME_24_HOURS_MULTIPLIER:</div> <div>Report a bug within first 24 hours after project being published and at the time of approval on the basis of the bug Severity, amount of your bug will be <b>1.5x</b>.</div></li>
          </ul>
          </div>
        </div>
      )}
    </div>
  );
};

const mapStateToProps = ({ testerDashboard, staticData, auth }) => ({
  isLoading: testerDashboard.isLoading,
  countriesList: staticData.countries,
  statesList: staticData.states,
  user: auth.user,
  role: auth.role,
});

export default connect(mapStateToProps, {
  setDashboardRoute,
  changePassword,
  updateProfile,
  updateProfileWithoutImage,
  getStaticCountries,
  getStaticStates,
  setAlert,
  generateReferralCode,
  removeProfilePicture,
})(Settings);
