import React, { useEffect } from "react";
import { connect } from "react-redux";
import { useParams } from "react-router-dom";

import DashboardHeading from "../../../../Common/DashboardHeading";
import Loader from "../../../../Loader";
import { getSingleBugData } from "../../../../../redux/TesterDashboard/actions";
import settings from "../../../../../constants/settings";

import "./style.scss";

const SingleBug = ({ getSingleBugData, singleBug, isLoading }) => {
  const { bugID } = useParams();
  useEffect(() => {
    getSingleBugData(bugID);
    // eslint-disable-next-line
  }, []);

  return (
    <div className="completed-project-bug">
      {isLoading ? (
        <Loader />
      ) : (
        <>
          <div className="heading">
            <DashboardHeading heading={singleBug.project} headingStrong={settings.submitted_bug_details.heading} />
          </div>
          <div className="label">{settings.submitted_bug_details.summary}</div>
          <div className="completed-test-summary">{singleBug.summary}</div>
          <div className="label">{settings.submitted_bug_details.component}</div>
          <div className="completed-test-severity">{singleBug.component}</div>
          <div className="label">{settings.submitted_bug_details.priority}</div>
          <div className="completed-test-oem">{singleBug.priority}</div>
          <div className="label">{settings.submitted_bug_details.attachments}</div>
          <button className="glb-btn" onClick={() => window.open(singleBug.attachments[0], "_blank")}>
            {singleBug.attachments && singleBug.attachments.length === 0 ? "No file attached" : "View Attachment"}
          </button>
          <div className="label">{settings.submitted_bug_details.Describe}</div>
          <div className="completed-test-description">{singleBug.description}</div>
        </>
      )}
    </div>
  );
};

const mapStateToProps = ({ testerDashboard }) => ({
  singleBug: testerDashboard.singleBugData,
  isLoading: testerDashboard.isLoading,
});

export default connect(mapStateToProps, { getSingleBugData })(SingleBug);
