import React from 'react'
import "./style.scss"
const AccountSetupHeading = ({data={}}) => {
  return (
    <div className="account-setup-heading">
      <h1 className="heading">{data.heading}</h1>
      <p className="description">{data.descriptiona} <span className="highlight">{data.descriptionb}</span> {data.descriptionc}</p>
    </div>
  )
}

export default AccountSetupHeading
