import React from "react";

//import { isURLValidator } from "../../../../../utils/validation";

import settings from "../../../../../constants/settings";

import "./style.scss";
const Step3 = ({ formFiles, setFormFiles, formDataStepThird, handleChangeStepThree , pathname}) => {
  const { websiteLink, version, testingHour, stage } = formDataStepThird;
  const { knownBug, file } = formFiles;
  const manualCard = "/dashboard/addtests/addtests/manualCard"

  //const [isURL, setIsURL] = useState(true);

  const handleStepThree = e => {
    handleChangeStepThree(e); 
  }

  return (
    <div className="addtest-step3">
      <div className="form">
        <div className="form-heading">{settings.client_add_tests_step3.heading}</div>
        <div className="input-grid">
          {/* Upload Files */}
          <div className="upload-file grid">
            <p className="addtest-input-label">{settings.client_add_tests_step3.label1}</p>
            <label htmlFor="bug-list" className="green">
              {knownBug ? knownBug.name : settings.client_add_tests_step3.btnText1}
            </label>
            <input
              type="file"
              name="knownBug"
              id="bug-list"
              accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
              onChange={e => setFormFiles({ ...formFiles, knownBug: e.target.files[0] })}
            />
          </div>

          <div className="upload-file grid">
            <p className="addtest-input-label">{settings.client_add_tests_step3.label2}</p>
            <label htmlFor="files" className="blue">
              {file ? file.name : settings.client_add_tests_step3.btnText2}
            </label>
            <input type="file" name="file" id="files" onChange={e => setFormFiles({ ...formFiles, file: e.target.files[0] })} />
          </div>
          {/* Drop Down */}

          <div className="addtest-dropdown grid">
            <label>{settings.client_add_tests_step3.label3}</label>
            <select name="testingHour" id="man-hours" value={testingHour} onChange={handleStepThree}>
              <option value="24" selected>
                {settings.client_add_tests_step3.label3_option1}
              </option>
              <option value="48">{settings.client_add_tests_step3.label3_option2}</option>
              <option value="72">{settings.client_add_tests_step3.label3_option3}</option>
              <option value="96">{settings.client_add_tests_step3.label3_option4}</option>
              <option value="120">{settings.client_add_tests_step3.label3_option5}</option>
            </select>
          </div>
          <div className="addtest-dropdown grid ">
            <label>{settings.client_add_tests_step3.label4}</label>
            <select name="stage" id="test-stage" value={stage} onChange={handleStepThree}>
              <option value="dev" selected>
                {settings.client_add_tests_step3.label4_option1}
              </option>
              <option value="testing">{settings.client_add_tests_step3.label4_option2}</option>
              <option value="pre-eploy">{settings.client_add_tests_step3.label4_option3}</option>
              <option value="deployed">{settings.client_add_tests_step3.label4_option4}</option>
            </select>
          </div>
          {/* Input Field */}
          <div className="addtest-step3-input grid mt-1">
            <label>{settings.client_add_tests_step3.label7}{pathname===manualCard && `*`}</label>
            <input
              type="text"
              placeholder={settings.client_add_tests_step3.placeholder3}
              name="websiteLink"
              value={websiteLink}
              onChange={handleStepThree}
              onBlur={e => {
                //eslint-disable-next-line+
                // if (isURLValidator(e.target.value)) setIsURL(true);
                // else setIsURL(false);
              }}
            />
            {/* <small className={!isURL ? "show-error" : "hide"}>Enter valid URL</small> */}
          </div>
          <div className="addtest-step3-input grid mt-1">
            <label>{settings.client_add_tests_step3.label8}</label>
            <input
              type="text"
              placeholder={settings.client_add_tests_step3.placeholder4}
              name="version"
              value={version}
              onChange={handleStepThree}
            />
          </div>
          {/* <div className="form-heading m-0">Test Credentials</div>
          <div></div>
          <div className="addtest-step3-input grid">
            <label>{settings.client_add_tests_step3.label5}</label>
            <input
              type="text"
              placeholder={settings.client_add_tests_step3.placeholder1}
              name="loginId"
              value={loginId}
              onChange={handleStepThree}
            />
          </div>
          <div className="addtest-step3-input grid">
            <label>{settings.client_add_tests_step3.label6}</label>
            <input
              type="text"
              placeholder={settings.client_add_tests_step3.placeholder2}
              name="loginPassword"
              value={loginPassword}
              onChange={handleStepThree}   
            /> 
          </div> */}
        </div>
      </div>
    </div>
  );
};

export default Step3;
