import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { uploadFeedback } from "../../../redux/TesterDashboard/actions";
import { setDashboardRoute } from "../../../redux/Routing/actions";
import DashboardHeading from "../../Common/DashboardHeading";

import { setAlert } from "../../../redux/Alerts/actions";
import "./style.scss";

function FeedbackTester({ uploadFeedback, setDashboardRoute, componentID, setAlert }) {
  const [form, setForm] = useState({
    feedBack: "",
  });

  const handelUploadFeedback = e => {
    e.preventDefault();
    if (form.feedBack.trim() === "") {
      setAlert({ message: "Please give us a Feedback.", type: "WARNING" });
    } else {
      uploadFeedback(form);
      setForm({ feedBack: "" });
    }
  };

  useEffect(() => {
    setDashboardRoute(componentID);
    // eslint-disable-next-line
  }, []);

  return (
    <div className="feedback">
      <DashboardHeading heading="" headingStrong="Your Feedback" showBtn={false} />
      <form onSubmit={handelUploadFeedback}>
        <div className="label">Please submit your review :</div>
        <textarea type="text" className="feedback-summary" name="summary" value={form.feedBack} onChange={e => setForm({ feedBack: e.target.value })} />
        <button className="glb-btn submit-button" type="submit">
          Submit
        </button>
      </form>
    </div>
  );
}

export default connect(null, { uploadFeedback, setDashboardRoute, setAlert })(FeedbackTester);
