import React from "react";
import settings from "../../../constants/settings";
import "./style.scss";

function UserTestingLeaderboard({ leaderBoard }) {

  let sortedLeaderBoard = []

  sortedLeaderBoard = leaderBoard?.sort((a,b)=>{
    return b.earned - a.earned
  })

  return (
    <div className="usertesting">
      <div className="head">
        <div className="head-left">
          <span className="head-left-textstyle-1">{settings.user_testing_leaderboard.heading.light}</span>
          <strong className="head-left-textstyle-2" style={{textAlign:"center"}} >{settings.user_testing_leaderboard.heading.bold} {leaderBoard?.[0]?.isPrevious && <span>(Previous Month Leaderboard)</span>}</strong>
        </div>
        <div className="head-right"></div>
      </div>
      <div className="leaderboard-table">
        <table className="leaderboard-table-1">
          <colgroup>
            <col span="1" style={{ width: "80%" }} />
            <col span="1" style={{ width: "20%" }} />
          </colgroup>
          <tbody>
            <tr>
              <th className="leaderboard-table-heading">{settings.user_testing_leaderboard.name}</th>
              <th className="leaderboard-table-heading">{settings.user_testing_leaderboard.earnings}</th>
            </tr>
            {sortedLeaderBoard &&
              sortedLeaderBoard.map(({ name, earned }, i) => (
                <tr key={i}>
                  <td className="Bhupendra-Yadapalli">
                    0{i + 1}. {name}
                  </td>
                  <td className="earnings">$ {earned ? earned.toFixed(2) : 0 }</td>
                </tr>
              ))}
          </tbody>
        </table>
      </div>
    </div>
  );
}

export default UserTestingLeaderboard;
