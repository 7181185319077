import React from "react";

import { Star, CloseSign } from "../../../assets/icons/icons";
import { useAlertConfirmation } from "../../../Services/AlertService";
import { setAlert } from "../../../redux/Alerts/actions";
import "./style.scss";

function Skillset({ name, rating, deleteSkill, skillID }) {
  const fallBackAlert = useAlertConfirmation();
  const numStars = 5;
  // const rank = 3;
  
  const handleDelete = async() =>{
    try {
      await fallBackAlert({
        message: "Are you sure you want to cancel this Skill",
        type: "ERROR",
        successText: "Yes",
        cancelText: "No",
      });
      deleteSkill(skillID);
    } catch (error) {
      setAlert({
        message: "Error while cancelling project",
        type: "ERROR",
      });
    } 
  }
  return (
    <div className="skill-sets">
      <div className="skill-label-clear">
        <div className="skill-label">{name}</div>
        <div className="skill-clear" onClick={handleDelete}>
          {CloseSign}
        </div>
      </div>

      <div className="star-icons">
        {[...Array(numStars)].map((e, i) => (
          <div className={rating > i ? "single-star selected" : "single-star"} key={i}>
            {Star}
          </div>
        ))}
      </div>
    </div>
  );
}

export default Skillset;
