import React from "react";
import "./style.scss";
import settings from "../../../constants/settings";
import DefaultProfilePic from "../../../assets/images/profilePic.jpg";

const TesterDetailCard = ({ amount, totalTests, testSuccess, bugsMajor, firstName, lastName, profilePic, position }) => {
  return (
    <div className="tester-detail-card">
      <div className="image">
        <img src={profilePic || DefaultProfilePic} alt="" />
      </div>
      <div className="details">
        <h3>
          {firstName} <strong>{lastName}</strong>
        </h3>
        <small>{position}</small>
        <ul>
          <li>
            <strong>{testSuccess}</strong> {settings.tester_detail_card.successful_test}
          </li>
          <li>
            <strong>{totalTests}</strong> {settings.tester_detail_card.total_tests}
          </li>
          <li>
            <strong>{bugsMajor}</strong> {settings.tester_detail_card.bugs_detected}
          </li>
        </ul>
        <small>{settings.tester_detail_card.amt_earned}</small>
        <h2>{amount ? "$ " + amount.toFixed(2) : "$ 0"}</h2> 
      </div>
    </div>
  );
};

export default TesterDetailCard;
