import React, { Fragment, useState } from "react";
import settings from "../../constants/settings";
import backspace from "../../assets/icons/backspace.svg";
import Step2 from "../../assets/images/ProgressForm2.png";
import { googleLogo, linkedInLogoBlue } from "../../assets/icons/icons";

const Form2 = ({ data, signUp, form, setForm, countriesList, getStaticStates, statesList, isLoading, setAlert, title, setCounter, handleSocialSignUp }) => {
  const { phone, domain, age } = form;
  const [currentState, setCurrentState] = useState({});
  const [isPhoneNumber, setIsPhoneNumber] = useState(true);
  const [isValidDomain, setIsValidDomain] = useState(true);
  const [disable,setDisable]=useState(true);

  // const [testerImage,setTesterImage] = useState(null);
  const nextForm = e => {
    e.preventDefault();
    if (phone.length < 10 || phone < 0) setAlert({ message: "Enter valid phone number", type: "WARNING" });
    else if (domain.length < 3) setAlert({ message: "Enter valid domain", type: "WARNING" });
    else if(!age||age<16) setAlert({message: "Enter valid Age", type: "WARNING"});
    else if(disable === true) setAlert({message: "Please choose gender", type: "WARNING"});
    // else signUp(form,testerImage);
    else setCounter(2);
  };

  const prevForm = () => {
    setCounter(0);
  };

  const setInput = e => {
    let value = e.target.type === "checkbox" ? e.target.checked : e.target.value;
    const name = e.target.name;
    if (name === "phone" && value.length > 10) return setAlert({ message: "Please enter number of 10 digit only", type: "WARNING" });
    if (name === "name" || name === "domain") {
      value = value.replace(/[^A-Za-z ]/gi, "");
    }
    if (name === "city") {
      setForm({
        ...form,
        [name]: currentState.cities.filter(cty => cty.name === e.target.value)[0],
      });
    } else {
      setForm({
        ...form,
        [name]: value,
      });
    }
  };

  console.log(setCurrentState);
  return (
    <Fragment>
      <form onSubmit={nextForm}>
        <div className="social-logins">
          <button
            type="button"
            onClick={e => {
              handleSocialSignUp(e, "LINKEDIN");
            }}
            className="LinkedIn"
          >
            {linkedInLogoBlue} Sign-up With LinkedIn
          </button>
          <button
            type="button"
            onClick={e => {
              handleSocialSignUp(e, "GOOGLE");
            }}
            className="Google"
          >
            {googleLogo} Sign-up With Google
          </button>
        </div>

        <div className="progress-bar">
          <img src={Step2} alt="Step 1" width="60%" />
        </div>

        <div className="tester-details-form">
          <div className="details-input-fields">
            <label>{settings.signuptester.contactinfo}</label>
            <input
              type="number"
              className="input-text"
              placeholder={settings.signuptester.placeholder4}
              required
              name="phone"
              value={phone}
              onChange={setInput}
              onBlur={() => {
                if (phone.length < 10 || phone < 0) setIsPhoneNumber(false);
                else setIsPhoneNumber(true);
              }}
            />
            <small className={!isPhoneNumber ? "show-error" : "hide"}>Enter a valid phone number</small>
          </div>
          <div className="details-input-fields">
            <label>{settings.signuptester.education}</label>
            <input
              type="text"
              className="input-text"
              placeholder={settings.signuptester.placeholder3}
              name="domain"
              required
              value={domain}
              onChange={setInput}
              onBlur={() => {
                if (domain.length < 3) setIsValidDomain(false);
                else setIsValidDomain(true);
              }}
            />
            <small className={!isValidDomain ? "show-error" : "hide"}>Enter a valid domain</small>
          </div>
          <div className="details-input-fields">
            <label>Age</label>
            <input
              type="number"
              className="input-text"
              placeholder='Age'
              name="age"
              required
              value={age}
              onChange={setInput}
            />
          </div>
          <div className="details-input-fields" >
            <label>Gender</label>
           <select name="gender" required onClick={()=>{setDisable(false)}} onChange={setInput}>
             <option value="" disabled={disable ? false:true} >Select Gender</option>
             <option value="Male" >Male</option>
             <option value="Female" >Female</option>
             <option value="Other" >Other</option>
           </select>
          </div>
        </div>
        <div className="prev-next">
          <div onClick={prevForm} style={{ cursor: "pointer" }}>
            <img src={backspace} alt="<-" width="40px" />
          </div>
          <button className="glb-btn submit-form" type="submit">
            Next
          </button>
        </div>
      </form>
    </Fragment>
  );
};

export default Form2;
