import React, { useState, useEffect } from "react";

import { connect } from "react-redux";

import { createTesterDevices } from "../../../redux/TesterDashboard/actions";

import SelectDevice from "./SelectDevice";
import DeviceDetails from "./DeviceDetails";
import settings from "../../../constants/settings";
import { CloseSign } from "../../../assets/icons/icons";
import "../style.scss";

const AddDeviceModal = ({ showModal, toggleModal, createTesterDevices, currentStep = 1, deviceData = {}, platforms }) => {
  const [step, setStep] = useState(1);
  const [platformFilter, setPlatformFilter] = useState({});
  const [platformName, setPlatformName] = useState("");
  const [formData, setFormData] = useState({
    platformId: "",
  });
  const { platformId } = formData;

  useEffect(() => {
    if (currentStep === 2) {
      const temp = platforms.filter(item => item.id === (deviceData.platform && deviceData.platform.id))[0];
      const meta =
        temp &&
        temp.metadata &&
        temp.metadata.map(dta => {
          if (dta.key === "name") return { name: (deviceData && deviceData.name) || "" };
          else {
            const singleData = deviceData && deviceData.extra && deviceData.extra.filter(dt => dt.key === dta.key);
            if (singleData[0]) return { [singleData[0].key]: singleData[0].value };
            else return null;
          }
        });
      setFormData({
        ...formData,
        ...meta.reduce((obj, currData) => ({ ...obj, ...currData })),
        platformId: (deviceData.platform && deviceData.platform.id) || "",
      });
      setPlatformName((deviceData.platform && deviceData.platform.name) || "");
      setStep(currentStep);
    }
    // eslint-disable-next-line
  }, [currentStep]);

  const setPlatformId = (id, platformN) => {
    setFormData({
      ...formData,
      platformId: id,
    });
    setPlatformName(platformN);
  };

  const nextStep = () => {
    setStep(2);
  };

  const handelDeviceSubmittion = e => {
    e.preventDefault();
    let keys = Object.keys(formData);
    keys = keys.filter(item => item !== "name");
    keys = keys.filter(item => item !== "platformId");
    const obj = keys.map(key => ({ key, value: formData[key] }));
    createTesterDevices({
      name: formData.name,
      platformId: formData.platformId,
      extra: obj,
      ...(deviceData.deviceID && { deviceID: deviceData.deviceID }),
    });
    closeModal();
  };

  const closeModal = () => {
    setStep(1);
    setFormData({});
    toggleModal();
    setPlatformFilter({});
    setFormData({
      platformId: "",
    });
  };

  return (
    <div className={showModal ? "modal-container" : "none"}>
      <div className="modal">
        <div className="heading">
          <h2>{settings.modals.heading}</h2>
          <div className="close-icon" onClick={closeModal}>
            {CloseSign}
          </div>
        </div>
        <div className="body-container">
          <div className="body">
            {step === 1 && (
              <SelectDevice
                platformId={platformId}
                setPlatformId={(id, name) => setPlatformId(id, name)}
                setPlatformFilter={setPlatformFilter}
                platformFilter={platformFilter}
                nextStep={nextStep}
              />
            )}
            {step === 2 && (
              <DeviceDetails
                formData={formData}
                setFormData={setFormData}
                handelDeviceSubmittion={handelDeviceSubmittion}
                platformName={platformName}
                deviceID={deviceData.deviceID}
              />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = ({ staticData }) => ({
  platforms: staticData.platforms,
});

export default connect(mapStateToProps, { createTesterDevices })(AddDeviceModal);
