import React, { useEffect } from "react";
import { connect } from "react-redux";

import { setDashboardRoute } from "../../../redux/Routing/actions";
import { getFeedbacks } from "../../../redux/AdminDashboard/actions"; 

import DashboardHeading from "../../Common/DashboardHeading";
import AllFeadbacksTable from "../../Common/DashboardTables/AllFeadbacksTable";

const Feedbacks = ({ setDashboardRoute, componentID, getFeedbacks, feedbacks }) => {


  useEffect(() => {
    setDashboardRoute(componentID);
    getFeedbacks();
    // eslint-disable-next-line
  }, []);


  return (
    <div className="allTesters">
      <DashboardHeading headingStrong={"All Feedbacks"} showBtn={false} />
      <AllFeadbacksTable mnageEarnings={feedbacks}/>
    </div>
  );
};
const mapStateToProps = ({ adminDashboard }) => ({
  feedbacks: adminDashboard.feedbacks,
  isLoading: adminDashboard.isLoading,
});

export default connect(mapStateToProps, { setDashboardRoute, getFeedbacks })(Feedbacks);