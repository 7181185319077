import React from "react";
import HalfPageBackground from "../../components/Layout/HalfPageBackground";
import ChoosePlatformComp from "../../components/ChoosePlatform";

import { withLayout } from "../../layouts";

import "../../assets/sass/commonStyles.scss";

const ChoosePlatform = () => {
  return (
    <div className="halfPageBackground-container">
      <HalfPageBackground />
      <ChoosePlatformComp />
    </div>
  );
};

export default withLayout(ChoosePlatform, true, false);
