import React, { useState, useEffect } from "react";
import "./style.scss";

function EditBadgeModal({ data, setModal, showModal, handleSubmitEdit }) {
  const [form, setForm] = useState({
    score: "",
    badgeName: "",
    logo: "",
    badgeDescription: "",
    id: "",
  });

  const [badgeIcon, setBadgeIcon] = useState();

  useEffect(() => {
    setForm({
      ...form,
      score: data.score,
      badgeName: data.badgeName,
      logo: data.logo,
      badgeDescription: data.badgeDescription,
      id: data.id,
    });
    // eslint-disable-next-line
  }, [data]);

  const setInput = e => {
    setForm({
      ...form,
      [e.target.name]: e.target.value,
    });
  };

  const handleFakeSelect = () => {
    const input = document.getElementById("badgeIconInputEdit");
    input.click();
  };

  const closeModal = () => {
    setModal(false);
  };

  const handleSubmit = e => {
    e.preventDefault();
    handleSubmitEdit(form, badgeIcon);
  };
  return (
    <div className="editModal" style={showModal ? { display: "flex" } : { display: "none" }}>
      <div className="formModal" style={showModal ? { display: "flex" } : { display: "none" }}>
        <div className="formChildContainer">
          <button className="glb-btn" id="close-popUpEdit" onClick={closeModal}>
            &#215;
          </button>
          <h2 className="heading">Edit Badge</h2>

          <form id="Editbadges-form" onSubmit={handleSubmit}>
            <div className="inputFieldBox">
              <label>Badge Name</label>
              <br />
              <input value={form.badgeName} type="text" name="badgeName" onChange={setInput} />
            </div>
            <div className="inputFieldBox">
              <label>Score</label>
              <br />
              <input value={form.score} type="text" name="score" onChange={setInput} />
            </div>
            <div className="inputFieldBox">
              <label>Badge Descirption</label>
              <br />
              <input value={form.badgeDescription} type="text" name="badgeDescription" onChange={setInput} />
            </div>
            <div className="inputFieldBox" id="badgeIconContainer">
              <label onClick={handleFakeSelect} htmlFor="logo" className="badge-icon-label">
                {badgeIcon ? badgeIcon.name : "Add Badge Icon"}
              </label>
              <input
                id="badgeIconInputEdit"
                type="file"
                name="logo"
                className="badge-icon"
                onChange={e => {
                  setBadgeIcon(e.target.files[0]);
                }}
                accept="image/*"
              />
            </div>
            <div style={{ textAlign: "center", width: "100%" }}>
              <button className="glb-btn" type="submit" id="submit-badge">
                Submit
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}

export default EditBadgeModal;
