import React from "react";
import settings from "../../../../constants/settings";
import "../style.scss";

// const tableHeadingArray = ["Platform", "Type", "Name", "Model", "OS", "SDK"];
// const tableHeadingArray = ["Platform", "Name", "Model", "OS"];
const DevicesListTable = ({ data = {}, isLoading }) => {
  return (
    <div className="dashboard-table deviceslist">
      <table>
        <tr>
          {settings.devices_list_table.tableHeadingArray.map((head, i) => (
            <th key={i} style={{ textAlign: "center" }}>
              {head}
            </th>
          ))}
        </tr>
        {!isLoading &&
          data.map((item, i) => (
            <tr key={i}>
              <td style={{ textAlign: "center" }}>{item.platform && item.platform.name}</td>
              {/* <td>{item.type}</td> */}
              <td style={{ width: "34%" }}>{(item.extra && item.extra.filter(item => item.key === "name")[0].value) || "-"}</td>
              <td style={{ width: "34%" }}>{(item.extra && item.extra.filter(item => item.key === "model")[0].value) || "-"}</td>
              <td style={{ width: "15%" }}>{(item.extra && item.extra.filter(item => item.key === "os")[0].value) || "-"}</td>
              {/* <td>{item.sdk}</td> */}
            </tr>
          ))}
      </table>
    </div>
  );
};

export default DevicesListTable;
