import React, { Fragment, useEffect } from "react";
import { connect } from "react-redux";
import CardsGrid01 from "../../components/HomeTester/CardsGrid01";
import Banner from "../../components/HomeTester/Banner";
import CounterComponent from "../../components/HomeTester/CounterComponent";
//import AdditionalInfoLanding from "../../components/HomeTester/AdditinalInfoLanding";
import NewInfoLanding from "../../components/HomeTester/NewInfoLanding";
import FAQ from "../../components/HomeTester/FAQ";
import AutoLogin from "../../components/AutoLogin";
import { withData, withLayout } from "../../layouts";
import { Helmet } from "react-helmet";
import { staticSiteNavHandler } from "../../redux/Routing/actions";
const HomeTester = ({ settings, staticSiteNavHandler }) => {
  useEffect(()=>{
    staticSiteNavHandler("HOMETESTER");
    // eslint-disable-next-line
  },[])

  return (
    <Fragment>
      <Helmet>
        <meta name="description" content="Ever growing community of verified testers for Android, iOS devices." />
      </Helmet>
      <AutoLogin />
      <Banner data={settings.testerBanner01} />
      <CardsGrid01 settings={{ greyBg: true, floatingCards: true }} data={settings.testerData1} />
      <NewInfoLanding data={settings.testerData4} />
      <CounterComponent />
      {/* <AdditionalInfoLanding data={settings.data7} /> */}
      <FAQ />
    </Fragment>
  );
};

export default connect(null,{staticSiteNavHandler})(withData(withLayout(HomeTester)));
