import React from "react";
import Header from "../components/Layout/Header";
import Footer from "../components/Layout/Footer";
import settings from "../constants/settings";

import "./style.scss";

const withLayout = (Component, isSticky, isShow = true) => props => {
  return (
    <div className="page-layout">
      <div className="content">
        <Header settings={{ isSticky: isSticky }} />
        <Component {...props} />
      </div>
      <Footer isShow={isShow} />
    </div>
  );
};

const withData = Component => props => {
  return <Component {...props} settings={settings} />;
};
export { withLayout, withData };
