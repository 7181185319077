import React, { Fragment } from 'react'
import graphic1 from "../../../assets/images/Group-266.png"
import graphic2 from "../../../assets/images/Group-286.png"

import "./style.scss";
const HalfPageBackground = ({showGraphic = false}) => {
  return (
    <div className="half-page-background">
      { showGraphic&&(
          <Fragment>
            <div className="img">
              <img src={graphic1} alt="working"/>
            </div>
            <div className="img">
              <img src={graphic2} alt="working"/>
            </div>
          </Fragment>
        )
      }
    </div>
  )
}

export default HalfPageBackground
