import React, { Fragment } from "react";
import SignUpTesterComp from "../../components/SignUpTester";
import AutoLogin from "../../components/AutoLogin";
import { withData, withLayout } from "../../layouts";
import { Helmet } from "react-helmet";

const SignUpTester = ({ settings }) => {
  return (
    <Fragment>
      <Helmet>
        <meta name="description" content="Register and be a part of the vast platform of crowdsourced testers" />
      </Helmet>
      <AutoLogin />
      <SignUpTesterComp data={settings.testerSignUp} />
    </Fragment>
  );
};
export default withData(withLayout(SignUpTester, true, false));
