import { all, call, fork, put, takeEvery } from "redux-saga/effects";
import axios from "axios";

import baseDomain, { staticCountrys, staticStates, getAllDevicesReq, staticPlatforms } from "../../constants/apiRoutes";

import { GET_STATIC_COUNTRIES, GET_STATIC_STATES, GET_STATIC_DEVICES, GET_STATIC_PLATFORMS } from "../actions";

import {
  getStaticCountriesSuccess,
  getStaticCountriesError,
  getStaticStatesSuccess,
  getStaticStatesError,
  getStaticDevicesSuccess,
  getStaticDevicesError,
  getStaticPlatformsSuccess,
  getStaticPlatformsError,
} from "./actions";

const getStaticCountriesAPI = async () => {
  return axios.get(`${baseDomain}${staticCountrys}`);
};

const getStaticStatesAPI = async countryId => {
  return axios.get(`${baseDomain}${staticStates}/${countryId}`);
};

const getStaticDevicesAPI = async () => {
  return axios.get(`${baseDomain}${getAllDevicesReq}`);
};

const getStaticPlatformsAPI = async () => {
  return axios.get(`${baseDomain}${staticPlatforms}`);
};

function* getStaticCountries() {
  try {
    const { data } = yield call(getStaticCountriesAPI);
    yield put(getStaticCountriesSuccess(data.data));
  } catch (error) {
    yield put(getStaticCountriesError(error));
  }
}

function* getStaticStates({ payload }) {
  try {
    const { data } = yield call(getStaticStatesAPI, payload);
    yield put(getStaticStatesSuccess(data.data));
  } catch (error) {
    yield put(getStaticStatesError(error));
  }
}

function* getStaticDevices() {
  try {
    const { data } = yield call(getStaticDevicesAPI);
    yield put(getStaticDevicesSuccess(data.data));
  } catch (error) {
    yield put(getStaticDevicesError(error));
  }
}

function* getStaticPlatforms() {
  try {
    const { data } = yield call(getStaticPlatformsAPI);
    yield put(getStaticPlatformsSuccess(data.data));
  } catch (error) {
    yield put(getStaticPlatformsError(error));
  }
}

export function* watchGetStaticCountries() {
  yield takeEvery(GET_STATIC_COUNTRIES, getStaticCountries);
}
export function* watchGetStaticStates() {
  yield takeEvery(GET_STATIC_STATES, getStaticStates);
}
export function* watchGetStatiDevices() {
  yield takeEvery(GET_STATIC_DEVICES, getStaticDevices);
}
export function* watchGetStaticPlatforms() {
  yield takeEvery(GET_STATIC_PLATFORMS, getStaticPlatforms);
}

export default function* rootSaga() {
  yield all([fork(watchGetStaticCountries), fork(watchGetStaticStates), fork(watchGetStatiDevices), fork(watchGetStaticPlatforms)]);
}
