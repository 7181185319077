import React from "react";

import CartIcon from "../../../../../assets/icons/cart.svg";
import settings from "../../../../../constants/settings";

import "./style.scss";
const Cart = ({ cartData }) => {
  const { platformName, basePrice, packageName, deviceCount, multiplier } = cartData;
  return (
    <div className="addtest-cart">
      <div className="cart-icon">
        <div className="img">
          <img src={CartIcon} alt="" />
        </div>
      </div>
      <div className="cart-heading">{settings.client_add_tests_cart.heading}</div>
      <div className="cart">
        <div className="seprator"></div> 

        <div className="align-items seprator">
          <div className="service">
            <div className="heading">{settings.client_add_tests_cart.platform}</div>
            <div className="sub-heading">{platformName}</div>
          </div>
          <div className="amount">-</div>
        </div>

        <div className="seprator">
          <div className="align-items space-below">
            <div className="service">
              <div className="heading">{settings.client_add_tests_cart.price}</div>
              <div className="sub-heading">{packageName}</div>
            </div>
            <div className="amount">{basePrice && `$ ${basePrice}`}</div>
          </div>
          <div className="align-items">
            <div className="service">
              <div className="heading">{settings.client_add_tests_cart.multiplier}</div>
              <div className="sub-heading"> {deviceCount && `Device Count: ${deviceCount}`}</div>
            </div>
            <div className="amount"> {multiplier && `X ${multiplier}`}</div>
          </div>
        </div>

        <div className="align-items sub-total-addtester">
          <div>{settings.client_add_tests_cart.total}</div>
          <div>{basePrice && multiplier && ` $ ${Number(multiplier) * Number(basePrice)}`}</div>
        </div>
      </div>
    </div>
  );
};

export default Cart;
