import React from "react";
import { Link } from "react-router-dom";

import { getFullDateTime } from "../../../../utils/getDateTime";
import NoDataMessage from "../../NoDataMessage";
import settings from "../../../../constants/settings";

import "../style.scss";

// const tableHeadingArray = ["Invitations", "Platform", "Your model", "Begin"];

const NotificationTable = ({ tableContentArray }) => {

  const notificationArray = [].concat(tableContentArray).reverse();

  return (
    <div className="dashboard-table notifications">
      {tableContentArray.length === 0 ? (
        <NoDataMessage message={settings.notification_table.message} />
      ) : (
        <table>
          <tr>
            {settings.notification_table.tableHeadingArray.map((head, i) => (
              <th key={i}>{head}</th>
            ))}
          </tr>
          {notificationArray.map((item, i) => (
            <tr key={i}>
              <td>
                <div className="notification-invit">
                  <div className="img">
                    <img src={ item?.icon?.url || item?.icon} alt={item.text} />
                  </div>
                  <div className="notification-text">
                    <div className="notification-text-heading">{item.text}</div>
                    <div className="notification-text-time">{getFullDateTime(item.createdAt)}</div>
                  </div>
                </div>
              </td>
              <td>
                <div className="linkBtn-container">
                  {item.projectStatus === "CANCELED" || item.projectStatus === "PAUSED" 
                    ? <div className={`linkBtn ${item.projectStatus === "CANCELED" || item.projectStatus === "PAUSED" ? "dark" : ''} `} >
                        {settings.notification_table.view}  
                      </div> 
                    : 
                      <Link
                        className={`linkBtn `} 
                        to={`/dashboard/test-taken/projects/active/${item.project}`}
                      >
                        {settings.notification_table.view}
                      </Link>
                  }
                  
                </div>
              </td>
            </tr>
          ))}
        </table>
      )}
    </div>
  );
};

export default NotificationTable;
