import React from "react";
import { Link } from "react-router-dom";

import NoDataMessage from "../../NoDataMessage";
import settings from "../../../../constants/settings";

import "../style.scss";

// const tableHeadingArray = ["Project Name", "Ongoing Hours", "Remaining Hours", "Status"];

const PauseTestTable = ({ tableContentArray }) => {
  return (
    <div className="dashboard-table pause-test-table">
      {tableContentArray.length === 0 ? (
        <NoDataMessage message={settings.pause_test_table.message} />
      ) : (
        <table>
          <tr>
            {settings.pause_test_table.tableHeadingArray.map((head, i) => (
              <th key={i}>{head}</th>
            ))}
          </tr>
          
          {tableContentArray &&
            tableContentArray.map(({ name, projectId }, i) => (
              <tr key={i}>
                <td>{name}</td>
                <td>
                  <Link to={`/dashboard/test-taken/projects/pause/${projectId}`} className="blue-color">
                    {settings.pause_test_table.view}
                  </Link>
                </td>
              </tr>
            ))}
        </table>
      )}
    </div>
  );
};

export default PauseTestTable;
