import React, { useState } from "react";
import { connect } from "react-redux";
import { useParams, useHistory } from "react-router-dom";
import { CloseSign } from "../../../assets/icons/icons";
import axios from "axios";

import baseDomain, { declineBugReq } from "../../../constants/apiRoutes";
import { setAlert } from "../../../redux/Alerts/actions";
import "./style.scss";

const DeclineBugModal = ({ showModal, toggleModal, setAlert }) => {
  const { bugID, projectID } = useParams();
  const history = useHistory();
  const [isDeclinedLoading, setIsDeclinedLoading] = useState(false);

  const [formData, setFormData] = useState({
    bugId: "",
    reason: "",
  });

  const modalToggle = () => {
    toggleModal();
  };

  const handleDecline = async () => {
    if (formData.reason !== "") {
      setIsDeclinedLoading(true);
      try {
        await axios.put(`${baseDomain}${declineBugReq}`, formData);
        setAlert({
          message: "Bug Declined Successfully",
          type: "SUCCESS",
        });
        setIsDeclinedLoading(false);
        toggleModal();
        history.push(`/dashboard/projects/projectlist/${projectID}/bugs`);
      } catch (error) {
        setAlert({
          message: error.response.data.message || "Error while declining bug",
          type: "ERROR",
        });
        setIsDeclinedLoading(false);
      }
    } else {
      setAlert({
        message: "Please give a Reason.",
        type: "ERROR",
      });
    }
  };

  return (
    <div className={showModal ? "declineBug-modal-container blur-bg" : "none"}>
      <div className="declineBug-modal">
        <div className="close-icon">
          <div className="close" onClick={modalToggle}>
            {CloseSign}
          </div>
        </div>
        <div className="heading">
          <h2>Decline Bug</h2>
        </div>
        <div className="body-container">
          <div className="body">
            <div className="declinePopUpContainer">
              <div className="declinePopUp">
                <div className="declinePopUpReason">Reason</div>
                <textarea
                  type="text"
                  className="declinePopUp-input"
                  name="reason"
                  value={formData.reason}
                  required
                  onChange={e => setFormData({ bugId: bugID, reason: e.target.value })}
                />
                <div className="declinePopUpButtons">
                  <button className={"glb-btn no"} onClick={modalToggle}>
                    Cancel
                  </button>
                  <button className={"glb-btn yes"} disabled={isDeclinedLoading} onClick={handleDecline}>
                    Proceed
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default connect(null, { setAlert })(DeclineBugModal);
