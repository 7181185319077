import './style.scss'
import { deleteBin, editBadge } from '../../../../assets/icons/icons'



const BadgesCard = ({data ,deleteBadge,handleEdit}) => {
        return (
            <div className="BadgeCard">  
            <div className="badgeOptions">
              <span className="editBadge" onClick={()=>{handleEdit(data)}} >{editBadge}</span>
              &ensp;
              <span className="deleteBadge" onClick={()=>{deleteBadge(data.id)}} >{deleteBin}</span>
            </div>          
            <div className="cardHeader">
              <img width="80px" src={data.logo} alt="Badge Icon" />
            </div>
            <div className="cardfooter">
            <div className="cardContent">
                <div className="cardHeading">{data.badgeName}</div>
                <div className="cardNumber">{data.score}</div>
            </div>                 
            <div className="cardDescription">{data.badgeDescription}</div>
            </div> 
          </div>
    )
}

export default BadgesCard