import React, { useState } from "react";
import { connect } from "react-redux";
import { uploadTestimonial } from "../../../../redux/TesterDashboard/actions";
import { setAlert } from "../../../../redux/Alerts/actions";

import "./style.scss";

const Testimonials = ({ uploadTestimonial, setAlert, userId }) => {
  const [form, setForm] = useState({
    testerId: "",
    testimonial: "",
  });

  const handleSubmit = e => {
    e.preventDefault();
    if (form.testimonial.trim() === "") {
      setAlert({ message: "Please give us a Feedback.", type: "WARNING" });
    } else {
      uploadTestimonial(form);
      setForm({ testerId: userId, testimonial: "" });
    }
  };

  return (
    <div className="testimonial">
      <form onSubmit={handleSubmit}>
        <textarea
          type="text"
          className="testimonial-summary"
          name="summary"
          maxlength="250"
          value={form.testimonial}
          onChange={e => setForm({ testimonial: e.target.value, testerId: userId })}
        />
        <button className="glb-btn glb-action-btn" type="submit">
          Submit
        </button>
      </form>
    </div>
  );
};

const mapStateToProps = ({ auth }) => {
  return {
    userId: auth.user.userId,
  };
};

export default connect(mapStateToProps, { uploadTestimonial, setAlert })(Testimonials);
