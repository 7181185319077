import React, { useState } from "react";
import { connect } from "react-redux";
import { useParams, useHistory } from "react-router-dom";
import axios from "axios";

import baseDomain, { resetPassReq } from "../../../constants/apiRoutes";
import { setAlert } from "../../../redux/Alerts/actions";
import { CloseSign } from "../../../assets/icons/icons";
import "./style.scss";

const ResetPasswordModal = ({ showModal, toggleModal }) => {
  const { id } = useParams();
  const [isResetPass, setIsResetPass] = useState(false);
  const history = useHistory();
  const [formData, setFormData] = useState({
    userId: id,
    newPassword: "",
  });

  const modalToggle = () => {
    toggleModal();
  };

  const handleReset = async () => {
    setIsResetPass(true);
    try {
      await axios.post(`${baseDomain}${resetPassReq}`, formData);
      setAlert({ message: "Password Sucessfuly Reset", type: "SUCCESS" });
      setIsResetPass(false);
      history.push(`/dashboard/testers/${id}`);
    } catch (error) {
      setIsResetPass(false);
      setAlert({ message: error.response.message, type: "ERROR" });
    }
  };

  return (
    <div className={showModal ? "reset-password-modal-container blur-bg" : "none"}>
      <div className="reset-password-modal">
        <div className="close-icon">
          <div className="close" onClick={modalToggle}>
            {CloseSign}
          </div>
        </div>
        <div className="heading">
          <h2>Reset Password</h2>
        </div>
        <div className="body-container">
          <div className="body">
            <div className="resetPasswordContainer">
              <div className="resetPasswordPopUp">
                <div className="declinePopUpNewPassword">New Password</div>
                <input
                  type="text"
                  className="resetPassword-input"
                  name="newPassword"
                  value={formData.newPassword}
                  required
                  onChange={e => setFormData({ userId: id, newPassword: e.target.value })}
                />
                <div className="resetPasswordButtons">
                  <button className={"glb-btn no"} onClick={modalToggle}>
                    Cancel
                  </button>
                  <button className={"glb-btn yes"} disabled={isResetPass} onClick={handleReset}>
                    Reset
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default connect(null, { setAlert })(ResetPasswordModal);
