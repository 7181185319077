import React, { useEffect } from "react";
import { useRouteMatch, Route, Switch, Redirect } from "react-router-dom";
import { connect } from "react-redux";

import { withLayout } from "../../layouts";

import icon from "../../assets/icons/hamburgerSidenav.png";

import { TesterRoutes, ClientRoutes, AdminRoutes, ReviewerRoutes } from "../../routes";
import { toggleSideNav } from "../../redux/Routing/actions";
import { userInfo } from "../../redux/auth/actions";
import DashboardNavigator from "../../components/DashboardNavigator";
import Loader from "../../components/Loader";

import "./style.scss";

const Dashboard = ({ loading, error, isLoadingDashboard, isAuth, role, isHaveDevice, toggleSideNav, showSideNav, userInfo, user }) => {
  const match = useRouteMatch();
  useEffect(() => {
    userInfo();
    // eslint-disable-next-line
  }, []);

  return (
    <div className="dashboard-layout">
      {isAuth ? (
        <>
          {isLoadingDashboard ? (
            <Loader />
          ) : (
            <div className="dashboard-main-container">
              <div className={showSideNav ? "dashboard-nav" : "dashboard-nav hide"}>
                <DashboardNavigator token={user.token}  role={role} />
              </div>
              <div className="dashboard-body">
                <div className="dashboard-toggle-btn-container">
                  <div className="dashboard-toggle-btn" onClick={() => toggleSideNav()}>
                    <div className="icon">
                      <img src={icon} alt="" />
                    </div>
                    <div className="heading">Dashboard</div>
                  </div>
                </div>
                {role === "AGENCY" && (
                  <Switch>
                    <Redirect exact from={`${match.url}/`} to={`${match.url}/home`} />
                    {ClientRoutes.map(({ path, Component, exact, id }, i) => (
                      <Route path={`${match.url}${path}`} exact={exact} render={() => <Component componentID={id} />} key={i} />
                    ))}
                    <Redirect to="/dashboard/home" />
                  </Switch>
                )}
                {role === "TESTER" && (
                  <Switch>
                    <Redirect exact from={`${match.url}/`} to={isHaveDevice ? `${match.url}/home` : `${match.url}/devices`} />
                    {TesterRoutes.map(({ path, Component, exact, id }, i) => (
                      <Route path={`${match.url}${path}`} exact={exact} render={() => <Component componentID={id} />} key={i} />
                    ))}
                    <Redirect to="/dashboard/home" />
                  </Switch>
                )}
                {role === "REVIEWER" && (
                  <Switch>
                    <Redirect exact from={`${match.url}/`} to={isHaveDevice ? `${match.url}/home` : `${match.url}/devices`} />
                    {ReviewerRoutes.map(({ path, Component, exact, id }, i) => (
                      <Route path={`${match.url}${path}`} exact={exact} render={() => <Component componentID={id} />} key={i} />
                    ))}
                    <Redirect to="/dashboard/home" />
                  </Switch>
                )}
                {role === "ADMIN" && (
                  <Switch>
                    <Redirect exact from={`${match.url}/`} to={`${match.url}/home`} />
                    {AdminRoutes.map(({ path, Component, exact, id }, i) => (
                      <Route path={`${match.url}${path}`} exact={exact} render={() => <Component componentID={id} />} key={i} />
                    ))}
                    <Redirect to="/dashboard/home" />
                  </Switch>
                )}
              </div>
            </div>
          )}
        </>
      ) : (
        <Redirect from={`${match.url}/`} to={`/`} />
      )}
    </div>
  );
};

const mapStateToProps = ({ auth: { loading, error, isAuth, role, user, isLoadingDashboard }, routeingState }) => ({
  loading,
  error,
  isAuth,
  role,
  user,
  isLoadingDashboard,
  isHaveDevice: user.isHaveDevice,
  showSideNav: routeingState.showSideNav,
});

export default connect(mapStateToProps, { toggleSideNav, userInfo })(withLayout(Dashboard, true, false));
