import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { useParams, useLocation } from "react-router-dom";
import DashboardHeading from "../../../../Common/DashboardHeading";

import InvitationModal from "../../../../Modals/InvitationModal";
import { toggleInviteModal } from "../../../../../redux/Routing/actions";
import {
  testerActivate,
  testerDeactivate,
  getTesterAllProjects,
  putTesterMakeReviewer,
  putTesterMakeTester,
  getTesterBankDetails,
  inviteParticularTester,
  getTesterDeviceDetails,
  getTesterSkillsetDetails,
} from "../../../../../redux/AdminDashboard/actions";
// import { getTesterDevices} from "../../../../../redux/TesterDashboard/actions";
import AllProjectsTakenTable from "../../.././../Common/DashboardTables/AllProjectsTakenTable";
import TesterBankTable from "../../../../Common/DashboardTables/TesterBankTable";
import DevicesTable from "../../../../Common/DashboardTables/DevicesTable";
import { toggleResetPasswordModal } from "../../../../../redux/Routing/actions";
import ResetPasswordModal from "../../../../Modals/ResetPasswordModal";
import Loader from "../../../../Loader";
import "./style.scss";
import SkillsetTable from "../../../../Common/DashboardTables/SkillsetTable";

const TesterDetails = ({
  testerActivate,
  testerDeactivate,
  getTesterAllProjects,
  testersAllProjects,
  showResetPassModal,
  toggleResetPasswordModal,
  isLoading,
  putTesterMakeReviewer,
  makeReviewer,
  role,
  putTesterMakeTester,
  getTesterBankDetails,
  testerBankDetails,
  toggleInviteModal,
  showInviteModal,
  inviteParticularTester,
  getTesterDeviceDetails,
  testerDeviceDetails,
  getTesterSkillsetDetails,
  testerSkillsetDetails,
}) => {
  const locations = useLocation();
  const isSending = locations.state;
  const { id } = useParams();

  const [statusState, setStatusState] = useState(isSending.status);
  const [currentRole, setCurrentRole] = useState(false);
  const [count, setCount] = useState(0);

  useEffect(() => {
    getTesterAllProjects(id);
    getTesterBankDetails(id);
    getTesterDeviceDetails(id);
    getTesterSkillsetDetails(id);
    // eslint-disable-next-line
  }, []);

  const toggleResetPassword = () => {
    toggleResetPasswordModal();
  };

  const handleChange = (status, id) => {
    status ? testerDeactivate(id) : testerActivate(id);
    status ? setStatusState(false) : setStatusState(true);
  };

  const makeReviewerFunction = role => {
    currentRole === "TESTER" || currentRole === "Tester" ? putTesterMakeTester(id) : putTesterMakeReviewer(id);
    currentRole === "TESTER" || currentRole === "Tester" ? setCurrentRole("Reviewer") : setCurrentRole("Tester");
  };

  const toggleInvite = () => {
    toggleInviteModal();
  };

  const handleShowBank = () => {
    setCount(0);
  };

  const handleShowDevice = () => {
    setCount(1);
  };

  const handleShowSkills = () => {
    setCount(2);
  };

  const logoIcon = testersAllProjects.length ? testersAllProjects[0]?.badge?.currentBadge?.logo : "";
  const logoName = testersAllProjects.length ? testersAllProjects[0]?.badge?.currentBadge?.name : "";

  return (
    <div className="tester-details">
      <DashboardHeading headingStrong={"Tester Details"} showBtn={false} />
      <div className="tester-details-header">
        <div className="tester-details-header-name">
          {isSending.name}&ensp;
          <img style={{ verticalAlign: "middle" }} width="25px" src={logoIcon} alt={logoName} />
        </div>
        <div className="tester-details-header-email">{isSending.email}</div>

        <button className="glb-btn" onClick={toggleResetPassword}>
          Reset Password
        </button>
        {testersAllProjects.slice(0).map((item, m) => (
          <button className="glb-btn" onClick={() => makeReviewerFunction(item.role[0])}>{`Make ${
            currentRole ? currentRole : item.role[0] === "TESTER" ? "Reviewer" : "Tester"
          }`}</button>
        ))}

        <ResetPasswordModal showModal={showResetPassModal} toggleModal={toggleResetPassword} />
        <InvitationModal showModal={showInviteModal} toggleModal={toggleInviteModal} id={id} sendInvitation={inviteParticularTester} />

        {/* <div className="tester-details-header-heading">All Platforms</div>
        <div className="tester-details-header-devices">
          {testersAllProjects.map((item, i) => (
            <>
              {item.projects.length === 0 ? (
                <div className="noPlatform-error">No Platforms</div>
              ) : (
                <>
                  {testersAllProjects[i].projects.map((abc, j) => (
                    <img src={abc.platform.icon.gray} alt={abc.platform.name} title={abc.platform.name} className="tester-devices-images" />
                  ))}
                </>
              )}
            </>
          ))}
          
        </div> */}
        <div className="tester-details-header-heading">Status</div>

        <div className="tester-details-status-container">
          <div className={`tester-details-status ${statusState ? "Active" : "Inactive"}`}></div>
          <h4 className="tester-details-status-status">{statusState ? "Active" : "Inactive"}</h4>
          <div className="tester-details-status-change-status" onClick={() => handleChange(statusState, id)}>
            Change Status
          </div>
        </div>

        <div className="tester-details-buttons">
          <button className="glb-btn" onClick={toggleInvite}>
            Send invitation
          </button>
          <button className="glb-btn" >View Total Earnings</button>
          <button className="glb-btn" onClick={handleShowBank}>
            View Bank Details
          </button>
          <button className="glb-btn" onClick={handleShowDevice}>
            View Tester Devices
          </button>
          <button className="glb-btn" onClick={handleShowSkills}>
            View Tester Skillset
          </button>
        </div>
      </div>

      {count === 0 && (
        <div className="view-bank-details">
          <h2>Bank Details</h2>
          <TesterBankTable bankDetailsArray={testerBankDetails} showItem={false} />
        </div>
      )}

      {count === 1 && (
        <div className="view-bank-details">
          <h2>Device Details</h2>
          <DevicesTable tableContentArray={testerDeviceDetails} showItem={false} />
        </div>
      )}

      {count === 2 && (
        <div className="view-bank-details">
          <h2>Skillset Details</h2>
          <SkillsetTable tableContentArray={testerSkillsetDetails} />
        </div>
      )}

      <div className="tester-details-all-projects">
        <DashboardHeading headingStrong={"All Projects Taken"} showBtn={false} />
        {isLoading ? <Loader /> : <AllProjectsTakenTable tableContentArray={testersAllProjects} />}
      </div>
    </div>
  );
};

const mapStateToProps = ({ routeingState, auth, adminDashboard, testerDashboard }) => ({
  showResetPassModal: routeingState.showResetPassModal,
  testersAllProjects: adminDashboard.testerAllProjects,
  makeReviewer: adminDashboard.makeReviewer,
  makeTester: adminDashboard.makeTester,
  testerBankDetails: adminDashboard.testerBankDetails,
  testerDeviceDetails: adminDashboard.testerDeviceDetails,
  testerSkillsetDetails: adminDashboard.testerSkillsetDetails,
  isLoading: adminDashboard.isLoading,
  role: auth.role,
  showInviteModal: routeingState.showInviteModal,
  // devices: testerDashboard.userDevices,
});

export default connect(mapStateToProps, {
  testerActivate,
  testerDeactivate,
  getTesterAllProjects,
  toggleResetPasswordModal,
  putTesterMakeReviewer,
  putTesterMakeTester,
  getTesterBankDetails,
  toggleInviteModal,
  inviteParticularTester,
  getTesterDeviceDetails,
  getTesterSkillsetDetails,
})(TesterDetails);
