import React, { useEffect } from "react";
import { connect } from "react-redux";

import { getTesterLiveProjects, getTesterCompletedProjects, getTesterPausedProjects } from "../../../../../redux/TesterDashboard/actions";

import DashboardHeading from "../../../../Common/DashboardHeading";
import ActiveTestTable from "../../../../Common/DashboardTables/ActiveTestTable";
import PauseTestTable from "../../../../Common/DashboardTables/PauseTestTable";
import CompletedTestTable from "../../../../Common/DashboardTables/CompletedTestTable";
import Loader from "../../../../Loader";
import settings from "../../../../../constants/settings";

import "./style.scss";

const AllTests = ({ getTesterLiveProjects, getTesterCompletedProjects, getTesterPausedProjects, activeProjects, pausedProjects, completedProjects, isLoading }) => {
  useEffect(() => {
    getTesterLiveProjects();
    getTesterCompletedProjects();
    getTesterPausedProjects();
    // eslint-disable-next-line
  }, []);
  return (
    <div className="client-dash-test-taken">
      <div className="active-tests">
        <DashboardHeading heading={settings.all_projects.heading1.light} headingStrong={settings.all_projects.heading1.bold} />
        <div className="cards">{isLoading ? <Loader /> : <ActiveTestTable tableContentArray={activeProjects} />}</div>
      </div>
      <div className="paused-tests">
        <DashboardHeading headingStrong={"Projects on hold"} />
        <div className="cards">{isLoading ? <Loader /> : <PauseTestTable tableContentArray={pausedProjects} />}</div>
      </div>
      <div className="completed-tests">
        <DashboardHeading heading={settings.all_projects.heading2.light} headingStrong={settings.all_projects.heading2.bold} />
        <div className="cards">{isLoading ? <Loader /> : <CompletedTestTable tableContentArray={completedProjects} />}</div>
      </div>
    </div>
  );
};

const mapStateToProps = ({ testerDashboard }) => ({
  activeProjects: testerDashboard.testerLiveProjects,
  pausedProjects: testerDashboard.testerPausedProjects,
  isLoading: testerDashboard.isLoading,
  completedProjects: testerDashboard.testerCompletedProjects,
});

export default connect(mapStateToProps, { getTesterLiveProjects, getTesterCompletedProjects, getTesterPausedProjects })(AllTests);
