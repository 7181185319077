import { React, useEffect, useState } from "react";
import { useParams, useHistory } from "react-router-dom";
import { connect } from "react-redux";

import { getTesterListedBugs, getTesterProjectByID } from "../../../../../redux/TesterDashboard/actions";

import "./style.scss";

import Loader from "../../../../Loader";
import settings from "../../../../../constants/settings";

import DashboardHeading from "../../../../Common/DashboardHeading";
import AnalysisTable from "../../../../Common/DashboardTables/AnalysisTable";

const SingleTest = ({ getTesterListedBugs, getTesterProjectByID, testerBugs, selectedProject, isLoading }) => {
  const { projectID } = useParams();
  const [truncated, setTruncated] = useState(true);
  const history = useHistory();

  const truncation = desc => {
    let description = truncated && desc.length > 150 ? desc.slice(0, 200).concat("...") : desc;
    return description;
  };

  useEffect(() => {
    getTesterListedBugs(projectID);
    getTesterProjectByID(projectID);
    // eslint-disable-next-line
  }, []);
  return (
    <div className="all-test-taken">
      {isLoading ? (
        <Loader />
      ) : (
        <>
          <DashboardHeading heading={selectedProject.name} headingStrong={settings.active_project.heading} showBtn={false} />
          {/* <div
            className="BackBtnContainer FormFillUp-backbtn"
            onClick={e => {
              e.preventDefault();
              history.push(`/dashboard/home`);
            }}
          >
            &laquo; {settings.report_bug_form.back_btn}
          </div> */}
          <div className="test-taken-container">
            <div className="test-taken">
              <div className="item">
                <div className="label">{settings.active_project.platform}</div>
                <div className="show-details">{(selectedProject.platform && selectedProject.platform.name) || "none"}</div>
              </div>
              {selectedProject.bugList && (
                <div className="item">
                  <div className="label">{settings.active_project.buglist}</div>
                  <div className="show-btn">
                    <button className="glb-btn" onClick={() => window.open(selectedProject.bugList, "_blank")}>
                      {settings.active_project.btnText1}
                    </button>
                  </div>
                </div>
              )}
              {selectedProject.website && (
                <div className="item">
                  <div className="label">{settings.active_project.website}</div>
                  <div
                    className="inherit"
                    onClick={() => {
                      if (selectedProject.website.startsWith("http")) window.open(selectedProject.website, "_blank");
                      else window.open("https://" + selectedProject.website, "_blank");
                    }}
                  >
                    <div className="show-details webLink">{selectedProject.website}</div>
                  </div>
                </div>
              )}
              <div className="item">
                <div className="label">{settings.active_project.version}</div>
                <div className="show-details"> {selectedProject.version}</div>
              </div>
            </div>
            {selectedProject && selectedProject.description && (
              <div className="test-taken-description-container">
                <div className="description-label">Description</div>
                <div className="test-taken-description">
                  <p className="test-taken-description-details">
                    {truncation(selectedProject.description)}
                    {selectedProject.description.length > 150 && (
                      <button
                        onClick={() => {
                          setTruncated(!truncated);
                        }}
                      >
                        {truncated ? "Read More" : "Read Less "}
                      </button>
                    )}
                  </p>
                </div>
                {selectedProject.file && (
                  <div className="download-application-btn">
                    <button className="glb-btn" onClick={() => window.open(selectedProject.file, "_blank")}>
                      {settings.active_project.btnText2}
                    </button>
                  </div>
                )}
              </div>
            )}
          </div>

          <div className="upload-results-heading">
            <DashboardHeading
              heading={settings.active_project.heading1a}
              headingStrong={settings.active_project.heading1b}
              showBtn={selectedProject.status === "PAUSED" || selectedProject.status === "COMPLETED" ? false : true}
              btnValue={settings.active_project.btnText3}
              btnOnClick={() => {
                history.push(`/dashboard/test-taken/projects/active/${projectID}/reportbug`);
              }}
              btnSize={settings.active_project.btnsize}
              showSecBtn={true}
              secBtnVal={settings.active_project.btnText4}
              secBtnOnClick={() => {
                history.push(`/dashboard/test-taken/projects/active/${projectID}/buglist`);
              }}
              secBtnSize={settings.active_project.btnsize}
            />
          </div>
          <AnalysisTable tableContentArray={testerBugs} projectID={projectID} role={"TESTER"} />
        </>
      )}
    </div>
  );
};

const mapStateToProps = ({ testerDashboard }) => ({
  testerBugs: testerDashboard.testerListedBugs,
  selectedProject: testerDashboard.testerSelectedProject,
  isLoading: testerDashboard.isLoading,
});

export default connect(mapStateToProps, { getTesterListedBugs, getTesterProjectByID })(SingleTest);
