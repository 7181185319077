import {
  GET_CLIENT_ONGOING_PROJECTS,
  GET_CLIENT_ONGOING_PROJECTS_SUCCESS,
  GET_CLIENT_ONGOING_PROJECTS_ERROR,
  GET_CLIENT_PAUSED_PROJECTS,
  GET_CLIENT_PAUSED_PROJECTS_SUCCESS,
  GET_CLIENT_PAUSED_PROJECTS_ERROR,
  GET_CLIENT_COMPLETED_PROJECTS,
  GET_CLIENT_COMPLETED_PROJECTS_SUCCESS,
  GET_CLIENT_COMPLETED_PROJECTS_ERROR,
  GET_CLIENT_SINGLE_PROJECT,
  GET_CLIENT_SINGLE_PROJECT_SUCCESS,
  GET_CLIENT_SINGLE_PROJECT_ERROR,
  GET_CLIENT_PROJECT_STATES,
  GET_CLIENT_PROJECT_STATES_SUCCESS,
  GET_CLIENT_PROJECT_STATES_ERROR,
  APP_DETAILS,
  APP_DETAILS_SUCCESS,
  APP_DETAILS_ERROR,
  APP_HINT,
  APP_HINT_SUCCESS,
  APP_HINT_ERROR,
  UPDATE_PROJECT_DETAILS_SUCCESS,
  UPDATE_PROJECT_DETAILS_ERROR,
  UPDATE_PROJECT_DETAILS,
  UPDATE_CLIENT_PROFILE,
  UPDATE_CLIENT_PROFILE_SUCCESS,
  UPDATE_CLIENT_PROFILE_ERROR,
  UPDATE_CLIENT_PROFILE_WITHOUT_IMAGE,
  UPDATE_CLIENT_PROFILE_WITHOUT_IMAGE_SUCCESS,
  UPDATE_CLIENT_PROFILE_WITHOUT_IMAGE_ERROR,
  DRAFT_PROJECT_CLIENT,
  DRAFT_PROJECT_CLIENT_SUCCESS,
  DRAFT_PROJECT_CLIENT_ERROR,
} from "../actions";

const initialState = {
  onGoingProjects: [],
  pausedProjects: [],
  completedProjects: [],
  currentProject: {},
  projectStates: {},
  isLoading: false,
  error: null,
  appHintList: [],
  appDetailsList: [],
  updateProjectDetails: [],
  draftProject:[]
};

const fun = (state = initialState, action) => {
  switch (action.type) {
    // Client Ongoing Projects
    case GET_CLIENT_ONGOING_PROJECTS:
      return {
        ...state,
        onGoingProjects: [],
        isLoading: true,
        error: null,
      };
    case GET_CLIENT_ONGOING_PROJECTS_SUCCESS:
      return {
        ...state,
        onGoingProjects: action.payload,
        isLoading: false,
        error: null,
      };
    case GET_CLIENT_ONGOING_PROJECTS_ERROR:
      return {
        ...state,
        onGoingProjects: [],
        isLoading: false,
        error: action.payload,
      };

    // Client Paused Projects
    case GET_CLIENT_PAUSED_PROJECTS:
      return {
        ...state,
        pausedProjects: [],
        isLoading: true,
        error: null,
      };
    case GET_CLIENT_PAUSED_PROJECTS_SUCCESS:
      return {
        ...state,
        pausedProjects: action.payload,
        isLoading: false,
        error: null,
      };
    case GET_CLIENT_PAUSED_PROJECTS_ERROR:
      return {
        ...state,
        pausedProjects: [],
        isLoading: false,
        error: action.payload,
      };

    // Client Ongoing Projects
    case GET_CLIENT_COMPLETED_PROJECTS:
      return {
        ...state,
        completedProjects: [],
        isLoading: true,
        error: null,
      };
    case GET_CLIENT_COMPLETED_PROJECTS_SUCCESS:
      return {
        ...state,
        completedProjects: action.payload,
        isLoading: false,
        error: null,
      };
    case GET_CLIENT_COMPLETED_PROJECTS_ERROR:
      return {
        ...state,
        completedProjects: [],
        isLoading: false,
        error: action.payload,
      };

    // Client Single Project
    case GET_CLIENT_SINGLE_PROJECT:
      return {
        ...state,
        currentProject: {},
        isLoading: true,
        error: null,
      };
    case GET_CLIENT_SINGLE_PROJECT_SUCCESS:
      return {
        ...state,
        currentProject: action.payload,
        isLoading: false,
        error: null,
      };
    case GET_CLIENT_SINGLE_PROJECT_ERROR:
      return {
        ...state,
        currentProject: {},
        isLoading: false,
        error: action.payload,
      };

    // Client Project States
    case GET_CLIENT_PROJECT_STATES:
      return {
        ...state,
        projectStates: {},
        isLoading: true,
        error: null,
      };
    case GET_CLIENT_PROJECT_STATES_SUCCESS:
      return {
        ...state,
        projectStates: {
          ...action.payload,
          blockerPer: (action.payload.blocker * 100) / action.payload.totalBugs,
          repeatedPer: (action.payload.repeated * 100) / action.payload.totalBugs,
          criticalPer: (action.payload.critical * 100) / action.payload.totalBugs,
          crashPer: (action.payload.crash * 100) / action.payload.totalBugs,
          lowPer: (action.payload.low * 100) / action.payload.totalBugs,
          majorBugPer: (action.payload.majorBug * 100) / action.payload.totalBugs,
          minorBugPer: (action.payload.minorBug * 100) / action.payload.totalBugs,
        },
        isLoading: false,
        error: null,
      };
    case GET_CLIENT_PROJECT_STATES_ERROR:
      return {
        ...state,
        projectStates: {},
        isLoading: false,
        error: action.payload,
      };

    // APP HINT
    case APP_HINT:
      return {
        appHintList: [],
        isLoading: true,
        error: null,
      };
    case APP_HINT_SUCCESS:
      return {
        ...state,
        appHintList: action.payload,
        isLoading: false,
        error: null,
      };
    case APP_HINT_ERROR:
      return {
        ...state,
        appHintList: [],
        isLoading: false,
        error: action.payload,
      };

    // UPDATE PROJECT DETAILS
    case UPDATE_PROJECT_DETAILS:
      return {
        ...state,
        isLoading: true,
        isError: false,
      };
    case UPDATE_PROJECT_DETAILS_SUCCESS:
      return {
        ...state,
        updateProjectDetails: [...state.updateProjectDetails, action.payload],
        currentProject:{...state.currentProject,
          stage:action.payload.stage,
          testingHour:action.payload.testingHour,
          websiteLink:action.payload.websiteLink,
          version:action.payload.version,
          loginId:action.payload.loginId,
          loginPassword:action.payload.loginPassword},
        isLoading: false,
        isError: false,
      };
    case UPDATE_PROJECT_DETAILS_ERROR:
      return {
        ...state,
        isLoading: false,
        isError: true,
      };

    // App Details
    case APP_DETAILS:
      return {
        ...state,
        appDetailsList: [],
        isLoading: true,
        error: null,
      };
    case APP_DETAILS_SUCCESS:
      return {
        ...state,
        appDetailsList: action.payload,
        isLoading: false,
        error: null,
      };
    case APP_DETAILS_ERROR:
      return {
        ...state,
        appDetailsList: [],
        isLoading: false,
        error: action.payload,
      };

    // Update CLIENT Profile
    case UPDATE_CLIENT_PROFILE:
      return {
        ...state,
        isLoading: true,
        isError: false,
      };
    case UPDATE_CLIENT_PROFILE_SUCCESS:
      return {
        ...state,
        isLoading: false,
        isError: false,
      };
    case UPDATE_CLIENT_PROFILE_ERROR:
      return {
        ...state,
        isLoading: false,
        isError: true,
      };

    // Update Profile Without Image
    case UPDATE_CLIENT_PROFILE_WITHOUT_IMAGE:
      return {
        ...state,
        isLoading: true,
        isError: false,
      };
    case UPDATE_CLIENT_PROFILE_WITHOUT_IMAGE_SUCCESS:
      return {
        ...state,
        isLoading: false,
        isError: false,
      };
    case UPDATE_CLIENT_PROFILE_WITHOUT_IMAGE_ERROR:
      return {
        ...state,
        isLoading: false,
        isError: true,
      };

    // Client Draft Projects
    case DRAFT_PROJECT_CLIENT:
      return {
        ...state,
        isLoading: true,
        isError: false,
      };
    case DRAFT_PROJECT_CLIENT_SUCCESS:
      return {
        ...state,
        isLoading: false,
        isError: false,
        draftProject: [...action.payload],
      };
    case DRAFT_PROJECT_CLIENT_ERROR:
      return {
        ...state,
        isLoading: false,
        isError: true,
      };

    default:
      return { ...state };
  }
};

export default fun;
