import React, { useEffect, useState } from "react";
import { useLocation, useHistory } from "react-router-dom";
import axios from "axios";

import baseDomain, { verifyEmailReq } from "../../constants/apiRoutes";
import HalfPageBackground from "../../components/Layout/HalfPageBackground";
import Loader from "../../components/Loader";
import { withLayout } from "../../layouts";
import settings from "../../constants/settings";

import "./style.scss";
const EmailVerification = () => {
  const [isVerified, setIsVerified] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const location = useLocation();
  const history = useHistory();
  const query = new URLSearchParams(location.search);

  useEffect(() => {
    if (query.get("token")) verifyEmail(query.get("token"));
    else history.push("/");
    // eslint-disable-next-line
  }, []);

  const verifyEmail = async token => {
    try {
      await axios.get(`${baseDomain}${verifyEmailReq}/${token}`);
      setIsVerified(true);
      setIsLoading(false);
    } catch (error) {
      setIsVerified(false);
      setIsLoading(false);
    }
  };

  return (
    <div className="email-verification">
      <HalfPageBackground />
      <div className="email-verification-container">
        {isLoading ? (
          <Loader />
        ) : (
          <>
            <h1> {isVerified ? settings.email_verification.email_verified : settings.email_verification.email_not_verified} </h1>
            {/* {isVerified && ( */}
            <button className="glb-btn" onClick={() => history.push("/")}>
              {settings.email_verification.btnText}
            </button>
            {/* )} */}
          </>
        )}
      </div>
    </div>
  );
};

export default withLayout(EmailVerification, true, false);
