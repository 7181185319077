import React, { useEffect } from "react";
import { connect } from "react-redux";
import { useRouteMatch, Route, Switch, Redirect } from "react-router-dom";

import AllProjects from "./Components/AllProjects";
import ProjectDetails from "./Components/ProjectDetails";
import SingleBug from "./Components/SingleBug";

import { setDashboardRoute } from "../../../redux/Routing/actions";
import "./style.scss";

const Home = ({ setDashboardRoute, componentID }) => {
  const match = useRouteMatch();
  useEffect(() => {
    setDashboardRoute(componentID);
    // eslint-disable-next-line
  }, []);

  return (
    <Switch>
      <Redirect exact from={`${match.url}/`} to={`${match.url}/projects`} />
      <Route path={`${match.url}/projects`} exact={true} component={AllProjects} />
      <Route path={`${match.url}/projects/:projectID`} exact={true} component={ProjectDetails} />
      <Route path={`${match.url}/projects/:projectID/bug/:bugID`} exact={true} component={SingleBug} />
      <Redirect to={`/dashboard/home`} />
    </Switch>
  );
};

export default connect(null, { setDashboardRoute })(Home);
