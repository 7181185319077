import {
  GET_ADMIN_ALL_CLIENTS,
  GET_ADMIN_ALL_CLIENTS_SUCCESS,
  GET_ADMIN_ALL_CLIENTS_ERROR,
  GET_ADMIN_ACTIVE_CLIENTS,
  GET_ADMIN_ACTIVE_CLIENTS_SUCCESS,
  GET_ADMIN_ACTIVE_CLIENTS_ERROR,
  GET_ADMIN_ALL_TESTER,
  GET_ADMIN_ALL_TESTER_SUCCESS,
  GET_ADMIN_ALL_TESTER_ERROR,
  GET_ADMIN_ACTIVE_TESTER,
  GET_ADMIN_ACTIVE_TESTER_SUCCESS,
  GET_ADMIN_ACTIVE_TESTER_ERROR,
  GET_ADMIN_DASHBOARD_STATS,
  GET_ADMIN_DASHBOARD_STATS_SUCCESS,
  GET_ADMIN_DASHBOARD_STATS_ERROR,
  GET_ADMIN_DASHBOARD_SEED,
  GET_ADMIN_DASHBOARD_SEED_SUCCESS,
  GET_ADMIN_DASHBOARD_SEED_ERROR,
  PUT_AGENCY_ACTIVATE,
  PUT_AGENCY_ACTIVATE_SUCCESS,
  PUT_AGENCY_ACTIVATE_ERROR,
  PUT_AGENCY_DEACTIVATE,
  PUT_AGENCY_DEACTIVATE_SUCCESS,
  PUT_AGENCY_DEACTIVATE_ERROR,
  PUT_TESTER_ACTIVATE,
  PUT_TESTER_ACTIVATE_SUCCESS,
  PUT_TESTER_ACTIVATE_ERROR,
  PUT_TESTER_DEACTIVATE,
  PUT_TESTER_DEACTIVATE_SUCCESS,
  PUT_TESTER_DEACTIVATE_ERROR,
  GET_TESTER_ALL_PROJECTS,
  GET_TESTER_ALL_PROJECTS_SUCCESS,
  GET_TESTER_ALL_PROJECTS_ERROR,
  PUT_TESTER_MAKE_REVIEWER,
  PUT_TESTER_MAKE_REVIEWER_SUCCESS,
  PUT_TESTER_MAKE_REVIEWER_ERROR,
  PUT_TESTER_MAKE_TESTER,
  PUT_TESTER_MAKE_TESTER_SUCCESS,
  PUT_TESTER_MAKE_TESTER_ERROR,
  GET_TESTER_SINGLE_PROJECTS,
  GET_TESTER_SINGLE_PROJECTS_SUCCESS,
  GET_TESTER_SINGLE_PROJECTS_ERROR,
  GET_FEEDBACKS,
  GET_FEEDBACKS_SUCCESS,
  GET_FEEDBACKS_ERROR,
  GET_ADMIN_LIVE_PROJECTS,
  GET_ADMIN_LIVE_PROJECTS_SUCCESS,
  GET_ADMIN_LIVE_PROJECTS_ERROR,
  GET_ADMIN_PAUSED_PROJECTS,
  GET_ADMIN_PAUSED_PROJECTS_SUCCESS,
  GET_ADMIN_PAUSED_PROJECTS_ERROR,
  GET_ADMIN_COMPLETED_PROJECTS,
  GET_ADMIN_COMPLETED_PROJECTS_SUCCESS,
  GET_ADMIN_COMPLETED_PROJECTS_ERROR,
  GET_TESTER_BANK_DETAILS,
  GET_TESTER_BANK_DETAILS_SUCCESS,
  GET_TESTER_BANK_DETAILS_ERROR,
  GET_TESTER_DEVICE_DETAILS,
  GET_TESTER_DEVICE_DETAILS_SUCCESS,
  GET_TESTER_DEVICE_DETAILS_ERROR,
  GET_TESTER_SKILLSET_DETAILS,
  GET_TESTER_SKILLSET_DETAILS_SUCCESS,
  GET_TESTER_SKILLSET_DETAILS_ERROR,
  INVITE_TESTER_ADMIN,
  INVITE_TESTER_ADMIN_SUCCESS,
  INVITE_TESTER_ADMIN_ERROR,
  INVITE_PARTICULAR_TESTER_ADMIN,
  INVITE_PARTICULAR_TESTER_ADMIN_SUCCESS,
  INVITE_PARTICULAR_TESTER_ADMIN_ERROR,
  GET_ADMIN_ALL_EARNING,
  GET_ADMIN_ALL_EARNING_SUCCESS,
  GET_ADMIN_ALL_EARNING_ERROR,
  PUT_ADMIN_DASHBOARD_MARK_AS_COMPLETE,
  PUT_ADMIN_DASHBOARD_MARK_AS_COMPLETE_SUCCESS,
  PUT_ADMIN_DASHBOARD_MARK_AS_COMPLETE_ERROR,
  GET_BADGES,
  GET_BADGES_SUCCESS,
  GET_BADGES_ERROR,
  GET_EVENT_BADGES_SUCCESS,
  GET_EVENT_BADGES_ERROR,
  GET_EVENT_BADGES,
  DELETE_BADGE,
  DELETE_BADGE_SUCCESS,
  DELETE_BADGE_ERROR,
  EDIT_BADGE,
  EDIT_BADGE_SUCCESS,
  EDIT_BADGE_ERROR,
  EDIT_EVENT,
  EDIT_EVENT_ERROR,
  EDIT_EVENT_SUCCESS,
  CLEAR_SELECTED_TESTER_ADMIN,
  INVITE_SELECTED_TESTER_ADMIN,
  GET_TESTIMONIALS,
  GET_TESTIMONIALS_SUCCESS,
  GET_TESTIMONIALS_ERROR,
  APPROVE_TESTIMONIAL,
  REJECT_TESTIMONIAL,
  UPDATE_TESTIMONIAL,
  APPROVE_TESTIMONIAL_SUCCESS,
  APPROVE_TESTIMONIAL_ERROR,
  REJECT_TESTIMONIAL_SUCCESS,
  REJECT_TESTIMONIAL_ERROR,
  UPDATE_TESTIMONIAL_SUCCESS,
  UPDATE_TESTIMONIAL_ERROR,
  GET_ADMIN_TESTERS_EXPORT,
  GET_ADMIN_TESTERS_EXPORT_SUCCESS,
  GET_ADMIN_TESTERS_EXPORT_ERROR,
  GET_FINANCE_REPORT,
  GET_FINANCE_REPORT_SUCCESS,
  GET_FINANCE_REPORT_ERROR,
  GET_REVIEWER,
  GET_REVIEWER_SUCCESS,
  GET_REVIEWER_ERROR,
  ASSIGN_REVIEWER_BUG,
  ASSIGN_REVIEWER_BUG_SUCCESS,
  ASSIGN_REVIEWER_BUG_ERROR,
  UPDATE_REVIEWER,
  UPDATE_REVIEWER_SUCCESS,
  UPDATE_REVIEWER_ERROR
} from "../actions";

const initialState = {
  isError: false,
  isLoading: false,
  adminAllClients: [],
  adminActiveClients: [],
  adminAllTesters: [],
  adminTesterExport:[],
  adminAllEarnings: [],
  adminActiveTesters: [],
  adminDashboardSeed: [],
  adminDashboardStats: [],
  agencyStatus: [],
  testerStatus: [],
  testerAllProjects: [],
  testerSingleProjects: [],
  makeReviewer: [],
  makeTester: [],
  feedbacks: [],
  adminLiveProjects: [],
  adminPausedProjects: [],
  adminCompletedProjects: [],
  testerBankDetails: [],
  testerDeviceDetails: [],
  testerSkillsetDetails: [],
  testerInvites: false,
  testerInvitesList: [],
  particularTesterInvite: false,
  allBadges: [],
  allEventBadges: [],
  testimonials: [],
  financeReportData:[],
  allReviewer:[],
};

const fun = (state = initialState, action) => {
  switch (action.type) {
    // Admin All Clients
    case GET_ADMIN_ALL_CLIENTS:
      return {
        ...state,
        adminAllClients: [],
        isLoading: true,
        isError: false,
      };
    case GET_ADMIN_ALL_CLIENTS_SUCCESS:
      return {
        ...state,
        adminAllClients: action.payload,
        isLoading: false,
        isError: false,
      };
    case GET_ADMIN_ALL_CLIENTS_ERROR:
      return {
        ...state,
        isLoading: false,
        adminAllClients: [],
        isError: true,
      };

    // Admin Active Clients
    case GET_ADMIN_ACTIVE_CLIENTS:
      return {
        ...state,
        adminActiveClients: [],
        isLoading: true,
        isError: false,
      };
    case GET_ADMIN_ACTIVE_CLIENTS_SUCCESS:
      return {
        ...state,
        adminActiveClients: action.payload,
        isLoading: false,
        isError: false,
      };
    case GET_ADMIN_ACTIVE_CLIENTS_ERROR:
      return {
        ...state,
        isLoading: false,
        adminActiveClients: [],
        isError: true,
      };

    // Admin All Testers
    case GET_ADMIN_ALL_TESTER:
      return {
        ...state,
        adminAllTesters: [],
        isLoading: true,
        isError: false,
      };
    case GET_ADMIN_ALL_TESTER_SUCCESS:
      return {
        ...state,
        adminAllTesters: action.payload,
        isLoading: false,
        isError: false,
      };
    case GET_ADMIN_ALL_TESTER_ERROR:
      return {
        ...state,
        isLoading: false,
        adminAllTesters: [],
        isError: true,
      };

    case GET_ADMIN_TESTERS_EXPORT:
      return {
        ...state,
        isLoading: true,
        adminTesterExport:[],
        isError:false
      }
      
    case GET_ADMIN_TESTERS_EXPORT_SUCCESS:
      return {
        ...state,
        isLoading:false,
        adminTesterExport:action.payload,
        isError:false
      }
      
    case GET_ADMIN_TESTERS_EXPORT_ERROR:
      return {
        ...state,
        isLoading:false,
        adminTesterExport:[],
        isError:true
      }  

    // Admin All Earnings
    case GET_ADMIN_ALL_EARNING:
      return {
        ...state,
        adminAllEarnings: [],
        isLoading: true,
        isError: false,
      };
    case GET_ADMIN_ALL_EARNING_SUCCESS:
      return {
        ...state,
        adminAllEarnings: action.payload,
        isLoading: false,
        isError: false,
      };
    case GET_ADMIN_ALL_EARNING_ERROR:
      return {
        ...state,
        isLoading: false,
        adminAllEarnings: [],
        isError: true,
      };

    // Admin Active Testers
    case GET_ADMIN_ACTIVE_TESTER:
      return {
        ...state,
        adminActiveTesters: [],
        isLoading: true,
        isError: false,
      };
    case GET_ADMIN_ACTIVE_TESTER_SUCCESS:
      return {
        ...state,
        adminActiveTesters: action.payload,
        isLoading: false,
        isError: false,
      };
    case GET_ADMIN_ACTIVE_TESTER_ERROR:
      return {
        ...state,
        isLoading: false,
        adminActiveTesters: [],
        isError: true,
      };

    // Admin Seed Dashboard
    case GET_ADMIN_DASHBOARD_SEED:
      return {
        ...state,
        adminDashboardSeed: [],
        isLoading: true,
        isError: false,
      };
    case GET_ADMIN_DASHBOARD_SEED_SUCCESS:
      return {
        ...state,
        adminDashboardSeed: action.payload,
        isLoading: false,
        isError: false,
      };
    case GET_ADMIN_DASHBOARD_SEED_ERROR:
      return {
        ...state,
        isLoading: false,
        adminDashboardSeed: [],
        isError: true,
      };

    // Admin Dashboard Stats
    case GET_ADMIN_DASHBOARD_STATS:
      return {
        ...state,
        adminDashboardStats: [],
        isLoading: true,
        isError: false,
      };
    case GET_ADMIN_DASHBOARD_STATS_SUCCESS:
      return {
        ...state,
        adminDashboardStats: action.payload,
        isLoading: false,
        isError: false,
      };
    case GET_ADMIN_DASHBOARD_STATS_ERROR:
      return {
        ...state,
        isLoading: false,
        adminDashboardStats: [],
        isError: true,
      };

    // AGENCY ACTIVATE
    case PUT_AGENCY_ACTIVATE:
      return {
        ...state,
        isLoading: true,
        isError: false,
      };
    case PUT_AGENCY_ACTIVATE_SUCCESS:
      return {
        ...state,
        agencyStatus: [...state.agencyStatus, action.payload],
        isLoading: false,
        isError: false,
      };
    case PUT_AGENCY_ACTIVATE_ERROR:
      return {
        ...state,
        isLoading: false,
        isError: true,
      };

    // AGENCY DEACTIVATE
    case PUT_AGENCY_DEACTIVATE:
      return {
        ...state,
        isLoading: true,
        isError: false,
      };
    case PUT_AGENCY_DEACTIVATE_SUCCESS:
      return {
        ...state,
        agencyStatus: [...state.agencyStatus, action.payload],
        isLoading: false,
        isError: false,
      };
    case PUT_AGENCY_DEACTIVATE_ERROR:
      return {
        ...state,
        isLoading: false,
        isError: true,
      };

    // TESTER ACTIVATE
    case PUT_TESTER_ACTIVATE:
      return {
        ...state,
        isLoading: true,
        isError: false,
      };
    case PUT_TESTER_ACTIVATE_SUCCESS:
      return {
        ...state,
        testerStatus: [...state.testerStatus, action.payload],
        isLoading: false,
        isError: false,
      };
    case PUT_TESTER_ACTIVATE_ERROR:
      return {
        ...state,
        isLoading: false,
        isError: true,
      };

    // TESTER DEACTIVATE
    case PUT_TESTER_DEACTIVATE:
      return {
        ...state,
        isLoading: true,
        isError: false,
      };
    case PUT_TESTER_DEACTIVATE_SUCCESS:
      return {
        ...state,
        testerStatus: [...state.testerStatus, action.payload],
        isLoading: false,
        isError: false,
      };
    case PUT_TESTER_DEACTIVATE_ERROR:
      return {
        ...state,
        isLoading: false,
        isError: true,
      };

    // TESTER ALL PROJECTS
    case GET_TESTER_ALL_PROJECTS:
      return {
        ...state,
        testerAllProjects: [],
        isLoading: true,
        isError: false,
      };
    case GET_TESTER_ALL_PROJECTS_SUCCESS:
      return {
        ...state,
        testerAllProjects: action.payload,
        isLoading: false,
        isError: false,
      };
    case GET_TESTER_ALL_PROJECTS_ERROR:
      return {
        ...state,
        isLoading: false,
        testerAllProjects: [],
        isError: true,
      };

    //MARK_AS_COMPLETE
    case PUT_ADMIN_DASHBOARD_MARK_AS_COMPLETE:
      return {
        ...state,
        isLoading: false,
        isError: true,
      };
    case PUT_ADMIN_DASHBOARD_MARK_AS_COMPLETE_SUCCESS:
      return {
        ...state,
        // projectStatus: [...state.projectStatus, action.payload],
        isLoading: false,
        isError: true,
      };
    case PUT_ADMIN_DASHBOARD_MARK_AS_COMPLETE_ERROR:
      return {
        ...state,
        isLoading: false,
        isError: true,
      };

    // TESTER SINGLE PROJECTS
    case GET_TESTER_SINGLE_PROJECTS:
      return {
        ...state,
        testerSingleProjects: [],
        isLoading: true,
        isError: false,
      };
    case GET_TESTER_SINGLE_PROJECTS_SUCCESS:
      return {
        ...state,
        testerSingleProjects: action.payload,
        isLoading: false,
        isError: false,
      };
    case GET_TESTER_SINGLE_PROJECTS_ERROR:
      return {
        ...state,
        isLoading: false,
        testerSingleProjects: [],
        isError: true,
      };

    // MAKE REVIEWER
    case PUT_TESTER_MAKE_REVIEWER:
      return {
        ...state,
        isLoading: true,
        isError: false,
      };
    case PUT_TESTER_MAKE_REVIEWER_SUCCESS:
      return {
        ...state,
        makeReviewer: [...state.makeReviewer, action.payload],
        isLoading: false,
        isError: false,
      };
    case PUT_TESTER_MAKE_REVIEWER_ERROR:
      return {
        ...state,
        isLoading: false,
        isError: true,
      };

    // MAKE TESTER
    case PUT_TESTER_MAKE_TESTER:
      return {
        ...state,
        isLoading: true,
        isError: false,
      };
    case PUT_TESTER_MAKE_TESTER_SUCCESS:
      return {
        ...state,
        makeTester: [...state.makeTester, action.payload],
        isLoading: false,
        isError: false,
      };
    case PUT_TESTER_MAKE_TESTER_ERROR:
      return {
        ...state,
        isLoading: false,
        isError: true,
      };

    // ALL FEEDBACKS
    case GET_FEEDBACKS:
      return {
        ...state,
        feedbacks: [],
        isLoading: true,
        isError: false,
      };
    case GET_FEEDBACKS_SUCCESS:
      return {
        ...state,
        feedbacks: action.payload,
        isLoading: false,
        isError: false,
      };
    case GET_FEEDBACKS_ERROR:
      return {
        ...state,
        isLoading: false,
        feedbacks: [],
        isError: true,
      };

    // Admin Live Projects
    case GET_ADMIN_LIVE_PROJECTS:
      return {
        ...state,
        adminLiveProjects: [],
        isLoading: true,
        isError: false,
      };
    case GET_ADMIN_LIVE_PROJECTS_SUCCESS:
      return {
        ...state,
        adminLiveProjects: action.payload,
        isLoading: false,
        isError: false,
      };
    case GET_ADMIN_LIVE_PROJECTS_ERROR:
      return {
        ...state,
        isLoading: false,
        adminLiveProjects: [],
        isError: true,
      };

    // Admin Paused Projects
    case GET_ADMIN_PAUSED_PROJECTS:
      return {
        ...state,
        adminPausedProjects: [],
        isLoading: true,
        isError: false,
      };
    case GET_ADMIN_PAUSED_PROJECTS_SUCCESS:
      return {
        ...state,
        adminPausedProjects: action.payload,
        isLoading: false,
        isError: false,
      };
    case GET_ADMIN_PAUSED_PROJECTS_ERROR:
      return {
        ...state,
        isLoading: false,
        adminPausedProjects: [],
        isError: true,
      };

    // Admin Completed Projects
    case GET_ADMIN_COMPLETED_PROJECTS:
      return {
        ...state,
        adminCompletedProjects: [],
        isLoading: true,
        isError: false,
      };
    case GET_ADMIN_COMPLETED_PROJECTS_SUCCESS:
      return {
        ...state,
        adminCompletedProjects: action.payload,
        isLoading: false,
        isError: false,
      };
    case GET_ADMIN_COMPLETED_PROJECTS_ERROR:
      return {
        ...state,
        isLoading: false,
        adminCompletedProjects: [],
        isError: true,
      };

    // Tester Bank Details
    case GET_TESTER_BANK_DETAILS:
      return {
        ...state,
        testerBankDetails: [],
        isLoading: true,
        isError: false,
      };
    case GET_TESTER_BANK_DETAILS_SUCCESS:
      return {
        ...state,
        testerBankDetails: action.payload,
        isLoading: false,
        isError: false,
      };
    case GET_TESTER_BANK_DETAILS_ERROR:
      return {
        ...state,
        isLoading: false,
        testerBankDetails: [],
        isError: true,
      };

    // Tester Device Details
    case GET_TESTER_DEVICE_DETAILS:
      return {
        ...state,
        testerDeviceDetails: [],
        isLoading: true,
        isError: false,
      };
    case GET_TESTER_DEVICE_DETAILS_SUCCESS:
      return {
        ...state,
        testerDeviceDetails: action.payload,
        isLoading: false,
        isError: false,
      };
    case GET_TESTER_DEVICE_DETAILS_ERROR:
      return {
        ...state,
        isLoading: false,
        testerDeviceDetails: [],
        isError: true,
      };

    // Tester Skillset Details
    case GET_TESTER_SKILLSET_DETAILS:
      return {
        ...state,
        testerSkillsetDetails: [],
        isLoading: true,
        isError: false,
      };
    case GET_TESTER_SKILLSET_DETAILS_SUCCESS:
      return {
        ...state,
        testerSkillsetDetails: action.payload,
        isLoading: false,
        isError: false,
      };
    case GET_TESTER_SKILLSET_DETAILS_ERROR:
      return {
        ...state,
        isLoading: false,
        testerSkillsetDetails: [],
        isError: true,
      };

    // Invite Tester
    case INVITE_TESTER_ADMIN:
      return {
        ...state,
        testerInvites: false,
        isLoading: true,
        isError: false,
      };
    case INVITE_TESTER_ADMIN_SUCCESS:
      return {
        ...state,
        testerInvites: true,
        testerInvitesList: [...action.payload],
        isLoading: false,
        isError: false,
      };
    case INVITE_TESTER_ADMIN_ERROR:
      return {
        ...state,
        testerInvites: false,
        isLoading: false,
        isError: true,
      };
    case INVITE_SELECTED_TESTER_ADMIN:
      return {
        ...state,
      };
    case CLEAR_SELECTED_TESTER_ADMIN:
      return {
        ...state,
        testerInvitesList: [],
      };

    // Invite Particular Tester
    case INVITE_PARTICULAR_TESTER_ADMIN:
      return {
        ...state,
        particularTesterInvite: false,
        isLoading: true,
        isError: false,
      };
    case INVITE_PARTICULAR_TESTER_ADMIN_SUCCESS:
      return {
        ...state,
        particularTesterInvite: true,
        isLoading: false,
        isError: false,
      };
    case INVITE_PARTICULAR_TESTER_ADMIN_ERROR:
      return {
        ...state,
        particularTesterInvite: false,
        isLoading: false,
        isError: true,
      };

    case GET_BADGES:
      return {
        ...state,
        isLoading: true,
      };

    case GET_BADGES_SUCCESS:
      return {
        ...state,
        isLoading: false,
        allBadges: [...action.payload],
      };
    case GET_BADGES_ERROR:
      return {
        ...state,
        isLoading: false,
        allBadges: [],
      };

    case GET_EVENT_BADGES:
      return {
        ...state,
        isLoading: true,
      };

    case GET_EVENT_BADGES_SUCCESS:
      return {
        ...state,
        isLoading: false,
        allEventBadges: [...action.payload],
      };
    case GET_EVENT_BADGES_ERROR:
      return {
        ...state,
        isLoading: false,
        allEventBadges: [],
      };
    case DELETE_BADGE:
      return {
        ...state,
        isLoading: true,
      };
    case DELETE_BADGE_SUCCESS:
      return {
        ...state,
        isLoading: false,
      };
    case DELETE_BADGE_ERROR:
      return {
        ...state,
        isLoading: false,
      };
    case EDIT_BADGE:
      return {
        ...state,
        isLoading: true,
      };
    case EDIT_BADGE_SUCCESS:
      return {
        ...state,
        isLoading: false,
      };
    case EDIT_BADGE_ERROR:
      return {
        ...state,
        isLoading: false,
      };
    case EDIT_EVENT:
      return {
        ...state,
        isLoading: true,
      };
    case EDIT_EVENT_SUCCESS:
      return {
        ...state,
        isLoading: false,
      };
    case EDIT_EVENT_ERROR:
      return {
        ...state,
        isLoading: false,
      };
    //TESTIMONIALS
    case GET_TESTIMONIALS:
      return {
        ...state,
        isLoading: true,
      };
    case GET_TESTIMONIALS_SUCCESS:
      return {
        ...state,
        isLoading: false,
        testimonials: [...action.payload],
        isError: false,
      };
    case GET_TESTIMONIALS_ERROR:
      return {
        ...state,
        isLoading: false,
        testimonials: [],
        isError: true,
      };

    case APPROVE_TESTIMONIAL:
      return {
        ...state,
        isLoading: true,
      };

    case APPROVE_TESTIMONIAL_SUCCESS:
      return {
        ...state,
        isLoading: false,
      };

    case APPROVE_TESTIMONIAL_ERROR:
      return {
        ...state,
        isLoading: false,
      };

    case REJECT_TESTIMONIAL:
      return {
        ...state,
        isLoading: true,
      };

    case REJECT_TESTIMONIAL_SUCCESS:
      return {
        ...state,
        isLoading: false,
      };

    case REJECT_TESTIMONIAL_ERROR:
      return {
        ...state,
        isLoading: false,
      };

    case UPDATE_TESTIMONIAL:
      return {
        ...state,
        isLoading: true,
      };

    case UPDATE_TESTIMONIAL_SUCCESS:
      return {
        ...state,
        isLoading: false,
      };

    case UPDATE_TESTIMONIAL_ERROR:
      return {
        ...state,
        isLoading: false,
      };
    case GET_FINANCE_REPORT:
      return {
        ...state,
        isLoading: true,
      };

    case GET_FINANCE_REPORT_SUCCESS:
      return {
        ...state,
        isLoading: false,
        financeReportData:[...action.payload]
      };

    case GET_FINANCE_REPORT_ERROR:
      return {
        ...state,
        isLoading: false,
        financeReportData:[]
      };

    case GET_REVIEWER:
      return {
        ...state,
        isLoading:true,
        isError:false
      }
    case GET_REVIEWER_SUCCESS:
      return {
        ...state,
        isLoading:false,
        allReviewer:action.payload,
        isError:false
      }
    case GET_REVIEWER_ERROR:
      return {
        ...state,
        isLoading:false,
        allReviewer:[],
        isError:true
      }
    case ASSIGN_REVIEWER_BUG:
      return {
        ...state,
        isLoading:true
      }
    case ASSIGN_REVIEWER_BUG_SUCCESS:
      return {
        ...state,
        isLoading:false
      }
    case ASSIGN_REVIEWER_BUG_ERROR:
      return {
        ...state,
        isLoading:false
      } 
    case UPDATE_REVIEWER:
      return {
        ...state,
        isLoading:true,
      }
    case UPDATE_REVIEWER_SUCCESS:
      return {
        ...state,
        isLoading:false
      }
     case UPDATE_REVIEWER_ERROR:
       return {
         ...state,
         isLoading:false
       } 
    default:
      return { ...state };
  }
};

export default fun;
