import React, { useEffect } from "react";
import Form from "./From/index";
import AutoLogin from "../../components/AutoLogin";
import { Helmet } from "react-helmet";
import { withLayout } from "../../layouts";

import "./style.scss";
const ContactUs = ({ staticSiteNavHandler }) => {
  useEffect(() => {
    staticSiteNavHandler("CONTACTUS");
    // eslint-disable-next-line
  }, []);

  return (
    <div className="contact-us bg-light">
      <Helmet>
        <meta name="description" content="Automation testing services for mobile, web and software testing " />
      </Helmet>
      <AutoLogin />
      <Form/>
    </div>
  );
};

export default withLayout(ContactUs);
