import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import exportFromJSON from "export-from-json";

import InvitationModal from "../../../../Modals/InvitationModal";
import { toggleInviteModal } from "../../../../../redux/Routing/actions";
import { getAdminAllTesters, getAdminActiveTesters, inviteTester, getAdminTesterExport } from "../../../../../redux/AdminDashboard/actions";
import DashboardHeading from "../../../../Common/DashboardHeading";
import AllTestersTable from "../../../../Common/DashboardTables/AllTesters";
import Loader from "../../../../Loader";

import "./style.scss";

const TesterList = ({
  getAdminAllTesters,
  allTesters,
  getAdminActiveTesters,
  adminActiveTesters,
  isLoading,
  toggleInviteModal,
  showInviteModal,
  inviteTester,
  getAdminTesterExport,
  adminTesterExport,
}) => {
  useEffect(() => {
    //getAdminAllTesters(1);
    getAdminActiveTesters();
    getAdminTesterExport();
    // eslint-disable-next-line
  }, []);

  const [search1, setSearch1] = useState("");
  const [search2, setSearch2] = useState("");
  const [currentAllTesters, setCurrentAllTesters] = useState([]);
  const [online, setOnline] = useState(false);
  //const [pageNumber, setPageNumber] = useState(1);

  //const buttons = [];

  useEffect(() => {
    setCurrentAllTesters(adminTesterExport);
  }, [adminTesterExport]);

  const handleChange1 = e => {
    setCurrentAllTesters(filterName === "Active Testers" ? adminTesterExport : adminActiveTesters);
    setCurrentAllTesters(oldArray => oldArray.filter(item => item.name.toLowerCase().includes(e.target.value.toLowerCase())));
    setSearch1(e.target.value);
  };

  const handleChange2 = e => {
    setCurrentAllTesters(filterName === "Active Testers" ? adminTesterExport : adminActiveTesters);
    setCurrentAllTesters(oldArray => oldArray.filter(item => item.id.toLowerCase().startsWith(e.target.value.toLowerCase())));
    setSearch2(e.target.value);
  };

  const toogleInvite = () => {
    toggleInviteModal();
  };

  // const renderPagination = (i = 1) => {
  //   for (i; i <= currentAllTesters?.[0]?.count; i++) {
  //     buttons.push(i);
  //   }
  // };
  // renderPagination();
  // const handlePageChange = i => {
  //   if (i >= 1 && i <= buttons.length) {
  //     setPageNumber(i);
  //     getAdminAllTesters(i);
  //     getAdminActiveTesters(i);
  //   }
  // };

  const onlineTesters = adminTesterExport.filter(item => item.isOnline === true);

  const [filterName, setFilterName] = useState("Active Testers");
  const [onlineFilter, setOnlineFilter] = useState("Online Testers");
  const handleTesterList = async () => {
    filterName === "Active Testers" ? setFilterName("All Testers") : setFilterName("Active Testers");
    filterName === "Active Testers" ? setCurrentAllTesters(adminActiveTesters) : setCurrentAllTesters(adminTesterExport);
    adminActiveTesters.length === 0 && setOnline(true);
  };
  const handleOnlineTesters = () => {
    onlineFilter === "Online Testers" ? setOnlineFilter("All Testers") : setOnlineFilter("Online Testers");
    onlineFilter === "Online Testers" ? setCurrentAllTesters(onlineTesters) : setCurrentAllTesters(adminTesterExport);
    onlineTesters.length === 0 && setOnline(true);
  };

  const ExportToExcel = () => {
    let testerList = adminTesterExport.map(item => {
      let name = item.name.trim();
      return { ...item, name: name };
    });
    const myList = testerList && testerList.sort((a, b) => (a.name > b.name ? 1 : -1));
    const myNewList = myList.map((item, i) => {
      const deviceData = item.devices.map(({ name, extra }, i) => {
        const devices = extra.map(({ key, value }) => ({
          [key + "-" + (i + 1)]: value,
        }));
        const deviceObject = devices.reduce((obj, currData) => ({ ...obj, ...currData }));
        return {
          ["deviceName-" + (i + 1)]: name,
          ...deviceObject,
        };
      });
      return {
        id: item.id,
        name: item.name,
        Email: item.email,
        Age:item.age,
        Gender:item.gender,
        Earnings: item.data[0].earning,
        "Bugs Reported": item.data[0].bugReported,
        "Bugs Approved": item.data[0].bugApproved,
        "Badge Score": item.badge ? item.badge.currentScore : "0",
        Status: item.status,
        key: i,
        ...(deviceData.length !== 0 && deviceData.reduce((obj, currData) => ({ ...obj, ...currData }))),
        City: item.address?.[0]?.city?.name,
        Country: item.address?.[0]?.country?.name,

      };
    });

    const fileName = "Tester Details";
    const exportType = "xls";
    const data = myNewList;

    exportFromJSON({ data, fileName, exportType });
  };

  return (
    <div className="allTesters">
      <InvitationModal showModal={showInviteModal} toggleModal={toggleInviteModal} id={false} sendInvitation={inviteTester} />
      <DashboardHeading headingStrong={"Manage All Testers"} showBtn={false} />
      <div className="allTesters-btns-container">
        <div className="alltesters-invitation-search">
          <button className={"glb-action-btn bg-action"} onClick={toogleInvite}>
            Send invitation
          </button>
          <input type="text" value={search1} placeholder="Search by Name" className="search-tester" onChange={handleChange1} />
          <input type="text" value={search2} placeholder="Search by Id" className="search-tester" onChange={handleChange2} />
        </div>

        <div className="active-buttons-media">
          <button className="glb-btn glb-action-btn" onClick={handleOnlineTesters}>
            {onlineFilter}
          </button>
          <button className="glb-btn glb-action-btn" onClick={handleTesterList}>
            {filterName}
          </button>
          <button className="glb-btn glb-action-btn" onClick={ExportToExcel} disabled={isLoading}>
            Export
          </button>
        </div>
      </div>
      {isLoading ? <Loader /> : <AllTestersTable online={online} tableContentArray={currentAllTesters} />}
      {/* <div className="pagination-container">
      <div
            className="controller element"
            onClick={() => {
              handlePageChange(pageNumber - 1);
            }}
          >
            {"<"}
          </div>
        <div className="pagination">
          {buttons.map((item, idx) => {
            return (
              <p
                className={pageNumber === idx + 1 ? "controller element current" : "controller element"}
                onClick={() => {
                  handlePageChange(item);
                }}
              >
                {item}
              </p>
            );
          })}
        </div>
        <div
            className="controller element"
            onClick={() => {
              handlePageChange(pageNumber+1 <= currentAllTesters?.[0]?.count ?  pageNumber + 1: pageNumber );
            }}
          >
            {">"}
          </div>
      </div> */}
    </div>
  );
};
const mapStateToProps = ({ adminDashboard, routeingState }) => ({
  allTesters: adminDashboard.adminAllTesters,
  adminActiveTesters: adminDashboard.adminActiveTesters,
  adminTesterExport: adminDashboard.adminTesterExport,
  isLoading: adminDashboard.isLoading,
  showInviteModal: routeingState.showInviteModal,
});

export default connect(mapStateToProps, { getAdminAllTesters, getAdminActiveTesters, toggleInviteModal, inviteTester, getAdminTesterExport })(TesterList);
