import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { connect } from "react-redux";
import {useLocation} from 'react-router-dom'

import { setAlert } from "../../../../redux/Alerts/actions";
import { addTestStepOneSubmit, addTestStepTwoSubmit, addTestStepThreeSubmit, getPaymentPackage } from "../../../../redux/ClientAddTest/actions";
import settings from "../../../../constants/settings";
import { isURLValidator } from "../../../../utils/validation";

import StepperThreeSteps from "../../../Common/StepperThreeSteps/index";
import Loader from "../../../Loader";
import Step1 from "./Step1";
import Step2 from "./Step2";
import Step3 from "./Step3";
import Cart from "./Cart";
import PaymentProcessingPopUp from "./PaymentProcessingPopUp";

import "./style.scss";

const AddTests = ({
  projectID,
  isPackageLoading,
  packages,
  setAlert,
  addTestStepOneSubmit,
  addTestStepTwoSubmit,
  addTestStepThreeSubmit,
  getPaymentPackage,
  platforms,
}) => {
  const history = useHistory();
  const [step, setStep] = useState(1);
  const [isPaymentProcessStarted, setIsPaymentProcessStarted] = useState(false);
  const [cartData, setCartData] = useState({
    platformName: "",
    basePrice: "",
    packageName: "",
    deviceCount: "",
    multiplier: "",
  });
  const [platformFilter, setPlatformFilter] = useState({});
  const {pathname} = useLocation() ;
  const manualCard = "/dashboard/addtests/addtests/manualCard"

  useEffect(() => {
    getPaymentPackage();
    const query = new URLSearchParams(window.location.search);
    if (query.get("success")) {
      setAlert({
        message: settings.client_add_tests_messages.message1,
        type: "SUCCESS",
      });
    }
    if (query.get("canceled")) {
      setAlert({
        message: settings.client_add_tests_messages.message2,
        type: "ERROR",
      });
    }
    // eslint-disable-next-line
  }, []);

  // First Step
  const [formDataStepOne, setFormDataStepOne] = useState({
    title: "",
    platform: "",
  });
  const { title, platform } = formDataStepOne;

  // 2nd Step
  const [appLogo, setPlatformLogo] = useState(null);
  const [formDataStepTwo, setFormDataStepTwo] = useState({
    appComplexity: "",
    deviceCount: "35",
    description: "",
  });
  const { appComplexity, deviceCount, description } = formDataStepTwo;
  const handleChangeStepTwo = e => {
    setFormDataStepTwo({
      ...formDataStepTwo,
      [e.target.name]: e.target.value,
    });
  };

  // 3rd Step
  const [formFiles, setFormFiles] = useState({
    knownBug: null,
    file: null,
  });
  // const { knownBug, file } = formFiles;
  const [formDataStepThird, setFormDataStepThird] = useState({
    loginId: "",
    loginPassword: "",
    websiteLink: "",
    version: "",
    testingHour: "24",
    stage: "dev",
  });
  const { version, testingHour, stage ,websiteLink } = formDataStepThird;
  const handleChangeStepThree = e => {
    setFormDataStepThird({
      ...formDataStepThird,
      [e.target.name]: e.target.value,
    });
  };

  const handelContinue = () => {
    if (step === 1) {
      if (title === "" || platform === "" || !platform) {
        setAlert({
          message: settings.client_add_tests_messages.message3,
          type: "WARNING",
        });
      } else {
        addTestStepOneSubmit(formDataStepOne);
        setStep(2);
      }
    }
    if (projectID || step === 1) {
      if (step === 2) {
        if (appComplexity === "" || deviceCount === "" || description === "" || !appLogo || deviceCount === "null" || appComplexity === "null")
          setAlert({
            message: settings.client_add_tests_messages.message4,
            type: "WARNING",
          });
        else {
          addTestStepTwoSubmit({ ...formDataStepTwo, projectId: projectID }, appLogo);
          setStep(3);
        }
      }
      if (step === 3) {
        if (version === "" || testingHour === "" || stage === "")
          setAlert({
            message: settings.client_add_tests_messages.message4,
            type: "WARNING",
          });
          else if(manualCard=== pathname && websiteLink === ""){
            setAlert({
              message: "Please Add Website Url",
              type: "WARNING",
            });
          }
          else if(manualCard === pathname &&  isURLValidator(websiteLink)===false){
            setAlert({
              message: "Please Add Valid Website URL. Include http(s)://",
              type: "WARNING",
            });
          }
        else {
          addTestStepThreeSubmit({ ...formDataStepThird, projectId: projectID }, formFiles, history);
          setIsPaymentProcessStarted(true);
        }
      }
    } else {
      setAlert({
        message: settings.client_add_tests_messages.message5,
        type: "ERROR",
      });
      setStep(1);
    }
  };

  return (
    <div className="dashboard-client-addtest">
      {isPaymentProcessStarted && <PaymentProcessingPopUp />}
      {isPackageLoading ? (
        <Loader />
      ) : (
        <>
          <div className="addtest-steps">
            <div className="stepper">
              <StepperThreeSteps currentStep={step} />
            </div>
            {step === 1 && (
              <div className="step1">
                <Step1
                  formDataStepOne={formDataStepOne}
                  setFormDataStepOne={setFormDataStepOne}
                  setCartData={setCartData}
                  cartData={cartData}
                  platformFilter={platformFilter}
                  setPlatformFilter={setPlatformFilter}
                />
              </div>
            )}
            {step === 2 && (
              <div className="step1">
                <div className="back-btn" onClick={() => setStep(1)}>
                  {settings.client_add_tests_messages.back_btn}
                </div>
                <Step2
                  setPlatformLogo={setPlatformLogo}
                  appLogo={appLogo}
                  handleChangeStepTwo={handleChangeStepTwo}
                  formDataStepTwo={formDataStepTwo}
                  packages={packages}
                  setCartData={setCartData}
                  cartData={cartData}
                  platforms={platforms}
                  platformId={platform}
                  logo={"logo"}
                />
              </div>
            )}
            {step === 3 && (
              <div className="step1">
                <div className="back-btn" onClick={() => setStep(2)}>
                  {settings.client_add_tests_messages.back_btn}
                </div>
                <Step3 formFiles={formFiles} setFormFiles={setFormFiles} formDataStepThird={formDataStepThird} handleChangeStepThree={handleChangeStepThree} pathname={pathname} />
              </div>
            )}
          </div>
          <div className="addtest-cart-container">
            <Cart cartData={cartData} />
            <button className="glb-btn" onClick={() => handelContinue()}>
              {step !== 3 && settings.client_add_tests_messages.btnText1}
              {step === 3 && settings.client_add_tests_messages.btnText2}
            </button>
          </div>
        </>
      )}
    </div>
  );
};

const mapStateToProps = ({ ClientAddTest, staticData }) => ({
  projectID: ClientAddTest.currentProjectId,
  isPackageLoading: ClientAddTest.isPackageLoading,
  packages: ClientAddTest.packages,
  platforms: staticData.platforms,
});

export default connect(mapStateToProps, {
  setAlert,
  addTestStepOneSubmit,
  addTestStepTwoSubmit,
  addTestStepThreeSubmit,
  getPaymentPackage,
})(AddTests);
