import React from "react";
import settings from "../../../constants/settings";

import "./style.scss";
const StepperThreeSteps = ({ currentStep }) => {
  return (
    <div className="timeline">
      <div className="timeline-container">
        <div className="step">
          <div className="icon">
            <div>{settings.stepper_three_steps.icon1}</div>
          </div>
          <h4>{settings.stepper_three_steps.label1}</h4>
        </div>
        <div className={currentStep >= 2 ? "step active" : "step"}>
          <div className="icon">
            <div>{settings.stepper_three_steps.icon2}</div>
          </div>
          <h4>{settings.stepper_three_steps.label2}</h4>
        </div>
        <div className={currentStep >= 3 ? "step active" : "step"}>
          <div className="icon">
            <div>{settings.stepper_three_steps.icon3}</div>
          </div>
          <h4>{settings.stepper_three_steps.label3}</h4>
        </div>
      </div>
    </div>
  );
};

export default StepperThreeSteps;
