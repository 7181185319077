import React, { useState, useEffect } from "react";
import { useParams, useHistory } from "react-router-dom";
import { connect } from "react-redux";

import { addTestStepOneSubmit, addTestStepTwoWithStoreSubmit, addTestStepThreeSubmit, getPaymentPackage } from "../../../../../redux/ClientAddTest/actions";
import { getStaticPlatforms } from "../../../../../redux/staticData/actions";
import { appHint, appDetails } from "../../../../../redux/ClientDashboard/actions";
import { setAlert } from "../../../../../redux/Alerts/actions";
import Cart from "../Cart";
import Step1Integration from "../Step1Integration";
import Step2 from "../Step2";
import Step3 from "../Step3";
import PaymentProcessingPopUp from "../PaymentProcessingPopUp";
import StepperThreeSteps from "../../../../Common/StepperThreeSteps/index";
import Loader from "../../../../Loader";
import settings from "../../../../../constants/settings";
//import { isURLValidator } from "../../../../../utils/validation";

import "../style.scss";
import "./style.scss";
const AppController = ({
  appHint,
  appHintList,
  appDetails,
  appDetailsList,
  isLoading,
  setAlert,
  getPaymentPackage,
  getStaticPlatforms,
  addTestStepOneSubmit,
  addTestStepTwoWithStoreSubmit,
  addTestStepThreeSubmit,
  packages,
  projectID,
  isPackageLoading,
  platforms,
}) => {
  const [stepOne, setStepOne] = useState({
    title: "",
    downloadUrl: "",
  });

  const [AndroidId, setAndroidId] = useState("");
  const [iPhoneId, setiPhoneId] = useState("");

  useEffect(() => {
    getStaticPlatforms();
    getPaymentPackage();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    for (var i = 0; i <= platforms.length - 1; i++) {
      if (platforms[i].name === "Android") {
        setAndroidId(platforms[i].id);
      }
      if (platforms[i].name === "iPhone") {
        setiPhoneId(platforms[i].id);
      }
    }
    // eslint-disable-next-line
  }, [platforms]);

  const { store } = useParams();
  const storeName = store === "androidCard" ? "Android" : "iOS";
  const history = useHistory();
  const [step, setStep] = useState(1);
  const [isPaymentProcessStarted, setIsPaymentProcessStarted] = useState(false);

  const [isDisable, setIsDisable] = useState(true);
  const [cartData, setCartData] = useState({
    platformName: storeName,
    basePrice: "",
    packageName: "",
    deviceCount: "",
    multiplier: "",
  });

  const [appHintValue, setAppHint] = useState("");
  const hintData = {
    store_type: store === "androidCard" ? "play-store" : "app-store",
    term: appHintValue,
  };

  const [formDataStepTwo, setFormDataStepTwo] = useState({
    appComplexity: "",
    deviceCount: "35",
    description: stepOne.description,
    logo: "",
  });
  const { appComplexity, description } = formDataStepTwo;
  const [appLogo, setPlatformLogo] = useState(null);

  const handleChangeStepTwo = e => {
    setFormDataStepTwo({
      ...formDataStepTwo,
      [e.target.name]: e.target.value,
    });
  };

  const handleSearch = () => {
    document.getElementById("search-response").style.display = "block";
    appHint(hintData);
  };

  const loadAppData = appID => {
    document.getElementById("search-response").style.display = "none";
    setStepOne({
      title: "Loading ...",
      description: "Loading ...",
      downloadUrl: "Loading ...",
    });
    appDetails({
      store_type: store === "androidCard" ? "play-store" : "app-store",
      app_id: appID,
    });
    setIsDisable(false);
  };

  const [formFiles, setFormFiles] = useState({
    knownBug: null,
    file: null,
  });
  const [formDataStepThird, setFormDataStepThird] = useState({
    loginId: "",
    loginPassword: "",
    websiteLink: "",
    version: "",
    testingHour: "24",
    stage: "dev",
  });
  const { version, testingHour, stage } = formDataStepThird;
  const handleChangeStepThree = e => {
    setFormDataStepThird({
      ...formDataStepThird,
      [e.target.name]: e.target.value,
    });
  };

  useEffect(() => {
    if (appDetailsList && appDetailsList !== []) {
      setStepOne({
        title: appDetailsList.title,
        downloadUrl: appDetailsList.url,
      });
      setFormDataStepTwo({
        ...formDataStepTwo,
        description: appDetailsList.description,
      });
      setFormDataStepThird({
        ...formDataStepThird,
        version: appDetailsList.version,
      });
    }
    // eslint-disable-next-line
  }, [appDetailsList]);

  const handelContinue = title => {
    if (step === 1) {
      if (!stepOne.title || !stepOne.downloadUrl || stepOne.title === "") {
        setAlert({
          message: "Please Fill the Required Feilds.",
          type: "WARNING",
        });
      } else {
        addTestStepOneSubmit({ title: title, platform: store === "androidCard" ? `${AndroidId}` : `${iPhoneId}`, downloadUrl: stepOne.downloadUrl });
        setStep(2);
        setPlatformLogo(appDetailsList.icon);
        setIsDisable(false);
        setCartData({ ...cartData, deviceCount: 35, multiplier: 1 });
      }
    }

    if (projectID || step === 1) {
      if (step === 2) {
        if (appComplexity === "" || description === "" || !appLogo || appComplexity === "null")
          setAlert({
            message: settings.client_add_tests_messages.message4,
            type: "WARNING",
          });
        else {
          addTestStepTwoWithStoreSubmit({ ...formDataStepTwo, projectId: projectID, logo: appDetailsList.icon });
          setStep(3);
        }
      }
      if (step === 3) {
        // setFormDataStepThird({ ...formDataStepThird, websiteLink: stepOne.url });
        if (version === "" || testingHour === "" || stage === "")
          setAlert({
            message: settings.client_add_tests_messages.message4,
            type: "WARNING",
          });
        else {
          addTestStepThreeSubmit({ ...formDataStepThird, projectId: projectID }, formFiles, history);
          setIsPaymentProcessStarted(true);
        }
      }
    } else {
      setAlert({
        message: settings.client_add_tests_messages.message5,
        type: "ERROR",
      });
      setStep(1);
    }
  };

  return (
    <div className="dashboard-client-addtest">
      {isPaymentProcessStarted && <PaymentProcessingPopUp />}
      {isPackageLoading ? (
        <Loader />
      ) : (
        <>
          <div className="addtest-steps">
            <div className="stepper">
              <StepperThreeSteps currentStep={step} />
            </div>
            {step === 1 && (
              <Step1Integration
                stepOne={stepOne}
                appHintValue={appHintValue}
                setAppHint={setAppHint}
                handleSearch={handleSearch}
                isLoading={isLoading}
                appHintList={appHintList}
                setStepOne={setStepOne}
                loadAppData={loadAppData}
                store={store}
              />
            )}

            {step === 2 && (
              <div className="step2">
                <div className="back-btn" onClick={() => setStep(1)}>
                  Back
                </div>
                <Step2
                  isDisable={isDisable}
                  appLogo={appLogo}
                  handleChangeStepTwo={handleChangeStepTwo}
                  formDataStepTwo={formDataStepTwo}
                  packages={packages}
                  setCartData={setCartData}
                  cartData={cartData}
                  logo={"url"}
                  platformId={store === "androidCard" ? `${AndroidId}` : `${iPhoneId}`}
                />
              </div>
            )}

            {step === 3 && (
              <div className="step3">
                <div className="back-btn" onClick={() => setStep(2)}>
                  Back
                </div>
                <Step3 formFiles={formFiles} setFormFiles={setFormFiles} formDataStepThird={formDataStepThird} handleChangeStepThree={handleChangeStepThree} />
              </div>
            )}
          </div>
          <div className="addtest-cart-container">
            <Cart cartData={cartData} />
            <button className="glb-btn" onClick={() => handelContinue(stepOne.title)}>
              {step !== 3 && "CONTINUE"}
              {step === 3 && "Proceed To Payment"}
            </button>
          </div>
        </>
      )}
    </div>
  );
};

const mapStateToProps = ({ ClientDashboard, ClientAddTest, staticData }) => ({
  appHintList: ClientDashboard.appHintList,
  appDetailsList: ClientDashboard.appDetailsList,
  isLoading: ClientDashboard.isLoading,
  packages: ClientAddTest.packages,
  platforms: staticData.platforms,
  projectID: ClientAddTest.currentProjectId,
  isPackageLoading: ClientAddTest.isPackageLoading,
});

export default connect(mapStateToProps, {
  appHint,
  appDetails,
  setAlert,
  addTestStepOneSubmit,
  addTestStepTwoWithStoreSubmit,
  addTestStepThreeSubmit,
  getPaymentPackage,
  getStaticPlatforms,
})(AppController);
