import React from "react";
import { Link } from "react-router-dom";

import NoDataMessage from "../../NoDataMessage";
import settings from "../../../../constants/settings";

import "../style.scss";

// const tableHeadingArray = ["Project Name", "Earnings", "Rating", "Bugs Reported", "Bugs Approved", "Details"];

const CompletedTestTable = ({ tableContentArray }) => {
  return (
    <div className="dashboard-table completed-test-table">
      {tableContentArray.length === 0 ? (
        <NoDataMessage message={settings.completed_test_table.message} />
      ) : (
        <table>
          <tr>
            {settings.completed_test_table.tableHeadingArray.map((head, i) => (
              <th key={i}>{head}</th>
            ))}
          </tr>

          {tableContentArray.map((item, i) => (
            <tr key={i}>
              <td>{item.name}</td>
              <td className="remove">{item.earning ? "$ " + item.earning.toFixed(2) : "-"}</td>
              {/* <td className="remove" >{item.rating ? item.rating : "-"}</td> */}
              <td className="remove">{item.bugReported ? item.bugReported : "-"}</td>
              <td className="remove">{item.bugApproved ? item.bugApproved : "-"}</td>
              <td className="completed-view-details">
                <Link to={`/dashboard/test-taken/projects/completed/${item.projectId}`} className="inherit">
                  {settings.completed_test_table.view}
                </Link>
              </td>
            </tr>
          ))}
        </table>
      )}
    </div>
  );
};

export default CompletedTestTable;
