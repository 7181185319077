import React, { useState, useEffect } from "react";
import "./style.scss";

function EditEventModal({ data, setModal, showModal, handleSubmitEdit }) {
  const [formEvent, setFormEvent] = useState({
    score: null,
    badgeEvent: "",
    weightage: "",
  });

  useEffect(() => {
    setFormEvent({
      ...formEvent,
      score: data.score,
      badgeEvent: data.badgeEvent,
      weightage: data.weightage,
      id: data.id,
    });
    // eslint-disable-next-line
  }, [data]);

  const setInput = e => {
    setFormEvent({
      ...formEvent,
      [e.target.name]: e.target.value,
    });
  };

  const closeModal = () => {
    setModal(false);
  };

  const handleSubmit = e => {
    e.preventDefault();
    handleSubmitEdit(formEvent);
    setFormEvent({ ...formEvent, score: null, badgeEvent: "" });
  };
  return (
    <div className="editModal" style={showModal ? { display: "flex" } : { display: "none" }}>
      <div className="formModal" style={showModal ? { display: "flex" } : { display: "none" }}>
        <div className="formChildContainer">
          <button className="glb-btn" id="close-popUpEditEvent" onClick={closeModal}>
            &#215;
          </button>
          <h2 className="heading">Edit Event</h2>

          <form id="EventEditbadges-form" onSubmit={handleSubmit}>
            <div className="inputFieldBox">
              <label>Event Name</label>
              <br />
              <input value={formEvent.badgeEvent} type="text" name="badgeEvent" onChange={setInput} />
            </div>
            <div className="inputFieldBox">
              <label>Score</label>
              <br />
              <input value={formEvent.score} type="number" name="score" onChange={setInput} />
            </div>
            <div className="inputFieldBox">
              <label>Multiplier</label>
              <br />
              <input value={formEvent.weightage} type="number" name="weightage" onChange={setInput} />
            </div>
            <div style={{ textAlign: "center", width: "100%" }}>
              <button className="glb-btn" type="submit" id="Eventsubmit-badge">
                Submit
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}

export default EditEventModal;
