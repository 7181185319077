import React,{useState,useEffect} from 'react';
import { connect } from 'react-redux';
import { addComment, getComment} from '../../../redux/TesterDashboard/actions';
import { setAlert } from '../../../redux/Alerts/actions';
import "./style.scss"

const AddComment = ({addCommentModal,bugName,bugId,getComment,addComment,comments,user,setAlert}) => {


    useEffect(()=>{
        getComment(bugId)
    // eslint-disable-next-line
    },[])

    const [observation,setObservation] = useState();

    const postComment = (e)=>{
        if(observation === "" || observation === " "){
         setAlert({message:"Can't save empty comments",type:"WARNING"});
        } else{
            e.preventDefault();
            const payload = {
                comments:observation,
                bugId:bugId,
                commenter:user.name,
            }
            addComment(payload);
            addCommentModal();
        }
    }

    return (
        <div className="commentModal" >
          <div className="commentModalContainer" >
              <div style={{float:"right",cursor:"pointer",fontSize:"24px"}} onClick={addCommentModal} >&#x2716;</div>
          <h3>Bug Name:</h3>
          <h3>{bugName}</h3>
            <br/>
            <div className="allComments" >
            {comments.length!==0 && comments?.map(item=>{
                if(item.message){
                return <div className="singleComment" >
                <p className="commentMessage" >{item.message}</p>
                <small className="commenterName" >~ {item.commenter}</small>
                </div>}
                else {
                    return null;
                }
            })}</div>
            <br/>
            <h4>Add a Comment</h4>
            <form onSubmit={postComment} >
            <textarea placeholder="Add a comment" value={observation} onChange={(e)=>{setObservation(e.target.value)}} required ></textarea>
            <br/>
            <button className="glb-btn glb-action-btn" type="submit">Save</button>
            <br/>
            </form>
          </div>
        </div>
    )
}

const mapStateToProps = ({testerDashboard,auth})=>({
    comments:testerDashboard.comments,
    user:auth.user

})


export default connect(mapStateToProps,{addComment,getComment,setAlert})(AddComment);
