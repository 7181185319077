import React from "react";
import device5 from "../../../assets/icons/Group 10770.png";
import device6 from "../../../assets/icons/software-engineer.svg";
import device7 from "../../../assets/icons/tester.svg";
import device8 from "../../../assets/icons/3463339.png";
import tick from "../../../assets/icons/check.svg";
import iconsAndDescriptionImg from "../../../assets/iconAndDesSolution.png";

import { useHistory } from "react-router-dom";

import "./style.scss";

function Component2({ data = {} }) {
  const history = useHistory();
  return (
    <div className="component2">
      <h4 className="component2-heading">{data.heading2}</h4>

      <div className="icons-and-description">
        <h4 className="component2-heading">{data.heading2a}</h4>
        <div className="icons-placement">
          <div className="icon-first">
            <img src={device5} alt="Crowdtesting working" />
          </div>
          <div className="icon-second">
            <img src={device6} alt="Crowdtesting working" />
          </div>
          <div className="icon-third">
            <img src={device7} alt="Crowdtesting working" />
          </div>
          <div className="icon-fourth">
            <img src={device8} alt="Crowdtesting working" />
          </div>
        </div>

        <div className="styled-border">
          <div className="first-circle"></div>
          <div className="first-empty-div"></div>
          <div className="second-circle"></div>
          <div className="second-empty-div"></div>
          <div className="third-circle"></div>
          <div className="third-empty-div"></div>
          <div className="fourth-circle"></div>
        </div>

        <div className="icons-content">
          <div className="content-first">{data.item2a}</div>
          <div className="content-second">{data.item2b}</div>
          <div className="content-third">{data.item2c}</div>
          <div className="content-fourth">{data.item2d}</div>
        </div>

        <div className="icon-and-description-img">
          <img src={iconsAndDescriptionImg} alt="" />
        </div>
      </div>

      <h4 className="component2-heading">{data.heading2b}</h4>

      <div className="component2-grid">
        {/* <div className="component2-box-container">
          <div className="component2-box">
            <div className="white-tick">
              <img src={tick} alt="icon" />
            </div>
            <div>{data.content2a}</div>
          </div>
        </div>
        <div className="component2-box-container">
          <div className="component2-box">
            <div className="white-tick">
              <img src={tick} alt="icon" />
            </div>
            <div>{data.content2b}</div>
          </div>
        </div>
        <div className="component2-box-container">
          <div className="component2-box">
            <div className="white-tick">
              <img src={tick} alt="icon" />
            </div>
            <div>{data.content2c}</div>
          </div>
        </div>
        <div className="component2-box-container">
          <div className="glb-card component2-box">
            <div className="white-tick">
              <img src={tick} alt="icon" />
            </div>
            <div>{data.content2d}</div>
          </div>
        </div> */}
        <ul className="benefitsSeciton" >
          <li className="benefitsItem" ><img src={tick} alt="tick" />&ensp;{data.content2a}</li>
          <li className="benefitsItem" ><img src={tick} alt="tick" />&ensp;{data.content2b}</li>
        </ul>
        <ul className="benefitsSeciton" >
          <li className="benefitsItem" ><img src={tick} alt="tick" />&ensp;{data.content2c}</li>
          <li className="benefitsItem" ><img src={tick} alt="tick" />&ensp;{data.content2d}</li>
        </ul>
      </div>

      <div className="component2-content-btn">
        <div className="component2-content">
          <span className="text-bold">{data.description2a}&nbsp;</span>
          {data.description2b}
        </div>
        <div className="glb-btn component2-btn" onClick={() => history.push("/signup/setup")}>
          {data.btnText}
        </div>
      </div>
    </div>
  );
}

export default Component2;
