import React from "react";
// import settings from "../../../../constants/settings";

import "../style.scss";

const TesterPricingTable = ({ tableContentArray }) => {

  const tableHeadingArray = [ "Type of Bug", "Reward" ];
  return (
    <div className="dashboard-table tester-pricing-table">
      
        <table>
          <tr>
            {tableHeadingArray.map((head, i) => (
              <th key={i}>{head}</th>
            ))}
          </tr>

          {tableContentArray.map(({ bugType, reward }, i) => (
            <tr key={i}>
              <td>{bugType}</td>
              <td>{reward}</td>
            </tr>
          ))}
        </table>
      
    </div>
  );
};

export default TesterPricingTable;
