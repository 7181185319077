import React, { useState, useEffect } from "react";
import { connect } from "react-redux";

import { SearchIcon } from "../../../../../assets/icons/icons";
import { getAllSkills, createTesterSkill, getTesterSkills, deleteTesterSkill } from "../../../../../redux/TesterDashboard/actions";
import DashboardHeading from "../../../../Common/DashboardHeading";
import Skillset from "../../../../Common/Skillset";
import Loader from "../../../../Loader";
import NoDataMessage from "../../../../Common/NoDataMessage";
import AddSkillPopUp from "./AddSkillPopUp";
import settings from "../../../../../constants/settings";
import { setAlert } from "../../../../../redux/Alerts/actions";

import "./style.scss";

const Skills = ({ getAllSkills, allSkills, createTesterSkill, isLoading, getTesterSkills, testerSkills, deleteTesterSkill }) => {
  const [showSkills, setShowSkills] = useState(false);
  const [showAddSkill, setShowAddSkill] = useState(false);
  const [search, setSearch] = useState("");
  const [currentAllSkills, setCurrentAllSkills] = useState([]);
  const [popUpData, setPopUpData] = useState({
    name: "",
    skillId: "",
  });
  useEffect(() => {
    getTesterSkills();
    getAllSkills();
    // eslint-disable-next-line
  }, []);
  const handleSkillSelect = ({ name, id }) => {
    setPopUpData({
      name,
      skillId: id,
    });
    setShowAddSkill(true);
  };

  useEffect(() => {
    let testerIds = testerSkills.map(item => item.id);
    setCurrentAllSkills(allSkills);
    setCurrentAllSkills(allSkills.filter(skills => !testerIds.includes(skills.id)));
    // eslint-disable-next-line
  }, [testerSkills]);

  const handleChange = e => {
    let testerIds = testerSkills.map(item => item.id);
    allSkills = allSkills.filter(skills => !testerIds.includes(skills.id));
    setCurrentAllSkills(allSkills);
    setCurrentAllSkills(oldArray => oldArray.filter(item => item.name.toLowerCase().startsWith(e.target.value.toLowerCase())));
    setSearch(e.target.value);
  };
  
  const clearSearch = () => { 
      setTimeout(()=>{
        setShowSkills(false);
      },500)
      setSearch('')
  }
  
  const skillsSet = () => {
    setShowSkills(true); 
    let testerIds = testerSkills.map(item => item.id);
    allSkills = allSkills.filter(skills => !testerIds.includes(skills.id));
    setCurrentAllSkills(allSkills);
  }

  return (
    <div className="boostmultiplyer-skills">
      <AddSkillPopUp
        showAddSkill={showAddSkill}
        setAlert={setAlert}
        popUpData={popUpData}
        hideShowAddSkill={() => setShowAddSkill(false)}
        createTesterSkill={createTesterSkill}
        getTesterSkills={getTesterSkills}
      />
      <div className="add-skills">
        <DashboardHeading heading={settings.boostmultiplier.add_skills.heading1.light} headingStrong={settings.boostmultiplier.add_skills.heading1.bold} />
        {isLoading ? (
          <Loader />
        ) : (
          <>
            <div className="card" id="searchbar">
              <div className="img">
                <SearchIcon color="#828282" size="17px" />
              </div>
              <input
                type="text"
                value={search}
                onChange={handleChange} 
                onFocus={skillsSet} 
                onBlur={clearSearch} 
              />
            </div>
            <div className={showSkills ? "select-skill" : "select-skill displayNone"} id="SkillInput">
              {currentAllSkills.length === 0 ? (
                <div className="no-skills-found">{settings.boostmultiplier.add_skills.label}</div>
              ) : (
                currentAllSkills.map(({ name, id }, i) => (
                  <div className="select-skill-item" key={i} onClick={() => handleSkillSelect({ name, id })}>
                    <div className="selector">
                      <div className="selector-inside"></div>
                    </div>
                    <div className="skill-name">{name}</div>
                  </div>
                ))
              )}
            </div>
          </>
        )}
      </div>

      <div className="my-skills">
        <DashboardHeading heading={settings.boostmultiplier.add_skills.heading2.light} headingStrong={settings.boostmultiplier.add_skills.heading2.bold} />
      </div>
      {isLoading ? (
        <Loader />
      ) : (
        <div className="skillset-grid">
          {testerSkills && testerSkills.length === 0 ? (
            <NoDataMessage message={settings.boostmultiplier.add_skills.label} />
          ) : (
            testerSkills.map((skill, i) => <Skillset name={skill.name} rating={skill.rating} key={i} deleteSkill={deleteTesterSkill} skillID={skill.id} />)
          )}
        </div>
      )}
    </div>
  );
};

const mapStateToProps = ({ testerDashboard }) => ({
  allSkills: testerDashboard.allSkillsList,
  testerSkills: testerDashboard.testerSkills,
  isLoading: testerDashboard.isLoading,
});

export default connect(mapStateToProps, { getAllSkills, createTesterSkill, getTesterSkills, deleteTesterSkill, setAlert })(Skills);
