import { combineReducers } from "redux";
import auth from "./auth/reducers";
import alert from "./Alerts/reducers";
import routeingState from "./Routing/reducers";
import staticData from "./staticData/reducers";
import testerDashboard from "./TesterDashboard/reducers";
import ClientAddTest from "./ClientAddTest/reducers";
import ClientDashboard from "./ClientDashboard/reducers";
import adminDashboard from "./AdminDashboard/reducers";

const reducers = combineReducers({
  auth,
  alert,
  routeingState,
  staticData,
  testerDashboard,
  ClientAddTest,
  ClientDashboard,
  adminDashboard,
});

export default reducers;
