import React,{useEffect} from 'react';
import { connect } from 'react-redux';
import { useParams } from 'react-router';
import { getAssignedBugs } from '../../../../../redux/TesterDashboard/actions';
import { getReviewer, assignReviewer, updateReviewer } from '../../../../../redux/AdminDashboard/actions';
import BugListTable from '../../../../Common/DashboardTables/BugListTable';
//import Loader from '../../../../Loader';

const BugsAssigned = ({getAssignedBugs,assignedBugs,user,getReviewer,assignReviewer,updateReviewer,allReviewer,isCommentLoaded}) => {
let {projectId} = useParams();

useEffect(() => {
getAssignedBugs({projectID:projectId, userID:user.userId});
getReviewer()
//eslint-disable-next-line
},[])

const assignedList = assignedBugs?.[0]?.bug || [];
    return (
        <div>
           <BugListTable assignedTable={true}  role={"REVIEWER"} projectID={projectId} tableContentArray={assignedList} reviewer={allReviewer} updateReviewer={updateReviewer} assignReviewer={assignReviewer} />
        </div>
    )
}

const mapStateToProps = ({ testerDashboard,auth,adminDashboard }) => ({
    assignedBugs:testerDashboard.assignedBugs,
    isCommentLoaded: testerDashboard.isCommentLoaded,
    allReviewer: adminDashboard.allReviewer,
    user:auth.user
  });

export default connect(mapStateToProps,{ getAssignedBugs, getReviewer, updateReviewer, assignReviewer })(BugsAssigned);
