import React, { useEffect, useState } from "react";
import { setDashboardRoute } from "../../../redux/Routing/actions";
import {
  createBadge,
  getBadges,
  createEventBadge,
  getEventBadges,
  deleteBadge,
  editBadge,
  deleteEvent,
  editEvent,
} from "../../../redux/AdminDashboard/actions";
import { connect } from "react-redux";
import DashboardHeading from "../../Common/DashboardHeading";
import "./style.scss";
import Form from "./Form";
import BadgeCard from "./BadgeCard";
import BadgeEventCard from "./BadgeEventCard";
import FormBadgeEvent from "./FormBadgeEvent";
import { useAlertConfirmation } from "../../../Services/AlertService";
import { setAlert } from "../../../redux/Alerts/actions";
import EditBadgeModal from "./EditBadgeModal/";
import EditEventModal from "./EditEventModal";
import Loader from "../../Loader";

const ManageBadges = ({
  setDashboardRoute,
  componentID,
  createBadge,
  getBadges,
  allBadges,
  createEventBadge,
  getEventBadges,
  allEventBadges,
  deleteBadge,
  editBadge,
  deleteEvent,
  editEvent,
  isLoading,
}) => {
  useEffect(() => {
    setDashboardRoute(componentID);
    getBadges();
    getEventBadges();
    // eslint-disable-next-line
  }, []);

  const fallBackAlert = useAlertConfirmation();

  const [form, setForm] = useState({
    score: "",
    badgeName: "",
    logo: "",
    badgeDescription: "",
  });

  const [formEvent, setFormEvent] = useState({
    score: "",
    badgeEvent: "",
    weightage: "",
  });

  const [showFormModal, setShowFormModal] = useState(false);
  const [showFormBadgeModal, setShowFormBadgeModal] = useState(false);
  const [showEditBadgeModal, setShowEditBadgeModal] = useState(false);
  const [singleEditBadgeData, setSingleEditBadgeData] = useState({});

  const [showEventEditBadgeModal, setShowEventEditBadgeModal] = useState(false);
  const [singleEventEditBadgeData, setSingleEventEditBadgeData] = useState({});

  const createBadgeFormSubmit = (formData, badgeIcon) => {
    createBadge(formData, badgeIcon);
  };
  const createBadgeEventFormSubmit = formData => {
    createEventBadge(formData);
  };

  const handleFormModal = () => {
    setShowFormModal(!showFormModal);
    setForm({
      ...form,
      score: "",
      badgeName: "",
      logo: "",
      badgeDescription: "",
    });
  };

  const handleBadgeEventModal = () => {
    setShowFormBadgeModal(!showFormBadgeModal);
    setFormEvent({
      ...formEvent,
      score: "",
      badgeEvent: "",
      weightage: "",
    });
  };
  const handleDeleteBadge = async badgeId => {
    try {
      await fallBackAlert({
        message: "Are you sure you want to delete this Badge?",
        type: "ERROR",
        successText: "Yes",
        cancelText: "No",
      });
      deleteBadge(badgeId);
    } catch (error) {
      setAlert({
        message: "Error while cancelling project",
        type: "ERROR",
      });
    }
  };
  const handleDeleteEvent = async eventId => {
    try {
      await fallBackAlert({
        message: "Are you sure you want to delete this Event?",
        type: "ERROR",
        successText: "Yes",
        cancelText: "No",
      });
      deleteEvent(eventId);
    } catch (error) {
      setAlert({
        message: "Error while cancelling project",
        type: "ERROR",
      });
    }
  };

  const handleEditBadgeFromCard = data => {
    setShowEditBadgeModal(true);
    setSingleEditBadgeData({ ...data });
  };
  const handleEventEditBadgeFromCard = data => {
    setShowEventEditBadgeModal(true);
    setSingleEventEditBadgeData({ ...data });
  };

  const handleEditBadgeSubmit = (formData, badgeIcon) => {
    editBadge(formData, badgeIcon);
    setShowEditBadgeModal(false);
  };

  const handleEventEditBadgeSubmit = formData => {
    editEvent(formData);
    setShowEventEditBadgeModal(false);
  };
  return (
    <div className="manageBadgesContainer">
      <div className="header">
        <DashboardHeading headingStrong="All Badges" />
        <button className="glb-btn" onClick={handleFormModal}>
          Create Badge
        </button>
      </div>
      <br/>
      <Form form={form} setForm={setForm} createBadgeFormSubmit={createBadgeFormSubmit} showFormModal={showFormModal} toggleFormModal={handleFormModal} />
      <EditBadgeModal
        data={singleEditBadgeData || {}}
        showModal={showEditBadgeModal}
        setModal={setShowEditBadgeModal}
        handleSubmitEdit={handleEditBadgeSubmit}
      />
      {isLoading ? (
        <Loader />
      ) : (
        <div className="badgescardContainer">
          {allBadges.length === 0 ? (
            <h1>No Badges Created yet</h1>
          ) : (
            <>
              {allBadges.map((item, i) => (
                <BadgeCard key={i} data={item} deleteBadge={handleDeleteBadge} handleEdit={handleEditBadgeFromCard} />
              ))}
            </>
          )}
        </div>
      )}
      <br/>
      <div className="header">
        <DashboardHeading headingStrong="Badges Event" />
        <button className="glb-btn" onClick={handleBadgeEventModal}>
          Create Badge Event
        </button>
      </div>
      <br/>
      <FormBadgeEvent
        showModal={showFormBadgeModal}
        setModal={handleBadgeEventModal}
        createBadgeEvent={createBadgeEventFormSubmit}
        form={formEvent}
        setForm={setFormEvent}
      />
      <EditEventModal
        data={singleEventEditBadgeData || {}}
        showModal={showEventEditBadgeModal}
        setModal={setShowEventEditBadgeModal}
        handleSubmitEdit={handleEventEditBadgeSubmit}
      />
      {isLoading ? (
        <Loader />
      ) : (
        <div className="badgescardContainer">
          {allEventBadges.length === 0 ? (
            <h1>No Event Created yet</h1>
          ) : (
            <>
              {allEventBadges.map((item, i) => (
                <BadgeEventCard key={i} data={item} handleEdit={handleEventEditBadgeFromCard} deleteEvent={handleDeleteEvent} />
              ))}
            </>
          )}
        </div>
      )}
    </div>
  );
};

const mapStateToProps = ({ adminDashboard }) => ({
  allBadges: adminDashboard.allBadges,
  isLoading: adminDashboard.isLoading,
  allEventBadges: adminDashboard.allEventBadges,
});

export default connect(mapStateToProps, {
  setDashboardRoute,
  createBadge,
  getBadges,
  createEventBadge,
  getEventBadges,
  deleteBadge,
  editBadge,
  deleteEvent,
  editEvent,
})(ManageBadges);
