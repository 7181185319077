import React, { useEffect } from "react";
import { connect } from "react-redux";
import { useHistory } from "react-router-dom";

import { userInfo } from "../../redux/auth/actions";

const AutoLogin = ({ isAuth }) => {
  const history = useHistory();
  useEffect(() => {
    if (isAuth) {
      history.push("/dashboard");
    }
    // eslint-disable-next-line
  }, []);
  return <div></div>;
};

const mapStateToProps = ({ auth: { isAuth } }) => ({
  isAuth,
});

export default connect(mapStateToProps, { userInfo })(AutoLogin);
