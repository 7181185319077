import React from "react";
import { useHistory } from "react-router-dom";
import "./style.scss";

function ToolsUsed({ data = {} }) {
  const history = useHistory();
  return (
    <div className="tools-used">
      <h4 className="tools-used-heading">{data.heading3}</h4>
      <div className="tools-used-img"></div>
      <div className="tools-used-content">
        <span className="text-bold">{data.content3a}&nbsp;</span>
        {data.content3b}
      </div>
      <div className="glb-btn tools-used-btn" onClick={() => history.push("/signup/tester")}>
        {data.btnText}
      </div>
    </div>
  );
}

export default ToolsUsed;
