import React from "react";
import { Link } from "react-router-dom";
import Loader from "../../../Loader"

import "../style.scss";

const tableHeadingArray = ["Tester Name", "All Earnings", "Badge", "Bugs Reported", "Bugs Approved", "Online Status" ,"Status"];

const AllTestersTable = ({ tableContentArray, online }) => {


  let sortedContent = [];
  let trimmed = [];
  
  trimmed = tableContentArray.map((item)=>{
    let name=item.name.trim()
    return(
      {...item,name:name}
    )
 }) 

 
 sortedContent = trimmed.sort((a, b) => a.name > b.name ? 1 : -1)


  return (
    <div className="dashboard-table all-testers">
      {tableContentArray.length === 0 ? (
      online ? <h4 style={{width:"100%", textAlign:"center"}} >No Tester Available</h4>:<Loader/>
      ) : (
        <table>
          <tr>
            {tableHeadingArray.map((head, i) => (
              <th key={i}>{head}</th>
            ))}
          </tr>

          {sortedContent &&
            sortedContent.map((item, i) => {
              if(item.name){
                return(
                  <tr key={i}>
                    <td style={{textTransform:"capitalize"}} className="name">
                      {/* <Link to={`/dashboard/testers/${item.id}`} className="blue-color">
                        {item.name}
                      </Link> */}
                      <Link to={{pathname:`/dashboard/tester/${item.id}`, state:{name: `${item.name}`, email: `${item.email}`, status: item.status}}} className="blue-color">
                      {item.name.toLowerCase()}
                      </Link>
                    </td>
                    <td>{`${item.data[0].earning !== 0 && item.data[0]?.earning ? '$' + item.data[0]?.earning.toFixed(2) : "-"}`} </td>
                    <td className="star">
                      {item.badge?.currentBadge?.logo ?
                        (<img src={item.badge?.currentBadge?.logo} alt={item.badge?.currentBadge?.name} />)
                        : "No Badge"}
                    </td>
                    <td>{`${item.data[0].bugReported !== 0 ? item.data[0].bugReported : "-"}`}</td>
                    <td>{`${item.data[0].bugApproved !== 0 ? item.data[0].bugApproved : "-"}`}</td>
                    <td style={item.isOnline === true ? {color:"#39B21C"}:{color:"red"}} >{`${item.isOnline === true ? "Online":"Offline"}`}</td>
                    <td className={item.status ? "Active" : "Inactive"}>{item.status ? "Active" : "Inactive"}</td>
                  </tr>
                )
              }
              return null;
            })}
        </table>
      )}
    </div>
  );
};

export default AllTestersTable;
