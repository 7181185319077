// export const baseDomain =
//   process.env.NODE_ENV === "development"
//     ? "https://dev.qantily.com"
//     : "https://qantily.com";

// const baseDomain = "http://13.234.46.46:8083";
const baseDomain = "https://staging.qantily.com";
const stage = "/api";

// default keys headers
export const xApiKeyObj = {
  key: "x-api-key",
  value: `qoRF7JUfbt34cg75AN1nx1osfiqQNjt09tEhOp56`,
};

export const staticCountrys = "/static/country"; //? GET
export const staticStates = "/static/states"; // add /countryId as params      //? GET
export const staticPlatforms = "/static/platform"; //? GET

// Login Signup
export const loginUserReq = "/auth/login"; //? POST
export const signupTesterReq = "/tester"; //? POST
export const signupClientReq = "/agency"; //? POST
export const userInfoReq = "/auth/userinfo"; //? POST
export const logoutUserApi = "/auth/logout";

// Tester Dashboard
export const getTesterLeaderboardProjectsReq = "/leaderboard/project";
export const getTesterLeaderboardReq = "/leaderboard";
export const getTesterLiveProjectsReq = "/project/live";

// Tester Notifications
export const getTesterNotificationReq = "/notification";

// Devices
export const getAllDevicesReq = "/device"; //? GET
export const getTesterDevicesReq = "/device/tester"; //? GET AUTH
export const createDeviceReq = "/device"; //? POST AUTH
export const updateDeviceReq = "/device"; //? PUT AUTH
export const deleteDeviceReq = "/device"; // add /{deviceId} as params //? DELETE AUTH
export const activateDeviceReq = "/device/activate"; // add /{deviceId} as params  //? PUT AUTH
export const deactivateDeviceReq = "/device/deactivate"; // add /{deviceId} as params //? PUT AUTH
export const testerDevicesReq = "/user/devices"; //? GET

// All Projects
export const getTesterActiveProjectsReq = "/project/publish";

// Project By ID
export const getTesterProjectByIDReq = "/project"; // add /projectID //? GET

// Completed Projects
export const getTesterCompletedProjectsReq = "/project/complete"; // add /projectId //? GET

// Paused Projects
export const getTesterPausedProjectsReq = "/project/paused"; //? GET

// Bugs
export const getTesterListedBugsReq = "/bugs/user"; // add /projectId //? GET
export const getProjectListedBugsReq = "/bugs/project"; // add /projectId //? GET
export const reportNewBugReq = "/bugs"; //? POST
export const getSingleBugDataReq = "/bugs"; // add /bugID //? GET
export const updateBugReq = "/bugs"; //? PUT

// Community
export const getCommunityStatesReq = "/community";

// Settings
export const changePassowrdReq = "/auth/password/change"; //? POST
export const updateProfileReq = "/tester"; //? PUT
export const deleteProfilePicture = "/tester/remove/profile/pic";

// API to file upload
export const fileUploadReq = "/file/upload"; // ADD /type at the end //? POST

// Settings
export const getWalletEarningReq = "/earning/wallet"; //? GET
export const getPaidEarningReq = "/earning/paid"; //? GET
export const deleteAccountReq = "/payment"; // add /{accountNo} as params //? DELETE

// ----------------------------------
// Client

// Stripe Payment
export const stripePaymentReq = "/payment/project"; // add /projectId //? GET

// Pricing Package
export const getPaymentPackageReq = "/package"; // add /projectId //? GET

// Get Earning Graph
export const getEarningGraphReq = "/earning/graph"; // add / //? GET

// Add test
export const addTestStepOneSubmitReq = "/project/one"; //? POST
export const addTestStepTwoSubmitReq = "/project/two"; //? POST
export const addTestStepThreeSubmitReq = "/project/three"; //? POST

// PLAYSTORE APP INTEFRATION
export const appHintReq = "/test/app"; //? POST
export const appDetailsReq = "/test/app/detail"; //? POST

// SKills
export const getAllSkillsReq = "/skill"; //? GET

// Create tester skill
export const createTesterSkillReq = "/tester/skills"; //? PUT

// Create tester skill
export const getTesterSkillsReq = "/skill/user"; //? PUT

// Delete Skill id
export const deleteTesterSkillReq = "/tester/skills"; // add /skillID  //? DELETE

// Check IFSC
export const checkIFSCReq = "/payment/bank/ifsc"; // add /ifsc  //? GET

// Submit Bank Details
export const submitBankDetailsReq = "/payment/bank"; // add   //? POST

// Get user banks
export const getUserBanksReq = "/payment/bank"; // add   //? GET

// Transfer Amount
export const transferAmountReq = "/payment/transfer"; // add   //? PUT

// Transfer Amount
export const transferAmountStatusReq = "/payment/transfer/status"; // add   //? GET

// set default Bank
export const setBankDefaultReq = "/payment/bank/default"; // add /bankAccountId  //? PUT

// Verify Email
export const verifyEmailReq = "/auth/verify"; // add /token  //? GET

// requestResetReq
export const requestResetReq = "/auth/password/forget"; //  //? POST

// requestResetReq
export const changePasswordReq = "/auth/password/forget/change"; //  //? POST

// requestResetReq
export const successPaymentStatusReq = "/payment/status"; // add /transactionId //? GET

// Client Home
export const getClientOnGoingProjectsReq = "/project/publish/agency"; //? GET
export const getClientPausedProjectsReq = "/project/publish/paused/agency"; //? GET
export const getClientCompletedProjectsReq = "/project/complete/agency"; //? GET
export const putEditProjectReq = "/project/update"; //? PUT

// Single Project
export const getClientSingleProjectReq = "/project"; // add /projectId //? GET

// Project States
export const getClientProjectStatesReq = "/project/stats"; // add /projectId //? GET

// Change Project Status
export const makeProjectActiveReq = "/project/publish"; // add /projectId //? PUT
export const makeProjectPauseReq = "/project/pause"; // add /projectId //? PUT
export const makeProjectCancelReq = "/project/cancel"; // add /projectId //? PUT

// Approve Bug
export const approveBugReq = "/bugs/approve"; // add /bugId //? PUT

// Decline Bug
export const declineBugReq = "/bugs/reject"; // add bugId //? PUT

// Initiate Transfer
export const initiateTransferReq = "/testerDetails"; // add paymentId //? PUT

//Update Clients
export const updateClientProfileReq = "/agency"; //? PUT

export default `${baseDomain}${stage}`; // eslint-disable-line

//  ADMIN -------------

// All Testers
export const allTesterReq = "/tester"; //? GET

// All Earnings
export const allEarningReq = "/testerDetails"; //? GET

// Active Testers
export const activeTesterReq = "/tester/active/testers"; //? GET

//All Clients
export const allClientsReq = "/agency"; //? GET

// Active Clients
export const activeClientsReq = "/agency/active/agencies"; // GET

// Dashboard Stats
export const seedDashboardReq = "/seed/DashBoard"; //? GET
export const dashboardStatsReq = "/dashBoard"; //? GET

// Agency Change Status
export const agencyDeactivateReq = "/agency/deactivate"; // add agencyId  //? PUT
export const agencyActivateReq = "/agency/activate"; // add agencyId  //? PUT

// Tester Change Status
export const testerDeactivateReq = "/tester/deactivate"; // add testerId  //? PUT
export const testerActivateReq = "/tester/activate"; // add testerId  //? PUT

// Tester All Projects
export const testerProjectsReq = "/tester/tester/projects"; // add testerId //? GET

// Tester Single Project Bugs
export const testerSingleProjectsReq = "/bugs/testers"; // add projectId add testerId //? GET

// Make Reviewer
export const makeReviewerReq = "/tester/assign/reviewer"; // add testerId //? PUT

// Make Tester
export const makeTesterReq = "/tester/assign/tester"; // add testerId //? PUT

//Mark AS COMPLETE
export const makeMakeAsCompleteReq = "/project/complete"; //ass projectID //? PUT

// Reset Password
export const resetPassReq = "/auth/password/reset"; //? POST

// Tester Bank Details
export const testerBankDetailsReq = "/payment/bank/admin"; //? GET Add {Tester ID}

// Tester Device Details
export const testerDeviceDetailsReq = "/device/tester/admin"; //? GET Add {Tester ID}

// Tester Skillset Details
export const testerSkillsetDetailsReq = "/skill/user"; //? GET Add {User ID}

//Feedback Request
export const feedbackReq = "/feedback"; //? GET
export const uploadFeedbackReq = "/feedback"; //? POST

//Testimonial Request
export const testimonialReq = "/testimonial"; //? GET
export const uploadTestimonialReq = "/testimonial"; //? POST
export const approveTestimonialReq = "/testimonial"; //? PUT
export const rejectTestimonialReq = "/testimonial/reject"; //? PUT
export const updateTestimonialReq = "/testimonial/update"; //? PUT

// Projects List
// api/project/live/admin
export const getAdminLiveProjectReq = "/project/live/admin"; //? GET
export const getAdminPausedProjectReq = "/project/paused/admin"; //? GET
export const getAdminCompletedProjectReq = "/project/complete/admin"; //? GET

// Invite Tester Admin Pannel
export const inviteTesterReq = "/notification/project"; // add /projectId //? GET

// Invite Particular Tester Admin Pannel
export const inviteParticularTesterReq = "/Invite"; // add /projectId // add TesterId ? GET

export const createBadgeApi = "/badge"; // post api for creating badge
export const createEventBadgeApi = "/badgeEvent"; // post api for creating event badge

export const getBadgesApi = "/badge"; // get api for  badges

export const getEventBadgesApi = "/badgeEvent"; // get api for event  badges

export const deleteBadgeApi = "/badge"; // delete api for deleting badge

export const deleteEventApi = "/badgeEvent"; // delete api for deleting event

export const editBadgeApi = "/badge"; // put api for editing badge

export const editEventApi = "/badgeEvent"; // put api for editing badge

// Invite selectTestersList Tester Admin Pannel
export const inviteSelectedTesterReqApi = "/Invite/testers"; //  Post (projectid,testersArray,customMessage)

// referal code generate api of get

export const referralCodeGenerateReq = "/auth/refferalCode";
// Save Draft Project Client
export const saveDraftProjectReq = "/project/draft/agency"; //? GET

export const getSocialPageUrl = "/signup"; //GET

export const socialSignUpCodeSend = "/signup"; // post

export const socialSignUpDetailsSend = "/signup"; //put

export const socialSignInGetUrlApi = "/signup"; //put

export const socialSignInApi = "/signup/sso/login"   //post

export const getFinanceReportApi = '/testerDetails/financeReport'

export const getReviewerReq = "/bugAutomation" //get
export const getAssignedBugsReq = "/bugAutomation" //get
export const assignReviewerReq = "/bugAutomation" // post
export const updateReviewerReq = "/bugAutomation" // put
export const addCommentReq = "/bugAutomation/comments"//post
export const getCommentReq = "/bugAutomation/comments"//get

export const sendQuery = '/Query';

export const testerActiveStatus = '/tester/testerStatus'//put
