import React, { useState } from "react";
import { Link } from "react-router-dom";
import axios from "axios";

import baseDomain, { getTesterListedBugsReq } from "../../../../constants/apiRoutes";
import NoDataMessage from "../../NoDataMessage";
import settings from "../../../../constants/settings";
import "./style.scss";
import "../style.scss";

const MyEarningsAccordian = ({ myearningscontent, key, showTable, showBtn }) => {
  const [showDetails1, setShowDetails1] = useState(false);
  const [mydata, setMydata] = useState();

  const UpArrow = ({ color }) => (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill={color} width="36px" height="36px">
      <path d="M0 0h24v24H0z" fill="none" />
      <path d="M12 8l-6 6 1.41 1.41L12 10.83l4.59 4.58L18 14z" />
    </svg>
  );
  const DownArrow = ({ color }) => (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill={color} width="36px" height="36px">
      <path d="M0 0h24v24H0z" fill="none" />
      <path d="M16.59 8.59L12 13.17 7.41 8.59 6 10l6 6 6-6z" />
    </svg>
  );

  const earningsDetails = async projectId => {
    setShowDetails1(!showDetails1);
    try {
      const { data } = await axios.get(`${baseDomain}${getTesterListedBugsReq}/${projectId}`);
      setMydata(data.data);
    } catch (error) {
      throw (error && error.response && error.response) || "Error";
    }
  };

  return (
    <div className="dashboard-table myearning-table">
      <div className="EarningsmainAccordian" key={key}>
        <div className="earnings-list-item">
          <div className="earnings-list-item-title">
            <h1>{myearningscontent.projectName}</h1>

            <div className="myearnings-project-header">
              <h1>{myearningscontent?.earning ? "$ " + myearningscontent.earning.toFixed(2) : "$ 0"}</h1>
              {showTable && (
                <span onClick={() => earningsDetails(myearningscontent.projectId)}>
                  {showDetails1 ? <UpArrow color="#123dfb" /> : <DownArrow color="#123dfb" />}
                </span>
              )}
              {showBtn && <button>Transfer</button>}
            </div>
          </div>
          {showTable && showDetails1 && (
            <div className="accordianContent">
              <div className="row">
                {myearningscontent.length === 0 ? (
                  <NoDataMessage message={settings.my_earnings_accordian.message} />
                ) : (
                  <table>
                    <tr>
                      {settings.my_earnings_accordian.tableHeadingArray.map((head, i) => (
                        <th key={i}>{head}</th>
                      ))}
                    </tr>
                    {mydata &&
                      mydata?.map((item, i) => (
                        <tr key={i}>
                          <td className="bugName">
                            <Link className="inherit blue-color" to={`/dashboard/test-taken/projects/active/${myearningscontent.projectId}/bug/${item.id}`}>
                              {item.summary}
                            </Link>
                          </td>
                          <td>{item.earning ? `$${item.earning}` : "-"}</td>
                          <td>{item.approvedDate ? item?.approvedDate?.split("T")[0] : item?.rejectedDate?.split("T")[0]}</td>
                          <td className={item.status}>{item.status}</td>
                        </tr>
                      ))}
                  </table>
                )}
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default MyEarningsAccordian;
