import React from "react";
import HalfPageBackground from "../../components/Layout/HalfPageBackground";
// import AccountSetupHeading from "../../components/AccountSetupHeading"
import SignupClient from "../../components/SignupClient";
import AutoLogin from "../../components/AutoLogin";
import { withLayout } from "../../layouts";
import { Helmet } from "react-helmet";
import "../../assets/sass/commonStyles.scss";

const AccountSetup = () => {
  return (
    <div className="halfPageBackground-container">
      <Helmet>
        <meta name="description" content="Upload your app and get it tested on real devices." />
      </Helmet>
      <AutoLogin />
      <HalfPageBackground showGraphic={true} />
      <SignupClient />
    </div>
  );
};

export default withLayout(AccountSetup, true, false);
