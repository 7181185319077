import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { getTestimonials } from "../../../redux/AdminDashboard/actions";
import TestimonialComponent from "./TestimonialComponent/index";
import NoDataMessage from "../../Common/NoDataMessage";
import LeftArrow from "../../../assets/icons/leftAro.svg";
import RightArrow from "../../../assets/icons/rightAro.svg";

import "./style.scss";

const TestimonialLanding = ({ getTestimonials, testimonials }) => {
  useEffect(() => {
    getTestimonials();
    //eslint-disable-next-line
  }, []);

  let approvedTestimonials = [];
  approvedTestimonials = testimonials && testimonials.length > 0 ? testimonials.filter(item => item.isApproved === true && item.isRejected !== true) : [];

  var windowWidth = window.innerWidth;
  const [x, setX] = useState(0);
  const activeTestimonials = document.getElementsByClassName("singleTestimonials");
  const moveRightArrow = () => {
    if (windowWidth > 1440) {
      x === -300 * (activeTestimonials.length - 4) ? setX(-300 * (activeTestimonials.length - 4)) : setX(x - 300);
    } else if (windowWidth > 1024 && windowWidth <= 1440) {
      x === -300 * (activeTestimonials.length - 3) ? setX(-300 * (activeTestimonials.length - 3)) : setX(x - 300);
    } else if (windowWidth > 700 && windowWidth <= 1024) {
      x === -300 * (activeTestimonials.length - 2) ? setX(-300 * (activeTestimonials.length - 2)) : setX(x - 300);
    } else {
      x === -300 * (activeTestimonials.length - 1) ? setX(-300 * (activeTestimonials.length - 1)) : setX(x - 300);
    }
  };
  const moveLeftArrow = () => {
    x === 0 ? setX(0) : setX(x + 300);
  };

  return (
    <div className="manageTestimonialss">
      <br/>
      {approvedTestimonials && approvedTestimonials.length !== 0 ? <h2 style={{ width: "100%" }}>See what our users have to say!</h2> : null}
      {approvedTestimonials && approvedTestimonials.length !== 0 ? (
        <div className="testimonials-container">
          {approvedTestimonials && approvedTestimonials.length !== 0 ? (
            <>
              <div className={"element left"}>
                <img src={LeftArrow} alt="left-arrow" onClick={moveLeftArrow}></img>
              </div>
              <div className="approvedTestimonials-container">
                <div className="approvedTestimonialss" style={{ transform: `translateX(${x}px)` }}>
                  {approvedTestimonials.map(item => (
                    <TestimonialComponent
                      key={item.testimonialId}
                      name={item.name}
                      profilePic={item.profilePic}
                      testimonial={item.testimonial}
                      approved={item.isApproved}
                      rejected={item.isRejected}
                      className="singleTestimonials"
                    />
                  ))}
                </div>
              </div>
              <div className={"element right"}>
                <img src={RightArrow} alt="right-arrow" onClick={moveRightArrow}></img>
              </div>
            </>
          ) : (
            <NoDataMessage message="No testimonials added!" />
          )}
        </div>
      ) : null}
    </div>
  );
};

const mapStateToProps = ({ adminDashboard }) => ({
  isLoading: adminDashboard.isLoading,
  testimonials: adminDashboard.testimonials,
});

export default connect(mapStateToProps, { getTestimonials })(TestimonialLanding);
