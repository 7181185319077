import React, { useEffect, useState } from "react";
import { CloseSign } from "../../../assets/icons/icons";
import { connect } from "react-redux";
import { useParams, useHistory } from "react-router-dom";

import { updateProjectDetails } from "../../../redux/ClientDashboard/actions";
import "./style.scss";

const EditProjectModal = ({ showModal, toggleModal, data, updateProjectDetails }) => {
  const history = useHistory();

  const [isDeclinedLoading, setIsDeclinedLoading] = useState(false);
  const { projectID } = useParams();

  const [formData, setFormData] = useState({
    testingHour: "",
    stage: "",
    websiteLink: "",
    version: "",
    loginId: "",
    loginPassword: "",
    projectId: "",
  });

  useEffect(() => {
    setFormData({
      testingHour: parseInt(data.testingHours) / 60,
      stage: data.stage,
      websiteLink: data.website,
      version: data.version,
      loginId: data.loginId,
      loginPassword: data.password,
      projectId: projectID,
    });
    // eslint-disable-next-line
  }, [data]);

  const modalToggle = () => {
    toggleModal();
  };

  const handleUpdate = () => {
    setIsDeclinedLoading(true);
    updateProjectDetails({ ...formData, testingHour: parseInt(formData.testingHour) });
    setIsDeclinedLoading(false);
    toggleModal();
    history.push("/dashboard");
  };

  return (
    <div className={showModal ? "updateClient-modal-container blur-bg" : "none"}>
      <div className="updateClient-modal">
        <div className="close-icon">
          <div className="close" onClick={modalToggle}>
            {CloseSign}
          </div>
        </div>
        <div className="heading">
          <h2>Edit Project Details</h2>
        </div>
        <div className="body-container">
          <div className="body">
            <div className="updateClientPopUpContainer">
              <div className="updateClientPopUp">
                <div className="updateClientPopUpRow">
                  <div>
                    <div className="updateClientPopUpLabel">Testing Man Hours</div>
                    <input
                      className="glb-input"
                      type="number"
                      defaultValue={formData.testingHour}
                      onChange={e => {
                        setFormData({ ...formData, testingHour: parseInt(e.target.value) });
                      }}
                    />
                  </div>
                  <div>
                    <div className="updateClientPopUpLabel">Testing Stage</div>
                    <input
                      className="glb-input"
                      value={formData.stage}
                      onChange={e => {
                        setFormData({ ...formData, stage: e.target.value });
                      }}
                    />
                  </div>
                </div>

                <div className="updateClientPopUpRow">
                  <div>
                    <div className="updateClientPopUpLabel">Build URL</div>
                    <input
                      className="glb-input"
                      value={formData.websiteLink}
                      onChange={e => {
                        setFormData({ ...formData, websiteLink: e.target.value });
                      }}
                    />
                  </div>
                  <div>
                    <div className="updateClientPopUpLabel">Version</div>
                    <input
                      className="glb-input"
                      value={formData.version}
                      onChange={e => {
                        setFormData({ ...formData, version: e.target.value });
                      }}
                    />
                  </div>
                </div>

                {/* <div className="updateClientPopUpRow">
                  <div>
                    <div className="updateClientPopUpLabel">Login ID</div>
                    <input className="glb-input" value={formData.loginId} onChange={(e) => {setFormData({...formData, loginId: e.target.value})}} />
                  </div>
                  <div>
                    <div className="updateClientPopUpLabel">Login Password</div>
                    <input className="glb-input" value={formData.loginPassword} onChange={(e) => {setFormData({...formData, loginPassword: e.target.value})}} />
                  </div>
                </div> */}

                <div className="updateClientPopUpRowButtons">
                  <button className={"glb-btn no"} onClick={modalToggle}>
                    Cancel
                  </button>
                  <button className={"glb-btn yes"} disabled={isDeclinedLoading} onClick={handleUpdate}>
                    Update
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default connect(null, { updateProjectDetails })(EditProjectModal);
