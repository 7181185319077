import React from "react";
import { Link } from "react-router-dom";
import settings from "../../../../constants/settings";
import "../style.scss";

// const tableHeadingArray = ["Name", "Device", "Type", "Platform + Version", "View"];

const BugsDetectedTable = ({ bugsdetectedcontent = [], projectID }) => {
  return (
    <div className="dashboard-table bug-detected-table">
      <table>
        <tr>
          {settings.bugs_detected_table.tableHeadingArray.map((head, i) => (
            <th key={i}>{head}</th>
          ))}
        </tr>

        {bugsdetectedcontent.map((item, i) => (
          <tr key={i}>
            <td>{item.summary || "-"}</td>
            <td>{item?.device?.name ? item?.device?.name : "-"}</td>
            <td>{item.priority || "-"}</td>
            {/* <td>{item.platform}</td> */}
            <td className="completed-view-details">
              <Link to={`/dashboard/home/projects/${projectID}/bug/${item.id}`} className="inherit">
                View
              </Link>
            </td>
          </tr>
        ))}
      </table>
    </div>
  );
};

export default BugsDetectedTable;
