import React from "react";

import "./style.scss";

const AdminCard = ({heading, icon, number, percent, color}) => {

  const percentage = percent >= 0 ? `+${percent.toFixed(2)}` : `-${percent.toFixed(2)}`

  return (
    <div className="AdminCard">

      <div className="cardHeader">
        <div className="cardContent">
          <div className="cardHeading">{heading}</div>
          <div className="cardNumber">{number}</div>
        </div>
        <img src={icon} alt={icon} />
      </div>
      <div className="cardfooter">
        <div className={`cardPercent ${color}`}>{percentage}</div>
        <div className="last-month">Since last month</div>
      </div>
      
      
    </div>
  );
};



export default (AdminCard);
