import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import DashboardHeading from "../../../../Common/DashboardHeading";
import MultiplierCard from "../../../../Common/MultiplierCard";
// import BoostMultiplierAccordion from "../../../../Common/BoostMultiplierAccordion";

import { withData } from "../../../../../layouts/index";

import Boost1 from "../../../../../assets/images/boost1.png";
import Boost2 from "../../../../../assets/images/boost2.png";
import Boost3 from "../../../../../assets/images/boost3.png";
import LeftArrow from "../../../../../assets/icons/leftAro.svg";
import RightArrow from "../../../../../assets/icons/rightAro.svg";

import "./style.scss";

const Boosters = ({ settings }) => {
  const history = useHistory();

  const [x, setX] = useState(0);
  const totalCards = document.getElementsByClassName("multiplier-card");
  //const totalCards = useRef(null);

  const moveRightArrow = () => {
    x === -325 * (totalCards.length - 1) ? setX(-325 * (totalCards.length - 1)) : setX(x - 325);
  };

  const moveLeftArrow = () => {
    x === 0 ? setX(0) : setX(x + 325);
  };

  return (
    <div className="dashboard-multiplier">
      <DashboardHeading heading={settings.boostmultiplier.heading.light} headingStrong={settings.boostmultiplier.heading.bold} showBtn={false} />
      <div className="multiplier-container">
        <div className={"element left"}>
          <img src={LeftArrow} alt="left-arrow" onClick={moveLeftArrow}></img>
        </div>
        <div className="multiplier-accordion-container">
          <div className="multiplier-cards" style={{ transform: `translateX(${x}px)` }}>
            <MultiplierCard
              heading={settings.boostmultiplier.card1.heading}
              listArray={settings.boostmultiplier.card1.ListCard1}
              showBtn={true}
              btnValue={settings.boostmultiplier.card1.btnText1}
              img={Boost1}
              alt="Upgrade/Improve Skills"
              btnOnClick={() => history.push("/dashboard/boostmultiplier/skills")}
            />

            <MultiplierCard
              heading={settings.boostmultiplier.card2.heading}
              listArray={settings.boostmultiplier.card2.ListCard2}
              showBtn={false}
              btnValue={settings.boostmultiplier.card2.btnText2}
              btnOnClick="/"
              img={Boost2}
              alt="Alpha Testing Illustration"
            />

            <MultiplierCard
              heading={settings.boostmultiplier.card3.heading}
              listArray={settings.boostmultiplier.card3.ListCard3}
              showBtn={false}
              btnValue={settings.boostmultiplier.card3.btnText3}
              img={Boost3}
              alt="Become Reviewer"
              btnOnClick="/"
            />
          </div>
        </div>

        <div className={"element right"}>
          <img src={RightArrow} alt="right-arrow" onClick={moveRightArrow}></img>
        </div>
      </div>

      {/* <BoostMultiplierAccordion /> */}
    </div>
  );
};

export default withData(Boosters);
