import React, { useState } from "react";
import { Link } from "react-router-dom";

import PlatformCard from "../PlatformCard";
import AndroidBlue from "../../../../../assets/icons/android-blue.png";
import AndroidWhite from "../../../../../assets/icons/android-white.png";
import AppleBlue from "../../../../../assets/icons/apple-blue.png";
import AppleWhite from "../../../../../assets/icons/apple-white.png";
import ManualBlue from "../../../../../assets/icons/manual-blue.png";
import ManualWhite from "../../../../../assets/icons/manual-white.png";

import "./style.scss";
const NewTests = () => {
  
  const [activeCard, setActiveCard] = useState({
    "activeCard": "androidCard",
    "cardName": "Google Play Store",
  });

  return (
    <div className="dashboard-client-new-test">
      <h2>Create a <strong>New Test</strong></h2>
      <div className="subHead">How would you like to share your application?</div>
      <div className="select-platform-card-container">

        <div className="android-card" onClick={() => setActiveCard({activeCard:"androidCard",cardName:"Google Play Store" })}>
          <PlatformCard image={activeCard.activeCard === "androidCard" ? AndroidWhite : AndroidBlue} name={"Android Play Store"} active={activeCard.activeCard === "androidCard" ? "active" : ""} />
        </div>
        <div className="ioS-card" onClick={() => setActiveCard({activeCard:"iosCard", cardName:"Apple Store"})}>
          <PlatformCard image={activeCard.activeCard === "iosCard" ? AppleWhite : AppleBlue} name={"iOS App Store"} active={activeCard.activeCard === "iosCard" ? "active" : ""} />
        </div>
        <div className="manual-card" onClick={() => setActiveCard({activeCard:"manualCard",cardName:"Manual Method" })}>
          <PlatformCard image={activeCard.activeCard === "manualCard" ? ManualWhite : ManualBlue} name={"Manual Method"} active={activeCard.activeCard === "manualCard" ? "active" : ""} />
        </div>
        
      </div>
      <div className="sub-bold-heading">{activeCard.cardName}</div>
      <div className="client-add-test-para">{activeCard.cardName !== "Manual Method" ? `Select this alternative if the app you want to test is already published on the ${activeCard.cardName === "Google Play Store" ? "Google Play Store" : "iOS App Store"}. Testers can directly download it from Store.` : `Select this alternative if the app you want to provide application details manually. The testers can download the installable and start.` }</div>
      <Link to={ `/dashboard/addtests/addtests/${activeCard.activeCard}`} className="blue-color">
        <div className="client-new-test-button">
          Use this Method
        </div>
      </Link>
    </div>
  );
};

export default NewTests;
