export const MenuItems1 = [
    {
      title: 'Crowdsourced Testing',
      path: '/crowdsourcedtesting/crowd-testing',
      cName: 'dropdown-link'
    },
    {
      title: 'Automation Testing',
      path: '/crowdsourcedtesting/automation',
      cName: 'dropdown-link'
    },
  ];

  export const MenuItems2 = [
    {
      title: 'About Qantily',
      path: '/automation',
      cName: 'dropdown-link'
    },
    {
      title: 'Leadership',
      path: '/devicehub',
      cName: 'dropdown-link'
    },
    {
      title: 'Contact Us',
      path: '/devicehub',
      cName: 'dropdown-link'
      },
  ];
  
  