import { all, call, fork, put, takeEvery } from "redux-saga/effects";

import { setAlert } from "../Alerts/actions";
import { logoutUser } from "../auth/actions";

import {
  GET_ADMIN_ALL_CLIENTS,
  GET_ADMIN_ACTIVE_CLIENTS,
  GET_ADMIN_ALL_TESTER,
  GET_ADMIN_ACTIVE_TESTER,
  GET_ADMIN_DASHBOARD_STATS,
  GET_ADMIN_DASHBOARD_SEED,
  PUT_AGENCY_ACTIVATE,
  PUT_AGENCY_DEACTIVATE,
  PUT_TESTER_ACTIVATE,
  PUT_TESTER_DEACTIVATE,
  GET_TESTER_ALL_PROJECTS,
  PUT_TESTER_MAKE_REVIEWER,
  PUT_TESTER_MAKE_TESTER,
  GET_TESTER_SINGLE_PROJECTS,
  GET_FEEDBACKS,
  GET_ADMIN_LIVE_PROJECTS,
  GET_ADMIN_PAUSED_PROJECTS,
  GET_ADMIN_COMPLETED_PROJECTS,
  GET_TESTER_BANK_DETAILS,
  GET_TESTER_DEVICE_DETAILS,
  GET_TESTER_SKILLSET_DETAILS,
  INVITE_TESTER_ADMIN,
  INVITE_PARTICULAR_TESTER_ADMIN,
  INVITE_SELECTED_TESTER_ADMIN,
  GET_ADMIN_ALL_EARNING,
  PUT_ADMIN_DASHBOARD_MARK_AS_COMPLETE,
  CREATE_BADGE,
  GET_BADGES,
  CREATE_EVENT_BADGE,
  GET_EVENT_BADGES,
  DELETE_BADGE,
  EDIT_BADGE,
  DELETE_EVENT,
  EDIT_EVENT,
  GET_TESTIMONIALS,
  APPROVE_TESTIMONIAL,
  REJECT_TESTIMONIAL,
  UPDATE_TESTIMONIAL,
  GET_ADMIN_TESTERS_EXPORT,
  GET_FINANCE_REPORT,
  GET_REVIEWER,
  ASSIGN_REVIEWER_BUG,
  UPDATE_REVIEWER
} from "../actions";

import {
  getAdminAllClientsSuccess,
  getAdminAllClientsError,
  getAdminActiveClientsSuccess,
  getAdminActiveClientsError,
  getAdminAllTestersSuccess,
  getAdminAllTestersError,
  getAdminActiveTestersSuccess,
  getAdminActiveTestersError,
  getAdminDashboardStatsSuccess,
  getAdminDashboardStatsError,
  getAdminSeedDashboardSuccess,
  getAdminSeedDashboardError,
  agencyActivateSuccess,
  agencyActivateError,
  agencyDeactivateSuccess,
  agencyDeactivateError,
  testerActivateSuccess,
  testerActivateError,
  testerDeactivateSuccess,
  testerDeactivateError,
  getTesterAllProjectsSuccess,
  getTesterAllProjectsError,
  putTesterMakeReviewerSuccess,
  putTesterMakeReviewerError,
  putTesterMakeTesterSuccess,
  putTesterMakeTesterError,
  getTesterSingleProjectSuccess,
  getTesterSingleProjectError,
  getFeedbacksSuccess,
  getFeedbacksError,
  getAdminLiveProjectsSuccess,
  getAdminLiveProjectsError,
  getAdminPausedProjectsSuccess,
  getAdminPausedProjectsError,
  getAdminCompletedProjectsSuccess,
  getAdminCompletedProjectsError,
  getTesterBankDetailsSuccess,
  getTesterBankDetailsError,
  getTesterDeviceDetailsSuccess,
  getTesterDeviceDetailsError,
  getTesterSkillsetDetailsSuccess,
  getTesterSkillsetDetailsError,
  inviteTesterSuccess,
  inviteTesterError,
  inviteParticularTesterSuccess,
  inviteParticularTesterError,
  getAdminAllEarningsSuccess,
  getAdminAllEarningsError,
  putAdminDashboardMarkAsCompleteSuccess,
  putAdminDashboardMarkAsCompleteError,
  getBadges,
  getBadgesSuccess,
  getBadgesError,
  getEventBadgesSuccess,
  getEventBadgesError,
  deleteBadgeSuccess,
  deleteBadgeError,
  deleteEventSuccess,
  deleteEventError,
  editBadgeSuccess,
  editBadgeError,
  getEventBadges,
  editEventSuccess,
  editEventError,
  getTestimonialsSuccess,
  getTestimonialsError,
  approveTestimonialSuccess,
  approveTestimonialError,
  rejectTestimonialSuccess,
  rejectTestimonialError,
  updateTestimonialSuccess,
  updateTestimonialError,
  getTestimonials,
  getAdminTesterExportSuccess,
  getAdminTesterExportError,
  getFinanceReportSuccess,
  getFinanceReportError,
  getReviewerSuccess,
  getReviewerError,
  assignReviewerSuccess,
  assignReviewerError,
  updateReviewerSuccess,
  updateReviewerError
} from "./actions";

import baseDomain, {
  allTesterReq,
  allEarningReq,
  allClientsReq,
  seedDashboardReq,
  dashboardStatsReq,
  agencyDeactivateReq,
  agencyActivateReq,
  testerDeactivateReq,
  testerActivateReq,
  activeTesterReq,
  activeClientsReq,
  testerProjectsReq,
  makeReviewerReq,
  makeTesterReq,
  testerSingleProjectsReq,
  feedbackReq,
  getAdminLiveProjectReq,
  getAdminPausedProjectReq,
  getAdminCompletedProjectReq,
  testerBankDetailsReq,
  testerDeviceDetailsReq,
  testerSkillsetDetailsReq,
  inviteTesterReq,
  inviteParticularTesterReq,
  inviteSelectedTesterReqApi,
  makeMakeAsCompleteReq,
  createBadgeApi,
  getBadgesApi,
  createEventBadgeApi,
  getEventBadgesApi,
  fileUploadReq,
  deleteBadgeApi,
  editBadgeApi,
  deleteEventApi,
  editEventApi,
  testimonialReq,
  approveTestimonialReq,
  rejectTestimonialReq,
  updateTestimonialReq,
  getFinanceReportApi,
  getReviewerReq,
  assignReviewerReq,
  updateReviewerReq
} from "../../constants/apiRoutes";

import axios from "axios";

const getAdminAllClientsAPI = async () => {
  return axios.get(`${baseDomain}${allClientsReq}`);
};

const getAdminActiveClientsAPI = async () => {
  return axios.get(`${baseDomain}${activeClientsReq}`);
};

const getAdminAllTestersAPI = async (pageNumber) => {
  return axios.get(`${baseDomain}${allTesterReq}/${pageNumber}`);
};

const getAdminTesterExportAPI = async ()=>{
  return axios.get(`${baseDomain}${allTesterReq}`);
};

const getAdminAllEarningsAPI = async () => {
  return axios.get(`${baseDomain}${allEarningReq}`);
};

const getAdminActiveTestersAPI = async () => {
  return axios.get(`${baseDomain}${activeTesterReq}`);
};

const getAdminSeedDashboardAPI = async () => {
  return axios.get(`${baseDomain}${seedDashboardReq}`);
};

const getAdminDashboardStatsAPI = async () => {
  return axios.get(`${baseDomain}${dashboardStatsReq}`);
};

const agencyDeactivateAPI = async agencyId => {
  return axios.put(`${baseDomain}${agencyDeactivateReq}/${agencyId}`);
};

const agencyActivateAPI = async agencyId => {
  return axios.put(`${baseDomain}${agencyActivateReq}/${agencyId}`);
};

const testerDeactivateAPI = async testerId => {
  return axios.put(`${baseDomain}${testerDeactivateReq}/${testerId}`);
};

const testerActivateAPI = async testerId => {
  return axios.put(`${baseDomain}${testerActivateReq}/${testerId}`);
};

const getTesterAllProjectsAPI = async testerId => {
  return axios.get(`${baseDomain}${testerProjectsReq}/${testerId}`);
};

const getTesterSingleProjectsAPI = async (projectId, testerId) => {
  return axios.get(`${baseDomain}${testerSingleProjectsReq}/${projectId}/${testerId}`);
};

const putTesterMakeReviewerAPI = async testerId => {
  return axios.put(`${baseDomain}${makeReviewerReq}/${testerId}`);
};

const putTesterMakeTesterAPI = async testerId => {
  return axios.put(`${baseDomain}${makeTesterReq}/${testerId}`);
};

const getTesterBankDetailsAPI = async testerId => {
  return axios.get(`${baseDomain}${testerBankDetailsReq}/${testerId}`);
};

const getTesterDeviceDetailsAPI = async testerId => {
  return axios.get(`${baseDomain}${testerDeviceDetailsReq}/${testerId}`);
};

const getTesterSkillsetDetailsAPI = async testerId => {
  return axios.get(`${baseDomain}${testerSkillsetDetailsReq}/${testerId}`);
};

const putAdminDashboardMarkAsCompleteAPI = async platformId => {
  return axios.put(`${baseDomain}${makeMakeAsCompleteReq}/${platformId}`);
};

const getFeedbacksAPI = async => {
  return axios.get(`${baseDomain}${feedbackReq}`);
};

const getAdminLiveProjectsAPI = async () => {
  return axios.get(`${baseDomain}${getAdminLiveProjectReq}`);
};

const getAdminPausedProjectsAPI = async () => {
  return axios.get(`${baseDomain}${getAdminPausedProjectReq}`);
};

const getAdminCompletedProjectsAPI = async () => {
  return axios.get(`${baseDomain}${getAdminCompletedProjectReq}`);
};

const inviteTesterListAPI = async projectId => {
  return axios.get(`${baseDomain}${inviteTesterReq}/${projectId}`);
};

const inviteParticularTesterAPI = async data => {
  return axios.post(`${baseDomain}${inviteParticularTesterReq}`, data);
};

const createBadgePostApi = async formData => {
  return axios.post(`${baseDomain}${createBadgeApi}`, formData);
};
const editBadgePostApi = async formData => {
  return axios.put(`${baseDomain}${editBadgeApi}`, formData);
};
const editEventPostApi = async formData => {
  return axios.put(`${baseDomain}${editEventApi}`, formData);
};

const createEventBadgePostApi = async formData => {
  return axios.post(`${baseDomain}${createEventBadgeApi}`, formData);
};

const getBadesApi = async () => {
  return axios.get(`${baseDomain}${getBadgesApi}`);
};
const getEventBadesApi = async () => {
  return axios.get(`${baseDomain}${getEventBadgesApi}`);
};
const deleteBagesApi = async badgeId => {
  return axios.delete(`${baseDomain}${deleteBadgeApi}/${badgeId}`);
};
const deleteEventApiReq = async eventId => {
  return axios.delete(`${baseDomain}${deleteEventApi}/${eventId}`);
};

const fileUploadAPI = async (file, type) => {
  return axios.post(`${baseDomain}${fileUploadReq}/${type}`, file);
};

const inviteSelectedTestersAPI = async ({ payload }) => {
  return axios.post(`${baseDomain}${inviteSelectedTesterReqApi}`, payload);
};

const getTestimonialsAPI = async () => {
  return axios.get(`${baseDomain}${testimonialReq}`);
};

const approveTestimonialAPI = async testimonialId => {
  return axios.put(`${baseDomain}${approveTestimonialReq}/${testimonialId}`);
};

const rejectTestimonialAPI = async testimonialId => {
  return axios.put(`${baseDomain}${rejectTestimonialReq}/${testimonialId}`);
};

const updateTestimonialAPI = async (testimonialId, input) => {
  return axios.put(`${baseDomain}${updateTestimonialReq}/${testimonialId}`, input);
};

const getFinanceReportApiCall = async()=>{
  return axios.get(`${baseDomain}${getFinanceReportApi}`)
};

const getReviewerAPI = async () => {
  return axios.get(`${baseDomain}${getReviewerReq}`);
};

const assignReviewerAPI = async payload => {
  return axios.post(`${baseDomain}${assignReviewerReq}`,payload);
};

const updateReviewerAPI = async payload => {
  return axios.put(`${baseDomain}${updateReviewerReq}`,payload)
};

function* tokenErrorFun() {
  yield put(
    setAlert({
      message: "You are logged in from another device or session expired",
      type: "ERROR",
    })
  );
  yield put(logoutUser());
}

function* getAdminAllClients() {
  try {
    const { data } = yield call(getAdminAllClientsAPI);
    yield put(getAdminAllClientsSuccess(data.data));
  } catch (error) {
    yield tokenErrorFun();
    yield put(getAdminAllClientsError(error));
  }
}

function* getAdminActiveClients() {
  try {
    const { data } = yield call(getAdminActiveClientsAPI);
    yield put(getAdminActiveClientsSuccess(data.data));
  } catch (error) {
    yield tokenErrorFun();
    yield put(getAdminActiveClientsError(error));
  }
}

function* getAdminAllTesters({payload}) {
  try {
    const { data } = yield call(getAdminAllTestersAPI,payload);
    yield put(getAdminAllTestersSuccess(data.data));
  } catch (error) {
    yield tokenErrorFun();
    yield put(getAdminAllTestersError(error));
  }
}

function* getAdminTesterExport(){
 try {
  const { data } = yield call(getAdminTesterExportAPI);
  yield put(getAdminTesterExportSuccess(data.data));
 }catch(error){
  yield put(getAdminTesterExportError());
 }
}

function* getAdminAllEarnings() {
  try {
    const { data } = yield call(getAdminAllEarningsAPI);
    yield put(getAdminAllEarningsSuccess(data.data));
  } catch (error) {
    yield tokenErrorFun();
    yield put(getAdminAllEarningsError(error));
  }
}

function* getAdminActiveTesters() {
  try {
    const { data } = yield call(getAdminActiveTestersAPI);
    yield put(getAdminActiveTestersSuccess(data.data));
  } catch (error) {
    yield tokenErrorFun();
    yield put(getAdminActiveTestersError(error));
  }
}

function* getAdminSeedDashboard() {
  try {
    const { data } = yield call(getAdminSeedDashboardAPI);
    yield put(getAdminSeedDashboardSuccess(data.data));
  } catch (error) {
    yield tokenErrorFun();
    yield put(getAdminSeedDashboardError(error));
  }
}

function* getAdminDashboardStats() {
  try {
    const { data } = yield call(getAdminDashboardStatsAPI);
    yield put(getAdminDashboardStatsSuccess(data.data));
  } catch (error) {
    yield tokenErrorFun();
    yield put(getAdminDashboardStatsError(error));
  }
}

function* agencyActivate({ payload }) {
  try {
    const { data } = yield call(agencyActivateAPI, payload);
    yield put(agencyActivateSuccess(data.data));
    yield put(setAlert({ message: data.message, type: "SUCCESS" }));
  } catch (error) {
    yield tokenErrorFun();
    const { data } = yield put(agencyActivateError(error));
    yield put(setAlert({ message: data.message, type: "ERROR" }));
  }
}

function* agencyDeactivate({ payload }) {
  try {
    const { data } = yield call(agencyDeactivateAPI, payload);
    yield put(agencyDeactivateSuccess(data.data));
    yield put(setAlert({ message: data.message, type: "SUCCESS" }));
  } catch (error) {
    yield tokenErrorFun();
    const { data } = yield put(agencyDeactivateError(error));
    yield put(setAlert({ message: data.message, type: "ERROR" }));
  }
}

function* testerActivate({ payload }) {
  try {
    const { data } = yield call(testerActivateAPI, payload);
    yield put(testerActivateSuccess(data.data));
    yield put(setAlert({ message: data.message, type: "SUCCESS" }));
  } catch (error) {
    yield tokenErrorFun();
    const { data } = yield put(testerActivateError(error));
    yield put(setAlert({ message: data.message, type: "ERROR" }));
  }
}

function* testerDeactivate({ payload }) {
  try {
    const { data } = yield call(testerDeactivateAPI, payload);
    yield put(testerDeactivateSuccess(data.data));
    yield put(setAlert({ message: data.message, type: "SUCCESS" }));
  } catch (error) {
    yield tokenErrorFun();
    const { data } = yield put(testerDeactivateError(error));
    yield put(setAlert({ message: data.message, type: "ERROR" }));
  }
}

function* getTesterAllProjects({ payload }) {
  try {
    const { data } = yield call(getTesterAllProjectsAPI, payload);
    yield put(getTesterAllProjectsSuccess(data.data));
  } catch (error) {
    yield tokenErrorFun();
    yield put(getTesterAllProjectsError(error));
  }
}

function* getTesterSingleProjects({ payload }) {
  try {
    const { data } = yield call(getTesterSingleProjectsAPI, payload);
    yield put(getTesterSingleProjectSuccess(data.data));
  } catch (error) {
    yield tokenErrorFun();
    yield put(getTesterSingleProjectError(error));
  }
}

function* putTesterMakeReviewer({ payload }) {
  try {
    const { data } = yield call(putTesterMakeReviewerAPI, payload);
    yield put(putTesterMakeReviewerSuccess(data.data));
    yield put(setAlert({ message: data.message, type: "SUCCESS" }));
  } catch (error) {
    yield tokenErrorFun();
    const { data } = yield put(putTesterMakeReviewerError(error));
    yield put(setAlert({ message: data.message, type: "ERROR" }));
  }
}

function* getFeedbacks({ payload }) {
  try {
    const { data } = yield call(getFeedbacksAPI, payload);
    yield put(getFeedbacksSuccess(data.data));
  } catch (error) {
    yield tokenErrorFun();
    yield put(getFeedbacksError(error));
  }
}

function* getAdminLiveProjects({ payload }) {
  try {
    const { data } = yield call(getAdminLiveProjectsAPI, payload);
    yield put(getAdminLiveProjectsSuccess(data.data));
  } catch (error) {
    yield tokenErrorFun();
    yield put(getAdminLiveProjectsError(error));
  }
}

function* getAdminPausedProjects({ payload }) {
  try {
    const { data } = yield call(getAdminPausedProjectsAPI, payload);
    yield put(getAdminPausedProjectsSuccess(data.data));
  } catch (error) {
    yield tokenErrorFun();
    yield put(getAdminPausedProjectsError(error));
  }
}

function* getAdminCompletedProjects({ payload }) {
  try {
    const { data } = yield call(getAdminCompletedProjectsAPI, payload);
    yield put(getAdminCompletedProjectsSuccess(data.data));
  } catch (error) {
    yield tokenErrorFun();
    yield put(getAdminCompletedProjectsError(error));
  }
}

function* inviteTester({ payload }) {
  try {
    const { data } = yield call(inviteTesterListAPI, payload);
    yield put(inviteTesterSuccess(data.data));
  } catch (error) {
    yield put(inviteTesterError(error));
    yield put(setAlert({ message: error.response.data.message, type: "ERROR" }));
  }
}

function* inviteParticularTester({ payload }) {
  try {
    const { data } = yield call(inviteParticularTesterAPI, payload);
    yield put(inviteParticularTesterSuccess(data.data));
    yield put(setAlert({ message: data.message, type: "SUCCESS" }));
  } catch (error) {
    yield put(inviteParticularTesterError(error));
    yield put(setAlert({ message: error.response.data.message, type: "ERROR" }));
  }
}

function* inviteSelectedTesters(payload) {
  try {
    yield call(inviteSelectedTestersAPI, payload);
    yield put(setAlert({ message: "Invitation sent", type: "SUCCESS" }));
  } catch (error) {
    yield put(setAlert({ message: error.response.data.message, type: "ERROR" }));
  }
}

// function* putTesterMakeTester({ payload }) {
//   try {
//     const { data } = yield call(putTesterMakeTesterAPI, payload);
//     yield put(putTesterMakeTesterSuccess(data.data));
//     yield put(setAlert({message: data.message, type: "SUCCESS" }));
//   } catch (error) {
//     yield tokenErrorFun();
//     const { data } = yield put(putTesterMakeTesterError(error));
//     yield put(setAlert({message: data.message, type: "ERROR" }));
//   }
// }

function* putTesterMakeTester({ payload }) {
  try {
    yield call(putTesterMakeTesterAPI, payload);
    yield put(putTesterMakeTesterSuccess());
    yield put(setAlert({ message: "TESTER ASSIGNED", type: "SUCCESS" }));
  } catch (error) {
    yield tokenErrorFun();
    yield put(putTesterMakeTesterError(error));
    yield put(setAlert({ message: "TESTER ASSIGNED", type: "ERROR" }));
  }
}

function* getTesterBankDetails({ payload }) {
  try {
    const { data } = yield call(getTesterBankDetailsAPI, payload);
    yield put(getTesterBankDetailsSuccess(data.data));
  } catch (error) {
    yield tokenErrorFun();
    yield put(getTesterBankDetailsError(error));
  }
}

function* getTesterDeviceDetails({ payload }) {
  try {
    const { data } = yield call(getTesterDeviceDetailsAPI, payload);
    yield put(getTesterDeviceDetailsSuccess(data.data));
  } catch (error) {
    yield tokenErrorFun();
    yield put(getTesterDeviceDetailsError(error));
  }
}

function* getTesterSkillsetDetails({ payload }) {
  try {
    const { data } = yield call(getTesterSkillsetDetailsAPI, payload);
    yield put(getTesterSkillsetDetailsSuccess(data.data));
  } catch (error) {
    yield tokenErrorFun();
    yield put(getTesterSkillsetDetailsError(error));
  }
}

function* putAdminDashboardMarkAsComplete({ payload }) {
  try {
    yield call(putAdminDashboardMarkAsCompleteAPI, payload);
    yield put(putAdminDashboardMarkAsCompleteSuccess());
    yield put(setAlert({ message: "Project Marked as Complete", type: "SUCCESS" }));
    window.location.reload();
  } catch (error) {
    yield tokenErrorFun();
    yield put(putAdminDashboardMarkAsCompleteError(error));
    yield put(setAlert({ message: "Failed!!", type: "ERROR" }));
  }
}
function* createBadge({ payload, badgeIcon }) {
  try {
    const fileData = new FormData();
    fileData.append("file", badgeIcon);
    const res = yield call(fileUploadAPI, fileData, "badgeIcon");
    yield call(createBadgePostApi, { ...payload, logo: res.data.data });
    yield put(setAlert({ message: "Badge Created", type: "SUCCESS" }));
    yield put(getBadges());
  } catch (error) {
    yield put(setAlert({ message: error, type: "ERROR" }));
  }
}
function* createEventBadge({ payload }) {
  try {
    yield call(createEventBadgePostApi, payload);
    yield put(setAlert({ message: "Badge Event Created", type: "SUCCESS" }));
    yield put(getEventBadges());
  } catch (error) {
    yield put(setAlert({ message: error, type: "ERROR" }));
  }
}

function* workerGetBadges() {
  try {
    const { data } = yield call(getBadesApi);
    yield put(getBadgesSuccess(data.data));
  } catch (error) {
    yield put(setAlert({ message: error, type: "ERROR" }));
    yield put(getBadgesError(error));
  }
}
function* workerGetEventBadges() {
  try {
    const { data } = yield call(getEventBadesApi);
    yield put(getEventBadgesSuccess(data.data));
  } catch (error) {
    yield put(setAlert({ message: error, type: "ERROR" }));
    yield put(getEventBadgesError(error));
  }
}
function* workerDeleteBadges({ payload }) {
  try {
    yield call(deleteBagesApi, payload);
    yield put(deleteBadgeSuccess());
    yield put(setAlert({ message: "Badge Deleted Successfully", type: "SUCCESS" }));
    yield put(getBadges());
  } catch (error) {
    yield put(setAlert({ message: error, type: "ERROR" }));
    yield put(deleteBadgeError(error));
  }
}

function* workerDeleteEvent({ payload }) {
  try {
    yield call(deleteEventApiReq, payload);
    yield put(deleteEventSuccess());
    yield put(setAlert({ message: "Event Deleted Successfully", type: "SUCCESS" }));
    yield put(getEventBadges());
  } catch (error) {
    yield put(setAlert({ message: error, type: "ERROR" }));
    yield put(deleteEventError(error));
  }
}
function* workerEditBadges({ formData, badgeIcon }) {
  try {
    if (badgeIcon?.name.length > 0) {
      const fileData = new FormData();
      fileData.append("file", badgeIcon);
      const res = yield call(fileUploadAPI, fileData, "badgeIcon");
      yield call(editBadgePostApi, { ...formData, logo: res.data.data });
      yield put(editBadgeSuccess());
      yield put(setAlert({ message: "Badge Edited Successfully", type: "SUCCESS" }));
      yield put(getBadges());
    } else {
      yield call(editBadgePostApi, formData);
      yield put(editBadgeSuccess());
      yield put(setAlert({ message: "Badge Edited Successfully", type: "SUCCESS" }));
      yield put(getBadges());
    }
  } catch (error) {
    yield put(setAlert({ message: error, type: "ERROR" }));
    yield put(editBadgeError(error));
  }
}
function* workerEditEvent({ formData }) {
  try {
    yield call(editEventPostApi, formData);
    yield put(editEventSuccess());
    yield put(setAlert({ message: "Event Edited Successfully", type: "SUCCESS" }));
    yield put(getEventBadges());
  } catch (error) {
    yield put(setAlert({ message: error, type: "ERROR" }));
    yield put(editEventError(error));
  }
}

function* workerGetTestimonials() {
  try {
    const { data } = yield call(getTestimonialsAPI);
    yield put(getTestimonialsSuccess(data.data));
  } catch (error) {
    yield put(setAlert({ message: error, type: "ERROR" }));
    yield put(getTestimonialsError(error));
  }
}

function* workerApproveTestimonial({ payload }) {
  try {
    yield call(approveTestimonialAPI, payload);
    yield put(approveTestimonialSuccess());
    yield put(getTestimonials());
    yield put(
      setAlert({
        message: "Testimonial Approved",
        type: "SUCCESS",
      })
    );
  } catch (error) {
    yield put(setAlert({ message: error, type: "ERROR" }));
    yield put(approveTestimonialError(error));
  }
}

function* workerRejectTestimonial({ payload }) {
  try {
    yield call(rejectTestimonialAPI, payload);
    yield put(rejectTestimonialSuccess());
    yield put(getTestimonials());
    yield put(
      setAlert({
        message: "Testimonial Rejected",
        type: "SUCCESS",
      })
    );
  } catch (error) {
    yield put(setAlert({ message: error, type: "ERROR" }));
    yield put(rejectTestimonialError(error));
  }
}

function* workerUpdateTestimonial({ id, input }) {
  try {
    yield call(updateTestimonialAPI, id, input);
    yield put(updateTestimonialSuccess());
    yield put(getTestimonials());
    yield put(
      setAlert({
        message: "Testimonial Updated",
        type: "SUCCESS",
      })
    );
  } catch (error) {
    yield put(setAlert({ message: error, type: "ERROR" }));
    yield put(updateTestimonialError(error));
  }
}
function* workerFinanceReport() {
  try {
    const {data} =  yield call(getFinanceReportApiCall);
     yield put(getFinanceReportSuccess(data.data));

  } 
  catch (error) {
    yield put(setAlert({ message: "Finance Report Request Failed", type: "ERROR" }));
    yield put(getFinanceReportError(error));
  }
}

function* workerGetReviewer(){
  try{
   const {data} = yield call(getReviewerAPI);
   yield put(getReviewerSuccess(data.data));
  }catch(error){
   yield put(getReviewerError());
  }
}

function* workerAssignReviewer({payload}){
  try{
    yield call(assignReviewerAPI,payload);
    yield put(assignReviewerSuccess());
    yield put(setAlert({
      message:"Reviewer Assigned",
      type:"SUCCESS"
    }))
  }catch(error){
    yield put(assignReviewerError());
    yield put(setAlert({
      message:error, type:"ERROR"
    }))
  }
}

function* workerUpdateReviewer({payload}){
  try{
    yield call(updateReviewerAPI,payload);
    yield put(updateReviewerSuccess());
    yield put(setAlert({
      message:"Reviewer Changed",
      type:"SUCCESS"
    }))
  }catch(error){
    yield put(updateReviewerError());
    yield put(setAlert({
      message:error, type:"ERROR"
    }))
  }
}

export function* watchGetAdminAllClients() {
  yield takeEvery(GET_ADMIN_ALL_CLIENTS, getAdminAllClients);
}

export function* watchGetAdminActiveClients() {
  yield takeEvery(GET_ADMIN_ACTIVE_CLIENTS, getAdminActiveClients);
}

export function* watchGetAdminAllTesters() {
  yield takeEvery(GET_ADMIN_ALL_TESTER, getAdminAllTesters);
}

export function* watchAdminTesterExport() {
  yield takeEvery(GET_ADMIN_TESTERS_EXPORT,getAdminTesterExport);
}

export function* watchGetAdminAllEarnings() {
  yield takeEvery(GET_ADMIN_ALL_EARNING, getAdminAllEarnings);
}

export function* watchGetAdminActiveTesters() {
  yield takeEvery(GET_ADMIN_ACTIVE_TESTER, getAdminActiveTesters);
}

export function* watchGetAdminSeedDashboard() {
  yield takeEvery(GET_ADMIN_DASHBOARD_SEED, getAdminSeedDashboard);
}

export function* watchGetAdminDashboardStats() {
  yield takeEvery(GET_ADMIN_DASHBOARD_STATS, getAdminDashboardStats);
}

export function* watchAgencyActivate() {
  yield takeEvery(PUT_AGENCY_ACTIVATE, agencyActivate);
}

export function* watchAgencyDeactivate() {
  yield takeEvery(PUT_AGENCY_DEACTIVATE, agencyDeactivate);
}

export function* watchTesterActivate() {
  yield takeEvery(PUT_TESTER_ACTIVATE, testerActivate);
}

export function* watchTesterDeactivate() {
  yield takeEvery(PUT_TESTER_DEACTIVATE, testerDeactivate);
}

export function* watchGetTesterAllProjects() {
  yield takeEvery(GET_TESTER_ALL_PROJECTS, getTesterAllProjects);
}

export function* watchGetTesterSingleProjects() {
  yield takeEvery(GET_TESTER_SINGLE_PROJECTS, getTesterSingleProjects);
}

export function* watchPutTesterMakeReviewer() {
  yield takeEvery(PUT_TESTER_MAKE_REVIEWER, putTesterMakeReviewer);
}

export function* watchPutTesterMakeTester() {
  yield takeEvery(PUT_TESTER_MAKE_TESTER, putTesterMakeTester);
}

export function* watchGetFeedbacks() {
  yield takeEvery(GET_FEEDBACKS, getFeedbacks);
}

export function* watchGetAdminLiveProjects() {
  yield takeEvery(GET_ADMIN_LIVE_PROJECTS, getAdminLiveProjects);
}

export function* watchGetAdminPausedProjects() {
  yield takeEvery(GET_ADMIN_PAUSED_PROJECTS, getAdminPausedProjects);
}

export function* watchGetAdminCompletedProjects() {
  yield takeEvery(GET_ADMIN_COMPLETED_PROJECTS, getAdminCompletedProjects);
}

export function* watchTesterBankDetails() {
  yield takeEvery(GET_TESTER_BANK_DETAILS, getTesterBankDetails);
}

export function* watchTesterDeviceDetails() {
  yield takeEvery(GET_TESTER_DEVICE_DETAILS, getTesterDeviceDetails);
}

export function* watchTesterSkillsetDetails() {
  yield takeEvery(GET_TESTER_SKILLSET_DETAILS, getTesterSkillsetDetails);
}

export function* watchInviteTester() {
  yield takeEvery(INVITE_TESTER_ADMIN, inviteTester);
}

export function* watchInviteParticularTester() {
  yield takeEvery(INVITE_PARTICULAR_TESTER_ADMIN, inviteParticularTester);
}
export function* watchInviteSelectedTester() {
  yield takeEvery(INVITE_SELECTED_TESTER_ADMIN, inviteSelectedTesters);
}

export function* watchPutMarkAsComplete() {
  yield takeEvery(PUT_ADMIN_DASHBOARD_MARK_AS_COMPLETE, putAdminDashboardMarkAsComplete);
}
export function* watchCreateBadge() {
  yield takeEvery(CREATE_BADGE, createBadge);
}
export function* watchGetBadges() {
  yield takeEvery(GET_BADGES, workerGetBadges);
}
export function* watchCreateEventBadge() {
  yield takeEvery(CREATE_EVENT_BADGE, createEventBadge);
}

export function* watchGetEventBadges() {
  yield takeEvery(GET_EVENT_BADGES, workerGetEventBadges);
}
export function* watchDeleteBadge() {
  yield takeEvery(DELETE_BADGE, workerDeleteBadges);
}
export function* watchEditBadge() {
  yield takeEvery(EDIT_BADGE, workerEditBadges);
}
export function* watchEditEvent() {
  yield takeEvery(EDIT_EVENT, workerEditEvent);
}

export function* watchDeleteEvent() {
  yield takeEvery(DELETE_EVENT, workerDeleteEvent);
}

export function* watchGetTestimonials() {
  yield takeEvery(GET_TESTIMONIALS, workerGetTestimonials);
}

export function* watchApproveTestimonial() {
  yield takeEvery(APPROVE_TESTIMONIAL, workerApproveTestimonial);
}

export function* watchRejectTestimonial() {
  yield takeEvery(REJECT_TESTIMONIAL, workerRejectTestimonial);
}

export function* watchUpdateTestimonial() {
  yield takeEvery(UPDATE_TESTIMONIAL, workerUpdateTestimonial);
}
export function* watchGetFinanceReport() {
  yield takeEvery(GET_FINANCE_REPORT, workerFinanceReport);
}

export function* watchGetReviewer() {
  yield takeEvery(GET_REVIEWER,workerGetReviewer);
}

export function* watchAssignReviewer(){
  yield takeEvery(ASSIGN_REVIEWER_BUG, workerAssignReviewer);
}

export function* watchUpdateReviewer(){
  yield takeEvery(UPDATE_REVIEWER,workerUpdateReviewer);
}

export default function* rootSaga() {
  yield all([
    fork(watchGetAdminAllClients),
    fork(watchGetAdminActiveClients),
    fork(watchGetAdminAllTesters),
    fork(watchAdminTesterExport),
    fork(watchGetAdminAllEarnings),
    fork(watchGetAdminActiveTesters),
    fork(watchGetAdminSeedDashboard),
    fork(watchGetAdminDashboardStats),
    fork(watchAgencyActivate),
    fork(watchAgencyDeactivate),
    fork(watchTesterActivate),
    fork(watchTesterDeactivate),
    fork(watchGetTesterAllProjects),
    fork(watchGetTesterSingleProjects),
    fork(watchPutTesterMakeReviewer),
    fork(watchPutTesterMakeTester),
    fork(watchGetFeedbacks),
    fork(watchGetAdminLiveProjects),
    fork(watchGetAdminPausedProjects),
    fork(watchGetAdminCompletedProjects),
    fork(watchTesterBankDetails),
    fork(watchTesterDeviceDetails),
    fork(watchTesterSkillsetDetails),
    fork(watchInviteTester),
    fork(watchInviteParticularTester),
    fork(watchInviteSelectedTester),
    fork(watchPutMarkAsComplete),
    fork(watchCreateBadge),
    fork(watchGetBadges),
    fork(watchCreateEventBadge),
    fork(watchGetEventBadges),
    fork(watchDeleteBadge),
    fork(watchDeleteEvent),
    fork(watchEditBadge),
    fork(watchEditEvent),
    fork(watchGetTestimonials),
    fork(watchApproveTestimonial),
    fork(watchRejectTestimonial),
    fork(watchUpdateTestimonial),
    fork(watchGetFinanceReport),
    fork(watchGetReviewer),
    fork(watchAssignReviewer),
    fork(watchUpdateReviewer)
  ]);
}
