import React, { useRef, useEffect, useState } from "react";
import { Link, useHistory, useRouteMatch } from "react-router-dom";

import { connect } from "react-redux";
import { logoutUser } from "../../../redux/auth/actions";
import { toggleRegisterModal } from "../../../redux/Routing/actions";

import profilePic from "../../../assets/images/profilePic.jpg";
import textsettings from "../../../constants/settings";
import { MenuItems1 } from "../../../utils/MenuItems/MenuItems";

import Logo from "../../../assets/images/quantlyBanner.png";
import hamburger from "../../../assets/icons/hamburger_icon.png";

import "./style.scss";
import NavDropdown from "../../Dropdown";

const Header = ({ isAuth, logoutUser, user, toggleRegisterModal, currentStaticPage }) => {
  const headerRef = useRef(null);
  const hambergerRef = useRef(null);
  const [isPaymentPage, setIsPaymentPage] = useState(false);
  const [accordian, setAccordian] = useState(false);
  const match = useRouteMatch();
  const history = useHistory();

  useEffect(() => {
    if (match.url.startsWith("/payment/")) setIsPaymentPage(true);
    else setIsPaymentPage(false);
  }, [match.url]);

  const handleRegisterModal = () => {
    toggleRegisterModal();
  };

  // Handle logout
  const handelLogout = () => {
    logoutUser(user.token);
  };

  const hamburgerfunction = () => {
    var hamRef = hambergerRef.current;
    if (hamRef.style.display === "none") {
      hamRef.style.display = "block";
    } else {
      hamRef.style.display = "none";
    }
  };

  const handleAccordian = () => {
    setAccordian(!accordian);
  };

  const handleSettings = () => {
    history.push("/dashboard/settings");
  };

  return (
    <div className="header-main">
      <div className={`header-main-container ${true ? " bg-white" : ""}`} ref={headerRef}>
        <div className="header-logo-container">
          <div className={`header-logo ${true ? "blue" : "white"}`}>
            <div className="logo">
              <Link to="/" className="inherit">
                <img src={Logo} alt="Logo" />
              </Link>
            </div>
          </div>
        </div>
        {!isPaymentPage && (
          <>
            <div className="header-navitems-container">
              {!isAuth && (
                <ul className={true ? "grey header-item" : "white header-item"}>
                  <li className={`${currentStaticPage === "SERVICES" ? "active" : "inActive"} dropdown`}>
                    <Link className="inherit" to="/crowdsourcedtesting/services">
                      {textsettings.layout_header.services}
                    </Link>
                     <NavDropdown MenuItems={MenuItems1}/>
                  </li>
                  {/* <li className={currentStaticPage === "AUTOMATION" ? "active" : "inActive"}>
                    <Link className="inherit" to="/automation">
                      {textsettings.layout_header.automation}
                    </Link>
                  </li>
                  <li className={currentStaticPage === "SOLUTION" ? "active" : "inActive"}>
                    <Link className="inherit" to="/crowdsourcedtesting/crowd-testing">
                      {textsettings.layout_header.crowdtest}
                    </Link>
                  </li>
                  <li className={currentStaticPage === "DEVICEFARM" ? "active" : "inActive"}>
                    <Link className="inherit" to="/crowdsourcedtesting/devicehub">
                      {textsettings.layout_header.device}
                    </Link>
                  </li> */}
                  <li className={`${currentStaticPage==="HOMETESTER" ? "active":"inActive"}`}>
                    <Link className="inherit" to="/crowdsourcedtesting/tester">
                    BECOME A TESTER
                    </Link>
                  </li>                                    
                  <li className={`${currentStaticPage === "ABOUTUS" ? "active" : "inActive"} dropdown `}>
                    <Link className="inherit" to="/crowdsourcedtesting/aboutus">
                      ABOUT US
                    </Link>
                   {/* <NavDropdown MenuItems={MenuItems2}/> */}
                  </li>
                  <li className={`${currentStaticPage === "CONTACTUS" ? "active" : "inActive"} dropdown `}>
                    <Link className="inherit" to="/crowdsourcedtesting/contactus">
                      CONTACT US
                    </Link>
                   {/* <NavDropdown MenuItems={MenuItems2}/> */}
                  </li>
                </ul>
              )}
            </div>

            <div className="header-control-section">
              {!(isAuth && user && user.name) && (
                <ul>
                  <li className={true ? "link-blue header-item" : "white header-item"}>
                    <Link to="/login" className="inherit">
                      {textsettings.layout_header.login}
                    </Link>
                  </li>
                  <li className={true ? "link-blue header-item" : "white header-item"}>
                    <div className="inherit cursor" onClick={handleRegisterModal}>
                      {textsettings.layout_header.register}
                    </div>
                  </li>
                  <li className="hamberger">
                    <img src={hamburger} alt="hamburger" width="15px" onClick={hamburgerfunction} />
                  </li>
                </ul>
              )}
              {isAuth && user && user.name && (
                <div>
                  <div className="login-user">
                    <div className="img">
                      <img src={user.profilePic || profilePic} alt="" />
                      {user?.badge && (
                        <>{user.badge?.currentBadge?.logo && <img className="testerBadge" src={user.badge.currentBadge.logo} alt="Badge Icon" />}</>
                      )}
                    </div>
                    <h4>
                      <span onClick={handleSettings}>{user.name}</span> | <span onClick={handelLogout}>{textsettings.layout_header.logout}</span>
                    </h4>
                  </div>
                  <li className="hamberger">
                    <img src={hamburger} alt="hamburger" width="15px" onClick={hamburgerfunction} />
                  </li>
                </div>
              )}
            </div>
          </>
        )}
      </div>
      {!isPaymentPage && (
        <>
          <div className="header-navitems-container-responsive fadeIn" ref={hambergerRef} style={{ display: "none" }}>
            {!isAuth && (
              <ul className={true ? "grey header-item" : "white header-item"}>
                <li onClick={handleAccordian}>
                    {textsettings.layout_header.services}&ensp;
                    {accordian===true?<span>&#11205;</span>:
                    <span>&#11206;</span>}
                </li>
                {accordian === true &&
                <li style={{padding:"0px"}}>
                  <NavDropdown MenuItems={MenuItems1}/>
                </li>
                }
                {/* <li>
                  <Link className="inherit" to="/automation">
                    {textsettings.layout_header.automation}
                  </Link>
                </li>
                <li>
                  <Link className="inherit" to="/crowd-testing">
                    {textsettings.layout_header.crowdtest}
                  </Link>
                </li>
                <li>
                  <Link className="inherit" to="/devicehub">
                    {textsettings.layout_header.device}
                  </Link>
                </li> */}
                <li>
                <Link className="inherit" to="/crowdsourcedtesting/tester">
                      BECOME A TESTER
                </Link>
                </li>
                <li>
                  <Link className="inherit" to="/crowdsourcedtesting/aboutus">
                    ABOUT US
                  </Link>
                </li>
                <li>
                  <Link className="inherit" to="/crowdsourcedtesting/contactus">
                    CONTACT US
                  </Link>
                </li>
              </ul>
            )}
            {!isAuth && (
              <ul>
                <li className={true ? "link-blue header-item" : "white header-item"}>
                  <Link to="/login" className="inherit">
                    {textsettings.layout_header.login}
                  </Link>
                </li>
                <li className={true ? "link-blue header-item" : "white header-item"}>
                  <div className="inherit" onClick={handleRegisterModal}>
                    {textsettings.layout_header.register}
                  </div>
                </li>
              </ul>
            )}
            {isAuth && (
              <div className="login-user-responsive">
                <div className="login-user">
                  <Link className="inherit" to="/dashboard/settings">
                    <div className="img">
                      <img src={user.profilePic || profilePic} alt="" />
                      {user?.badge && (
                        <>{user.badge?.currentBadge?.logo && <img className="testerBadge" src={user.badge.currentBadge.logo} alt="Badge Icon" />}</>
                      )}
                    </div>
                    <h4>{user.name}</h4>
                  </Link>
                </div>
                <h4 onClick={handelLogout}>{textsettings.layout_header.logout}</h4>
              </div>
            )}
          </div>
        </>
      )}
    </div>
  );
};

const mapStateToProps = ({ auth: { loading, error, isAuth, role, user, isLoadingDashboard }, routeingState: { currentStaticPage } }) => ({
  loading,
  error,
  isAuth,
  role,
  user,
  isLoadingDashboard,
  currentStaticPage,
});

export default connect(mapStateToProps, { logoutUser, toggleRegisterModal })(Header);
