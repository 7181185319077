import React,{useState} from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";

import { getFullDateTime } from "../../../../utils/getDateTime";
import Comment from "../../../../assets/icons/ftcomment.svg";

import NoDataMessage from "../../../Common/NoDataMessage";
import settings from "../../../../constants/settings";
import {addCommentModal} from "../../../../redux/Routing/actions";

import "../style.scss";
import AddComment from "../../../Modals/AddCommentModal";

// const tableHeadingArray = ["Bug Title", "Platform", "Version", "Submission Time", "Status", "Approval"];
// const tableHeadingArray = ["Bug Title", "Submission Time", "Status", "Approval", "View"];
const BugListTable = ({ tableContentArray, role, projectID, reviewer, assignReviewer, updateReviewer, addCommentModal,isModal, assignedTable }) => {


   const [itemId,setItemId] = useState();
   const [name,setName] = useState();

   const popModal = (summary,id,bugId)=>{
    setName(summary); 
    id ? setItemId(id) : setItemId(bugId)
    addCommentModal()
   }

  return (
    <div className="dashboard-table bug-list">
      {tableContentArray.length === 0 ? 
        assignedTable ? <NoDataMessage message={"No Bugs Assigned for this project"} />:<NoDataMessage message={settings.bugs_list_table.message} />
       : (
        <table>
          <tr>
            {settings.bugs_list_table.tableHeadingArray.map((head, i) => (
              <th key={i}>{head}</th>
            ))}
            {(role==="ADMIN"||role==="REVIEWER") && <th>Comment</th>}
            {(role==="ADMIN"||role==="REVIEWER") && <th>Assigned Reviewer</th>}
          </tr>

          {tableContentArray.map(({ summary, createAt, priority,severity, status, id, bugId,reviewerAssigned }, i) => (
            <tr key={i}>
              <td className="bugName">
                <Link
                  className="inherit blue-color"
                  to={
                    role === "TESTER"
                      ? `/dashboard/test-taken/projects/active/${projectID}/bug/${id || bugId}`
                      : ( role === "ADMIN" )
                      ? `/dashboard/projects/projectlist/${projectID}/bugs/${id || bugId}/details`
                      : `/dashboard/test-taken/projects/completed/${projectID}/bug/${id || bugId}`
                  }
                >
                  {summary.length > 30 ? summary.slice(0,30).concat("..."):summary}
                </Link>
              </td>

              <td>{getFullDateTime(createAt)}</td>
              <td>{priority||severity}</td>
              {role!=='TESTER' ? <td approval={status}>{status}</td> : <td>-</td>}

              {(role==="ADMIN"||role==="REVIEWER") &&
            <td onClick={()=>popModal(summary,id,bugId)} >
              <img src={Comment} alt="observation" />
              </td>
            }
              { (role==="ADMIN"||role==="REVIEWER") && <td>
               <select className="assignReviewer" disabled={status==="PENDING" ? false : true} onChange={(e)=>{
                  const payload = {
                    assignee:e.target.value,
                    bugId:id || bugId
                  }
                  reviewerAssigned ?
                  updateReviewer(payload):assignReviewer(payload)
                }} >
                  { !reviewerAssigned && <option>Unassigned</option>}
                  {reviewer.map(item=>{
                    if(reviewerAssigned && reviewerAssigned===item.id){
                      return <option key={item.id} value={item.id} selected>{item.name}</option>
                    } else {
                      return <option key={item.id} value={item.id} >{item.name}</option>
                    }
                  })}
                </select>
              </td>}
            </tr>
          ))}
        </table>
      )}
       {isModal && <AddComment addCommentModal={addCommentModal} bugId={itemId} bugName={name} />}
    </div>
  );
};

const mapStatesToProps = ({routeingState})=>({
 isModal: routeingState.showCommentModal
})

export default connect(mapStatesToProps,{addCommentModal})(BugListTable);
