import {
  ADD_TEST_STEP_ONE_SUBMIT,
  ADD_TEST_STEP_ONE_SUBMIT_SUCCESS,
  ADD_TEST_STEP_ONE_SUBMIT_ERROR,
  ADD_TEST_STEP_TWO_SUBMIT,
  ADD_TEST_STEP_TWO_SUBMIT_SUCCESS,
  ADD_TEST_STEP_TWO_SUBMIT_ERROR,
  ADD_TEST_STEP_THREE_SUBMIT,
  ADD_TEST_STEP_THREE_SUBMIT_SUCCESS,
  ADD_TEST_STEP_THREE_SUBMIT_ERROR,
  GET_PAYMENT_PACKAGE,
  GET_PAYMENT_PACKAGE_SUCCESS,
  GET_PAYMENT_PACKAGE_ERROR,
  ADD_TEST_STEP_TWO_WITH_STORE_SUBMIT,
  ADD_TEST_STEP_TWO_WITH_STORE_SUBMIT_SUCCESS,
  ADD_TEST_STEP_TWO_WITH_STORE_SUBMIT_ERROR,
} from "../actions";

const initialState = {
  currentProjectId: null,
  stripeSessionId: "",
  isLoading: false,
  error: null,
  isPackageLoading: false,
  packages: [],
};

const fun = (state = initialState, action) => {
  switch (action.type) {
    // Step 1
    case ADD_TEST_STEP_ONE_SUBMIT:
      return {
        ...state,
        currentProjectId: null,
        isLoading: true,
        error: null,
      };
    case ADD_TEST_STEP_ONE_SUBMIT_SUCCESS:
    case ADD_TEST_STEP_TWO_SUBMIT_SUCCESS:
      return {
        ...state,
        currentProjectId: action.payload,
        stripeSessionId: "",
        isLoading: false,
        error: null,
      };
    case ADD_TEST_STEP_TWO_WITH_STORE_SUBMIT_SUCCESS:
      return {
        ...state,
        currentProjectId: action.payload,
        stripeSessionId: "",
        isLoading: false,
        error: null,
      };
    case ADD_TEST_STEP_THREE_SUBMIT_SUCCESS:
      return {
        ...state,
        currentProjectId: action.payload.projectID,
        stripeSessionId: action.payload.stripeID,
        isLoading: false,
        error: null,
      };

    case ADD_TEST_STEP_ONE_SUBMIT_ERROR:
    case ADD_TEST_STEP_TWO_SUBMIT_ERROR:
    case ADD_TEST_STEP_TWO_WITH_STORE_SUBMIT_ERROR:
    case ADD_TEST_STEP_THREE_SUBMIT_ERROR:
      return {
        ...state,
        isLoading: false,
        currentProjectId: null,
        error: action.payload,
      };
    case ADD_TEST_STEP_TWO_SUBMIT:
    case ADD_TEST_STEP_TWO_WITH_STORE_SUBMIT:
    case ADD_TEST_STEP_THREE_SUBMIT:
      return {
        ...state,
        isLoading: true,
        error: null,
      };

    case GET_PAYMENT_PACKAGE:
      return {
        ...state,
        isPackageLoading: true,
        packages: [],
        error: null,
      };
    case GET_PAYMENT_PACKAGE_SUCCESS:
      return {
        ...state,
        isPackageLoading: false,
        packages: action.payload,
        error: null,
      };
    case GET_PAYMENT_PACKAGE_ERROR:
      return {
        ...state,
        isPackageLoading: false,
        error: action.payload,
      };

    default:
      return { ...state };
  }
};

export default fun;
