import React, { useEffect } from "react";
import UserTesting from "../Services/UserTesting";
import QAPackages from "../Services/QAPackages";
import AutoLogin from "../../components/AutoLogin";
import { Helmet } from "react-helmet";
import { withLayout } from "../../layouts";

import "./style.scss";
import settings from "../../constants/settings";
const Services = ({ staticSiteNavHandler }) => {
  useEffect(() => {
    staticSiteNavHandler("SERVICES");
    // eslint-disable-next-line
  }, []);
  return (
    <div className="home-services">
      <Helmet>
        <meta name="description" content="On demand QA services, Bug hunting and crowdsourced device testing " />
      </Helmet>
      <AutoLogin />
      <div className="services-heading">
        <h4 className="services-heading-content">
          {settings.services.descriptiona}
          <span className="text-yellow">&nbsp;{settings.services.descriptionb}&nbsp;</span>
          {settings.services.descriptionc}
        </h4>
      </div>
      <UserTesting data={settings.services.user_testing} />
      <QAPackages data={settings.services.QA_packages} />
    </div>
  );
};

export default withLayout(Services);
