import React, { Fragment, useEffect } from "react";
import { connect } from "react-redux";
import { useHistory } from "react-router-dom";
import SignUpSuccess from "../../assets/images/SignUpSuccess.png";
import { signUpTesterProceed } from "../../redux/auth/actions";

const WelcomePage = ({ form, signUpTesterProceed }) => {
  useEffect(() => {
    signUpTesterProceed();
    // eslint-disable-next-line
  }, []);

  const history = useHistory();

  return (
    <Fragment>
      <div className="welcome-heading">
        Congratulations, {form.name}!
        <br />
        <br />
        We are glad to have you onboard.
        <br />
        Show your Super Testing skills &amp; start earning.
      </div>
      <div className="signup-success">
        <img src={SignUpSuccess} alt="Sign-up Success" width="350px" height="500px" />
      </div>
      <div className="prev-next">
        <button
          type="submit"
          className="submit-form"
          onClick={() => {
            history.push("/login");
          }}
        >
          Proceed!
        </button>
      </div>
    </Fragment>
  );
};

export default connect(null, { signUpTesterProceed })(WelcomePage);
