import React from "react";
import "./style.scss";

const AboutUsComp = () => {

  return (
    <div className="aboutus">
      <h1 className="text-yellow" >About Us</h1>
      <p>
      Qantily is a product of Nexgen IoT Solutions, one of the leading IT organizations. Qantily serves as a testing platform for the Modern and Global Software Development Team. Getting insights about your application from real-time users around the world has been made easy via this platform. We provide an easily accessible platform to our clients which saves time and allows them to understand the testing process efficiently. At Qantily we believe in providing top-notch services to our clients in minimal time. Being a node of Nexgen, quality assurance, secured environment, and timely delivery is bound to happen when associated with Qantily.  
      </p>
    </div>
  );
};

export default AboutUsComp;
