import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import exportFromJSON from "export-from-json";

import DashboardHeading from "../../Common/DashboardHeading";
// import AllClientsTable from "../../Common/DashboardTables/AllClients";
import ClientAccordian from "./Component/Accordian";
import { setDashboardRoute } from "../../../redux/Routing/actions";
import { getAdminAllClients, getAdminActiveClients } from "../../../redux/AdminDashboard/actions";
import "./Component/Accordian/style.scss";
// import Loader from "../../Loader";

const ManageClients = ({ setDashboardRoute, componentID, allClients, getAdminAllClients, getAdminActiveClients, adminActiveClients }) => {
  useEffect(() => {
    setDashboardRoute(componentID);
    getAdminAllClients();
    getAdminActiveClients();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    setCurrentlist(allClients);
    // eslint-disable-next-line
  }, [allClients]);

  useEffect(() => {
    setCurrentlist(adminActiveClients);
    // eslint-disable-next-line
  }, [adminActiveClients]);

  const [filterName, setFilterName] = useState("Active Clients");
  const [currentList, setCurrentlist] = useState(false);
  const handleClientList = () => {
    filterName === "Active Clients" ? setCurrentlist(adminActiveClients) : setCurrentlist(allClients);
    filterName === "Active Clients" ? setFilterName("All Clients") : setFilterName("Active Clients");
  };
  const myList = currentList;
  const myNewList = [];

  const [search1, setSearch1] = useState("");
  const [currentAllClients, setCurrentAllClients] = useState([]);

  const handleChange1 = e => {
    setCurrentAllClients(allClients);
    setCurrentAllClients(oldArray => oldArray.filter(item => item.clientName.toLowerCase().includes(e.target.value.toLowerCase())));
    setSearch1(e.target.value);
  };

  Object.keys(myList).map((item, i) =>
    myList[item].projects.map((abc, j) =>
      myNewList.push({
        id: myList[item].id,
        "Client Name": myList[item].clientName,
        "Client Email": myList[item].email,
        Website: myList[item].website,
        Status: myList[item].active,
        "Projects Name": abc.name,
        Date: abc.date,
        "Bugs Reported": abc.bugtReported,
        "Amount Spend": abc.amountSpent,
        "Project Status": abc.status,
        Platform: abc.platform.name,
      })
    )
  );

  const fileName = "Client Details";
  const exportType = "xls";
  const data = myNewList;
  const ExportToExcel = () => {
    exportFromJSON({ data, fileName, exportType });
  };

  return (
    <div className="allClients client-accordian">
      <DashboardHeading headingStrong={"Manage All Clients"} showBtn={false} />
      <div className="buttons-container">
        <input type="text" value={search1} placeholder="Search by name" className="search-client" onChange={handleChange1} />
        <button className="glb-btn glb-action-btn" onClick={handleClientList}>
          {filterName}
        </button>
        <button className="glb-btn glb-action-btn" onClick={ExportToExcel}>
          Export
        </button>
      </div>
      {(currentList ? (search1 === "" ? currentList : currentAllClients) : allClients).map((item, i) => (
        <ClientAccordian tablecontentarray={item} />
      ))}
    </div>
  );
};

const mapStateToProps = ({ adminDashboard }) => ({
  allClients: adminDashboard.adminAllClients,
  adminActiveClients: adminDashboard.adminActiveClients,
});

export default connect(mapStateToProps, { setDashboardRoute, getAdminAllClients, getAdminActiveClients })(ManageClients);
