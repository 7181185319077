import { all, call, fork, put, takeEvery } from "redux-saga/effects";
import axios from "axios";

import { setAlert } from "../Alerts/actions";

import baseDomain, { loginUserReq, signupTesterReq, signupClientReq, userInfoReq, fileUploadReq ,referralCodeGenerateReq , getSocialPageUrl ,socialSignUpCodeSend , socialSignUpDetailsSend, socialSignInGetUrlApi, socialSignInApi, logoutUserApi } from "../../constants/apiRoutes";

import { SIGNUP_TESTER, SIGNUP_CLIENT, LOGIN_USER, LOGOUT_USER, USER_INFO, GENERATE_REFERRAL_CODE ,SOCIAL_SIGNUP , SOCIAL_SIGNUP_CODE_SEND, SOCIAL_SIGNUP_SUBMIT ,SOCIAL_SIGNIN_GET_URL , SOCIAL_SIGNIN_CODE_SUBMIT} from "../actions";

import setDefaultToken from "../../utils/setDefaultToken";
import { setLocal, clearLocal, getLocal } from "../../utils/localStorage";
import { getAdminDashboardStats } from "../AdminDashboard/actions";

import {
  signUpTesterSuccess,
  signUpTesterError,
  signUpClientSuccess,
  signUpClientError,
  loginUserSuccess,
  loginUserError,
  logoutUserSuccess,
  logoutUserError,
  userInfoSuccess,
  userInfoError,
  generateReferralCodeSuccess,
  generateReferralCodeError,
  socialSignupSuccess,
  socialSignupError,
  socialSignupCodeSendSuccess,
  socialSignupCodeSendError,
  socialSignupSubmitSuccess,
  socialSignupSubmitError,
  socialSigninGetUrlSuccess,
  socialSigninGetUrlError,
  socialSignInCodeSubmitSuccess,
  socialSignInCodeSubmitError
} from "./actions";

const loginUserAPI = async data => {
  return axios.post(`${baseDomain}${loginUserReq}`, data);
};

const signUpTesterAPI = async data => {
  return axios.post(`${baseDomain}${signupTesterReq}`, data);
};

const signUpClientAPI = async data => {
  return axios.post(`${baseDomain}${signupClientReq}`, data);
};

const userInfoAPI = async () => {
  return axios.get(`${baseDomain}${userInfoReq}`, null, {
    headers: {
      token: getLocal("token"),
    },
  });
};

const fileUploadAPI = async (file, type) => {
  return axios.post(`${baseDomain}${fileUploadReq}/${type}`, file);
};

const referralCodeApi = async userId =>{
  return axios.get(`${baseDomain}${referralCodeGenerateReq}/${userId}`)
}
const socialPageReqApi = async (identity,provider) =>{
  return axios.get(`${baseDomain}${getSocialPageUrl}/${identity}/${provider}`)
}
const socialSignUpCodeSendApiReq = async (code,state) =>{
  return axios.post(`${baseDomain}${socialSignUpCodeSend}/${code}/${state}`)
}

const socialSignUpDetailsSendReq = async (data) =>{
  return axios.put(`${baseDomain}${socialSignUpDetailsSend}`,data)
}
const socialSigninGetUrlReq = async (provider) =>{
  return axios.get(`${baseDomain}${socialSignInGetUrlApi}/${provider}`,)
}
const socialSigninReq = async (code,state) =>{
  return axios.post(`${baseDomain}${socialSignInApi}/${code}/${state}`,)
}

const logoutUserAPI = async (token) => {
  return axios.put(`${baseDomain}${logoutUserApi}/${token}`)
}

function* loginUserSaga({ payload, history }) {
  try {
    const { data } = yield call(loginUserAPI, { ...payload, isTester: true });
    setDefaultToken("token", data.token);
    setLocal("token", data.token);
    // setLocal("role", data.role[0]);
    yield put(loginUserSuccess(data));
    yield getAdminDashboardStats();
    yield history.push("/dashboard");
  } catch (error) {
    yield put(loginUserError(error));
    yield put(setAlert({ message: error.response.data.message, type: "ERROR" }));
  }
}

function* logoutUserSaga({token }) {
  try {
    yield call(logoutUserAPI, token);
    yield put(logoutUserSuccess());
    setDefaultToken("token",token);
    clearLocal();
    //yield history.push("/");
    yield put(setAlert({ message: "Logged Out", type: "SUCCESS" }));
  } catch (error) {
    yield put(logoutUserError(error));
  }
}

function* signUpTesterSaga({ payload,profilePic, history }) {
  try {
    if(profilePic){
      const fileData = new FormData();
      fileData.append("file", profilePic);
      const req = yield call(fileUploadAPI, fileData, "PROFILE_IMAGE_TESTER");
      delete payload.passwordRep;
      yield call(signUpTesterAPI, { ...payload, image: req.data.data });
    }
    else{
      delete payload.passwordRep;
      yield call(signUpTesterAPI, { ...payload });}
    
    yield put(setAlert({ message: "Your account is created. Please verify your email.", type: "SUCCESS" }));
    yield put(signUpTesterSuccess());
    // history.push("/login");
  } catch (error) {
    yield put(signUpTesterError(error));
    yield put(setAlert({ message: error.response.data.message, type: "ERROR" }));
  }
}

function* signUpClientSaga({ payload, profilePic, history }) {
  try {
    if(profilePic){
    const fileData = new FormData();
    fileData.append("file", profilePic);
    const req = yield call(fileUploadAPI, fileData, "PROFILE_IMAGE_AGENCY");
    delete payload.passwordRep;
    yield call(signUpClientAPI, { ...payload, image: req.data.data });
    yield put(setAlert({ message: "Your account is created. Check your email for verification", type: "SUCCESS" }));
    yield put(signUpClientSuccess());
    history.push("/login");
  }else{
    delete payload.passwordRep;
    yield call(signUpClientAPI, payload);
    yield put(setAlert({ message: "Your account is created. Check your email for verification", type: "SUCCESS" }));
    yield put(signUpClientSuccess());
    history.push("/login");
  }
  } catch (error) {
    yield put(signUpClientError(error));
    yield put(setAlert({ message: error.response.data.message, type: "ERROR" }));
  }
}

function* userInfoSaga({ history }) {
  try {
    const token = getLocal("token");
    setDefaultToken("token", token);
    const { data } = yield call(userInfoAPI);
    yield put(userInfoSuccess(data.data));
    // history.push("/dashboard");
  } catch (error) {
    yield put(userInfoError(error));
  }
}
function* userReferralCode({payload}){
  try{
    const data  = yield call(referralCodeApi,payload)
    yield put(generateReferralCodeSuccess(data.data))
  }
  catch(error){
    yield put(generateReferralCodeError())
    yield put(setAlert({ message:error || "failed to generate", type: "ERROR" }));
  }
}
function* workerSocialSignup({identity,provider}){
  
  try{
   const res = yield call(socialPageReqApi,identity,provider)
   yield put(socialSignupSuccess(res.data.data))
  }
  catch(error){
    yield put(socialSignupError())
  }
}
function* workerSocialSignupCodeSend({code,state,history}){
  try{
   const res = yield call(socialSignUpCodeSendApiReq,code,state)
   yield put(socialSignupCodeSendSuccess(res?.data?.data[0]))

  }
  catch(error){
    yield put(socialSignupCodeSendError())
    yield put(setAlert({ message: error.response.data.error || error.response.data.message, type: "ERROR" }));
    history.push("/");
  }
}
function* workerSocialSignupSubmit({form,history}){
      try{
        yield call(socialSignUpDetailsSendReq,form)
        yield put(setAlert({ message: "You are Now Registered ", type: "SUCCESS" }));
        yield put(socialSignupSubmitSuccess())
        yield history.push("/");

      }
    catch(error){
      yield put(socialSignupSubmitError())
      yield put(setAlert({ message: error.response.data.message , type: "ERROR" }));
      yield history.push("/");
    }
}
function* workerSocialSigninGetUrl({provider}){
  try{
    const res = yield call(socialSigninGetUrlReq,provider)
    yield put(socialSigninGetUrlSuccess(res.data.data))
  }
  catch(error){
    yield put(socialSigninGetUrlError())
    yield put(setAlert({ message: "Failed Login", type: "ERROR" }));
  }
}
function* workerSocialSignin({code,state,history}){
 try{
      const res = yield call(socialSigninReq,code,state)
      const data = res && res.data && res.data.data 
      const token = data.token || " "
      setDefaultToken("token", token);
      setLocal("token", token);
      setLocal("role", data.role[0]);
      yield put(socialSignInCodeSubmitSuccess(data));
       yield history.push("/dashboard");
 }
 catch(error){
  history.push('/login/password')
  setDefaultToken("token");
  clearLocal();
  yield put(socialSignInCodeSubmitError(error));
  yield put(setAlert({ message: error.response.data.message || " login failed", type: "ERROR" }));  
 }

}

export function* watchLoginUser() {
  yield takeEvery(LOGIN_USER, loginUserSaga);
}

export function* watchLogoutUser() {
  yield takeEvery(LOGOUT_USER, logoutUserSaga);
}

export function* watchSignUpTester() {
  yield takeEvery(SIGNUP_TESTER, signUpTesterSaga);
}
export function* watchsignUpClient() {
  yield takeEvery(SIGNUP_CLIENT, signUpClientSaga);
}
export function* watchUserInfo() {
  yield takeEvery(USER_INFO, userInfoSaga);
}
export function* watchUserReferralCode(){
  yield takeEvery(GENERATE_REFERRAL_CODE,userReferralCode)
}
export function* watchUserSocialSignup(){
  yield takeEvery(SOCIAL_SIGNUP,workerSocialSignup)
}
export function* watchUserSocialSignUpCodeSend(){
  yield takeEvery(SOCIAL_SIGNUP_CODE_SEND,workerSocialSignupCodeSend)
}
export function* watchUserSocialSignUpSubmit(){
  yield takeEvery(SOCIAL_SIGNUP_SUBMIT,workerSocialSignupSubmit)
}
  
export function* watchUserSocialSigninGetUrl(){
  yield takeEvery(SOCIAL_SIGNIN_GET_URL,workerSocialSigninGetUrl)
}

export function* watchUserSocialSignin(){
  yield takeEvery(SOCIAL_SIGNIN_CODE_SUBMIT,workerSocialSignin)
}

export default function* rootSaga() {
  yield all([fork(watchLoginUser), fork(watchLogoutUser), fork(watchSignUpTester), fork(watchsignUpClient), fork(watchUserInfo),fork(watchUserReferralCode),fork(watchUserSocialSignup),fork(watchUserSocialSignUpCodeSend),fork(watchUserSocialSignUpSubmit),fork(watchUserSocialSigninGetUrl),fork(watchUserSocialSignin)]);
}
