import React, { useState, useEffect } from "react";
import { Link, useHistory } from "react-router-dom";

import { connect } from "react-redux";
import { loginUser, socialSigninGetUrl, socialSignInCodeSubmit } from "../../../redux/auth/actions";
import settings from "../../../constants/settings";
import { PasswordViewer } from "../../../utils/passwordVisibilityToggle";

import { eyeSign, googleLogo, linkedInLogo } from "../../../assets/icons/icons";

import "../style.scss";
import Loader from "../../Loader";
import { clearLocal } from "../../../utils/localStorage";
const LoginPassword = ({ loginUser, redirectUrl, socialSigninGetUrl, socialSignInCodeSubmit, loading }) => {
  const history = useHistory();
  const [formData, setFormData] = useState({
    user: "",
    password: "",
  });

  useEffect(() => {
    clearLocal();
    const queryParams = new URLSearchParams(window.location.search);
    const code = queryParams.get("code")?.replace("/", "%2F");
    const state = queryParams.get("state");
    if (code && state) {
      socialSignInCodeSubmit(code, state, history);
    }
    if (redirectUrl && redirectUrl?.length !== 0) window.location.href = `${redirectUrl}`;
    // eslint-disable-next-line
  }, [redirectUrl]);

  const [showpassword, setShowPassword] = useState(false);
  const { user, password } = formData;

  const handelInputChange = e => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const handleLoginUser = e => {
    e.preventDefault();
    loginUser(formData, history);
  };

  const handleSocialSignUp = (e, prov) => {
    e.preventDefault();
    const provider = prov;
    socialSigninGetUrl(provider);
  };
  return (
    <div className="login-component">
      <h1 className="login-heading">{loading ? "Please Wait" : settings.login_component.login_password.user_login}</h1>
      {loading ? (
        <Loader />
      ) : (
        <form className="login-form" onSubmit={handleLoginUser}>
          <div className="form-group">
            <label>{settings.login_component.login_password.email}</label>
            <input type="user" name="user" onChange={handelInputChange} value={user} required />
          </div>
          <div className="form-group">
            <label>{settings.login_component.login_password.password}</label>
            <input type={showpassword ? "text" : "password"} name="password" onChange={handelInputChange} value={password} required />
            <svg
              onMouseOver={() => {
                PasswordViewer(showpassword, setShowPassword);
              }}
              onMouseOut={() => {
                PasswordViewer(showpassword, setShowPassword);
              }}
              onTouchStart={() => {
                PasswordViewer(showpassword, setShowPassword);
              }}
              onTouchEnd={() => {
                PasswordViewer(showpassword, setShowPassword);
              }}
              className="password-show-hide"
              fill={showpassword ? "#646464" : "#bbbbbb"}
              width="24"
              height="24"
              xmlns="http://www.w3.org/2000/svg"
              fill-rule="evenodd"
            >
              {eyeSign}
            </svg>
          </div>
          <Link to="/password" className="forgot-pass">
            {settings.login_component.login_password.forgot_password}
          </Link>
          <button className="submit-btn">{settings.login_component.login_password.login}</button>
          <button
            onClick={e => {
              handleSocialSignUp(e, "LINKEDIN");
            }}
            style={{ marginTop: "10px" }}
            type="text"
            className="submit-btn"
          >
            {linkedInLogo} Login With LinkedIn
          </button>
          <button
            onClick={e => {
              handleSocialSignUp(e, "GOOGLE");
            }}
            style={{ marginTop: "10px" }}
            type="text"
            className="submit-btn"
          >
            {googleLogo} Login With Google
          </button>
        </form>
      )}
    </div>
  );
};

const mapStateToProps = ({ auth }) => ({
  loading: auth.loading,
  redirectUrl: auth.redirectUrl,
});

export default connect(mapStateToProps, { loginUser, socialSigninGetUrl, socialSignInCodeSubmit })(LoginPassword);
