import React, { Fragment, useState } from "react";

import AndroidWhite from "../../assets/icons/634909.svg";
import AppleWhite from "../../assets/icons/731985.svg";
import WebWhite from "../../assets/icons/2301129.svg";
import TVWhite from "../../assets/icons/2177992.svg";

import AndroidBlue from "../../assets/icons/asset-3.svg";
import AppleBlue from "../../assets/icons/asset-2.svg";
import WebBlue from "../../assets/icons/asset-4.svg";
import TVBlue from "../../assets/icons/asset-1.svg";

import rightArrow from "../../assets/icons/rightAro.svg";
import leftArrow from "../../assets/icons/leftAro.svg";

// import upload from "../../assets/icons/upload.svg";

import PlatformData from "./PlatformData";

const Platforms = ({ setStep, currentStep, people, setPeople }) => {
  const [xvalue, setXvalue] = useState(0);
  const [platforms, setPlatforms] = useState([]);
  const [platformData, setplatformData] = useState([]);
  const platformList = ["Android", "Apple Iphone", "Web", "Fire TV", "Apple TV"];

  const iconBlue = {
    Android: AndroidBlue,
    "Apple Iphone": AppleBlue,
    Web: WebBlue,
    "Fire TV": TVBlue,
  };

  const iconWhite = {
    Android: AndroidWhite,
    "Apple Iphone": AppleWhite,
    Web: WebWhite,
    "Fire TV": TVWhite,
  };

  const setPlatform = e => {
    const tech = e.target.getAttribute("value");
    if (platforms) setStep(2);
    if (!platforms.includes(tech)) {
      setPlatforms(prev => {
        const cp = [...prev];
        cp.push(tech);
        return cp;
      });
      setplatformData([...platformData, { platform: tech, hours: 0, price: 0 }]);
    } else {
      setPlatforms(prev => {
        const cp = prev.filter(item => item !== tech);
        if (cp.length === 0) setStep(1);
        return cp;
      });
      const filterPF = platformData.filter(data => data.platform !== tech);
      setplatformData([...filterPF]);
    }
  };

  const moveRightArrow = () => {
    xvalue === -255 * ((platformList.length - 1) / 4) ? setXvalue(0) : setXvalue(xvalue - 255);
  };
  const moveLeftArrow = () => {
    xvalue === 0 ? setXvalue(0) : setXvalue(xvalue + 255);
  };

  return (
    <Fragment>
      <h3 className="form-heading">Please choose your type of platform</h3>
      <div className="platform-container">
        <button className="glb-btn" onClick={moveLeftArrow}>
          <img src={leftArrow} alt="" />
        </button>
        <div className="platforms">
          {platformList.map((item, i) => (
            <div
              key={i}
              className={platforms.includes(item) ? "platform selected" : "platform"}
              value={item}
              onClick={setPlatform}
              style={{ transform: `translateX(${xvalue}px)` }}
            >
              <img src={platforms.includes(item) ? iconWhite[item] : iconBlue[item]} alt="" value={item} />
              <div value={item}>{item}</div>
            </div>
          ))}
        </div>
        <button className="glb-btn" onClick={moveRightArrow}>
          <img src={rightArrow} alt="" />
        </button>
      </div>
      {platforms && currentStep >= 2 && (
        <div className="inputs">
          <div className="input-container">
            <PlatformData people={people} setPeople={setPeople} platformData={platformData} setplatformData={setplatformData} iconBlue={iconBlue} />
          </div>
        </div>
      )}
      <div className="form-submit" onClick={() => setStep(3)}>
        <button className="glb-btn" type="button" disabled={platforms.length === 0}>
          Next
        </button>
      </div>
    </Fragment>
  );
};

export default Platforms;
