// Main UI
import Home from "./views/Home";
import HomeTesterPge from "./views/HomeTester";
import Pricing from "./views/Pricing";
import Automation from "./views/Automation";
import Services from "./views/Services";
import Solution from "./views/Solution";
import ContactUs from "./views/ContactUs";
import DeviceFarm from "./views/DeviceFarm";
import AboutUs from "./views/AboutUs";
import SignUpTester from "./views/SignUpTester";
import SignUpClient from "./views/SignUpClient";
import Login from "./views/Login";
import ChoosePlatform from "./views/ChoosePlatform";
import Dashboard from "./views/Dashboard";
import EmailVerification from "./views/EmailVerification";
import ForgotPassword from "./views/ForgotPassword";
import PaymentSuccess from "./views/PaymentStatus/PaymentSuccess";
import PaymentFailed from "./views/PaymentStatus/PaymentFailed";
import Error from "./views/Error";
import Privacy from "./views/Privacy";
import Terms from "./views/Terms";

// Tester Components
import HomeTester from "./components/DashboardTester/Home";
import Notifications from "./components/DashboardTester/Notifications";
import DevicesTester from "./components/DashboardTester/Devices";
import TestTaken from "./components/DashboardTester/TestTaken";
import CommunityTester from "./components/DashboardTester/Community";
import Earnings from "./components/DashboardTester/Earnings";
import SettingsTester from "./components/DashboardTester/Settings";
import BoostMultiplier from "./components/DashboardTester/BoostMultiplier";
import FeedbackTester from "./components/DashboardTester/Feedback";
import AssignedBugs from "./components/DashboardTester/AssignedBugs";

// Client Components
import HomeClient from "./components/DashboardClient/Home";
// import AddTests from "./components/DashboardClient/AddTests/Components";
import NewProject from "./components/DashboardClient/AddTests";
import ClientPricing from "./components/DashboardClient/ClientPricing";
import SettingsClient from "./components/DashboardClient/Settings";

// Admin Components
import HomeAdmin from "./components/DashboardAdmin/Home";
import ManageTesters from "./components/DashboardAdmin/ManageTesters";
import ManageBadges from "./components/DashboardAdmin/ManageBadges";
import ManageClients from "./components/DashboardAdmin/ManageClients";
import ManageBugs from "./components/DashboardAdmin/ManageBugs";
import ManageEarnings from "./components/DashboardAdmin/ManageEarnings";
import ManageTestimonials from "./components/DashboardAdmin/ManageTestimonials";
import Feedbacks from "./components/DashboardAdmin/Feedbacks";

// Login Components
import LoginPassword from "./components/LoginComponents/LoginPassword";
import LoginSSO from "./components/LoginComponents/LoginSSO";

// Assets
import Star from "./assets/icons/star.svg";

// Icons
import { EarningNavIcon, ManageTestersIcon, ManageClientsIcon, manageBugReports, manageBadges, managetestimonial, reviewBugs } from "./assets/icons/icons";

export const MainRoutes = [
  { Component: Home, path: "/", exact: true },
  { Component: Home, path: "/crowdsourcedtesting", exact: true },
  { Component: HomeTesterPge, path: "/crowdsourcedtesting/tester", exact: true },
  { Component: Pricing, path: "/pricing", exact: true },
  { Component: SignUpTester, path: "/signup/tester", exact: true },
  { Component: SignUpClient, path: "/signup/setup", exact: true },
  { Component: Automation, path: "/crowdsourcedtesting/automation", exact: true },
  { Component: Services, path: "/crowdsourcedtesting/services", exact: true },
  { Component: ContactUs, path: "/crowdsourcedtesting/contactus", exact: true},
  { Component: Solution, path: "/crowdsourcedtesting/crowd-testing", exact: true },
  { Component: DeviceFarm, path: "/crowdsourcedtesting/devicehub", exact: true },
  { Component: AboutUs, path: "/crowdsourcedtesting/aboutus", exact: true },
  { Component: ChoosePlatform, path: "/signup/choose-platform", exact: true },
  { Component: Login, path: "/login", exact: false },
  { Component: Dashboard, path: "/dashboard", exact: false },
  { Component: EmailVerification, path: "/email/verify", exact: true },
  { Component: ForgotPassword, path: "/password", exact: false },
  { Component: PaymentSuccess, path: "/payment/success/:transactionID", exact: true },
  { Component: PaymentFailed, path: "/payment/failed/:transactionID", exact: true },
  { Component: Error, path: "/error", exact: true },
  { Component: Privacy, path: "/privacy", exact: true },
  { Component: Terms, path: "/terms", exact: true },
];

export const LoginRoutes = [
  { Component: LoginPassword, path: "/password", exact: true },
  { Component: LoginSSO, path: "/sso", exact: true },
];

export const TesterRoutes = [
  { Component: HomeTester, path: "/home", exact: true, name: "Home", id: "homeTester", routePath: "/dashboard/home", iconClass: "flaticon-home" },
  {
    Component: Notifications,
    path: "/notifications",
    exact: true,
    name: "Notifications",
    id: "homeNotifications",
    routePath: "/dashboard/notifications",
    iconClass: "flaticon-mail",
  },
  {
    Component: DevicesTester,
    path: "/devices",
    exact: true,
    name: "Devices",
    id: "homeDevices",
    routePath: "/dashboard/devices",
    iconClass: "flaticon-responsive",
  },
  {
    Component: TestTaken,
    path: "/test-taken",
    exact: false,
    name: "All Projects",
    id: "homeAllTestTaken",
    routePath: "/dashboard/test-taken",
    iconClass: "flaticon-google-docs",
  },
  {
    Component: Earnings,
    path: "/earning",
    exact: false,
    name: "My Earning",
    id: "myearning",
    routePath: "/dashboard/earning",
    icon: EarningNavIcon,
    isIconClass: false,
  },
  {
    Component: CommunityTester,
    path: "/community",
    exact: true,
    name: "Community",
    id: "homeCommunity",
    routePath: "/dashboard/community",
    iconClass: "flaticon-user",
  },
  {
    Component: SettingsTester,
    path: "/settings",
    exact: true,
    name: "Profile Settings",
    id: "homeSettings",
    routePath: "/dashboard/settings",
    iconClass: "flaticon-settings",
  },
  {
    Component: FeedbackTester,
    path: "/feedback",
    exact: true,
    name: "Feedback",
    id: "homefeedback",
    routePath: "/dashboard/feedback",
    iconClass: "flaticon-google-docs",
  },
  {
    Component: BoostMultiplier,
    path: "/boostmultiplier",
    exact: false,
    name: "Boost your Earning",
    id: "homeBoostmultiplier",
    routePath: "/dashboard/boostmultiplier",
    iconClass: Star,
    type: "focus",
  },
];

export const ReviewerRoutes = [
  { Component: HomeTester, path: "/home", exact: true, name: "Home", id: "homeTester", routePath: "/dashboard/home", iconClass: "flaticon-home" },
  {
    Component: Notifications,
    path: "/notifications",
    exact: true,
    name: "Notifications",
    id: "homeNotifications",
    routePath: "/dashboard/notifications",
    iconClass: "flaticon-mail",
  },
  {
    Component: DevicesTester,
    path: "/devices",
    exact: true,
    name: "Devices",
    id: "homeDevices",
    routePath: "/dashboard/devices",
    iconClass: "flaticon-responsive",
  },
  {
    Component: AssignedBugs,
    path: '/assignedBugs',
    exact:false,
    id: "assignedBugs",
    name:"Assigned Bugs",
    routePath: "/dashboard/assignedBugs",
    icon: reviewBugs,
    isIconClass:false
  },
  {
    Component: TestTaken,
    path: "/test-taken",
    exact: false,
    name: "All Projects",
    id: "homeAllTestTaken",
    routePath: "/dashboard/test-taken",
    iconClass: "flaticon-google-docs",
  },
  {
    Component: Earnings,
    path: "/earning",
    exact: false,
    name: "My Earning",
    id: "myearning",
    routePath: "/dashboard/earning",
    icon: EarningNavIcon,
    isIconClass: false,
  },
  {
    Component: CommunityTester,
    path: "/community",
    exact: true,
    name: "Community",
    id: "homeCommunity",
    routePath: "/dashboard/community",
    iconClass: "flaticon-user",
  },
  {
    Component: SettingsTester,
    path: "/settings",
    exact: true,
    name: "Profile Settings",
    id: "homeSettings",
    routePath: "/dashboard/settings",
    iconClass: "flaticon-settings",
  },
  {
    Component: FeedbackTester,
    path: "/feedback",
    exact: true,
    name: "Feedback",
    id: "homefeedback",
    routePath: "/dashboard/feedback",
    iconClass: "flaticon-google-docs",
  },
  {
    Component: BoostMultiplier,
    path: "/boostmultiplier",
    exact: false,
    name: "Boost your Earning",
    id: "homeBoostmultiplier",
    routePath: "/dashboard/boostmultiplier",
    iconClass: Star,
    type: "focus",
  },
];

export const ClientRoutes = [
  { Component: HomeClient, path: "/home", exact: false, name: "Home", id: "homeClient", routePath: "/dashboard/home", iconClass: "flaticon-home" },
  {
    Component: NewProject,
    path: "/addtests",
    exact: false,
    name: "New Project",
    id: "homeNewTests",
    routePath: "/dashboard/addtests",
    iconClass: "flaticon-google-docs",
  },
  // {
  //   Component: AddTests,
  //   path: "/addtests",
  //   exact: true,
  //   name: "Add Project",
  //   id: "homeAddTests",
  //   routePath: "/dashboard/addtests",
  //   iconClass: "flaticon-google-docs",
  // },
  {
    Component: CommunityTester,
    path: "/community",
    exact: true,
    name: "Community",
    id: "homeCommunity",
    iconClass: "flaticon-user",
    routePath: "/dashboard/community",
  },
  {
    Component: ClientPricing,
    path: "/pricing",
    exact: true,
    name: "Pricing",
    id: "clientPricing",
    routePath: "/dashboard/pricing",
    icon: EarningNavIcon,
    isIconClass: false,
  },
  {
    Component: SettingsClient,
    path: "/settings",
    exact: true,
    name: "Settings",
    id: "homeSettings",
    routePath: "/dashboard/settings",
    iconClass: "flaticon-settings",
  },
];

export const AdminRoutes = [
  { Component: HomeAdmin, path: "/home", exact: true, name: "Home", id: "adminHome", routePath: "/dashboard/home", iconClass: "flaticon-home" },
  {
    Component: ManageTesters,
    path: "/tester",
    exact: false,
    name: "Manage Testers",
    id: "adminManageTesters",
    routePath: "/dashboard/tester",
    icon: ManageTestersIcon,
    isIconClass: false,
  },
  {
    Component: ManageClients,
    path: "/clients",
    exact: true,
    name: "Manage Clients",
    id: "adminManageClients",
    routePath: "/dashboard/clients",
    icon: ManageClientsIcon,
    isIconClass: false,
  },
  {
    Component: ManageBugs,
    path: "/projects",
    exact: false,
    name: "Manage Bug Reports",
    id: "adminAllProjects",
    routePath: "/dashboard/projects",
    icon: manageBugReports,
    isIconClass: false,
  },
  {
    Component: ManageBadges,
    path: "/badges",
    exact: true,
    name: "Manage Badges",
    id: "adminManageBadge",
    routePath: "/dashboard/badges",
    icon: manageBadges,
    isIconClass: false,
  },
  // {
  //   Component: HomeAdmin,
  //   path: "/messages",
  //   exact: false,
  //   name: "Messages",
  //   id: "messages",
  //   routePath: "/dashboard/messages",
  //   icon: MessageSquareIcon,
  //   isIconClass: false,
  // },
  // {
  //   Component: HomeAdmin,
  //   path: "/history",
  //   exact: true,
  //   name: "History",
  //   id: "history",
  //   routePath: "/dashboard/history",
  //   icon: HistoryIcon,
  //   isIconClass: false,
  // },
  {
    Component: ManageEarnings,
    path: "/earnings",
    exact: true,
    name: "Manage Earnings",
    id: "adminManageEarnings",
    routePath: "/dashboard/earnings",
    icon: EarningNavIcon,
    isIconClass: false,
  },
  {
    Component: ManageTestimonials,
    path: "/testimonials",
    exact: true,
    name: "Manage Testimonials",
    id: "adminManageTestimonials",
    routePath: "/dashboard/testimonials",
    icon: managetestimonial,
    isIconClass: false,
  },
  {
    Component: Feedbacks,
    path: "/feedback",
    exact: true,
    name: "Feedbacks",
    id: "adminFeedbacks",
    routePath: "/dashboard/feedback",
    iconClass: "flaticon-mail",
  },
  {
    Component: SettingsTester,
    path: "/settings",
    exact: true,
    name: "Settings",
    id: "adminSettings",
    routePath: "/dashboard/settings",
    iconClass: "flaticon-settings",
  },
];
