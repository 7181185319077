import React, { Fragment } from "react";
import client1 from "../../../assets/images/Hoichoi-Logo_Red_140x40.png";
import client2 from "../../../assets/images/CHORKI.png";
import client3 from "../../../assets/images/aha.png";
import client4 from "../../../assets/images/cricview-logo.png";
import client5 from "../../../assets/images/Zee5.png";


import "./style.scss"

const ClientList = ({data={}}) => {
    return (
        <Fragment>
             <div className="ourClients" >
            <h4 className="new-info-02-heading" >{data.heading}</h4>
            <div className="clientsBanner">
              <div className="clientsList" >
               <div className="image-container">
                        <img width="280px" src={client1} alt="icon" />
                      </div>
                      <div className="image-container">
                        <img width="210px" src={client2} alt="icon" />
                      </div>
                      <div className="image-container">
                        <img width="150px" src={client3} alt="icon" />
                      </div>
                      <div className="image-container">
                        <img width="220px" src={client4} alt="icon" />
                      </div>
                      <div className="image-container">
                        <img width="120px" src={client5} alt="icon" />
                      </div>
              </div>
              </div>
        </div>
        <br/>
        </Fragment>
    )
}

export default ClientList;
