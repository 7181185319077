import React, { useState, useEffect } from "react";
import axios from "axios";

import baseDomain, { getAllDevicesReq } from "../../constants/apiRoutes";
import DevicesListTable from "../Common/DashboardTables/DevicesListTable";
import AccountSetupHeading from "../Common/AccountSetupHeading";
import settings from "../../constants/settings";
import Loader from "../../components/Loader";

import "./style.scss";

const DeviceFarm = () => {
  // const maxVal = 10;
  const [totalPages, setTotalPages] = useState(5);
  const [pageNo, setPageNo] = useState(0);
  const [currentDevices, setCurrentDevices] = useState([]);
  const [allDevices, setAllDevices] = useState([]);
  // const [show, setShow] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  useEffect(() => {
    getDevice();
    // eslint-disable-next-line
  }, []);

  const getDevice = async () => {
    const { data } = await axios.get(`${baseDomain}${getAllDevicesReq}`);
    setAllDevices(data.data);
  };

  useEffect(() => {
    setTotalPages(Math.floor(allDevices.length / 10));
    const temp = allDevices.slice(0, 10);
    setPageNo(0);
    setCurrentDevices(temp);
    setIsLoading(false);
  }, [allDevices]);

  const setPage = n => {
    let temp;
    if (n === 0) temp = allDevices.slice(0, 10);
    else temp = allDevices.slice(n * 10, n * 10 + 10);
    setCurrentDevices(temp);
  };

  const handleNext = () => {
    if (pageNo > totalPages - 2) {
      setPageNo(0);
      setPage(0);
    } else {
      setPageNo(pageNo + 1);
      setPage(pageNo + 1);
    }
  };
  const handlePrevious = () => {
    if (pageNo < 1) {
      setPageNo(totalPages - 1);
      setPage(totalPages - 1);
    } else {
      setPageNo(pageNo - 1);
      setPage(pageNo - 1);
    }
  };
  return (
    <div className="device-farm-comp">
      <AccountSetupHeading data={settings.devicesfarm} />
      {isLoading ? (
        <Loader />
      ) : (
        <div className="devices-list-container">
          <div className="devices-list-table">
            <DevicesListTable data={currentDevices} isLoading={isLoading} />
          </div>
          <div className="pagination-container">
            <div className="pagination">
              <div className="controller element" onClick={handlePrevious}>
                {"<"}
              </div>
              <>
                {[...Array(totalPages)].map((e, i) => {
                  if (i < 15) {
                    return (
                      <div
                        className={pageNo === i ? "number element current" : "number element"}
                        onClick={() => {
                          setPage(i);
                          setPageNo(i);
                        }}
                        key={i}
                      >
                        {i + 1}
                      </div>
                    );
                  } else return null;
                })}
              </>
              <div className="controller element" onClick={handleNext}>
                {">"}
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default DeviceFarm;
