import React from "react";
import { Link } from "react-router-dom";
import { withData } from "../../../layouts";

import "./style.scss";

const Banner = ({ data = {} }) => {
  return (
    <div className="tester_banner-main-container bg-light">
      <div className="tester_banner-details-container">
        <div className="tester_banner-image-container">
          <img src={data.urlToImage} alt="banner" />
        </div>
        <div className="tester_banner-details">
          <div className="tester_banner-text">{data.heading}</div>
          <div className="tester_banner-text div-yellow">{data.subHeading}</div>
          <span>{data.description}</span>
          <div className="tester_banner-button-flex">
            
            <Link to="/signup/tester" className="tester_banner-button-client glb-btn">
              {data.buttonTextSecondary}
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default withData(Banner);
