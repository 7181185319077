import { SET_TESTER_ROUTE, TOGGLE_SIDENAV, TOGGLE_REGISTER_MODAL, TOGGLE_DECLINE_BUG_MODAL, TOGGLE_RESET_PASSWORD_MODAL, TOGGLE_EDIT_CLIENT_MODAL, TOOGLE_INVITE_MODAL, STATIC_SITE_NAV_HANDLER, TOGGLE_TRANSFER_INITIATED_MODAL, ADD_COMMENT_MODAL   } from "../actions";

export const setDashboardRoute = payload => ({
  type: SET_TESTER_ROUTE,
  payload,
});

export const toggleSideNav = () => ({
  type: TOGGLE_SIDENAV,
});

export const toggleRegisterModal = () => ({
  type: TOGGLE_REGISTER_MODAL,
});

export const toggleDeclineBugModal = () => ({
  type: TOGGLE_DECLINE_BUG_MODAL,
});

export const toggleResetPasswordModal = () => ({
  type: TOGGLE_RESET_PASSWORD_MODAL,
});

export const toggleEditClientModal = () => ({
  type: TOGGLE_EDIT_CLIENT_MODAL,
});

export const toggleInviteModal = () => ({
  type: TOOGLE_INVITE_MODAL,
});

export const staticSiteNavHandler = (currentPage) => ({
  type: STATIC_SITE_NAV_HANDLER,
  payload: currentPage
});


export const toggleTransferInitiatedModal = (payload) => ({
  type: TOGGLE_TRANSFER_INITIATED_MODAL,
  payload,
});

export const addCommentModal = () => ({
  type: ADD_COMMENT_MODAL,
})