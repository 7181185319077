import React from 'react';
import ProfilePic from "../../../../assets/images/profilePic.jpg"
import "./style.scss"


const TestimonialComponent = ({name,profilePic,testimonial,approved,rejected,className}) => {


    return (
        <div className={className}> 
          <div className="testimonials-header">
            <div className="testerNames">
              <img style={{verticalAlign:"middle"}} className="testimonialTesterImage" width="50px" height="50px" src={profilePic ? profilePic:ProfilePic} alt="" />
              <div style={{display:"flex",alignItems:"center",minWidth:"fit-content"}}>&ensp;{name}</div> 
            </div>  
          </div> 
          <div className="testimonials-div">
            <span className="testimonials-text">
              " {testimonial} " 
            </span>  
          </div> 
        </div> 
    );
}

export default TestimonialComponent;