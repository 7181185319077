import React from "react";
import device1 from "../../../assets/icons/Global.svg";
import device2 from "../../../assets/icons/Devices.svg";
import device3 from "../../../assets/icons/Coverage.svg";
import device4 from "../../../assets/icons/AllTesters.svg";

import "./style.scss";

function Component1({ data = {} }) {
  return (
    <div className="component1">
      <h4 className="component1-heading">
        {data.heading1a}
        <span className="text-bold">&nbsp;{data.heading1b}</span>
      </h4>

      <div className="component1-icons">
        <div className="component1-box">
          <img src={device1} alt="Crowdtesting working" />

          <h4 className="component1-box-heading">{data.item1a}</h4>
          {data.content1a}
        </div>

        <div className="component1-box">
          <img src={device2} alt="Crowdtesting working" />

          <h4 className="component1-box-heading">{data.item1b}</h4>
          {data.content1b}
        </div>

        <div className="component1-box">
          <img src={device3} alt="Crowdtesting working" />

          <h4 className="component1-box-heading">{data.item1c}</h4>
          {data.content1c}
        </div>

        <div className="component1-box">
          <img src={device4} alt="Crowdtesting working" />

          <h4 className="component1-box-heading">{data.item1d}</h4>
          {data.content1d}
        </div>
      </div>
    </div>
  );
}

export default Component1;
