import React, { useState, useEffect } from "react";
import { connect } from "react-redux";

import { setDashboardRoute } from "../../../redux/Routing/actions";
import { getTesterDevices, deleteTesterDevice } from "../../../redux/TesterDashboard/actions";

import DashboardHeading from "../../Common/DashboardHeading";
import DevicesTable from "../../Common/DashboardTables/DevicesTable";
import AddDeviceModal from "../../Modals/AddDeviceModal";
import settings from "../../../constants/settings";

import Loader from "../../Loader";
import {setIsDeviceHavingFalse} from "../../../redux/auth/actions";

import { useAlertConfirmation } from "../../../Services/AlertService";

import "./style.scss";

const Devices = ({ setDashboardRoute, componentID, getTesterDevices, deleteTesterDevice, devices, isLoading, setIsDeviceHavingFalse }) => {
  const [showModal, setShowModal] = useState(false);
  const [modalStep, setModalStep] = useState(1);
  const [deviceData, setDeviceData] = useState({});

  const fallBackAlert = useAlertConfirmation();

  const editDevice = item => {
    setModalStep(2);
    setDeviceData(item);
  };

  const DeleteDevice = deviceID => {
    deleteTesterDevice(deviceID);
    // setTimeout(() => {
    //   getTesterDevices();
    // }, 50);
  };

  useEffect(() => {
    if (modalStep === 2) setShowModal(true);
    // eslint-disable-next-line
  }, [modalStep]);

  useEffect(() => {
    if (showModal === false) {
      setModalStep(1);
      setDeviceData({});
    }
    // eslint-disable-next-line
  }, [showModal]);

  useEffect(() => {
    setDashboardRoute(componentID);
    getTesterDevices();
    // eslint-disable-next-line
  }, []);

  const deleteDeviceHandler = async deviceID => {
    try {
      await fallBackAlert({
        message: "Are you sure you want to delete",
        type: "ERROR",
        successText: "Yes",
        cancelText: "No",
      });
      DeleteDevice(deviceID);
      devices.length === 1 && setIsDeviceHavingFalse()
    } catch (error) {}
  };

  const editDevieHandler = item => {
    editDevice(item);
  };

  return (
    <div className="client-dash-devices">
      <AddDeviceModal showModal={showModal} toggleModal={() => setShowModal(!showModal)} currentStep={modalStep} deviceData={deviceData} />
      <DashboardHeading
        heading={settings.devices.heading.light}
        headingStrong={settings.devices.heading.bold}
        btnValue={settings.devices.btnText}
        btnOnClick={() => setShowModal(!showModal)}
        showBtn="true"
      />
      <div className="devices">
        <div className="cards">
          {isLoading ? <Loader /> : <DevicesTable tableContentArray={devices} deleteTesterDeviceFun={deleteDeviceHandler} editDeviceFun={editDevieHandler} showItem={true} />} 
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = ({ testerDashboard, staticData }) => ({
  devices: testerDashboard.userDevices,
  isLoading: testerDashboard.isLoading,
  platforms: staticData.platforms, 
});

export default connect(mapStateToProps, { setDashboardRoute, getTesterDevices, deleteTesterDevice, setIsDeviceHavingFalse })(Devices);
