import banner_image from "../assets/images/trial003.svg";
import tester_image from "../assets/images/testerHome.png";
import tester_signup_img from "../assets/images/1.png";
import icon1 from "../assets/icons/Faster time to market.svg";
//import icon2 from "../assets/icons/Multiple platform support.svg";
import icon3 from "../assets/icons/5000+ Devices.svg";
import icon4 from "../assets/icons/Realtime Results.svg";
import icon5 from "../assets/icons/Scalable Model.svg";
//import icon6 from "../assets/icons/High ROI.svg";
// import icon7 from "../assets/icons/558593.svg";
// import icon8 from "../assets/icons/2977697.svg";
import Pre from "../assets/images/AlphaTesting.svg";
import Alpha from "../assets/images/BetaTesting.svg";
import Post from "../assets/images/PostLiveTesting.svg";

import icon9 from "../assets/icons/Desktop.svg";
import icon10 from "../assets/icons/Smart tv.svg";
import icon11 from "../assets/icons/Smartphone and tablets.svg";
import icon12 from "../assets/icons/Browser.svg";
import icon13 from "../assets/icons/Streaming devices.svg";
import icon14 from "../assets/icons/Gaming platforms.svg";
import icon15 from "../assets/icons/Wearables.svg";
//import icon16 from "../assets/icons/iot.svg";
import icon17 from "../assets/images/XMLID_220_.png";

import client1 from "../assets/images/Hoichoi-Logo_Red_140x40.png";
import client2 from "../assets/images/CHORKI.png";
import client3 from "../assets/images/aha.png";
import client4 from "../assets/images/cricview-logo.png";
import client5 from "../assets/images/Zee5.png"

import money from "../assets/images/MoneyBag.png";
import medal from "../assets/images/Medal.png";
import telegram from "../assets/images/TelegramYellow.png";
import Zee5 from "../assets/images/Zee55.png"


// eslint-disable-next-line
export default {
  siteName: "Apni Testing App",
  layout_header: {
    services: "SERVICES",
    automation: "AUTOMATION",
    crowdtest: "CROWD TESTING",
    device: "DEVICE HUB",
    register: "REGISTER",
    login: "LOGIN",
    logout: "Logout",
  },
  layout_footer: {
    services: "SERVICES",
    automation: "AUTOMATION",
    crowdtest: "CROWD TESTING",
    device: "DEVICE HUB",
    text1: "Elevate your customer’s digital experiences using the power of crowdsourced testing",
    text2: "Copyright © 2020 NexG. All rights reserved.",
    text3: "info@qantily.com",
  },
  login_component: {
    login_password: {
      user_login: "User Login",
      email: "Email",
      password: "Password",
      forgot_password: "Forgot Password",
      login: "Login",
    },
    login_sso: {
      sso: "Log in with sso",
      email: "Email Address",
      btnText: "Continue",
      or: "OR",
      username: "Log in with Username",
    },
  },
  banner01: {
    heading: "Comprehensive web and app testing that is perfect for your",
    subHeading: "Website",
    description: "Experienced testers, Real devices, On demand services for all your testing requirements.",
    buttonTextPrimary: "Register as a Tester",
    buttonTextSecondary: "Register as a Client",
    urlToImage: banner_image,
  },
  pricing: {
    heading: "Pricing",
    subHeading:
      "We know your requirements change throughout the development of your business, which is why we offer a range of packages that best support you along the journey.",
  },
  testerSignUp: {
    urlToImage: tester_signup_img,
    heading: "Please Enter Your Details",
    domainHeading: "How many Devices can you test in",
    domains: [
      "iPhone 5",
      "iPhone 5c",
      "iPhone 5s",
      "iPhone 6",
      "iPhone 6 Plus",
      "iPhone 6s",
      "iPhone 6s Plus",
      "iPhone 7",
      "iPhone 7 Plus",
      "iPhone 8",
      "iPhone 8 Plus",
      "iPhone X",
      "iPhone XR",
      "iPhone XS",
      "iPhone XS Max",
      "iPhone 12 Pro Max",
      "iPhone 11",
      "iPhone 11 Pro",
      "iPhone 11 Pro Max",
      "iPhone 12",
      "iPhone 12 Pro",
    ],
    consentMessageOne: "I hereby declare that the information provided above is true to its very nature.",
    consentMessageTwo: "I want to subscribe to notifications of new projects via Email.",
  },
  data1: {
    heading: "Why Qantily?",
    navigate: "/crowdsourcedtesting/services",
    items: [
      { urlToImage: icon4, heading: "Managed Services", description: "Driven with excellence by our testing domain, accounts team, and our expert tester system." },
      { urlToImage: icon3, heading: "Device Hub", description: "Luxury of testing available on 500+ real devices which cover all the widely used brands and OS versions." },
      { urlToImage: icon1, heading: "Economical", description: "Gives Financial Benefits by avoidance of needing an ihoise QA team, the quick TaT outputs higher returns." },
      { urlToImage: icon5, heading: "Scalable", description: "Flexibility of choosing number of testers according to your project’s requirement. Be it 10 or 1000, Quantity provides them all efficiently." },
    ],
    btnText: "Learn More",
  },
  data2: {
    heading: "Test at every stage of your Product Lifecycle",
    items: [
      {
        urlToImage: Pre,
        prefixText: "ALPHA",
        suffixText: "Testing",
      },
      {
        urlToImage: Alpha,
        prefixText: "BETA",
        suffixText: "Testing",
      },
      {
        urlToImage: Post,
        prefixText: "POST LIVE",
        suffixText: "Testing",
      },
    ],
  },
  data3: {
    heading: "Test on any device",
    description: "Choose our framework to determine experimental results on 500+ devices. We have a wide collection of android phones, smart tv's , streaming devices. Get real-time results with our 1000+ testers and real devices.",
    items: [
      { urlToImage: icon9, description: "Desktop" },
      { urlToImage: icon10, description: "Smart TV" },
      { urlToImage: icon11, description: "Smart Devices" },
      { urlToImage: icon12, description: "Browser" },
      { urlToImage: icon13, description: "Streaming Devices" },
      { urlToImage: icon14, description: "Gaming Platforms" },
      { urlToImage: icon15, description: "Wearables" },
    ],
    btnText: "View all Devices",
    navigate: "/crowdsourcedtesting/devicehub",
  },
  data4: {
    heading: "Our Approach",
    description: `For best results, each test will be managed by a team/ of testers and reviewers. Every bug gets reviewed by the reviewer before final submittion to the client. We ensures a seamless experience without false alarms and high accuracy.`,
    // item1: {
    //   heading: "Test Case Creation",
    //   description: "Decide what parameters you want to get tested on which devices. Upload you application link along with the required information",
    // },
    // item2: {
    //   heading: "Execution",
    //   description: "AI enabled algoritm shortlists the testers for your application. They download the application and test it under desired conditions.",
    // },
    // item3: {
    //   heading: "Report",
    //   description: "Once the bug has been identified, reviewers cross checks for authenticity and duplicacy and review the real time reports.",
    // },
    btnText: "",
    item2a: "Tell us your testing needs",
    item2b: "Our team structures and plans the process analytically",
    item2c: "Testers are allocated best according to your needs of execution",
    item2d: "We will share the test results with you in 24 hours",
  },
  data5: {
    heading: "Testing As A Service",
    description: `We offer fast, human-powered QA testing for agile teams. Qantily offers the testing services for the following:`,
    item1: "Functional Testing",
    item2: "Cross Browser Testing",
    item3: "Compatibility Testing",
    item4: "Beta Testing",
    item5: "Network Testing",
    item6: "Localization Testing",
    btnText: "Learn More",
  },
  data6: {
    heading: "Test Automation Solutions",
    description: `We offer ready-to-use testing frameworks and bot-based solutions to assess your apps consistency, usability, performance and content in both test and live conditions. Our service suite comprises of following:`,
    item1: "Regression Testing",
    item2: "Live Monitoring",
    item3: "API Testing",
    item4: "Usability Testing",
    item5: "Performance Testing",
    btnText: "Learn More",
  },
  data7: {
    headingA: "How it works for Clients?",
    descriptionA:
      "To ensure exhaustive testing across all stages of the Product Lifecycle, each test is managed by our test and delivery team. Our expertise ensures professional delivery at every stage, from scoping requirement to devolping test plans to publishing reports.",
    headingB: "How it works for Testers?",
    descriptionB:
      "The tester can apply for the project from the dashboard as per their device ownership. On approval link for the app/ software is shared with testers and they earn money for every unique bug they find.",
    btnText: "Get Started",
  },
  data8: {
      heading:"Our Clients",
      items:[
          {urlToImage:client1},
          {urlToImage:client2},
          {urlToImage:client3},
          {urlToImage:client4},
          {urlToImage:client5}
      ]
  },
  data9:{
    heading:"Latest Updates",
    items:[
      {
      title:"Zee5 Is Here",
      urlToImage:Zee5,
      description:"We are happy to announce the addition of Zee5 as our new client."
      },
      {
      title:"Earn By Referring Your Friends",
      urlToImage:money,
      description:"We are giving you more opportunity to earn. Generate your referral code and share with Friends."
      },
      {
      title:"Boost Your Earning",
      urlToImage:medal,
      description:"Collect badges & bag a multiplier to your earning."
      },
      {
      title:"Telegram",
      urlToImage:telegram,
      description:"Be a part of our fastest growing community & stay updated on our latest happening."
      }
    ]
  },
  cardsgrid03: {
    heading: "Start Crowd testing now",
    title: "Crowdsourced testing is the fastest and the most effective way to test your digital applications.",
    description:
      "Start using our Test Management Tool for free by signing up now. You can start a test cycle and start crowdsource testing in hours. Our team is here to help and will be happy to guide you at every step of the way.",
    btnText: "Become a Partner",
    urlToImage: icon17,
  },
  counter_component: {
    counts: [
      {
        id: 1,
        number: "500",
        label: "Testers",
        duration: "10",
      },
      {
        id: 2,
        number: "45",
        label: "Projects Delivered",
        duration: "10",
      },
      {
        id: 3,
        number: "200",
        label: "Assessments Made",
        duration: "10",
      },
    ],
    number: "10K +",
    bugs_encountered: "Bugs Encountered",
  },
  services: {
    descriptiona: "On Demand",
    descriptionb: "Testing Platform",
    descriptionc: "for Modern and Global Software Development Team",
    QA_packages: {
      heading1: "QA Services",
      content1: "Extend your QA team with Qantily to help you take a bug-free product to market in minimal time.",
      item1a: "Test Automation",
      item1b: "Regression Testing",
      item1c: "Performance Testing",
      item1d: "Security Testing",
      item1e: "Accessibility Testing",
      item1f: "Usability Testing",
      content1a: "Access ready-to-use or personalized automation frameworks for multi browser web apps, and APIs.",
      content1b: "Get a modularized and prioritized regression designed by risk-based-testing methodology.",
      content1c: "Ready to use Apache Jmeter / Neoload based load/performance testing setup for concurrent usage, CPU and memory Utilization",
      content1d: "Test your project vulnerabilities and exploits.",
      content1e:
        "Get a modularized and prioritized regression designed by risk-based-testing methodology. Achieve scale through on demand execution of regression suite within hours by qualified testers having relevant domain expertise",
      content1f:
        "Usability assessment with real users in real devices across multiple geographic locations in the targeted age group to gauge the overall user experience of the application.",
    },
    user_testing: {
      heading2: "User Testing",
      content2:
        "Get insights into the user experience and journey by getting your applications tested by real users from wide demographic and devices powered by Qantily platform.",
      item2a: "Functional Testing",
      item2b: "Cross Browser Testing",
      item2c: "Compatibility Testing",
      item2d: "Beta Testing",
      item2e: "Network Testing",
      item2f: "Localization Testing",
      content2a:
        "Detailed functional testing through low-level test cases.Testing of standard flows, business rules, navigation, crashes, integrations and interfaces.",
      content2b:
        "Exploratory testing through test scenarios. Testing in real-conditions on real devices across multiple browsers for compatibility of websites and webapps.",
      content2c:
        "Guided exploratory testing through test scenarios. Testing in real-conditions on real devices. Compatibility of app for different types of crashes",
      content2d: " Structured beta testing to assess market readiness of the app in live conditions. Sometimes it is also known as User Acceptance Testing.",
      content2e: " Detailed localization testing covering multi-lingual text and UI compatibility, orientations, expressions and much more.",
      content2f:
        "Analysis of live performance of your app for streaming speed, page rendering, upload / download speeds, application size, battery consumption and more ",
    },
  },
  automation: {
    heading: "Test Automation Solution",
    description:
      "Ready-to-use test frameworks and bot-based solutions to assess your app's consistency, usability, performance and content in both test and live conditions.",
    service_offerings: {
      heading1: "Our Service Offerings",
      item1a: "Regression Testing",
      item1b: "Live Monitoring",
      item1c: "API Testing",
      item1d: "Usability Testing",
      item1e: "Performance Testing",
    },
    benefits: {
      heading2a: "How it works?",
      heading2b: "Benefits",
      item2a: "Cloud Test setup",
      item2b: "Pass Fail Result",
      item2c: "Trigger/Data Repo",
      item2d: "Test Results",
      content2a: "Identification of revenue leakage scenarios",
      content2b: "Continuous assurance of business flows",
      content2c: "No break in user journeys",
      content2d: "Early detection of user concerns",
      content2e: "Improved user journey flows and conversion",
      content2f: "Improved user ratings with an user aligned app",
    },
    tools_used: {
      heading3: "Tools that we use for Automation Testing",
      content3a: "Let’s get started.",
      content3b: "Share your requirements and our team will get back to you with the perfect solution.",
      btnText: "Talk to Us",
    },
  },
  solution: {
    heading: "Crowd Testing",
    description:
      // "The growing complexity of modern applications with the emergence of mobile and cloud technologies is making it imperative to have a real-world assurance to ensure app success in the market. We help you launch an accurate and robust app which works consistently for all your users.",
      "Qantily facilitates you in developing a robust and bug-free app that works consistently for all of your consumers, across all devices through exhaustive bug testing.",
    component1: {
      heading1a: "Fastest Growing Community Of Application Testers powered by",
      heading1b: "Qantily",
      item1a: "10+",
      item1b: "500+",
      item1c: "100%",
      item1d: "1000+",
      content1a: "Countries",
      content1b: "Devices and Configs",
      content1c: "Real world coverage",
      content1d: "Crowd testers",
    },
    component2: {
      heading2a: "How it works?",
      heading2b: "Benefits",
      item2a: "Tell us your testing needs",
      item2b: "Our team structures and plans the process analytically",
      item2c: "Testers are allocated best according to your needs of execution",
      item2d: "We will share the test results with you in 24 hours.",
      content2a: "Reliable market behaviour",
      content2b: "Bug free - crash free - user aligned",
      content2c: "Early user issues detected",
      content2d: "Fast TAT",
      btnText: "Start Now",
      description2a: "Let’s get started.",
      description2b: "Share your requirements and our team will get back to you with the perfect solution.",
    },
  },
  testerBanner01: {
    heading: "Become a part of Fastest",
    subHeading: "Growing Community",
    description: "Become a tester, test the latest apps, websites, and games, and get paid for every issue you find.",
    buttonTextPrimary: "Start Testing",
    buttonTextSecondary: "Sign Up",
    urlToImage: tester_image,
  },
  testerData1: {
    heading: "Why Qantily?",
    navigate: "/crowdsourcedtesting/automation",
    items: [
      { urlToImage: icon4, heading: "Flexibility", description: "Work anytime from anywhere on your exsisting devices." },
      { urlToImage: icon3, heading: "Latest Technology", description: "Work on some of the most innovative applications and digital products" },
      { urlToImage: icon1, heading: "Earn", description: "Earn for every approved bug" },
      { urlToImage: icon5, heading: "Upskill", description: "Get mentored and learn new skills while working with Qantily team" },
      //{ urlToImage: icon6, heading: "Earn Good Money", description: "" },
      //{ urlToImage: icon1, heading: "Get Mentored by the Best", description: "" },
    ],
    btnText: "Learn More",
  },
  testerData2: {
    heading: "Test at every stage of your Product Lifecycle",
    items: [
      {
        urlToImage: Pre,
        prefixText: "ALPHA",
        suffixText: "Testing",
      },
      {
        urlToImage: Alpha,
        prefixText: "BETA",
        suffixText: "Testing",
      },
      {
        urlToImage: Post,
        prefixText: "POST LIVE",
        suffixText: "Testing",
      },
    ],
  },
  testerData4: {
    heading: "How it works for tester?",
    description: ``,
    item1: "Register on Qantily & easily add all the available devices with you.",
    item2: "After registeration, start picking up open jobs from the dashboard",
    item3: "Get paid in convenient monthly / by monthly cycles.",
    btnText: "",
  },
  testerFAQ: {
    heading: "Frequently Asked Questions",
    accordian1: {
      title: "How much can I earn?",
      description:
        "Most tests pay by the bug. You earn more money for more critical bugs — up to $50 for the most important bugs. You can also earn guaranteed money by reviewing apps or completing scripted test cases.",
    },
    accordian2: {
      title: "When/how do I get paid?",
      description: "We pay once per month using PayPal, Payoneer, Skrill, or bank transfer.",
    },
    accordian3: {
      title: "What devices do I need to test?",
      description:
        "You will need access at least to a desktop computer. Beyond that, the more devices you have, the more opportunities you will have to test. Most testers have one or more mobile phones, and some have smart home devices and wearables. You don’t need the latest gadget; there are plenty of tests on older phones, and many great bugs to be found!",
    },
    accordian4: {
      title: "What happens if I can’t accept an invitation?",
      description:
        "We want to work with your schedule, so there’s no penalty for not accepting an invitation. It’s helpful to let us know if you can’t test so we can offer your place to someone else right away. Please test if you say you’re going to — it’s good karma and better for your fellow testers.",
    },
  },
  devicesfarm: {
    heading: "Test your products under real conditions",
    descriptiona: "Over",
    descriptionb: "500+",
    descriptionc: "users to test your products, services, website, applications and IoT Devices.",
  },
  signupclient: {
    heading: "Set up your Account today!",
    name: "Your Name *",
    email: "Email Address *",
    password: "Password *",
    confirmPassword: "Confirm Password *",
    position: "Position *",
    contactinfo: "Phone Number *",
    headquarters: "Headquarters *",
    countryCode:"Country Code *",
    country: "Select Country",
    employees: "Number of Employees *",
    website: "Website URL *",
    profileimg: "Add Profile Image ",
    decision: "I am in a position to make decision for my firm *",
    declaration: "I accept the privacy notice for the crowd services & cloud services *",
    placeholder1: "Enter your Name",
    placeholder2: "Enter your Email",
    placeholder3: "Enter your Position",
    placeholder4: "Enter your Number",
    placeholder5: "e.g: 10",
    placeholder6: "Enter your Company Website",
    placeholder7: "******",
    placeholder8: "******",
    message1: "Invalid Contact Information",
    message2: "Invalid Employee Number",
    message3: "Enter valid URL",
    submit_btn: "Submit",
  },
  signuptester: {
    name: "Your Name *",
    email: "Email Address *",
    password: "Password *",
    repeatpassword: "Repeat Password *",
    img: "Add Profile Image *",
    education: "Domain *",
    contactinfo: "Phone Number *",
    country: "Country *",
    state: "State *",
    city: "City",
    time: "Address *",
    btnText: "Submit",
    placeholder1: "John Doe",
    placeholder2: "e.g. john@example.com",
    placeholder3: "e.g. User Experience Design",
    placeholder4: "",
    placeholder5: "Enter address here",
    back_btn: "Back",
    submit_btn: "Submit",
    warningPassword: "Please Enter correct password",
    warningEmail: "Please Enter email",
    warningImage: "Please Add profile image",
    valid_email: "Enter Valid Email",
    message: "Password is not matched",
    loading: "Select country first",
    loadingCity: "Select state first",
  },
  alerts: {
    message: "No Message found",
  },
  community: {
    heading: {
      light: "",
      bold: "Our Community Leaderboard",
    },
    applicationtested: "Number of Application Tested",
    bugsfound: "Number of Bugs Found",
    testersavailable: "Number of Testers Available",
    devicefarm: "Unique Device Hub",
  },
  totalearnings: {
    graphheading: "Total Earnings Graph",
    heading1: {
      light: "",
      bold: "My Earnings",
    },
    heading2: "Lifetime",
    totalBalance: "Total Balance",
    message: "($ 300 Marked for transfer)",
    btnText: "Request Transfer",
  },
  transferamount: {
    heading1: {
      light: "",
      bold: "Raise Transfer Request",
    },
    heading2: {
      light: "Added Bank Details",
      bold: "",
    },
    bank_details: {
      heading3: "Add Bank Details",
      ifsc: "IFSC Code",
      ifsc_plcholder: "HDFC0000011",
      account: "Account Number",
      account_no: "01232580",
      name: "Account Holder Name",
      placeholder: "Add Desired Name",
    },
    heading4: {
      light: "",
      bold: "Initialized Transfers",
    },
    currentbalance: "Current Balance",
    message: "($ 300 Marked for transfer)",
    btnText: "Submit",
    error1: "Error While validation IFSC",
    warning1: "Enter Valid IFSC",
    success_message: "Amount Successfully Transfered",
    error2: "Error While Transfering Amount",
    transfer_amt: "Transfer Amount",
  },
  testimonials:{
    heading1:"Give A Testimonial",
  },
  transfer_amount_modals: {
    heading1: "Verify Your Bank Details and Submit",
    bank: "Bank",
    ifsc: "IFSC Code",
    branch: "Branch",
    name: "Account Holder Name",
    account: "Account Number",
    btnText: "Verify",
    added_successfully:"Bank Account Added Successfully ",
  },
  testerhome: {
    heading1: {
      light: "",
      bold: "Current Projects",
    },
    message1: "To see projects add a device",
    heading2: {
      light: "",
      bold: "Our Community Leaderboard",
    },
    message2: "No leaderboard found",
    heading3: {
      light: "",
      bold: "Live Projects",
    },
    btnText: "Take Test",
    line1: "Crash $5-$15 Blocker $4.5-$13 Critical $4-$11",
    line2: "Major $3.75 to $7.5 Minor $2.8-$5.6 Low $1.75-$3.75",
  },
  notifications: {
    heading1: {
      light: "",
      bold: "My Notifications",
    },
  },
  testersettings: {
    heading1: {
      light: "",
      bold: "All Settings",
    },
    change_password: {
      heading2: "Change Password",
      currpassword: "Your Current Password",
      newpassword: "Your New Password",
      repeatpassword: "ReEnter New Password",
      btnText1: "Change Password",
    },
    heading3: "Language",
    LanguageList: [{ name: "English" }, { name: "Hindi" }, { name: "French" }, { name: "German" }],
    heading4: "Time Zone",
    TimezoneList: [{ name: "Kolkata" }, { name: "Germany" }],
    placeholder: "Select",
    btnText2: "Save Changes",
    heading5: {
      light: "",
      bold: "Profile Settings",
    },
    profileimg: "Add Profile Image :",
  },
  devices: {
    heading: {
      light: "",
      bold: "My Devices",
    },
    btnText: "Add Device",
  },
  modals: {
    heading: "Add a Device",
    name: "Device Name",
    manufacturer: "Device Manufacturer",
    model: "Model",
    osversion: "OS Version",
    browser: "Browser",
    btnText: "Add Device",
    clear: "X",
  },
  homescreenModal: {
    heading: "Welcome to Qantily",
    subHeading: "What are you looking for?",
    client_heading: "Testing Services",
    client_button: "Become a client",
    tester_heading: "Become a Tester",
    tester_button: "Start Testing Now",
    clear: "X",
  },
  boostmultiplier: {
    heading: {
      light: "",
      bold: "Boost your Multiplier",
    },
    card1: {
      heading: "Upgrade skill",
      ListCard1: [
        { name: "Additional skills", link: "/dashboard/boostmultiplier/skills" },
        { name: "Increase Number of devices", link: "/dashboard/devices" },
        { name: "Critical thinker" },
        { name: "Quick Learner" },
      ],
      btnText1: "Add Now",
    },
    card2: {
      heading: "Become Prime tester",
      ListCard2: [{ name: "Join Regular tester plan" }, { name: "Maintain availability in scheduled hours" }, { name: "Maintain 4+ ratings " }],
      btnText2: "Start Now",
    },
    card3: {
      heading: "Become reviewer",
      ListCard3: [{ name: "Attain 90% accuracy " }, { name: "Maintain availability for min 5 days a week for 6 months" }, { name: "Outperform your peers" }],
      btnText3: "Apply Now",
    },
    add_skills: {
      heading1: {
        light: "",
        bold: "Boost your Multiplier",
      },
      heading2: {
        light: "",
        bold: "My Skillsets",
      },
      btnText4: "Apply",
      label: "No Skills Found",
    },
    accordion1: "How to add payment mode for transfer of money",
    content1:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Etiam urna felis, rhoncus vitae diam vitae, consectetur volutpat nunc. Etiam egestas tincidunt consequat. Quisque turpis erat, porta eu ex non",
    accordion2: "How to add devices in your account",
    content2:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Etiam urna felis, rhoncus vitae diam vitae, consectetur volutpat nunc. Etiam egestas tincidunt consequat. Quisque turpis erat, porta eu ex non",
    accordion3: "How to add payment mode for transfer of money",
    content3:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Etiam urna felis, rhoncus vitae diam vitae, consectetur volutpat nunc. Etiam egestas tincidunt consequat. Quisque turpis erat, porta eu ex non",
  },
  dashboardactivetests: {
    name: "Name",
    project: "Project",
    platform: "Platform to test",
    testingon: "Overall Duration",
    hours: "Hours",
    testingends: "Left Duration",
    leaderboard: "Leaderboard",
    noleaderboard: "No Leaderboard",
    earned: "earned",
    btnText1: "Begin Testing",
    btnText2: "View Bug List",
  },
  live_application_testing: {
    heading: "Live Application Testing",
    task: "Incomplete Tasks",
    option1: "August 2020",
    option2: "November 2020",
    option3: "November 2020",
    option4: "December 2020",
    view: "View All",
    content1: "Send the billing Agreement",
    content2: "Schedule for 24 mar 2019",
  },
  user_testing_leaderboard: {
    heading: {
      light: "",
      bold: "USERTESTING'S Leaderboard",
    },
    name: "Name",
    earnings: "Earnings",
  },
  vote: {
    heading1: "Who do you think will win the USA Elections?",
    label1: "Joe Biden",
    label2: "Donald Trump",
    label3: "Barack Obama",
  },
  active_project: {
    heading: "Project",
    platform: "Platforms",
    buglist: "Known Bug List",
    website: "Website Link",
    version: "Version",
    btnText1: "Download Excel",
    btnText2: "Download Application",
    heading1a: "Reported",
    heading1b: "Bugs",
    btnText3: "Report a Bug",
    btnsize: "1",
    btnText4: "View All Bug List",
  },
  all_projects: {
    heading1: {
      light: "",
      bold: "Active Projects",
    },
    heading2: {
      light: "",
      bold: "Completed Projects",
    },
  },
  bug_list: {
    heading1: {
      light: "",
      bold: "Your Analysis",
    },
    heading2: "Project",
    btnText: "Report Bug",
    btnSize: "1",
  },
  completed_projects_bug_list: {
    heading1: {
      light: "",
      bold: "Your Analysis",
    },
    tableContentArray: [
      {
        name: "OTP Confirmation not working",
        submissionTime: "13:51",
        status: "Verified",
        approval: "Approved",
        id: "55d55s",
      },
      {
        name: "OTP Confirmation not working",
        submissionTime: "13:51",
        status: "Verified",
        approval: "Declined",
        id: "55d55s",
      },
      {
        name: "OTP Confirmation not working",
        submissionTime: "13:51",
        status: "Verified",
        approval: "Declined",
        id: "55d55s",
      },
      {
        name: "OTP Confirmation not working",
        submissionTime: "13:51",
        status: "Verified",
        approval: "Approved",
        id: "55d55s",
      },
      {
        name: "OTP Confirmation not working",
        submissionTime: "13:51",
        status: "Verified",
        approval: "Declined",
        id: "55d55s",
      },
      {
        name: "OTP Confirmation not working",
        submissionTime: "13:51",
        status: "Verified",
        approval: "Approved",
        id: "55d55s",
      },
    ],
  },
  report_bug_form: {
    heading: "Test",
    reportbug: {
      light: "",
      bold: "Report a New Bug",
    },
    back_btn: "Back",
    Summary: "Defect Summary*",
    Component: "Component*",
    priority: "Severity*",
    priority_option1: "CRASH",
    priority_option2: "BLOCKER",
    priority_option3: "CRITICAL",
    priority_option4: "MAJOR",
    priority_option5: "MINOR",
    priority_option6: "REPEATED",
    priority_option7: "LOW",
    attach_file: "Attach reference document (in zip if multiple)",
    screenshots: "Attach screen shots",
    bug: "Describe The Bug *",
    btnText: "Submit",
    placeholder1: "Add brief summary",
    placeholder2: "Component of problem",
    placeholder3: "Add brief description about the bug encountered",
  },
  submitted_bug_details: {
    heading: "Project",
    status: "Status",
    summary: "Defect Summary",
    component: "Component",
    priority: "Severity",
    attachments: "Attachments",
    Describe: "Description",
    forReviewerYes: "Approve Bug",
    forReviewerNo: "Reject Bug",
  },
  client_add_tests_cart: {
    heading: "Cart Summary",
    particular: "Particular",
    amt: "Amount",
    platform: "Platform",
    price: "Base Price",
    multiplier: "Multiplier",
    total: "Sub Total",
  },
  client_add_tests_step1: {
    heading1: {
      light: "",
      bold: "Add Project Title*",
    },
    placeholder1: "Add Title Text Here",
    heading2: {
      light: "",
      bold: "Choose Your Platform*",
    },
  },
  client_add_tests_step2: {
    heading: "Add Basic Testing Details",
    label1: "Add Project Logo*",
    label2: "What is your app Complexity?*",
    label3: "Devices",
    label4: "Describe the project*",
    option: "Choose Package",
  },
  client_add_tests_step3: {
    heading: "Add Application Testing Details",
    label1: "Upload Existing Bugs",
    btnText1: "Upload Excel",
    label2: "Upload Additional Files",
    btnText2: "Installables / Zip",
    label3: "Testing Man Hours*",
    label3_option1: "24",
    label3_option2: "48",
    label3_option3: "72",
    label3_option4: "96",
    label3_option5: "120",
    label4: "Testing Stage*",
    label4_option1: "Dev",
    label4_option2: "Testing",
    label4_option3: "Pre Deploy",
    label4_option4: "Deployed",
    label5: "Login ID",
    label6: "Login Password",
    label7: "Provide Build URL",
    label8: "Version*",
    placeholder1: "Add login id",
    placeholder2: "P@ssw0rd12",
    placeholder3: "https://www.google.com",
    placeholder4: "1.2.3",
  },
  client_add_tests_messages: {
    message1: "Order placed! You will receive an email confirmation.",
    message2: "Order canceled -- continue to shop around and checkout when you're ready.",
    message3: "Please enter title and select platform",
    message4: "Please enter all details",
    message5: "Please Start over",
    back_btn: "< Back",
    btnText1: "Continue",
    btnText2: "Proceed to Payment",
  },
  client_community: {
    heading: "Community",
  },
  client_devices: {
    heading: "Devices",
  },
  client_home: {
    bugsdetectedcontent: [
      {
        name: "Bug Title",
        device: "Android",
        type: "Major",
        platform: "7.8.4",
        view: "Detailed View",
      },
      {
        name: "Dell Inspiron 15 5590 Laptop",
        device: "iOS",
        type: "Minor",
        platform: "iOS 14",
        view: "Check File",
      },
      {
        name: "iPhone 8",
        device: "Android",
        type: "Major",
        platform: "7.8.4",
        view: "Check File",
      },
      {
        name: "Dell Inspiron 15 5590 Laptop",
        device: "iOS",
        type: "Minor",
        platform: "iOS 14",
        view: "Check File",
      },
      {
        name: "iPhone 8",
        device: "Android",
        type: "Major",
        platform: "7.8.4",
        view: "Check File",
      },
    ],
    heading1: {
      light: "",
      bold: "Hoichoi Android Test",
    },
    placeholder: "Platform",
    PlatformList: [{ name: "iOS" }, { name: "Android" }, { name: "Microsoft" }],
    option1: "Android",
    option2: "iOS",
    option3: "Microsoft",
    pie_data: {
      label1: "Repeated Bugs",
      data1: "42%",
      label2: "Critical Bugs",
      data2: "17%",
      label3: "Minor Bugs",
      data3: "09%",
      label4: "Major Bugs",
      data4: "10%",
      label5: "Blockades",
      data5: "08%",
      label6: "Health Score",
      data6: "85%",
      label7: "Time Tested",
      data7: "15 Hours 25 Minutes",
      label8: "Time Remaining",
      data8: "25 mins",
      label9: "Total Bugs Detected",
      data9: "2500",
    },
    heading2: {
      light: "",
      bold: "Bugs Detected",
    },
    btnText: "Download All",
  },
  client_pricing: {
    heading: "Pricing",
  },
  dashboard_navigator: {
    heading: "Dashboard",
  },
  error: {
    heading: "Page Not Found",
  },
  active_test_table: {
    tableHeadingArray: ["Project Name", "Status"],
    message: "No Active Projects",
    view: "View Test",
  },
  pause_test_table: {
    tableHeadingArray: ["Project Name", "Status"],
    message: "No Paused Projects",
    view: "View Test",
  },
  analysis_table: {
    tableHeadingArray: ["Name", "Submission Date", "Earnings", "Severity", "Status"],
    message: "No Updated Results",
    view: "View Details",
  },
  all_projects_taken_table: {
    tableHeadingArray: ["Project Name", "Platform"],
    message: "No Projects Taken",
    view: "View Project",
  },
  bugs_list_table: {
    tableHeadingArray: ["Bug Title", "Submission Date", "Severity", "Status"],
    message: "No Bugs Reported",
    view: "View Details",
  },
  bugs_detected_table: {
    // tableHeadingArray: ["Bug Summary", "Device", "Type", "Platform + Version", "Details"],
    tableHeadingArray: ["Bug Summary", "Device", "Type", "Details"],
  },
  client_add_tests_cart_table: {
    tableHeadingArray: ["Platforms", "Hours", "Amount"],
  },
  completed_bugs_table: {
    tableHeadingArray: ["Bug Name", "Submission Time", "Status", "Approval", "Details"],
    view: "View",
  },
  completed_test_table: {
    // tableHeadingArray: ["Project Name", "Earnings", "Rating", "Bugs Reported", "Bugs Approved", "Details"],
    tableHeadingArray: ["Project Name", "Earnings", "Bugs Reported", "Bugs Approved", "Details"],
    message: "No Completed Projects",
    view: "View Details",
  },
  devices_list_table: {
    tableHeadingArray: ["Platform", "Name", "Model", "OS"],
  },
  devices_table: {
    tableHeadingArray: ["Name", "Browsers", "Platform", "Operating System", "Manufacturer", "Model", "Action"],
    message: "No Device Found",
    view: "View Details",
  },
  lifetime_earning_table: {
    tableHeadingArray: ["Project Name", "Earnings", "Date", "Status"],
    message: "No Previous Earning",
    view: "View Details",
  },
  live_projects: {
    tableHeadingArray: ["Project Name", "Platform", "Earnings", "Bugs Reported", "Bugs Approved", "Bug list"],
    message: "To see projects add a device",
    view: "View",
  },
  my_earnings_table: {
    tableHeadingArray: ["Project Name", "Earnings", "Date", "Status"],
    message: "No Earnings",
  },
  my_earnings_accordian: {
    tableHeadingArray: ["Bug Details", "Earnings", "Date", "Status"],
    message: "No Earnings",
  },
  notification_table: {
    tableHeadingArray: ["Invitations", "Begin"],
    message: "No Notifications",
    view: "Start Testing",
  },
  tester_bank_table: {
    tableHeadingArray: ["Account Holder Name", "Account Number", "IFSC", "Bank Name", "Bank Branch", "Default"],
    message: "No Bank Accounts",
  },
  initialized_transfer_table: {
    tableHeadingArray: ["Transaction Code", "Amount", "Date", "Payment Status"],
    message: "No Bank Accounts",
  },
  linechart1: {
    tasks: "Tasks Done",
  },
  linechart2: {
    from: "from",
    to: "to",
    valid_dates: "Choose valid Dates",
  },
  record_card: {
    label: "Since last month",
  },
  tester_detail_card: {
    successful_test: "Successful Tests",
    total_tests: "Total Tests",
    bugs_detected: "Major Bugs Detected",
    amt_earned: "Amount earned in the last month",
  },
  stepper_three_steps: {
    icon1: "01",
    label1: "Select Platform",
    icon2: "02",
    label2: "Choose Pack",
    icon3: "03",
    label3: "Upload Files",
  },
  forget_password_flow: {
    change_password: "Change your password",
    new_password: "New Password",
    re_enter_password: "Re-enter New Password",
    message1: "Make sure password match",
    message2: "Password changed successfully, try to login",
    message3: "Unable to change password",
    btnText: "Change Password",
    request_reset: {
      message1: "Don't worry. It happens to the best of us.",
      message2: "Email sent to below mail, please check your mail",
      message3: "Please enter your email address",
      email_not_found: "Email not found",
      email_sent: "Email Sent",
      forgot_password: "Forgot Password",
      placeholder: "****@gmail.com",
      label: "Email Address",
      btnText: "Send Recovery Mail",
    },
  },
  email_verification: {
    email_verified: "Email Verified",
    email_not_verified: "Email Not verified",
    btnText: "Go To Home",
  },
  payment_status: {
    payment_failed: {
      message: "Your payment failed",
      btnText: "Go to dashboard",
    },
    payment_success: {
      message: "Payment Sucessful",
      btnText: "Continue",
    },
  },
};
