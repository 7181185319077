import React, { useState } from "react";
import { setAlert } from "../../../../redux/Alerts/actions";
import { connect } from "react-redux";
import "./style.scss";

const Form = ({ form, setForm, createBadgeFormSubmit, setAlert, showFormModal, toggleFormModal }) => {
  const { badgeName, badgeDescription, score } = form;
  const [badgeIcon, setBadgeIcon] = useState();

  const setInput = e => {
    setForm({
      ...form,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = e => {
    e.preventDefault();
    if (badgeName.length < 2) {
      return setAlert({ message: "ENTER BADGE NAME", type: "ERROR" });
    } else if (score.length === 0) {
      return setAlert({ message: "ENTER BADGE TYPE", type: "ERROR" });
    } else if (badgeDescription.length <= 10) {
      return setAlert({ message: "ENTER BADGE DESCRIPTION ", type: "ERROR" });
    } else if (!badgeIcon) {
      return setAlert({ message: "Please select badge icon ", type: "ERROR" });
    } else {
      createBadgeFormSubmit(form, badgeIcon);
      toggleFormModal();
      setForm({
        ...form,
        score: "",
        badgeName: "",
        logo: "",
        badgeDescription: "",
      });
      setBadgeIcon(null);
    }
  };

  const handleFakeSelect = () => {
    const input = document.getElementById("badgeIconInput");
    input.click();
  };

  return (
    <div className="formModal" style={showFormModal ? { display: "flex" } : { display: "none" }}>
      <div className="formChildContainer">
        <button className="glb-btn" id="close-popUp" onClick={toggleFormModal}>
          &#215;
        </button>
        <h2 className="heading">Create Badge</h2>

        <form id="badges-form" onSubmit={handleSubmit}>
          <div className="inputFieldBox">
            <label>Badge Name</label>
            <br />
            <input value={badgeName} type="text" name="badgeName" onChange={setInput} />
          </div>
          <div className="inputFieldBox">
            <label>Score</label>
            <br />
            <input value={score} type="text" name="score" onChange={setInput} />
          </div>
          <div className="inputFieldBox">
            <label>Badge Descirption</label>
            <br />
            <input value={badgeDescription} type="text" name="badgeDescription" onChange={setInput} />
          </div>
          <div className="inputFieldBox" id="badgeIcon">
            <label onClick={handleFakeSelect} htmlFor="logo" className="badge-icon-label">
              {badgeIcon ? badgeIcon.name : "Add Badge Icon"}
            </label>
            <input
              id="badgeIconInput"
              type="file"
              name="logo"
              className="badge-icon"
              onChange={e => {
                setBadgeIcon(e.target.files[0]);
              }}
              accept="image/*"
            />
          </div>
          <div style={{ width: "100%" }}></div>
          <div style={{ textAlign: "center", width: "100%" }}>
            <button className="glb-btn" type="submit" id="submit-badge">
              Submit
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default connect(null, { setAlert })(Form);
