import React from "react";
import benefit1 from "../../../assets/icons/4206122.png";
import benefit2 from "../../../assets/icons/888034.png";
import benefit3 from "../../../assets/icons/1147212.png";
import benefit4 from "../../../assets/icons/3463339.png";
import tick from "../../../assets/icons/check.svg";
import iconsAndDescriptionImg from "../../../assets/iconsAndDescription.png";
import "./style.scss";

function Benefits({ data = {} }) {
  return (
    <div className="benefits">
      <h4 className="benefits-heading">{data.heading2a}</h4>

      <div className="icons-and-description">
        <div className="icons-placement">
          <div className="icon-first">
            <img src={benefit1} alt="Automation Working" />
          </div>
          <div className="icon-second">
            <img src={benefit2} alt="Automation Working" />
          </div>
          <div className="icon-third">
            <img src={benefit3} alt="Automation Working" />
          </div>
          <div className="icon-fourth">
            <img src={benefit4} alt="Automation Working" />
          </div>
        </div>

        <div className="styled-border">
          <div className="first-circle"></div>
          <div className="first-empty-div"></div>
          <div className="second-circle"></div>
          <div className="second-empty-div"></div>
          <div className="third-circle"></div>
          <div className="third-empty-div"></div>
          <div className="fourth-circle"></div>
        </div>

        <div className="icons-content">
          <div className="content-first">{data.item2a}</div>
          <div className="content-second">{data.item2b}</div>
          <div className="content-third">{data.item2c}</div>
          <div className="content-fourth">{data.item2d}</div>
        </div>
      </div>

      <div className="icons-and-description-img">
        <img src={iconsAndDescriptionImg} alt="" />
      </div>

      <h4 className="benefits-heading">{data.heading2b}</h4>

      <div className="benefits-grid">
        {/* <div className="glb-card benefits-box">
          <div className="white-tick">
            <img src={tick} alt="icon" />
          </div>
          <div>{data.content2a}</div>
        </div>
        <div className="benefits-box">
          <div className="white-tick">
            <img src={tick} alt="icon" />
          </div>
          <div>{data.content2b}</div>
        </div>
        <div className="benefits-box">
          <div className="white-tick">
            <img src={tick} alt="icon" />
          </div>
          <div>{data.content2c}</div>
        </div>
        <div className="benefits-box">
          <div className="white-tick">
            <img src={tick} alt="icon" />
          </div>
          <div>{data.content2d}</div>
        </div>
        <div className="benefits-box">
          <div className="white-tick">
            <img src={tick} alt="icon" />
          </div>
          <div>{data.content2e}</div>
        </div>
        <div className="benefits-box">
          <div className="white-tick">
            <img src={tick} alt="icon" />
          </div>
          <div>{data.content2f}</div>
        </div> */}
        <ul className="benefitsSeciton" >
          <li className="benefitsItem" ><img src={tick} alt="tick" />&ensp;{data.content2a}</li>
          <li className="benefitsItem" ><img src={tick} alt="tick" />&ensp;{data.content2b}</li>
        </ul>
        <ul className="benefitsSeciton" >
          <li className="benefitsItem" ><img src={tick} alt="tick" />&ensp;{data.content2c}</li>
          <li className="benefitsItem" ><img src={tick} alt="tick" />&ensp;{data.content2d}</li>
        </ul>
        <ul className="benefitsSeciton" >
          <li className="benefitsItem" ><img src={tick} alt="tick" />&ensp;{data.content2e}</li>
          <li className="benefitsItem" ><img src={tick} alt="tick" />&ensp;{data.content2f}</li>
        </ul>
      </div>

    </div>
  );
}

export default Benefits;
