import React, { useEffect } from "react";

import { connect } from "react-redux";
import { useRouteMatch, Route, Switch, Redirect } from "react-router-dom";

import { setDashboardRoute } from "../../../redux/Routing/actions";
import Boosters from "./Components/Boosters";
import Skills from "./Components/Skills";

const BoostMultiplier = ({ setDashboardRoute, componentID }) => {
  const match = useRouteMatch();
  useEffect(() => {
    setDashboardRoute(componentID);
    // eslint-disable-next-line
  }, []);

  return (
    <div className="dashboard-multiplier-container">
      <Switch>
        <Redirect exact from={`${match.url}/`} to={`${match.url}/boosters`} />
        <Route path={`${match.url}/boosters`} exact={true} component={Boosters} />
        <Route path={`${match.url}/skills`} exact={true} component={Skills} />
        <Redirect to={`/dashboard/boostmultiplier`} />
      </Switch>
    </div>
  );
};

export default connect(null, { setDashboardRoute })(BoostMultiplier);
