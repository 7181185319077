import React, { useEffect } from "react";
import { connect } from "react-redux";
import { useRouteMatch, Route, Switch, Redirect } from "react-router-dom";

import { setDashboardRoute } from "../../../redux/Routing/actions";
import { getWalletEarning } from "../../../redux/TesterDashboard/actions";

import TotalEarning from "./Components/TotalEarning";
import TransferAmount from "./Components/TransferAmount";

const Earning = ({ setDashboardRoute, componentID }) => {
  const match = useRouteMatch();
  useEffect(() => {
    setDashboardRoute(componentID);
    getWalletEarning();
    // eslint-disable-next-line
  }, []);

  return (
    <div className="earnings">
      <Switch>
        <Redirect exact from={`${match.url}/`} to={`${match.url}/total`} />
        <Route path={`${match.url}/total`} exact={true} component={TotalEarning} />
        <Route path={`${match.url}/transfer`} exact={true} component={TransferAmount} />
        <Redirect to={`/dashboard/earning`} />
      </Switch>
    </div>
  );
};

export default connect(null, { setDashboardRoute })(Earning);
