import {
  GET_STATIC_COUNTRIES,
  GET_STATIC_COUNTRIES_SUCCESS,
  GET_STATIC_COUNTRIES_ERROR,
  GET_STATIC_STATES,
  GET_STATIC_STATES_SUCCESS,
  GET_STATIC_STATES_ERROR,
  GET_STATIC_DEVICES,
  GET_STATIC_DEVICES_SUCCESS,
  GET_STATIC_DEVICES_ERROR,
  GET_STATIC_PLATFORMS,
  GET_STATIC_PLATFORMS_SUCCESS,
  GET_STATIC_PLATFORMS_ERROR,
} from "../actions";

const initialState = {
  error: false,
  isLoading: false,
  countries: [],
  states: [],
  devices: [],
  platforms: [],
};

const fun = (state = initialState, action) => {
  switch (action.type) {
    // Countries
    case GET_STATIC_COUNTRIES:
      return {
        ...state,
        isLoading: true,
        countries: [],
        error: false,
      };
    case GET_STATIC_COUNTRIES_SUCCESS:
      return {
        ...state,
        countries: action.payload,
        isLoading: false,
        error: false,
      };
    case GET_STATIC_COUNTRIES_ERROR:
      return {
        ...state,
        isLoading: false,
        countries: [],
        error: true,
      };

    // States
    case GET_STATIC_STATES:
      return {
        ...state,
        isLoading: true,
        states: [],
        error: false,
      };
    case GET_STATIC_STATES_SUCCESS:
      return {
        ...state,
        states: action.payload,
        isLoading: false,
        error: false,
      };
    case GET_STATIC_STATES_ERROR:
      return {
        ...state,
        states: [],
        isLoading: false,
        error: true,
      };

    // Devices
    case GET_STATIC_DEVICES:
      return {
        ...state,
        isLoading: true,
        devices: [],
        error: false,
      };
    case GET_STATIC_DEVICES_SUCCESS:
      return {
        ...state,
        devices: action.payload,
        isLoading: false,
        error: false,
      };
    case GET_STATIC_DEVICES_ERROR:
      return {
        ...state,
        isLoading: false,
        devices: [],
        error: true,
      };

    // Platforms
    case GET_STATIC_PLATFORMS:
      return {
        ...state,
        isLoading: true,
        platforms: [],
        error: false,
      };
    case GET_STATIC_PLATFORMS_SUCCESS:
      return {
        ...state,
        platforms: action.payload,
        isLoading: false,
        error: false,
      };
    case GET_STATIC_PLATFORMS_ERROR:
      return {
        ...state,
        isLoading: false,
        platforms: [],
        error: true,
      };

    default:
      return { ...state };
  }
};

export default fun;
