import React, { useEffect } from "react";

import { connect } from "react-redux";

import { getStaticPlatforms } from "../../../redux/staticData/actions";
import Loader from "../../Loader";

import "./style.scss";

const PlatformPicker = ({ platformId, setPlatform, isLoading, platforms, getStaticPlatforms, setPlatformFilter, platformFilter }) => {
  useEffect(() => {
    getStaticPlatforms();
    // eslint-disable-next-line
  }, []);
  return (
    <div className="platform-select-container">
      {isLoading ? (
        <Loader />
      ) : (
        <>
          <div className="platform-select-component-with-icon">
            {platforms.map((item, i) => {
              if (item.isCover) {
                return (
                  <div
                    className={platformFilter.id === item.id ? "platform selected" : "platform"}
                    key={i}
                    onClick={() => {
                      item.id === platformFilter.id ? setPlatform("", "") : setPlatform(item.id, item.name);
                      item.id === platformFilter.id ? setPlatformFilter({}, "") : setPlatformFilter(item, item.name);
                    }}
                  >
                    <div className="icon">
                      <img src={platformFilter.id === item.id ? item.icon.white : item.icon.blue} alt="" />
                    </div>
                    <div className="platform-name">{item.name}</div>
                  </div>
                );
              } else {
                return null;
              }
            })}
          </div>
          <div className="platform-select-component-with-name">
            {platforms.map((item, i) => {
              if (!item.isCover) {
                return (
                  <div
                    className={
                      platformFilter.subPlatform
                        ? platformFilter.subPlatform.includes(item.id)
                          ? platformId === item.id
                            ? "platform selected"
                            : "platform"
                          : "platform disabled"
                        : platformId === item.id
                        ? "platform selected"
                        : "platform"
                    }
                    key={i}
                    onClick={
                      platformFilter.subPlatform
                        ? platformFilter.subPlatform.includes(item.id)
                          ? () => (item.id === platformId ? setPlatform(platformFilter.id, platformFilter.name) : setPlatform(item.id, item.name))
                          : null
                        : () => (item.id === platformId ? setPlatform("", "") : setPlatform(item.id, item.name))
                    }
                  >
                    <div className="platform-name">{item.name}</div>
                  </div>
                );
              } else {
                return null;
              }
            })}
          </div>
        </>
      )}
    </div>
  );
};

const mapStateToProps = ({ staticData }) => ({
  isLoading: staticData.isLoading,
  platforms: staticData.platforms,
});

export default connect(mapStateToProps, { getStaticPlatforms })(PlatformPicker);
