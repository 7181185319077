import React from "react";
import { useHistory } from "react-router-dom";

//import Laptop from "../../../assets/icons/2933190.svg";
import functional from "../../../assets/icons/1612618.png";
import CrossBrowser from "../../../assets/icons/896530.png";
import Compatibility from "../../../assets/icons/4205637.png";
import Beta from "../../../assets/icons/782711.png";
import Network from "../../../assets/icons/684809.png";
import Localization from "../../../assets/icons/748151.png";

import "./style.scss";

function NewInfoLanding01({ data = {} }) {
  const history = useHistory();
  return (
    <div className="new-info-01">
      <h4 className="new-info-01-heading">{data.heading}</h4>
      <div className="new-info-01-content">{data.description}</div>
      <div className="new-info-01-flex">
        <div className="new-info-01-img"></div>
        <div className="new-info-01-features">
          <div className="new-info-01-grid">
            <div className="new-info-icon">
              <img src={functional} alt="icon" />
              <p className="icon-describe">{data.item1}</p>
            </div>
            <div className="new-info-icon">
              <img src={CrossBrowser} alt="icon" />
              <p className="icon-describe">{data.item2}</p>
            </div>
            <div className="new-info-icon">
              <img src={Compatibility} alt="icon" />
              <p className="icon-describe">{data.item3}</p>
            </div>
            <div className="new-info-icon">
              <img src={Beta} alt="icon" />
              <p className="icon-describe">{data.item4}</p>
            </div>
            <div className="new-info-icon">
              <img src={Network} alt="icon" />
              <p className="icon-describe">{data.item5}</p>
            </div>
            <div className="new-info-icon">
              <img src={Localization} alt="icon" />
              <p className="icon-describe">{data.item6}</p>
            </div>
          </div>
          <div className="glb-btn new-info-01-btn" onClick={() => history.push("/crowdsourcedtesting/services#")}>
            {data.btnText}
          </div>
        </div>
      </div>
    </div>
  );
}

export default NewInfoLanding01;
