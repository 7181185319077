import React, { useState, useEffect } from "react";
import axios from "axios";
import { connect } from "react-redux";
import { useLocation, useHistory } from "react-router-dom";

import baseDomain, { changePasswordReq } from "../../../constants/apiRoutes";
import { setAlert } from "../../../redux/Alerts/actions";
import settings from "../../../constants/settings";

import "./style.scss";
const RecoverPassword = ({ setAlert }) => {
  const location = useLocation();
  const history = useHistory();
  const query = new URLSearchParams(location.search);
  const [isLoading, setIsLoading] = useState(false);
  const [formData, setFormData] = useState({
    token: query.get("token"),
    password: "",
    confirmPassword: "",
  });
  const { password, confirmPassword } = formData;

  useEffect(() => {
    if (!query.get("token")) history.push("/");
    // eslint-disable-next-line
  }, []);

  const handelSubmit = async e => {
    e.preventDefault();
    setIsLoading(true);
    try {
      if (password !== confirmPassword) {
        setAlert({ message: settings.forget_password_flow.message1, type: "WARNING" });
      } else {
        await axios.post(`${baseDomain}${changePasswordReq}`, formData);
        setAlert({ message: settings.forget_password_flow.message2, type: "SUCCESS" });
        history.push("/login/password");
      }
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      setIsLoading(false);
      if (error.response) setAlert({ message: error.response.data.message, type: "ERROR" });
      else setAlert({ message: settings.forget_password_flow.message3, type: "ERROR" });
    }
  };

  return (
    <div className="recover-password">
      <h1>{settings.forget_password_flow.change_password}</h1>

      <form className="form-inputs" onSubmit={e => handelSubmit(e)}>
        <div className="form-group">
          <label>{settings.forget_password_flow.new_password}</label>
          <input
            type="password"
            placeholder="*******"
            required
            value={password}
            onChange={e => setFormData({ ...formData, password: e.target.value })}
            disabled={isLoading}
          />
        </div>
        <div className="form-group">
          <label>{settings.forget_password_flow.re_enter_password}</label>
          <input
            type="password"
            placeholder="*******"
            required
            value={confirmPassword}
            onChange={e => setFormData({ ...formData, confirmPassword: e.target.value })}
            disabled={isLoading}
          />
        </div>
        <button className="glb-btn submit-btn" type="submit" disabled={isLoading}>
          {settings.forget_password_flow.btnText}
        </button>
      </form>
    </div>
  );
};

export default connect(null, { setAlert })(RecoverPassword);
