import React from "react";
import { Link } from "react-router-dom";
import Loader from "../../../../Loader";

const Step1Integration = ({ stepOne, appHintValue, setAppHint, handleSearch, isLoading, appHintList, setStepOne, loadAppData, store }) => {
  const applicationHint = e => {
    setAppHint(e.target.value);
  };

  const searchHandle = () => {
    handleSearch();
  };

  return (
    <div className="addtest-step1">
      <div>
        <div className="subHeading" style={{ fontSize: "24px", color: "#233137" }}>
          {`Share the application via ${store === "androidCard" ? "Google Play Store" : "Apple Store"}`}
        </div>
        <div className="form">
          <div className="label">Search for the app</div>
          <div className="search-container">
            <input type="text" value={appHintValue} onChange={applicationHint}></input>
            <button className="glb-btn" type="submit" onClick={searchHandle}>
              Search
            </button>
          </div>
          <div className="search-response" id="search-response">
            {isLoading ? (
              <Loader />
            ) : (
              <>
                {appHintList && appHintList.length === 0 && (
                  <>
                    <p>No Results Found</p>
                    <p className="blue-color">
                      <Link className="inherit blue-color" to={`/dashboard/addtests/addtests/manualCard`}>
                        Try our Manual Method
                      </Link>
                    </p>
                  </>
                )}
                {appHintList &&
                  appHintList.map((item, i) => (
                    <div className="list-item" key={i} onClick={() => loadAppData(store === "androidCard" ? item.appId : item.id)}>
                      <img src={item.icon} alt={item.title} />
                      <div className="appName">{item.title}</div>
                    </div>
                  ))}
              </>
            )}
          </div>
          <div className="label">Application Name</div>
          <input
            type="text"
            placeholder="Name of the Application"
            value={stepOne.title}
            onChange={e => setStepOne({ ...stepOne, title: e.target.value })}
            disabled={true}
          ></input>
          <div className="label">Link to your app on App store</div>
          <input
            type="text"
            placeholder="https://play.google.com/store/apps/details?id=com.nexgen.cricview"
            value={stepOne.downloadUrl}
            onChange={e => setStepOne({ ...stepOne, downloadUrl: e.target.value })}
            disabled={true}
          ></input>
        </div>
      </div>
    </div>
  );
};

export default Step1Integration;
