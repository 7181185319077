import React from "react";
import "./style.scss";
import item1 from "../../../assets/icons/126481.png";
import item2 from "../../../assets/icons/1147212.png";
import item3 from "../../../assets/icons/481270.png";
import item4 from "../../../assets/icons/388531.png";
import item5 from "../../../assets/icons/87125.png";
import item6 from "../../../assets/icons/1930814.png";

function QAPackages({ data = {} }) {
  return (
    <div className="QA-testing bg-light">
      <h4 className="QA-testing-heading">{data.heading1}</h4>

      <div className="QA-testing-content">{data.content1}</div>

      <div className="QA-testing-grid">
        <div className="QA-testing-box">
          <img src={item1} alt="icon" />
          <h4 className="QA-box-heading">{data.item1a}</h4>
          <p className="QA-box-content">{data.content1a}</p>
        </div>

        <div className="QA-testing-box">
          <img src={item2} alt="icon" />
          <h4 className="QA-box-heading">{data.item1b}</h4>
          <p className="QA-box-content">{data.content1b}</p>
        </div>

        <div className="QA-testing-box">
          <img src={item3} alt="icon" />
          <h4 className="QA-box-heading">{data.item1c}</h4>
          <p className="QA-box-content">{data.content1c}</p>
        </div>

        <div className="QA-testing-box">
          <img src={item4} alt="icon" />
          <h4 className="QA-box-heading">{data.item1d}</h4>
          <p className="QA-box-content">{data.content1d}</p>
        </div>

        <div className="QA-testing-box">
          <img src={item5} alt="icon" />
          <h4 className="QA-box-heading">{data.item1e}</h4>
          <p className="QA-box-content">{data.content1e}</p>
        </div>

        <div className="QA-testing-box">
          <img src={item6} alt="icon" />
          <h4 className="QA-box-heading">{data.item1f}</h4>
          <p className="QA-box-content">{data.content1f}</p>
        </div>
      </div>
    </div>
  );
}

export default QAPackages;
