import React, { useEffect } from "react";
import { useHistory } from "react-router-dom";
import { connect } from "react-redux";
import { setDashboardRoute, toggleSideNav } from "../../redux/Routing/actions";
import { logoutUser } from "../../redux/auth/actions";
import settings from "../../constants/settings";
import baseDomain,{ testerActiveStatus } from "../../constants/apiRoutes";
import icon from "../../assets/icons/hamburgerSidenav.png";
import telegramIcon2 from "../../assets/icons/telegram(2).png";

import { TesterRoutes, ClientRoutes, AdminRoutes, ReviewerRoutes } from "../../routes";

import deviceAlertIcon from "../../assets/images/DeviceAlert.png";

import "./style.scss";
import axios from "axios";

const DashboardNavigator = ({ setDashboardRoute, dashboardRoute, role, isHaveDevice, toggleSideNav, pendingNotification, logoutUser, token }) => {
  const history = useHistory();
  const handelNav = (id, routePath) => {
    setDashboardRoute(id);
    history.push(routePath);
    toggleSideNav();
  };

  const events = ["mousemove", "scroll", "click", "keypress", "keydown", "load"];
  let time;
  useEffect(() => {
    if (role === "TESTER") {
      for (const event of events) {
        window.addEventListener(event, inActiveTester);
      }
    } else {
      for (const event of events) {
        window.removeEventListener(event, inActiveTester);
      }
    }
    setInterval(async ()=>{
      const date = new Date();
      console.log(date);
      axios.put(`${baseDomain}${testerActiveStatus}/${token}/${date}`)
    },300000);
    // eslint-disable-next-line
  }, [role]);

  const inActiveTester = () => {
    if (role === "TESTER") {
      const timeLimit = 3000;
      clearTimeout(time);
      time = setTimeout(() => {
        logoutUser(token);
      }, [timeLimit * 600]);
    }
  };

  return (
    <div className="dash-nav-comp">
      <div className="nav-heading" onClick={() => toggleSideNav()}>
        <div className="icon">
          <img src={icon} alt="/" />
        </div>
        <div className="nav-heading-text">{settings.dashboard_navigator.heading}</div>
      </div>
      {role === "AGENCY" && (
        <div className="nav-items ">
          {ClientRoutes.map(({ name, routePath, id, iconClass, type, isIconClass = true, icon }, i) => (
            <div className={dashboardRoute === id ? "item active" : type === "focus" ? "item imp" : "item"} key={i} onClick={() => handelNav(id, routePath)}>
              {isIconClass && <div className="nav-icon">{type === "focus" ? <img src={iconClass} alt="*" /> : <i className={iconClass}></i>}</div>}
              {!isIconClass && <div className="nav-icon-svg">{icon}</div>}
              <h3 name={name} routepath={routePath}>
                {name}
              </h3>
              {id === "homeDevices" && !isHaveDevice && (
                <div className="alert-icon">
                  <img width="20px" src={deviceAlertIcon} alt="!" />
                </div>
              )}
            </div>
          ))}
        </div>
      )}
      {role === "TESTER" && (
        <div className="nav-items ">
          {TesterRoutes.map(({ name, routePath, id, iconClass, type, isIconClass = true, icon }, i) => (
            <div className={type === "focus" ? "item imp" : dashboardRoute === id ? "item active" : "item"} key={i} onClick={() => handelNav(id, routePath)}>
              {isIconClass && <div className="nav-icon">{type === "focus" ? <img src={iconClass} alt="*" /> : <i className={iconClass}></i>}</div>}
              {!isIconClass && <div className="nav-icon-svg">{icon}</div>}
              <h3 name={name} routepath={routePath}>
                {name}
              </h3>
              {id === "homeDevices" && !isHaveDevice && (
                <div className="alert-icon">
                  <img width="20px" src={deviceAlertIcon} alt="!" />
                </div>
              )}
              {id === "homeNotifications" && pendingNotification > 0 && (
                <div className="alert-icon">
                  <div className="new-notification">{pendingNotification > 10 ? "10+" : pendingNotification}</div>
                </div>
              )}
            </div>
          ))}
          <button className="telegram-button">
            <img src={telegramIcon2} alt="Tel" width="20px" style={{verticalAlign:"middle"}} />
            <a href="https://t.me/qantily_community_bot" target="_blank" rel="noreferrer opener">
              Join Telegram Community
            </a>
          </button>
        </div>
      )}

      {role === "REVIEWER" && (
        <div className="nav-items ">
          {ReviewerRoutes.map(({ name, routePath, id, iconClass, type, isIconClass = true, icon }, i) => (
            <div className={type === "focus" ? "item imp" : dashboardRoute === id ? "item active" : "item"} key={i} onClick={() => handelNav(id, routePath)}>
              {isIconClass && <div className="nav-icon">{type === "focus" ? <img src={iconClass} alt="*" /> : <i className={iconClass}></i>}</div>}
              {!isIconClass && <div className="nav-icon-svg">{icon}</div>}
              <h3 name={name} routepath={routePath}>
                {name}
              </h3>
              {id === "homeDevices" && !isHaveDevice && (
                <div className="alert-icon">
                  <img width="20px" src={deviceAlertIcon} alt="!" />
                </div>
              )}
              {id === "homeNotifications" && pendingNotification > 0 && (
                <div className="alert-icon">
                  <div className="new-notification">{pendingNotification > 10 ? "10+" : pendingNotification}</div>
                </div>
              )}
            </div>
          ))}
          <button className="telegram-button">
            <img src={telegramIcon2} alt="Tel" width="20px" style={{verticalAlign:"middle"}} />
            <a href="https://t.me/qantily_community_bot" target="_blank" rel="noreferrer opener">
              Join Telegram Community
            </a>
          </button>
        </div>
      )}
      {role === "ADMIN" && (
        <div className="nav-items ">
          {AdminRoutes.map(({ name, routePath, id, iconClass, type, isIconClass = true, icon }, i) => (
            <div className={type === "focus" ? "item imp" : dashboardRoute === id ? "item active" : "item"} key={i} onClick={() => handelNav(id, routePath)}>
              {isIconClass && <div className="nav-icon">{type === "focus" ? <img src={iconClass} alt="*" /> : <i className={iconClass}></i>}</div>}
              {!isIconClass && <div className="nav-icon-svg">{icon}</div>}
              <h3 name={name} routepath={routePath}>
                {name}
              </h3>
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

const mapStateToProps = ({ routeingState, auth }) => ({
  dashboardRoute: routeingState.dashboardCurrentRoute,
  isHaveDevice: auth.user.isHaveDevice,
  pendingNotification: auth.user.pendingNotification,
  // showSideNav: routeingState.showSideNav,
});

export default connect(mapStateToProps, { setDashboardRoute, toggleSideNav, logoutUser })(DashboardNavigator);
