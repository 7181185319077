import React from "react";
import { Link } from "react-router-dom";

import NoDataMessage from "../../NoDataMessage";
import settings from "../../../../constants/settings";

import "../style.scss";

const AllProjectsTakenTable = ({ tableContentArray }) => {
  return (
    <div className="dashboard-table">
      {tableContentArray.map((item, i) => (
        <div className="all-projects-taken-table" key={i}>
          {item.projects.length === 0 ? (
            <NoDataMessage message={settings.all_projects_taken_table.message} />
          ) : (
            <table>
              <tr>
                {settings.all_projects_taken_table.tableHeadingArray.map((head, i) => (
                  <th key={i}>{head}</th>
                ))}
              </tr>
              {tableContentArray[i].projects.map((abc, j) => (
                <tr key={j}>
                  <td className="remove projectName">
                    <Link className="inherit blue-color" to={`/dashboard/tester/${abc.status}/bug/${item.id}`}>
                      {abc.name}
                    </Link>
                  </td>
                  <td>
                    {abc.platform[0].name}
                  </td>
                  {/* <td className="remove">{abc.onGoingHours}</td>
                  <td className="remove">{abc.remainingHours}</td> */}
                </tr>
              ))}
            </table>
          )}
        </div>
      ))}
    </div>
  );
};

export default AllProjectsTakenTable;
