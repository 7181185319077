import React from "react";

import NoDataMessage from "../../NoDataMessage";

import "../style.scss";

const BadgeDetailsTable = ({ tableContentArray }) => {

  const tableHeadingArray = ["Your acheived events", "Score","No. of Occurance"];

  return (
    <div className="dashboard-table badge-details-table">
      {tableContentArray.length === 0 ? (
        <NoDataMessage message="No details found" />
      ) : (
        <table>
          <tr>
            {tableHeadingArray.map((head, i) => (
              <th key={i}>{head}</th>
            ))}
          </tr>
          {tableContentArray.map((item, i) => (
            <tr key={i}>
              <td>{item.event ? item.event : "NA"}</td>
              <td>{item.score ? item.score : "0"}</td>
              <td>{item.instance ? item.instance : "0"}</td>
            </tr>
          ))}
        </table>
      )}
    </div>
  );
}; 

export default BadgeDetailsTable;
