import React, { useEffect } from "react";
import Component1 from "../Solution/Component1";
import Component2 from "../Solution/Component2";
import settings from "../../constants/settings";
import AutoLogin from "../../components/AutoLogin";
import { Helmet } from "react-helmet";

import { withLayout } from "../../layouts";

import "./style.scss";
const Solution = ({ staticSiteNavHandler }) => {
  useEffect(() => {
    staticSiteNavHandler("SOLUTION");
    // eslint-disable-next-line
  }, []);
  return (
    <div className="home-solution">
      <Helmet>
        <meta name="description" content="Crowdsourced testing for Android , iOS" />
      </Helmet>
      <AutoLogin />
      <div className="solution-heading">
        <div className="solution-heading-content">
          <h4 className="solution-heading-highlight text-yellow">{settings.solution.heading}</h4>
          {settings.solution.description}
        </div>
      </div>
      <Component1 data={settings.solution.component1} />
      <Component2 data={settings.solution.component2} />
    </div>
  );
};

export default withLayout(Solution);
