// Tester Auth
export const SIGNUP_TESTER = "SIGNUP_TESTER";
export const SIGNUP_TESTER_SUCCESS = "SIGNUP_TESTER_SUCCESS";
export const SIGNUP_TESTER_ERROR = "SIGNUP_TESTER_ERROR";

// Tester Proceed 
export const SIGNUP_TESTER_PROCEED = "SIGNUP_TESTER_PROCEED";

// Partner Login
export const SIGNUP_CLIENT = "SIGNUP_CLIENT";
export const SIGNUP_CLIENT_SUCCESS = "SIGNUP_CLIENT_SUCCESS";
export const SIGNUP_CLIENT_ERROR = "SIGNUP_CLIENT_ERROR";

// Login User
export const LOGIN_USER = "LOGIN_USER";
export const LOGIN_USER_SUCCESS = "LOGIN_USER_SUCCESS";
export const LOGIN_USER_ERROR = "LOGIN_USER_ERROR";

// Logout User
export const LOGOUT_USER = "LOGOUT_USER";
export const LOGOUT_USER_SUCCESS = "LOGOUT_USER_SUCCESS";
export const LOGOUT_USER_ERROR = "LOGOUT_USER_ERROR";

// User Info
export const USER_INFO = "USER_INFO";
export const USER_INFO_SUCCESS = "USER_INFO_SUCCESS";
export const USER_INFO_ERROR = "USER_INFO_ERROR";

// Get Static Countries
export const GET_STATIC_COUNTRIES = "GET_STATIC_COUNTRIES";
export const GET_STATIC_COUNTRIES_SUCCESS = "GET_STATIC_COUNTRIES_SUCCESS";
export const GET_STATIC_COUNTRIES_ERROR = "GET_STATIC_COUNTRIES_ERROR";

// Get Static States
export const GET_STATIC_STATES = "GET_STATIC_STATES";
export const GET_STATIC_STATES_SUCCESS = "GET_STATIC_STATES_SUCCESS";
export const GET_STATIC_STATES_ERROR = "GET_STATIC_STATES_ERROR";

// Get Static Devices
export const GET_STATIC_DEVICES = "GET_STATIC_DEVICES";
export const GET_STATIC_DEVICES_SUCCESS = "GET_STATIC_DEVICES_SUCCESS";
export const GET_STATIC_DEVICES_ERROR = "GET_STATIC_DEVICES_ERROR";

// Get Static Platforms
export const GET_STATIC_PLATFORMS = "GET_STATIC_PLATFORMS";
export const GET_STATIC_PLATFORMS_SUCCESS = "GET_STATIC_PLATFORMS_SUCCESS";
export const GET_STATIC_PLATFORMS_ERROR = "GET_STATIC_PLATFORMS_ERROR";

// App Routing
export const SET_TESTER_ROUTE = "SET_TESTER_ROUTE";
export const TOGGLE_SIDENAV = "TOGGLE_SIDENAV";
export const TOGGLE_REGISTER_MODAL = "TOGGLE_REGISTER_MODAL";
export const TOGGLE_DECLINE_BUG_MODAL = "TOGGLE_DECLINE_BUG_MODAL";
export const TOGGLE_EDIT_CLIENT_MODAL = "TOGGLE_EDIT_CLIENT_MODAL";
export const TOGGLE_TRANSFER_INITIATED_MODAL = "TOGGLE_TRANSFER_INITIATED_MODAL";
export const ADD_COMMENT_MODAL = "ADD_COMMENT_MODAL";

// Tester Dashboard
// Home
export const GET_TESTER_LEADERBOARD_PROJECTS = "GET_TESTER_LEADERBOARD_PROJECTS";
export const GET_TESTER_LEADERBOARD_PROJECTS_SUCCESS = "GET_TESTER_LEADERBOARD_PROJECTS_SUCCESS";
export const GET_TESTER_LEADERBOARD_PROJECTS_ERROR = "GET_TESTER_LEADERBOARD_PROJECTS_ERROR";
// Leadboard
export const GET_TESTER_LEADERBOARD = "GET_TESTER_LEADERBOARD";
export const GET_TESTER_LEADERBOARD_SUCCESS = "GET_TESTER_LEADERBOARD_SUCCESS";
export const GET_TESTER_LEADERBOARD_ERROR = "GET_TESTER_LEADERBOARD_ERROR";
// Live Projects
export const GET_TESTER_LIVE_PROJECTS = "GET_TESTER_LIVE_PROJECTS";
export const GET_TESTER_LIVE_PROJECTS_SUCCESS = "GET_TESTER_LIVE_PROJECTS_SUCCESS";
export const GET_TESTER_LIVE_PROJECTS_ERROR = "GET_TESTER_LIVE_PROJECTS_ERROR";

// Notifications
export const GET_TESTER_NOTIFICATIONS = "GET_TESTER_NOTIFICATIONS";
export const GET_TESTER_NOTIFICATIONS_SUCCESS = "GET_TESTER_NOTIFICATIONS_SUCCESS";
export const GET_TESTER_NOTIFICATIONS_ERROR = "GET_TESTER_NOTIFICATIONS_ERROR";

// Devices
// Get Devices
export const GET_TESTER_DEVICES = "GET_TESTER_DEVICES";
export const GET_TESTER_DEVICES_SUCCESS = "GET_TESTER_DEVICES_SUCCESS";
export const GET_TESTER_DEVICES_ERROR = "GET_TESTER_DEVICES_ERROR";

// Create Devices
export const CREATE_TESTER_DEVICES = "CREATE_TESTER_DEVICES";
export const CREATE_TESTER_DEVICES_SUCCESS = "CREATE_TESTER_DEVICES_SUCCESS";
export const CREATE_TESTER_DEVICES_ERROR = "CREATE_TESTER_DEVICES_ERROR";

// Delete Devices
export const DELETE_TESTER_DEVICE = "DELETE_TESTER_DEVICE";
export const DELETE_TESTER_DEVICE_SUCCESS = "DELETE_TESTER_DEVICE_SUCCESS";
export const DELETE_TESTER_DEVICE_ERROR = "DELETE_TESTER_DEVICE_ERROR";

// Completed Projects
export const GET_TESTER_COMPLETED_PROJECTS = "GET_TESTER_COMPLETED_PROJECTS";
export const GET_TESTER_COMPLETED_PROJECTS_SUCCESS = "GET_TESTER_COMPLETED_PROJECTS_SUCCESS";
export const GET_TESTER_COMPLETED_PROJECTS_ERROR = "GET_TESTER_COMPLETED_PROJECTS_ERROR";

// Paused Projects
export const GET_TESTER_PAUSED_PROJECTS = "GET_TESTER_PAUSED_PROJECTS";
export const GET_TESTER_PAUSED_PROJECTS_SUCCESS = "GET_TESTER_PAUSED_PROJECTS_SUCCESS";
export const GET_TESTER_PAUSED_PROJECTS_ERROR = "GET_TESTER_PAUSED_PROJECTS_ERROR";

// Project ID
export const GET_TESTER_PROJECT_BY_ID = "GET_TESTER_PROJECT_BY_ID";
export const GET_TESTER_PROJECT_BY_ID_SUCCESS = "GET_TESTER_PROJECT_BY_ID_SUCCESS";
export const GET_TESTER_PROJECT_BY_ID_ERROR = "GET_TESTER_PROJECT_BY_ID_ERROR";

// Project Bugs
export const GET_PROJECT_LISTED_BUGS = "GET_PROJECT_LISTED_BUGS";
export const GET_PROJECT_LISTED_BUGS_SUCCESS = "GET_PROJECT_LISTED_BUGS_SUCCESS";
export const GET_PROJECT_LISTED_BUGS_ERROR = "GET_PROJECT_LISTED_BUGS_ERROR";

export const GET_PROJECT_BUGS_EXPORT = "GET_PROJECT_BUGS_EXPORT";
export const GET_PROJECT_BUGS_EXPORT_SUCCESS = "GET_PROJECT_BUGS_EXPORT_SUCCESS";
export const GET_PROJECT_BUGS_EXPORT_ERROR = "GET_PROJECT_BUGS_EXPORT_ERROR";

// Tester Bugs
export const GET_TESTER_LISTED_BUGS = "GET_TESTER_LISTED_BUGS";
export const GET_TESTER_LISTED_BUGS_SUCCESS = "GET_TESTER_LISTED_BUGS_SUCCESS";
export const GET_TESTER_LISTED_BUGS_ERROR = "GET_TESTER_LISTED_BUGS_ERROR";

// Report New Bug
export const REPORT_NEW_BUG = "REPORT_NEW_BUG";
export const REPORT_NEW_BUG_SUCCESS = "REPORT_NEW_BUG_SUCCESS";
export const REPORT_NEW_BUG_ERROR = "REPORT_NEW_BUG_ERROR";

// Earnings
export const GET_WALLET_EARNING = "GET_WALLET_EARNING";
export const GET_WALLET_EARNING_SUCCESS = "GET_WALLET_EARNING_SUCCESS";
export const GET_WALLET_EARNING_ERROR = "GET_WALLET_EARNING_ERROR";

// Earnings
export const GET_EARNING_GRAPH = "GET_EARNING_GRAPH";
export const GET_EARNING_GRAPH_SUCCESS = "GET_EARNING_GRAPH_SUCCESS";
export const GET_EARNING_GRAPH_ERROR = "GET_EARNING_GRAPH_ERROR";

export const GET_PAID_EARNING = "GET_PAID_EARNING";
export const GET_PAID_EARNING_SUCCESS = "GET_PAID_EARNING_SUCCESS";
export const GET_PAID_EARNING_ERROR = "GET_PAID_EARNING_ERROR";

// Delete Account
export const DELETE_TESTER_ACCOUNT = "DELETE_TESTER_ACCOUNT";
export const DELETE_TESTER_ACCOUNT_SUCCESS = "DELETE_TESTER_ACCOUNT_SUCCESS";
export const DELETE_TESTER_ACCOUNT_ERROR = "DELETE_TESTER_ACCOUNT_ERROR";

// Community States
export const GET_COMMUNITY_STATES = "GET_COMMUNITY_STATES";
export const GET_COMMUNITY_STATES_SUCCESS = "GET_COMMUNITY_STATES_SUCCESS";
export const GET_COMMUNITY_STATES_ERROR = "GET_COMMUNITY_STATES_ERROR";

// Change Password
export const CHANGE_PASSWORD = "CHANGE_PASSWORD";
export const CHANGE_PASSWORD_SUCCESS = "CHANGE_PASSWORD_SUCCESS";
export const CHANGE_PASSWORD_ERROR = "CHANGE_PASSWORD_ERROR";

// Update Profile
export const UPDATE_PROFILE = "UPDATE_PROFILE";
export const UPDATE_PROFILE_SUCCESS = "UPDATE_PROFILE_SUCCESS";
export const UPDATE_PROFILE_ERROR = "UPDATE_PROFILE_ERROR";
export const DELETE_PROFILE_PICTURE = "DELETE_PROFILE_PICTURE";
export const DELETE_PROFILE_PICTURE_SUCCESS = "DELETE_PROFILE_PICTURE_SUCCESS";
export const DELETE_PROFILE_PICTURE_ERROR = "DELETE_PROFILE_PICTURE_ERROR";

// Update Profile Without Image
export const UPDATE_PROFILE_WITHOUT_IMAGE = "UPDATE_PROFILE_WITHOUT_IMAGE";
export const UPDATE_PROFILE_WITHOUT_IMAGE_SUCCESS = "UPDATE_PROFILE_WITHOUT_IMAGE_SUCCESS";
export const UPDATE_PROFILE_WITHOUT_IMAGE_ERROR = "UPDATE_PROFILE_WITHOUT_IMAGE_ERROR";

// Update CLIENT Profile
export const UPDATE_CLIENT_PROFILE = "UPDATE_CLIENT_PROFILE";
export const UPDATE_CLIENT_PROFILE_SUCCESS = "UPDATE_CLIENT_PROFILE_SUCCESS";
export const UPDATE_CLIENT_PROFILE_ERROR = "UPDATE_CLIENT_PROFILE_ERROR";

// Update CLIENT Profile Without Image
export const UPDATE_CLIENT_PROFILE_WITHOUT_IMAGE = "UPDATE_CLIENT_PROFILE_WITHOUT_IMAGE";
export const UPDATE_CLIENT_PROFILE_WITHOUT_IMAGE_SUCCESS = "UPDATE_CLIENT_PROFILE_WITHOUT_IMAGE_SUCCESS";
export const UPDATE_CLIENT_PROFILE_WITHOUT_IMAGE_ERROR = "UPDATE_CLIENT_PROFILE_WITHOUT_IMAGE_ERROR";

// Feedback
export const UPLOAD_FEEDBACK = "UPLOAD_FEEDBACK";
export const UPLOAD_FEEDBACK_SUCCESS = "UPLOAD_FEEDBACK_SUCCESS";
export const UPLOAD_FEEDBACK_ERROR = "UPLOAD_FEEDBACK_ERROR";

//testimonials
export const UPLOAD_TESTIMONIAL = "UPLOAD_TESTIMONIAL";
export const UPLOAD_TESTIMONIAL_SUCCESS = "UPLOAD_TESTIMONIAL_SUCCESS";
export const UPLOAD_TESTIMONIAL_ERROR = "UPLOAD_TESTIMONIAL_ERROR";

// Alerts
export const SET_ALERT = "SET_ALERT";
export const REMOVE_ALERT = "REMOVE_ALERT";

// Client
// App Hint
export const APP_HINT = "APP_HINT";
export const APP_HINT_SUCCESS = "APP_HINT_SUCCESS";
export const APP_HINT_ERROR = "APP_HINT_ERROR";

// App Details
export const APP_DETAILS = "APP_DETAILS";
export const APP_DETAILS_SUCCESS = "APP_DETAILS_SUCCESS";
export const APP_DETAILS_ERROR = "APP_DETAILS_ERROR";

// Add Test Submit 1
export const ADD_TEST_STEP_ONE_SUBMIT = "ADD_TEST_STEP_ONE_SUBMIT";
export const ADD_TEST_STEP_ONE_SUBMIT_SUCCESS = "ADD_TEST_STEP_ONE_SUBMIT_SUCCESS";
export const ADD_TEST_STEP_ONE_SUBMIT_ERROR = "ADD_TEST_STEP_ONE_SUBMIT_ERROR";

// Add Test Submit 2
export const ADD_TEST_STEP_TWO_SUBMIT = "ADD_TEST_STEP_TWO_SUBMIT";
export const ADD_TEST_STEP_TWO_SUBMIT_SUCCESS = "ADD_TEST_STEP_TWO_SUBMIT_SUCCESS";
export const ADD_TEST_STEP_TWO_SUBMIT_ERROR = "ADD_TEST_STEP_TWO_SUBMIT_ERROR";

// Add Test Submit With Store 2
export const ADD_TEST_STEP_TWO_WITH_STORE_SUBMIT = "ADD_TEST_STEP_TWO_WITH_STORE_SUBMIT";
export const ADD_TEST_STEP_TWO_WITH_STORE_SUBMIT_SUCCESS = "ADD_TEST_STEP_TWO_WITH_STORE_SUBMIT_SUCCESS";
export const ADD_TEST_STEP_TWO_WITH_STORE_SUBMIT_ERROR = "ADD_TEST_STEP_TWO_WITH_STORE_SUBMIT_ERROR";

// Add Test Submit 3
export const ADD_TEST_STEP_THREE_SUBMIT = "ADD_TEST_STEP_THREE_SUBMIT";
export const ADD_TEST_STEP_THREE_SUBMIT_SUCCESS = "ADD_TEST_STEP_THREE_SUBMIT_SUCCESS";
export const ADD_TEST_STEP_THREE_SUBMIT_ERROR = "ADD_TEST_STEP_THREE_SUBMIT_ERROR";

// Get payment package
export const GET_PAYMENT_PACKAGE = "GET_PAYMENT_PACKAGE";
export const GET_PAYMENT_PACKAGE_SUCCESS = "GET_PAYMENT_PACKAGE_SUCCESS";
export const GET_PAYMENT_PACKAGE_ERROR = "GET_PAYMENT_PACKAGE_ERROR";

// Get all skills
export const GET_ALL_SKILLS = "GET_ALL_SKILLS";
export const GET_ALL_SKILLS_SUCCESS = "GET_ALL_SKILLS_SUCCESS";
export const GET_ALL_SKILLS_ERROR = "GET_ALL_SKILLS_ERROR";

// Create Tester skill
export const CREATE_TESTER_SKILL = "CREATE_TESTER_SKILL";
export const CREATE_TESTER_SKILL_SUCCESS = "CREATE_TESTER_SKILL_SUCCESS";
export const CREATE_TESTER_SKILL_ERROR = "CREATE_TESTER_SKILL_ERROR";

// Update Project Details
export const UPDATE_PROJECT_DETAILS = "UPDATE_PROJECT_DETAILS";
export const UPDATE_PROJECT_DETAILS_SUCCESS = "UPDATE_PROJECT_DETAILS_SUCCESS";
export const UPDATE_PROJECT_DETAILS_ERROR = "UPDATE_PROJECT_DETAILS_ERROR";

// Get Tester Skills
export const GET_TESTER_SKILLS = "GET_TESTER_SKILLS";
export const GET_TESTER_SKILLS_SUCCESS = "GET_TESTER_SKILLS_SUCCESS";
export const GET_TESTER_SKILLS_ERROR = "GET_TESTER_SKILLS_ERROR";

// Get Single Bug Data
export const GET_SINGLE_BUG_DATA = "GET_SINGLE_BUG_DATA";
export const GET_SINGLE_BUG_DATA_SUCCESS = "GET_SINGLE_BUG_DATA_SUCCESS";
export const GET_SINGLE_BUG_DATA_ERROR = "GET_SINGLE_BUG_DATA_ERROR";

// Get Single Bug Data
export const DELETE_TESTER_SKILL = "DELETE_TESTER_SKILL";
export const DELETE_TESTER_SKILL_SUCCESS = "DELETE_TESTER_SKILL_SUCCESS";
export const DELETE_TESTER_SKILL_ERROR = "DELETE_TESTER_SKILL_ERROR";

// Change IS DEVICE HAVING TRUE
export const SET_IS_DEVICE_HAVING_TRUE = "SET_IS_DEVICE_HAVING_TRUE";

// Change IS NOTIFICATION HAVING TRUE
export const SET_IS_NOTIFICATION = "SET_IS_NOTIFICATION";
// Change IS DEVICE HAVING FALSE
export const SET_IS_DEVICE_HAVING_FALSE = "SET_IS_DEVICE_HAVING_FALSE";

// Clent Dashboard
// On Going Projects
export const GET_CLIENT_ONGOING_PROJECTS = "GET_CLIENT_ONGOING_PROJECTS";
export const GET_CLIENT_ONGOING_PROJECTS_SUCCESS = "GET_CLIENT_ONGOING_PROJECTS_SUCCESS";
export const GET_CLIENT_ONGOING_PROJECTS_ERROR = "GET_CLIENT_ONGOING_PROJECTS_ERROR";

// On Paused Projects
export const GET_CLIENT_PAUSED_PROJECTS = "GET_CLIENT_PAUSED_PROJECTS";
export const GET_CLIENT_PAUSED_PROJECTS_SUCCESS = "GET_CLIENT_PAUSED_PROJECTS_SUCCESS";
export const GET_CLIENT_PAUSED_PROJECTS_ERROR = "GET_CLIENT_PAUSED_PROJECTS_ERROR";

// Completed Projects
export const GET_CLIENT_COMPLETED_PROJECTS = "GET_CLIENT_COMPLETED_PROJECTS";
export const GET_CLIENT_COMPLETED_PROJECTS_SUCCESS = "GET_CLIENT_COMPLETED_PROJECTS_SUCCESS";
export const GET_CLIENT_COMPLETED_PROJECTS_ERROR = "GET_CLIENT_COMPLETED_PROJECTS_ERROR";

// Completed Projects
export const GET_CLIENT_SINGLE_PROJECT = "GET_CLIENT_SINGLE_PROJECT";
export const GET_CLIENT_SINGLE_PROJECT_SUCCESS = "GET_CLIENT_SINGLE_PROJECT_SUCCESS";
export const GET_CLIENT_SINGLE_PROJECT_ERROR = "GET_CLIENT_SINGLE_PROJECT_ERROR";

// Project States
export const GET_CLIENT_PROJECT_STATES = "GET_CLIENT_PROJECT_STATES";
export const GET_CLIENT_PROJECT_STATES_SUCCESS = "GET_CLIENT_PROJECT_STATES_SUCCESS";
export const GET_CLIENT_PROJECT_STATES_ERROR = "GET_CLIENT_PROJECT_STATES_ERROR";

// Admin Dashboard

// All Client
export const GET_ADMIN_ALL_CLIENTS = "GET_ADMIN_ALL_CLIENTS";
export const GET_ADMIN_ALL_CLIENTS_SUCCESS = "GET_ADMIN_ALL_CLIENTS_SUCCESS";
export const GET_ADMIN_ALL_CLIENTS_ERROR = "GET_ADMIN_ALL_CLIENTS_ERROR";

//Active Client
export const GET_ADMIN_ACTIVE_CLIENTS = "GET_ADMIN_ACTIVE_CLIENTS";
export const GET_ADMIN_ACTIVE_CLIENTS_SUCCESS = "GET_ADMIN_ACTIVE_CLIENTS_SUCCESS";
export const GET_ADMIN_ACTIVE_CLIENTS_ERROR = "GET_ADMIN_ACTIVE_CLIENTS_ERROR";

//All Testers
export const GET_ADMIN_ALL_TESTER = "GET_ADMIN_ALL_TESTER";
export const GET_ADMIN_ALL_TESTER_SUCCESS = "GET_ADMIN_ALL_TESTER_SUCCESS";
export const GET_ADMIN_ALL_TESTER_ERROR = "GET_ADMIN_ALL_TESTER_ERROR";

export const GET_ADMIN_TESTERS_EXPORT = "GET_ADMIN_TESTERS_EXPORT";
export const GET_ADMIN_TESTERS_EXPORT_SUCCESS = "GET_ADMIN_TESTERS_EXPORT_SUCCESS";
export const GET_ADMIN_TESTERS_EXPORT_ERROR = "GET_ADMIN_TESTERS_EXPORT_ERROR";

//All Earnings
export const GET_ADMIN_ALL_EARNING = "GET_ADMIN_ALL_EARNING";
export const GET_ADMIN_ALL_EARNING_SUCCESS = "GET_ADMIN_ALL_TESTER_EARNING";
export const GET_ADMIN_ALL_EARNING_ERROR = "GET_ADMIN_ALL_TESTER_EARNING"; 

//Active Testers
export const GET_ADMIN_ACTIVE_TESTER = "GET_ADMIN_ACTIVE_TESTER";
export const GET_ADMIN_ACTIVE_TESTER_SUCCESS = "GET_ADMIN_ACTIVE_TESTER_SUCCESS";
export const GET_ADMIN_ACTIVE_TESTER_ERROR = "GET_ADMIN_ACTIVE_TESTER_ERROR";

// ADMIN DASHBOARD STATS
export const GET_ADMIN_DASHBOARD_SEED = "GET_ADMIN_DASHBOARD_SEED";
export const GET_ADMIN_DASHBOARD_SEED_SUCCESS = "GET_ADMIN_DASHBOARD_SEED_SUCCESS";
export const GET_ADMIN_DASHBOARD_SEED_ERROR = "GET_ADMIN_DASHBOARD_SEED_ERROR";

export const GET_ADMIN_DASHBOARD_STATS = "GET_ADMIN_DASHBOARD_STATS";
export const GET_ADMIN_DASHBOARD_STATS_SUCCESS = "GET_ADMIN_DASHBOARD_STATS_SUCCESS";
export const GET_ADMIN_DASHBOARD_STATS_ERROR = "GET_ADMIN_DASHBOARD_STATS_ERROR";

// AGENCY STATUS CONTROL
export const PUT_AGENCY_ACTIVATE = "PUT_AGENCY_ACTIVATE";
export const PUT_AGENCY_ACTIVATE_SUCCESS = "PUT_AGENCY_ACTIVATE_SUCCESS";
export const PUT_AGENCY_ACTIVATE_ERROR = "PUT_AGENCY_ACTIVATE_ERROR";

export const PUT_AGENCY_DEACTIVATE = "PUT_AGENCY_DEACTIVATE";
export const PUT_AGENCY_DEACTIVATE_SUCCESS = "PUT_AGENCY_DEACTIVATE_SUCCESS";
export const PUT_AGENCY_DEACTIVATE_ERROR = "PUT_AGENCY_DEACTIVATE_ERROR";

// TESTER STATUS CONTROL
export const PUT_TESTER_ACTIVATE = "PUT_TESTER_ACTIVATE";
export const PUT_TESTER_ACTIVATE_SUCCESS = "PUT_TESTER_ACTIVATE_SUCCESS";
export const PUT_TESTER_ACTIVATE_ERROR = "PUT_TESTER_ACTIVATE_ERROR";

export const PUT_TESTER_DEACTIVATE = "PUT_TESTER_DEACTIVATE";
export const PUT_TESTER_DEACTIVATE_SUCCESS = "PUT_TESTER_DEACTIVATE_SUCCESS";
export const PUT_TESTER_DEACTIVATE_ERROR = "PUT_TESTER_DEACTIVATE_ERROR";

// TESTER ALL PROJECTS
export const GET_TESTER_ALL_PROJECTS = "GET_TESTER_ALL_PROJECTS";
export const GET_TESTER_ALL_PROJECTS_SUCCESS = "GET_TESTER_ALL_PROJECTS_SUCCESS";
export const GET_TESTER_ALL_PROJECTS_ERROR = "GET_TESTER_ALL_PROJECTS_ERROR";

// TESTER ALL PROJECTS
export const GET_TESTER_SINGLE_PROJECTS = "GET_TESTER_SINGLE_PROJECTS";
export const GET_TESTER_SINGLE_PROJECTS_SUCCESS = "GET_TESTER_SINGLE_PROJECTS_SUCCESS";
export const GET_TESTER_SINGLE_PROJECTS_ERROR = "GET_TESTER_SINGLE_PROJECTS_ERROR";

// MAKE REVIEWER
export const PUT_TESTER_MAKE_REVIEWER = "PUT_TESTER_MAKE_REVIEWER";
export const PUT_TESTER_MAKE_REVIEWER_SUCCESS = "PUT_TESTER_MAKE_REVIEWER_SUCCESS";
export const PUT_TESTER_MAKE_REVIEWER_ERROR = "PUT_TESTER_MAKE_REVIEWER_ERROR";

// MAKE TESTER
export const PUT_TESTER_MAKE_TESTER = "PUT_TESTER_MAKE_TESTER";
export const PUT_TESTER_MAKE_TESTER_SUCCESS = "PUT_TESTER_MAKE_TESTER_SUCCESS";
export const PUT_TESTER_MAKE_TESTER_ERROR = "PUT_TESTER_MAKE_TESTER_ERROR";

// Tester Bank Details
export const GET_TESTER_BANK_DETAILS = "GET_TESTER_BANK_DETAILS";
export const GET_TESTER_BANK_DETAILS_SUCCESS = "GET_TESTER_BANK_DETAILS_SUCCESS";
export const GET_TESTER_BANK_DETAILS_ERROR = "GET_TESTER_BANK_DETAILS_ERROR";

// Tester Device Details
export const GET_TESTER_DEVICE_DETAILS = "GET_TESTER_DEVICE_DETAILS";
export const GET_TESTER_DEVICE_DETAILS_SUCCESS = "GET_TESTER_DEVICE_DETAILS_SUCCESS";
export const GET_TESTER_DEVICE_DETAILS_ERROR = "GET_TESTER_DEVICE_DETAILS_ERROR";

// Tester Skillset Details
export const GET_TESTER_SKILLSET_DETAILS = "GET_TESTER_SKILLSET_DETAILS";
export const GET_TESTER_SKILLSET_DETAILS_SUCCESS = "GET_TESTER_SKILLSET_DETAILS_SUCCESS";
export const GET_TESTER_SKILLSET_DETAILS_ERROR = "GET_TESTER_SKILLSET_DETAILS_ERROR";

// App Routing
export const TOGGLE_RESET_PASSWORD_MODAL = "TOGGLE_RESET_PASSWORD_MODAL";

// FeedBack Get Req
export const GET_FEEDBACKS = "GET_FEEDBACKS";
export const GET_FEEDBACKS_SUCCESS = "GET_FEEDBACKS_SUCCESS";
export const GET_FEEDBACKS_ERROR = "GET_FEEDBACKS_ERROR";

// Live Projects
export const GET_ADMIN_LIVE_PROJECTS = "GET_ADMIN_LIVE_PROJECTS";
export const GET_ADMIN_LIVE_PROJECTS_SUCCESS = "GET_ADMIN_LIVE_PROJECTS_SUCCESS";
export const GET_ADMIN_LIVE_PROJECTS_ERROR = "GET_ADMIN_LIVE_PROJECTS_ERROR";

// Paused Projects
export const GET_ADMIN_PAUSED_PROJECTS = "GET_ADMIN_PAUSED_PROJECTS";
export const GET_ADMIN_PAUSED_PROJECTS_SUCCESS = "GET_ADMIN_PAUSED_PROJECTS_SUCCESS";
export const GET_ADMIN_PAUSED_PROJECTS_ERROR = "GET_ADMIN_PAUSED_PROJECTS_ERROR";

// Completed Projects
export const GET_ADMIN_COMPLETED_PROJECTS = "GET_ADMIN_COMPLETED_PROJECTS";
export const GET_ADMIN_COMPLETED_PROJECTS_SUCCESS = "GET_ADMIN_COMPLETED_PROJECTS_SUCCESS";
export const GET_ADMIN_COMPLETED_PROJECTS_ERROR = "GET_ADMIN_COMPLETED_PROJECTS_ERROR";

// Invite Tester Api
export const INVITE_TESTER_ADMIN = "INVITE_TESTER_ADMIN";
export const INVITE_TESTER_ADMIN_SUCCESS = "INVITE_TESTER_ADMIN_SUCCESS";
export const INVITE_TESTER_ADMIN_ERROR = "INVITE_TESTER_ADMIN_ERROR";

// selected TesterListList Invite 
export const INVITE_SELECTED_TESTER_ADMIN = "INVITE_SELECTED_TESTER_ADMIN";
export const INVITE_SELECTED_TESTER_ADMIN_SUCCESS = "INVITE_SELECTED_TESTER_ADMIN_SUCCESS";
export const INVITE_SELECTED_TESTER_ADMIN_ERROR = "INVITE_SELECTED_TESTER_ADMIN_ERROR";

// clear selected TesterListList Invite 
export const CLEAR_SELECTED_TESTER_ADMIN = "CLEAR_INVITE_SELECTED_TESTER_ADMIN";


// Invite Modal
export const TOOGLE_INVITE_MODAL = "TOOGLE_INVITE_MODAL";

// Invite particular Tester Api
export const INVITE_PARTICULAR_TESTER_ADMIN = "INVITE_PARTICULAR_TESTER_ADMIN";
export const INVITE_PARTICULAR_TESTER_ADMIN_SUCCESS = "INVITE_PARTICULAR_TESTER_ADMIN_SUCCESS";
export const INVITE_PARTICULAR_TESTER_ADMIN_ERROR = "INVITE_PARTICULAR_TESTER_ADMIN_ERROR";

// Active Navigation Handler
export const STATIC_SITE_NAV_HANDLER = "STATIC_SITE_NAV_HANDLER";
// MARK_AS_COMPLETE
export const PUT_ADMIN_DASHBOARD_MARK_AS_COMPLETE =  "PUT_ADMIN_DASHBOARD_MARK_AS_COMPLETE";
export const PUT_ADMIN_DASHBOARD_MARK_AS_COMPLETE_SUCCESS = "PUT_ADMIN_DASHBOARD_MARK_AS_COMPLETE_SUCCESS";
export const PUT_ADMIN_DASHBOARD_MARK_AS_COMPLETE_ERROR = "PUT_ADMIN_DASHBOARD_MARK_AS_COMPLETE_ERROR";

// CREATE_A_BADGE
export const CREATE_BADGE =  "CREATE_BADGE";
export const CREATE_BADGE_SUCCESS = "CREATE_BADGE_SUCCESS";
export const CREATE_BADGE_ERROR = "CREATE_BADGE_ERROR";

// DELETE_A_BADGE
export const DELETE_BADGE =  "DELETE_BADGE";
export const DELETE_BADGE_SUCCESS = "DELETE_BADGE_SUCCESS";
export const DELETE_BADGE_ERROR = "DELETE_BADGE_ERROR";

// EDIT_A_BADGE
export const EDIT_BADGE =  "EDIT_BADGE";
export const EDIT_BADGE_SUCCESS = "EDIT_BADGE_SUCCESS";
export const EDIT_BADGE_ERROR = "EDIT_BADGE_ERROR";

// EDIT_A_EVENT
export const EDIT_EVENT =  "EDIT_EVENT";
export const EDIT_EVENT_SUCCESS = "EDIT_EVENT_SUCCESS";
export const EDIT_EVENT_ERROR = "EDIT_EVENT_ERROR";

// CREATE_EVENT_A_BADGE
export const CREATE_EVENT_BADGE = "CREATE_EVENT_BADGE";
export const CREATE_EVENT_BADGE_SUCCESS = "CREATE_EVENT_BADGE_SUCCESS";
export const CREATE_EVENT_BADGE_ERROR = "CREATE_EVENT_BADGE_ERROR";

// DELETE_A_EVENT
export const DELETE_EVENT =  "DELETE_EVENT";
export const DELETE_EVENT_SUCCESS = "DELETE_EVENT_SUCCESS";
export const DELETE_EVENT_ERROR = "DELETE_EVENT_ERROR";

//get badges
export const GET_BADGES =  "GET_BADGES";
export const GET_BADGES_SUCCESS = "GET_BADGES_SUCCESS";
export const GET_BADGES_ERROR = "GET_BADGES_ERROR";

//get badges Event
export const GET_EVENT_BADGES =  "GET_EVENT_BADGES";
export const GET_EVENT_BADGES_SUCCESS = "GET_EVENT_BADGES_SUCCESS";
export const GET_EVENT_BADGES_ERROR = "GET_EVENT_BADGES_ERROR";

export  const  GENERATE_REFERRAL_CODE = "GENERATE_REFERRAL_CODE"
export  const  GENERATE_REFERRAL_CODE_SUCCESS = "GENERATE_REFERRAL_CODE_SUCCESS"
export  const  GENERATE_REFERRAL_CODE_ERROR = "GENERATE_REFERRAL_CODE_ERROR"
// Save Draft Project Api
export const DRAFT_PROJECT_CLIENT = "DRAFT_PROJECT_CLIENT";
export const DRAFT_PROJECT_CLIENT_SUCCESS = "DRAFT_PROJECT_CLIENT_SUCCESS";
export const DRAFT_PROJECT_CLIENT_ERROR = "DRAFT_PROJECT_CLIENT_ERROR";

// get social signup url

export const SOCIAL_SIGNUP = "SOCIAL_SIGNUP";
export const SOCIAL_SIGNUP_SUCCESS = "SOCIAL_SIGNUP_SUCCESS";
export const SOCIAL_SIGNUP_ERROR = "SOCIAL_SIGNUP_ERROR";


export const SOCIAL_SIGNUP_CODE_SEND = "SOCIAL_SIGNUP_CODE_SEND"
export const SOCIAL_SIGNUP_CODE_SEND_SUCCESS = "SOCIAL_SIGNUP_CODE_SEND_SUCCESS"
export const SOCIAL_SIGNUP_CODE_SEND_ERROR = "SOCIAL_SIGNUP_CODE_SEND_ERROR"

export const SOCIAL_SIGNUP_SUBMIT = "SOCIAL_SIGNUP_SUBMIT"
export const SOCIAL_SIGNUP_SUBMIT_SUCCESS = "SOCIAL_SIGNUP_SUBMIT_SUCCESS"
export const SOCIAL_SIGNUP_SUBMIT_ERROR = "SOCIAL_SIGNUP_SUBMIT_ERROR"

export const SOCIAL_SIGNIN_GET_URL ="SOCIAL_SIGNIN_GET_URL"
export const SOCIAL_SIGNIN_GET_URL_SUCCESS ="SOCIAL_SIGNIN_GET_URL_SUCCESS"
export const SOCIAL_SIGNIN_GET_URL_ERROR = "SOCIAL_SIGNIN_GET_URL_ERROR"

export const SOCIAL_SIGNIN_CODE_SUBMIT ="SOCIAL_SIGNIN_CODE_SUBMIT"
export const SOCIAL_SIGNIN_CODE_SUBMIT_SUCCESS ="SOCIAL_SIGNIN_CODE_SUBMIT_SUCCESS"
export const SOCIAL_SIGNIN_CODE_SUBMIT_ERROR = "SOCIAL_SIGNIN_CODE_SUBMIT_ERROR"
//Get Testimonials
export const GET_TESTIMONIALS ="GET_TESTIMONIALS";
export const GET_TESTIMONIALS_SUCCESS ="GET_TESTIMONIALS_SUCCESS";
export const GET_TESTIMONIALS_ERROR="GET_TESTIMONIALS_ERROR";

//Approve or Reject or Update TEstimonial
export const APPROVE_TESTIMONIAL ="APPROVE_TESTIMONIAL";
export const APPROVE_TESTIMONIAL_SUCCESS ="APPROVE_TESTIMONIAL_SUCCESS";
export const APPROVE_TESTIMONIAL_ERROR ="APPROVE_TESTIMONIAL_ERROR";
export const REJECT_TESTIMONIAL ="REJECT_TESTIMONIAL";
export const REJECT_TESTIMONIAL_SUCCESS ="REJECT_TESTIMONIAL_SUCCESS";
export const REJECT_TESTIMONIAL_ERROR ="REJECT_TESTIMONIAL_ERROR";
export const UPDATE_TESTIMONIAL ="UPDATE_TESTIMONIAL";
export const UPDATE_TESTIMONIAL_SUCCESS ="UPDATE_TESTIMONIAL_SUCCESS";
export const UPDATE_TESTIMONIAL_ERROR ="UPDATE_TESTIMONIAL_ERROR";

//get finance report data
export const GET_FINANCE_REPORT ="GET_FINANCE_REPORT"; 
export const GET_FINANCE_REPORT_SUCCESS ="GET_FINANCE_REPORT_SUCCESS"; 
export const GET_FINANCE_REPORT_ERROR ="GET_FINANCE_REPORT_ERROR"; 
//Reviewer List
export const GET_REVIEWER = "GET_REVIEWER";
export const GET_REVIEWER_SUCCESS = "GET_REVIEWER_SUCCESS";
export const GET_REVIEWER_ERROR = "GET_REVIEWER_ERROR";

export const ASSIGN_REVIEWER_BUG = "ASSIGN_REVIEWER_BUG";
export const ASSIGN_REVIEWER_BUG_SUCCESS = "ASSIGN_REVIEWER_SUCCESS";
export const ASSIGN_REVIEWER_BUG_ERROR = "ASSIGN_REVIEWER_ERROR";

export const UPDATE_REVIEWER = "UPDATE_REVIEWER";
export const UPDATE_REVIEWER_SUCCESS = "UPDATE_REVIEWER_SUCESS";
export const UPDATE_REVIEWER_ERROR = "UPDATE_REVIEWER_ERROR";

export const GET_ASSIGNED_BUGS = "GET_ASSIGNED_BUGS";
export const GET_ASSIGNED_BUGS_SUCCESS = "GET_ASSIGNED_BUGS_SUCCESS";
export const GET_ASSIGNED_BUGS_ERROR = "GET_ASSIGNED_BUGS_ERROR";

export const ADD_COMMENT = "ADD_COMMENT";
export const ADD_COMMENT_SUCCESS = "ADD_COMMENT_SUCCESS";
export const ADD_COMMENT_ERROR = "ADD_COMMENT_ERROR";

export const GET_COMMENT = "GET_COMMENT";
export const GET_COMMENT_SUCCESS = "GET_COMMENT_SUCCESS";
export const GET_COMMENT_ERROR = "GET_COMMENT_ERROR";



