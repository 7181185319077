import React,{useState} from 'react';
import axios from 'axios';
import { connect } from "react-redux";
import { setAlert } from '../../../redux/Alerts/actions';
import baseDomain,{sendQuery} from "../../../constants/apiRoutes";
import "../style.scss";

const Form = ({setAlert}) => {

    const sendEmail = async (e) => {
        //window.location = "mailto:info@qantily.com";
        e.preventDefault();
        try{
         await axios.post(`${baseDomain}${sendQuery}`,
         {name:name,
          email:email,
          phone:phone,
          message:message});
          setAlert({message:"Thanks for reaching out! we will get back to you soon.",type:"SUCESS"});
          setName("");
          setEmail("");
          setPhone("");
          setMessage("");
        }catch(err){
          setAlert({message:"Sorry couldn't send message! Mail us at info@qantily.com", type:"ERROR"})
        }
        
      };
      const [name, setName] = useState("");
      const [email, setEmail] = useState("");
      const [phone, setPhone] = useState("");
      const [message, setMessage] = useState("");
    

    return (
        <div className="contactUs" >
        <h1 className="text-yellow" >Contact Us</h1>
        <p>We look forward to collaborate with you and find the best testing solutions for your business. Get started today and let our team take care of the rest.</p>
        <div className="actionSection" >
          <div className="contactUsFormContainer" >
            <form className="contactUsForm" onSubmit={sendEmail} >
              <h2>Reach Us</h2>
              <fieldset>
                <label className="contactFormLabel" style={name !== "" ? { opacity: "1" } : { opacity: "0" }} htmlFor="name" >*Name:</label>
                <input name="name" placeholder="*Name" type="text" onChange={e => setName(e.target.value)} value={name} />
              </fieldset>
              <fieldset>
                <label className="contactFormLabel" style={email !== "" ? { opacity: "1" } : { opacity: "0" }} htmlFor="email" >*Email:</label>
                <input name="email" placeholder="*Email" type="email" onChange={e => setEmail(e.target.value)} value={email} />
              </fieldset>
              <fieldset>
                <label className="contactFormLabel" style={phone !== "" ? { opacity: "1" } : { opacity: "0" }} htmlFor="phone" >*Phone:</label>
                <input name="phone" placeholder="*Phone" type="tel" onChange={e => setPhone(e.target.value)} value={phone} />
              </fieldset>
              <fieldset>
                <label className="contactFormLabel" style={message !== "" ? { opacity: "1" } : { opacity: "0" }} htmlFor="message" >*Message:</label>
                <textarea name="message" placeholder="*Message" type="text" onChange={e => setMessage(e.target.value)} value={message} />
              </fieldset>
              <fieldset style={{textAlign:"right"}} >
              <button type="submit" className="glb-btn glb-action-btn" style={{padding:"0.5rem 1rem",fontSize:"18px"}} >Send</button>
              </fieldset>
            </form>
          </div>
          <div className="contactUsDetails" >
            <div className="ourAddress" >
              <p className="infoTitle" >
                <b>Address:</b>
              </p>
              <p className="infoContent">
                <b>HQ</b>: 450 Century Pkwy Suite 250, Allen, TX 75013, USA
                <br />
                <b>India</b>: WeWork, 393, VI-John Tower Phase-3, Udyog Vihar, Gurugram, Haryana 122016
              </p>
            </div>
            <div className="ourAddress" >
              <p className="infoTitle" >
                <b>Email:</b>
              </p>
              <p className="infoContent">
                <a href="mailto:info@qantily.com" >info@qantily.com</a>
              </p>
            </div>
            {/* <div className="ourAddress" >
              <p className="infoTitle" >
                <b>Phone:</b>
              </p>
              <p className="infoContent">
                <a href="tel:+91000000000" >+91-000000000</a> 
              </p>
            </div> */}
          </div>
        </div>
      </div>
    )
}

export default connect(null,{setAlert})(Form);
