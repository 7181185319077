import React from "react";

import "./style.scss";
const PlatformCard = ({image, name, active}) => {

  return (
    <div className={`platform-card ${active}`}>
      <img src={image} alt={name} className="platform-img" />
      <div className="platform-name">{name}</div>
    </div>
  );
};

export default PlatformCard;
