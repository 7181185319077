import React from "react";
import HalfPageBackground from "../../components/Layout/HalfPageBackground";
import { useRouteMatch, Route, Switch, Redirect } from "react-router-dom";

import RequestReset from "../../components/ForgetPasswordFlow/RequestReset";
import RecoverPassword from "../../components/ForgetPasswordFlow/RecoverPassword";
import { withLayout } from "../../layouts";

import "./style.scss";
const ForgotPassword = () => {
  const match = useRouteMatch();
  return (
    <div className="forgot-password-container">
      <HalfPageBackground />
      <div className="forgot-password">
        <Switch>
          <Redirect exact from={`${match.url}/`} to={`${match.url}/requestreset`} />
          <Route path={`${match.url}/requestreset`} exact={true} component={RequestReset} />
          <Route path={`${match.url}/recover`} exact={true} component={RecoverPassword} />
          <Redirect to="/error" />
        </Switch>
      </div>
    </div>
  );
};

export default withLayout(ForgotPassword, true, false);
