import React, { useState } from "react";
// import axios from "axios";

import NoDataMessage from "../../NoDataMessage";
import { CloseSign } from "../../../../assets/icons/icons";
import EditIcon from "../../../../assets/icons/edit.svg";
import AddDeviceModal from "../../../Modals/AddDeviceModal";
import settings from "../../../../constants/settings";
import "../style.scss";

const DevicesTable = ({ tableContentArray, deleteTesterDeviceFun, editDeviceFun, showItem }) => {
  const [showModal, setShowModal] = useState(false);

  return (
    <div className="dashboard-table device-table">
      <AddDeviceModal showModal={showModal} toggleModal={() => setShowModal(!showModal)} />
      {tableContentArray.length === 0 ? (
        <NoDataMessage message={settings.devices_table.message} />
      ) : (
        <table>
          <tr>
            {settings.devices_table.tableHeadingArray.map((head, i) => (
              <th key={i}>{head}</th>
            ))}
            {showItem && (
              <th>Edit</th>
            )}
            {showItem && (
            <th>Remove</th>
            )} 
          </tr>

          {tableContentArray.map((item, i) => {
            if (item) {
              return (
                <tr key={i}>
                  <td>{item.name || "-"}</td>
                  <td className="remove">{(item.extra && item.extra.filter(item => item.key === "browser")[0].value) || "-"}</td>
                  <td className="remove">{item.platform.name || "-"}</td>
                  <td>{(item.extra && item.extra.filter(item => item.key === "os")[0].value) || "-"}</td>
                  <td className="remove">{(item.extra && item.extra.filter(item => item.key === "manufacturer")[0].value) || "-"}</td>
                  <td className="remove">{(item.extra && item.extra.filter(item => item.key === "model")[0].value) || "-"}</td>
                  <td className="remove">{item.isActive ? "Active" : "Pending"}</td>
                  {showItem && (
                  <td className="edit-device" onClick={() => editDeviceFun({ ...item, deviceID: item.id })}>
                    <img src={EditIcon} alt="edit" className="editIcon" />
                  </td> 
                  )} 
                  {showItem && (
                  <td className="remove-device" onClick={() => deleteTesterDeviceFun(item.id)}>
                    {CloseSign}
                  </td> 
                  )} 
                </tr>
              ); 
            } else return null;
          })}
        </table>
      )}
    </div>
  );
};
 
export default DevicesTable;
