import React, { useEffect } from "react";
import { connect } from "react-redux";

import { getTesterLiveProjects, getTesterPausedProjects } from "../../../../../redux/TesterDashboard/actions";

import DashboardHeading from "../../../../Common/DashboardHeading";
import Loader from "../../../../Loader";
//import settings from "../../../../../constants/settings";
import "./style.scss";
import AssignedProjectsTable from "../../../../Common/DashboardTables/AssignedProjectTable";

const AssignedProjects = ({ getTesterLiveProjects,  activeProjects, isLoading, getTesterPausedProjects,pausedProjects }) => {
  useEffect(() => {
    getTesterLiveProjects();
    getTesterPausedProjects();
    // eslint-disable-next-line
  }, []);
  return (
    <div className="client-dash-test-taken">
      <div className="active-tests">
        <DashboardHeading headingStrong={"All Projects"} />
        <br/>
        <h3>Live Projects</h3>
        <div className="cards">{isLoading ? <Loader /> : <AssignedProjectsTable tableContentArray={activeProjects} />}</div>
        <br/>
        <h3>Paused Projects</h3>
        <div className="cards">{isLoading ? <Loader /> : <AssignedProjectsTable tableContentArray={pausedProjects} />}</div>
      </div>
    </div>
  );
};

const mapStateToProps = ({ testerDashboard }) => ({

  activeProjects: testerDashboard.testerLiveProjects,
  pausedProjects: testerDashboard.testerPausedProjects,
  isLoading: testerDashboard.isLoading,
});

export default connect(mapStateToProps, { getTesterLiveProjects, getTesterPausedProjects })(AssignedProjects);
