import React, { useState } from "react";

import { CloseSign, Star } from "../../../../../../assets/icons/icons";
import settings from "../../../../../../constants/settings";

import "./style.scss";
const AddSkillPopUp = ({ showAddSkill, hideShowAddSkill, popUpData: { name, skillId }, createTesterSkill, getTesterSkills }) => {
  const numStars = 5;
  const [rank, setRank] = useState(0);
  const [rankError, setRankError] = useState(false);

  const closeModel = () => {
    hideShowAddSkill();
    setRank(0);
  };

  const submitDetails = async () => {
    if (rank && rank !== 0) {
      await createTesterSkill({
        skillId: skillId,
        ratting: rank,
      });
      await hideShowAddSkill();
      setRank(0);
      setRankError(false);
    } else {
      setRankError(true);
    }
  };

  const handleCloseModal = () => {
    closeModel();
    setRankError(false);
  };

  return (
    <div className={showAddSkill ? "add-skill-popup-container" : "none"}>
      <div className="add-skill-popup">
        <div className="heading">
          <div className="text">{name}</div>
          <div className="close-icon" onClick={handleCloseModal}>
            {CloseSign}
          </div>
        </div>
        <div className="stars">
          {[...Array(numStars)].map((e, i) => (
            <div
              className={rank >= i + 1 ? "single-star selected" : "single-star"}
              key={i}
              onClick={() => {
                setRank(i + 1);
              }}
            >
              {Star}
            </div>
          ))}
        </div>
        {rankError && <div className="rankError">Please rate yourself</div>}
        <div className="submit-btn">
          <button className="glb-btn" onClick={submitDetails}>
            {settings.boostmultiplier.add_skills.btnText4}
          </button>
        </div>
      </div>
    </div>
  );
};

export default AddSkillPopUp;
