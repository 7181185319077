import React from "react";
import NoDataMessage from "../../NoDataMessage";
// import settings from "../../../../constants/settings";

import "../style.scss";

const SkillsetTable = ({ tableContentArray }) => {

  const tableHeadingArray = [ "Skillset", "Rating" ];
  return (
    <div className="dashboard-table tester-pricing-table">
      
      {tableContentArray.length === 0 ? (
        <NoDataMessage message="No Skillset Found" />
      ) : (
        <table>
          <tr>
            {tableHeadingArray.map((head, i) => (
              <th key={i}>{head}</th>
            ))}
          </tr>

          {tableContentArray.map((item, i) => {
            if(item) {
             return (
              <tr key={i}>
                <td>{item.name ? item.name : "-"}</td>
                <td>{item.rating ? item.rating : "-"} / 5</td>
              </tr>
             );
            }else return null;
          })} 
        </table>
      )}  
    </div>
  );
};

export default SkillsetTable;
