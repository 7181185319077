import React, { useState } from "react";
import { connect } from "react-redux";
import axios from "axios";

import { setAlert } from "../../../redux/Alerts/actions";
import baseDomain, { requestResetReq } from "../../../constants/apiRoutes";
import Loader from "../../Loader";
import settings from "../../../constants/settings";

import "./style.scss";
const RequestReset = ({ setAlert }) => {
  const [email, setEmail] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [isMailSent, setIsMailSent] = useState(false);
  const handelSubmit = async e => {
    e.preventDefault();
    setIsLoading(true);
    try {
      await axios.post(`${baseDomain}${requestResetReq}`, { user: email });
      setIsLoading(false);
      setIsMailSent(true);
    } catch (error) {
      setIsLoading(false);
      setIsMailSent(false);
      setAlert({ message: settings.forget_password_flow.request_reset.email_not_found, type: "ERROR" });
    }
  };
  return (
    <div className="request-reset">
      {isLoading ? (
        <Loader />
      ) : (
        <>
          <h1>{isMailSent ? settings.forget_password_flow.request_reset.forgot_password : settings.forget_password_flow.request_reset.forgot_password}</h1>
          {!isMailSent && <div className="message">{settings.forget_password_flow.request_reset.message1}</div>}
          <div className="message-bold">
            {isMailSent ? settings.forget_password_flow.request_reset.message2 : settings.forget_password_flow.request_reset.message3}
          </div>

          <form className="email-input-form" onSubmit={e => handelSubmit(e)}>
            <div className="form-group">
              <label>{settings.forget_password_flow.request_reset.label}</label>
              <input
                type="email"
                placeholder={settings.forget_password_flow.request_reset.placeholder}
                required
                value={email}
                onChange={e => setEmail(e.target.value)}
                disabled={isLoading || isMailSent}
              />
            </div>
            {!isMailSent && (
              <button className="glb-btn submit-btn" type="submit" disabled={isLoading}>
                {settings.forget_password_flow.request_reset.btnText}
              </button>
            )}
          </form>
        </>
      )}
    </div>
  );
};

export default connect(null, { setAlert })(RequestReset);
