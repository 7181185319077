import React from 'react'
import { deleteBin, editBadge } from '../../../../assets/icons/icons'
import './style.scss'

const BadgeEventCard = ({data , handleEdit , deleteEvent}) => {
    return (
        <div className='BadgeCard' id="badgeEventCard" >
          <div className="badgeOptions" style={{width:"100%",textAlign:"right"}} >
              <span className="editBadge" onClick={()=>{handleEdit(data)}} >{editBadge}</span>
              &ensp;
              <span className="deleteBadge" onClick={()=>{deleteEvent(data.id)}}>{deleteBin}</span>
            </div> 
         <div className="eventItems" ><small className="event-card-title" >EVENT</small>
          <h3 className="eventName" >{data.badgeEvent}</h3></div>
        <div className="eventItems"  >
        <small className="event-card-title">SCORE</small>
          <h3 className="eventScore">{data.score}</h3>
        </div>
        { data?.weightage && <div className="eventItems"  >
        <small className="event-card-title">Multiplier</small>
          <h3 className="eventScore">{data.weightage}</h3>
        </div>}
        </div>
    )
}

export default BadgeEventCard
