import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import settings from "../../../constants/settings";

import "./style.scss";
const DeviceDetails = ({ formData, setFormData, handelDeviceSubmittion, platforms, platformName, deviceID }) => {
  const { platformId } = formData;
  const [formMeta, setFormMeta] = useState([]);
  useEffect(() => {
    setFormMeta(platforms.filter(item => item.id === platformId)[0].metadata);
    // eslint-disable-next-line
  }, []);

  const handelChange = e =>
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });

  return (
    <div className="device-details-modal">
      <div className="selected-platform">Selected platform: {platformName}</div>
      <form onSubmit={handelDeviceSubmittion}>
        {formMeta.map((item, i) => (
          <div className="form-group" key={i}>
            <label>{item.name}</label>
            <input type="text" name={item.key} required value={formData[item.key]} onChange={handelChange} placeholder={item.placeHolder} />
          </div>
        ))}
        <div className="form-submit">
          <button className="glb-btn" type="submit">
            {deviceID ? "Update Device" : settings.modals.btnText}
          </button>
        </div>
      </form>
    </div>
  );
};

const mapStateToProps = ({ staticData }) => ({
  platforms: staticData.platforms,
});

export default connect(mapStateToProps, {})(DeviceDetails);
