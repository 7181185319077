import React, { Fragment } from "react";
import TestResourceList from "./TestResourceList";

import ShopingCart from "./ShopingCart";

const Checkout = ({ people }) => {
  const styles = {
    borderRadius: "4px",
    backgroundColor: "rgb(0, 148, 0)",
    padding: "10px 30px",
    width: "max-content",
    margin: "10px auto",
    color: "white",
    fontSize: "19px",
    fontWeight: "600",
    fontStretch: "normal",
  };
  return (
    <Fragment>
      <h3 className="form-heading">Just one step away</h3>
      <div className="checkout">
        <TestResourceList />
        <ShopingCart people={people} />
      </div>
      <div style={styles}>Proceed to Pay</div>
    </Fragment>
  );
};

export default Checkout;
