import { Pie } from "react-chartjs-2";
import React, { useEffect, useState } from "react";

const Piedata = ({ data = [0, 0, 0, 0, 0, 0], data2 }) => {
  const [chartData, setChartData] = useState({});
  const [pieOptions, setPieOptions] = useState({});

  const setPI = () => {
    const { innerWidth: width } = window;
    if (width < 850)
      setPieOptions({
        responsive: true,
        maintainAspectRatio: false,
        legend: {
          position: "right",
          labels: {
            boxWidth: 10,
          },
        },
      });
    else
      setPieOptions({
        responsive: true,
        maintainAspectRatio: true,
        legend: {
          position: "right",
          labels: {
            boxWidth: 10,
            fontSize: 20,
          },
        },
      });
  };

  useEffect(() => {
    setPI();
    const handleResize = () => {
      setPI();
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    setChartData({
      datasets: [
        {
          data: data,
          backgroundColor: ["#00a3ff", "#ffaa00", "#00d4ff", "#ff4900", "#ff7b00", "#58c70e"],
        },
      ],
      labels: data2,
      // legend: {
      //   position: "right",
      //   labels: {
      //     data: data2,
      //     boxWidth: 10,
      //   },
      // },
      hoverBorderWidth: 50,
    });
  }, [data, data2]);

  return (
    <Pie
      data={chartData}
      height={null}
      width={null}
      options={pieOptions}
      // options={{
      //   responsive: true,
      //   maintainAspectRatio: true,
      //   legend: {
      //     position: "right",
      //     labels: {
      //       boxWidth: 10,
      //       fontSize: 20,
      //     },
      //   },
      // }}
    />
  );
};

export default Piedata;
