import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { convertMinutesToHours } from "../../../../../utils/getDateTime";

import { getClientOnGoingProjects, getClientCompletedProjects, getClientPausedProjects,draftClientProjects} from "../../../../../redux/ClientDashboard/actions";
import DashboardHeading from "../../../../Common/DashboardHeading/index";
import ProjectCard from "../../../../Common/ProjectCard";
import Loader from "../../../../Loader";
import NoDataMessage from "../../../../Common/NoDataMessage";

import LeftArrow from "../../../../../assets/icons/leftAro.svg";
import RightArrow from "../../../../../assets/icons/rightAro.svg";

import "./style.scss";
const AllProjects = ({ getClientOnGoingProjects, getClientPausedProjects, getClientCompletedProjects, onGoingProjects, pausedProjects, completedProjects, isLoading, draftClientProjects ,draftProjects}) => {
  useEffect(() => {
    draftClientProjects();
    getClientOnGoingProjects();
    getClientPausedProjects();
    getClientCompletedProjects();
    // eslint-disable-next-line
  }, []); 
  var windowWidth = window.innerWidth;
  const [x, setX] = useState(0);
  const activeProjects = document.getElementsByClassName("client-ongoing-card");
  //let slider = (activeProjects.length <= 4) ? false : true;
  const moveRightArrow = () => {
    if (windowWidth <= 1024) {
      x === -300 * (activeProjects.length - 1) ? setX(-300 * (activeProjects.length - 1)) : setX(x - 300);
    } else if (windowWidth > 1024 && windowWidth <= 1440) {
      x === -300 * (activeProjects.length - 3) ? setX(-300 * (activeProjects.length - 3)) : setX(x - 300);
    } else {
      x === -300 * (activeProjects.length - 4) ? setX(-300 * (activeProjects.length - 4)) : setX(x - 300);
    }
  };
  const moveLeftArrow = () => {
    x === 0 ? setX(0) : setX(x + 300);
  };

  const [a, setA] = useState(0);
  const completedProjectsScroller = document.getElementsByClassName("client-completed-card");

  const moveCompletedRightArrow = () => {
    a === -300 * (completedProjectsScroller.length - 1) ? setA(-300 * (completedProjectsScroller.length - 1)) : setA(a - 300);
  };
  const moveCompletedLeftArrow = () => {
    a === 0 ? setA(0) : setA(a + 300);
  };

  const [b, setB] = useState(0);
  const pausedProjectsScroller = document.getElementsByClassName("client-paused-card");

  const movePausedRightArrow = () => {
    b === -300 * (pausedProjectsScroller.length - 1) ? setB(-300 * (pausedProjectsScroller.length - 1)) : setB(b - 300);
  };
  const movePausedLeftArrow = () => {
    b === 0 ? setB(0) : setB(b + 300);
  };

 

  return (
    <div className="client-dashobard-all-projects">
      
      <DashboardHeading heading={"Ongoing"} headingStrong={"Projects"} showBtn={false} />
      {isLoading ? (
        <Loader />
      ) : (
        <div className="client-dashboard-projects">
          {onGoingProjects && onGoingProjects.length !== 0 ? (
            <>
              <div className={"element left"}>
                <img src={LeftArrow} alt="left-arrow" onClick={moveLeftArrow}></img>
              </div>
              <div className="project-container">
                <div className="client-dashboard-projects-cards" style={{ transform: `translateX(${x}px)` }}>
                  {onGoingProjects.map(({ projectId, icon, name, platform, burnHours, testingHours }, i) => (
                    <ProjectCard
                      projectID={projectId}
                      img={icon}
                      project={name}
                      platformToTest={platform.name}
                      testingOn={`${convertMinutesToHours(parseInt(burnHours))} hours`}
                      testingEnds={`${convertMinutesToHours(parseInt(testingHours) - parseInt(burnHours))} hours`}
                      btnColor="blue"
                      btnText="Monitor Results"
                      className="client-ongoing-card"
                      key={i}
                    />
                  ))}
                </div>
              </div>
              <div className={"element right"} >
                <img src={RightArrow} alt="right-arrow" onClick={moveRightArrow}></img>
              </div>
            </>
          ) : (
            <NoDataMessage message="No Active Project found, add a project" />
          )}
        </div>
      )}


      <DashboardHeading heading={"Projects"} headingStrong={"on hold"} showBtn={false} />
      {isLoading ? (
        <Loader />
      ) : (
        <div className="client-dashboard-projects">
          {pausedProjects && pausedProjects.length !== 0 ? (
            <>
              <div className={"element left"}>
                <img src={LeftArrow} alt="left-arrow" onClick={movePausedLeftArrow}></img>
              </div>
              <div className="project-container">
                <div className="client-dashboard-projects-cards" style={{ transform: `translateX(${x}px)` }}>
                  {pausedProjects.map(({ projectId, icon, name, platform, burnHours, testingHours }, i) => (
                    <ProjectCard
                      projectID={projectId}
                      img={icon}
                      project={name}
                      platformToTest={platform.name}
                      testingOn={`${convertMinutesToHours(parseInt(burnHours))} hours`}
                      testingEnds={`${convertMinutesToHours(parseInt(testingHours) - parseInt(burnHours))} hours`}
                      btnColor="blue"
                      btnText="Monitor Results"
                      className="client-paused-card"
                      key={i}
                    />
                  ))}
                </div>
              </div>
              <div className={"element right"}>
                <img src={RightArrow} alt="right-arrow" onClick={movePausedRightArrow}></img>
              </div>
            </>
          ) : (
            <NoDataMessage message="No Paused Project" />
          )}
        </div>
      )}

      <DashboardHeading heading={"Completed"} headingStrong={"Projects"} showBtn={false} />
      {isLoading ? (
        <Loader />
      ) : (
        <div className="client-dashboard-projects">
          {completedProjects && completedProjects.length !== 0 ? (
            <>
              <div className={"element left"}>
                <img src={LeftArrow} alt="left-arrow" onClick={moveCompletedLeftArrow}></img>
              </div>
              <div className="project-container">
                <div className="client-dashboard-projects-cards" style={{ transform: `translateX(${a}px)` }}>
                  {completedProjects.map(({ projectId, icon, name, burnHours, testingHours }, i) => (
                    <ProjectCard
                      projectID={projectId}
                      img={icon}
                      project={name}
                      // platformToTest={platform.name}
                      testingOn={`${convertMinutesToHours(parseInt(burnHours))} hours`}
                      testingEnds={`${convertMinutesToHours(parseInt(testingHours) - parseInt(burnHours))} hours`}
                      btnColor="green"
                      btnText="Check Results"
                      className="client-completed-card"
                      key={i}
                    />
                  ))}
                </div>
              </div>
              <div className={"element right"}>
                <img src={RightArrow} alt="right-arrow" onClick={moveCompletedRightArrow}></img>
              </div>
            </>
          ) : (
            <NoDataMessage message="No Completed Project" />
          )}
        </div>
      )}
    </div>
  );
};

const mapStateToProps = ({ ClientDashboard }) => ({
  onGoingProjects: ClientDashboard.onGoingProjects,
  pausedProjects: ClientDashboard.pausedProjects,
  draftProjects: ClientDashboard.draftProject,
  isLoading: ClientDashboard.isLoading,
  completedProjects: ClientDashboard.completedProjects,
});

export default connect(mapStateToProps, { getClientOnGoingProjects, getClientPausedProjects, getClientCompletedProjects, draftClientProjects })(AllProjects);
