import {
  ADD_TEST_STEP_ONE_SUBMIT,
  ADD_TEST_STEP_ONE_SUBMIT_SUCCESS,
  ADD_TEST_STEP_ONE_SUBMIT_ERROR,
  ADD_TEST_STEP_TWO_SUBMIT,
  ADD_TEST_STEP_TWO_SUBMIT_SUCCESS,
  ADD_TEST_STEP_TWO_SUBMIT_ERROR,
  ADD_TEST_STEP_THREE_SUBMIT,
  ADD_TEST_STEP_THREE_SUBMIT_SUCCESS,
  ADD_TEST_STEP_THREE_SUBMIT_ERROR,
  GET_PAYMENT_PACKAGE,
  GET_PAYMENT_PACKAGE_SUCCESS,
  GET_PAYMENT_PACKAGE_ERROR,
  ADD_TEST_STEP_TWO_WITH_STORE_SUBMIT,
  ADD_TEST_STEP_TWO_WITH_STORE_SUBMIT_SUCCESS,
  ADD_TEST_STEP_TWO_WITH_STORE_SUBMIT_ERROR,
} from "../actions";

export const addTestStepOneSubmit = payload => ({
  type: ADD_TEST_STEP_ONE_SUBMIT,
  payload,
});
export const addTestStepOneSubmitSuccess = payload => ({
  type: ADD_TEST_STEP_ONE_SUBMIT_SUCCESS,
  payload,
});
export const addTestStepOneSubmitError = payload => ({
  type: ADD_TEST_STEP_ONE_SUBMIT_ERROR,
  payload,
});

export const addTestStepTwoSubmit = (payload, appLogo) => ({
  type: ADD_TEST_STEP_TWO_SUBMIT,
  payload,
  appLogo,
});
export const addTestStepTwoSubmitSuccess = (payload, appLogo) => ({
  type: ADD_TEST_STEP_TWO_SUBMIT_SUCCESS,
  payload,
  appLogo,
});
export const addTestStepTwoSubmitError = (payload) => ({
  type: ADD_TEST_STEP_TWO_SUBMIT_ERROR,
  payload,
});

export const addTestStepTwoWithStoreSubmit = (payload) => ({
  type: ADD_TEST_STEP_TWO_WITH_STORE_SUBMIT,
  payload,
});
export const addTestStepTwoWithStoreSubmitSuccess = payload => ({
  type: ADD_TEST_STEP_TWO_WITH_STORE_SUBMIT_SUCCESS,
  payload,
});
export const addTestStepTwoWithStoreSubmitError = payload => ({
  type: ADD_TEST_STEP_TWO_WITH_STORE_SUBMIT_ERROR,
  payload,
});

export const addTestStepThreeSubmit = (payload, formFiles, history) => ({
  type: ADD_TEST_STEP_THREE_SUBMIT,
  payload,
  formFiles,
  history,
});
export const addTestStepThreeSubmitSuccess = payload => ({
  type: ADD_TEST_STEP_THREE_SUBMIT_SUCCESS,
  payload,
});
export const addTestStepThreeSubmitError = payload => ({
  type: ADD_TEST_STEP_THREE_SUBMIT_ERROR,
  payload,
});

export const getPaymentPackage = () => ({
  type: GET_PAYMENT_PACKAGE,
});
export const getPaymentPackageSuccess = payload => ({
  type: GET_PAYMENT_PACKAGE_SUCCESS,
  payload,
});
export const getPaymentPackageError = payload => ({
  type: GET_PAYMENT_PACKAGE_ERROR,
  payload,
});
