import React from "react";
import { withLayout } from "../../../layouts";
import { useHistory } from "react-router-dom";

import Failed from "../../../assets/icons/failPayment.svg";
import settings from "../../../constants/settings";

import "../style.scss";

const PaymentFailed = () => {
  const history = useHistory();
  return (
    <div className="payment-container">
      <div className="payment-status">
        <div className="img">
          <img src={Failed} alt="Failed" />
        </div>
        <div className="message">{settings.payment_status.payment_failed.message}</div>
        <div className="continew-btn error" onClick={() => history.push("/dashboard")}>
          {settings.payment_status.payment_failed.btnText}
        </div>
      </div>
    </div>
  );
};

export default withLayout(PaymentFailed, true, false);
