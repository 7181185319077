import React from "react";
import "./style.scss";

const Dropdown = ({ placeholder, OptionListArray }) => {
  return (
    <div className="dropdown">
      <label>{placeholder}</label>
      <select>
        {OptionListArray.map((item, i) => (
          <option className="option" value={i} key={i}>
            {item.name}
          </option>
        ))}
      </select>
    </div>
  );
};

export default Dropdown;
