import React,{useEffect} from 'react'
import { connect } from 'react-redux';
import { getTestimonials} from "../../../redux/AdminDashboard/actions";
import Testimonial from "./Component/index";

import "./style.scss"

const ManageTestimonials = ({getTestimonials,testimonials}) => {

    useEffect(()=>{
        getTestimonials();
        // eslint-disable-next-line
       },[]);

       let pendindTestimonials = [];
       pendindTestimonials = testimonials && testimonials.length > 0 ? testimonials.filter(item => item.isApproved !== true && item.isRejected !== true) : []

       let approvedTestimonials =[];
       approvedTestimonials = testimonials && testimonials.length > 0 ? testimonials.filter(item=> item.isApproved === true && item.isRejected !== true) : []

       let rejectedTestimonials =[];
       rejectedTestimonials = testimonials && testimonials.length > 0 ? testimonials.filter(item=> item.isApproved !== true && item.isRejected === true) : []


    return (
        <div className="manageTestimonials" >
            <div className="approvedTestimonials" >
                <h2 style={{width:'100%'}} >Approved Testimonials</h2>
                {
                     approvedTestimonials.length!==0 ?
                    approvedTestimonials.map((item)=>(
                        <Testimonial 
                        key={item.testimonialId} 
                        testimonialId={item.testimonialId}
                        name={item.name} 
                        profilePic={item.profilePic} 
                        testimonial={item.testimonial}
                        approved={item.isApproved}
                        rejected={item.isRejected}
                        />
                    )) : (<h1 style={{width:"100%",color:"#bbb",textAlign:"center"}} >No Approved Testimonials</h1>)
                }
            </div>
            <div className="pendingTestimonials" >
               <h2 style={{width:'100%'}} >Pending Testimonials</h2>
               {
               pendindTestimonials.length !== 0 ?
               pendindTestimonials.map((item)=>(
                 <Testimonial 
                key={item.testimonialId} 
                testimonialId={item.testimonialId}
                name={item.name} 
                profilePic={item.profilePic} 
                testimonial={item.testimonial}
                approved={item.isApproved}
                rejected={item.isRejected}
                />
               )) : 
               <h1 style={{width:"100%",color:"#bbb",textAlign:"center"}} >No Pending Testimonials</h1>
               }
            </div> 
            
            <div className="rejectedTestimonials" >
               <h2 style={{width:'100%'}} >Rejected Testimonials</h2>
               {
               rejectedTestimonials.length!==0 ?
               rejectedTestimonials.map((item)=>(
                 <Testimonial 
                key={item.testimonialId} 
                testimonialId={item.testimonialId}
                name={item.name} 
                profilePic={item.profilePic} 
                testimonial={item.testimonial}
                approved={item.isApproved}
                rejected={item.isRejected}
                />
               )):(<h1 style={{width:"100%",color:"#bbb",textAlign:"center"}} >No Rejected Testimonials</h1>)
               }
            </div>  
        </div>
    )
}

const mapStateToProps = ({adminDashboard})=>({
    isLoading:adminDashboard.isLoading,
    testimonials:adminDashboard.testimonials,
});

export default connect(mapStateToProps,{getTestimonials})(ManageTestimonials);
