import React from "react";

import "./style.scss";

const ClientPricingCard = ({heading, subheading, price1 }) => {

  return (
    <div>
      <div className="pricing-container">
        <div className="pricing-card">
          <div className="pricing-card-heading">{heading}</div>
          <div className="pricing-card-subHeading">{subheading}</div>
          <div className="pricing-card-flex">
            <div className="pricing-card-flex-heading">
              <div className="devices">Number of Devices</div>
              <div className="price">Price</div>
            </div>
            <div className="pricing-card-flex-row">
              <div className="devices">1-35</div>
              <div className="price">{price1}</div>
            </div> 
          </div>
        </div>
      </div>
    </div>
  );
};

export default ClientPricingCard;
