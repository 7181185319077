import React from "react";

import Loader from "../../../../Loader";

import "./style.scss";
const PaymentProcessingPopUp = () => {
  return (
    <div className="payment-processing-popup-container">
      <div className="payment-processing-popup">
        <div className="heading">Redirecting you to payment gateway</div>
        <div className="message">Please don't refresh or press back, while redirecting you to payment page, please wait</div>
        <Loader />
      </div>
    </div>
  );
};

export default PaymentProcessingPopUp;
