import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
// import { useHistory } from "react-router-dom";

import { setAlert } from "../../../../../redux/Alerts/actions";
import { agencyActivate, agencyDeactivate, putAdminDashboardMarkAsComplete } from "../../../../../redux/AdminDashboard/actions";
import NoDataMessage from "../../../../Common/NoDataMessage";
import "./style.scss";
//import { platform } from "chart.js";
const ClientAccordian = ({ agencyActivate, agencyDeactivate, tablecontentarray, putAdminDashboardMarkAsComplete, setAlert }) => {
  // const history = useHistory();
  const [showDetails1, setShowDetails1] = useState(false);
  const [statusState, setStatusState] = useState();

  useEffect(() => {
    setStatusState(tablecontentarray.active);
  }, [tablecontentarray]);

  const handleComplete = projectId => {
    putAdminDashboardMarkAsComplete(projectId);
    setTimeout(() => {
      window.location.reload();
    }, 3000);
  };
  const handleChange = (status, id) => {
    status ? agencyDeactivate(id) : agencyActivate(id);
    status ? setStatusState(false) : setStatusState(true);
    // history.push("/dashboard/clients");
    // getAdminAllClients();
  };
  const showDetails = () => {
    setShowDetails1(!showDetails1);
  };

  const UpArrow = ({ color }) => (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill={color} width="36px" height="36px">
      <path d="M0 0h24v24H0z" fill="none" />
      <path d="M12 8l-6 6 1.41 1.41L12 10.83l4.59 4.58L18 14z" />
    </svg>
  );
  const DownArrow = ({ color }) => (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill={color} width="36px" height="36px">
      <path d="M0 0h24v24H0z" fill="none" />
      <path d="M16.59 8.59L12 13.17 7.41 8.59 6 10l6 6 6-6z" />
    </svg>
  );
  return (
    <div className="dashboard-table">
      <div className="clients-mainAccordian">
        <div className="clients-list-item">
          <div className="clients-list-item-title">
            <div className="clients-accordian-header">
              <div className="clients-accordian-client-details">
                <h1 className="clients-accordian-header-name">{tablecontentarray.clientName}</h1>
                <div className="clients-accordian-client-website">{tablecontentarray.website}</div>
              </div>
              <div className="clients-accordian-header-active">
                <div className={`clients-accordian-header-status ${statusState ? "Active" : "Inactive"}`}></div>
                <h4 className="accordian-client-status">{statusState ? "Active" : "Inactive"}</h4>
                <div className="change-status" onClick={() => handleChange(statusState, tablecontentarray.id)}>
                  Change Status
                </div>
              </div>
            </div>
            <span onClick={showDetails} className="accordion-toggle">
              {showDetails1 ? <UpArrow color="#123dfb" /> : <DownArrow color="#123dfb" />}
            </span>
          </div>
          {showDetails1 && (
            <div className="accordianContent">
              <div className="row">
                {tablecontentarray.projects.length === 0 ? (
                  <NoDataMessage message={"No Projects"} />
                ) : (
                  <table>
                    <tr>
                      <th style={{ width: "25%" }}>Project Details</th>
                      <th>Platform</th>
                      <th>Amount Spent</th>
                      <th>Reported Bugs</th>
                      <th>Date</th>
                      <th>Status</th>
                      <th>Mark as complete</th>
                    </tr>
                    {tablecontentarray.projects.map((abc, j) => (
                      <tr key={j}>
                        <td>{abc.name}</td>
                        <td>
                          {abc.platform.map(platform => {
                            return platform.name;
                          })}
                        </td>
                        <td>{abc.amountSpent !== 0 ? abc.amountSpent : "-"}</td>
                        <td>{abc.bugtReported !== 0 ? abc.bugtReported : "-"}</td>
                        <td>{abc.date.split("T")[0]}</td>
                        <td className={abc.status}>{abc.status ? 
                        abc.status==="PUBLISH" ? "LIVE" : abc.status 
                        : "-"}</td>
                        <td className="mark-as-complete" style={{ cursor: "pointer" }}>
                          <button onClick={() => handleComplete(abc.projectId)} disabled={!(abc.status === "PUBLISH" || abc.status === "PAUSED")}>
                            Complete
                          </button>
                        </td>
                      </tr>
                    ))}
                  </table>
                )}
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default connect(null, { agencyActivate, agencyDeactivate, putAdminDashboardMarkAsComplete, setAlert })(ClientAccordian);
