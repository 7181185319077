import React, { useEffect } from "react";
import { withLayout } from "../../layouts";
import AutoLogin from "../../components/AutoLogin";
import { Helmet } from "react-helmet";
import HalfPageBackground from "../../components/Layout/HalfPageBackground";
import DeviceFarmComp from "../../components/DeviceFarm";

import "./style.scss";
const DeviceFarm = ({ staticSiteNavHandler }) => {
  useEffect(() => {
    staticSiteNavHandler("DEVICEFARM");
    // eslint-disable-next-line
  }, []);

  return (
    <div className="device-farm">
      <Helmet>
        <meta name="description" content="Exhaustive range of real Android, iOS devices " />
      </Helmet>
      <AutoLogin />
      <HalfPageBackground />
      <DeviceFarmComp />
    </div>
  );
};

export default withLayout(DeviceFarm, true, false);
