import React, { useEffect } from "react";
import { connect } from "react-redux";
import settings from "../../../constants/settings";

import { setDashboardRoute } from "../../../redux/Routing/actions";
import ClientPricingCard from "./components";
import DashboardHeading from "../../Common/DashboardHeading";
import "./style.scss";
const ClientPricing = ({ setDashboardRoute, componentID }) => {
  useEffect(() => {
    setDashboardRoute(componentID);
    // eslint-disable-next-line
  }, []);

  return (
    <div className="client-pricing-container">
      <DashboardHeading headingStrong={settings.client_pricing.heading} showBtn={false} />
      <div className="cards-container-pricing">
        <ClientPricingCard 
          heading={"High App Complexity Price"} 
          subheading={"20+ Screen / Dailog"} 
          price1={"$2000"}  
        />
        <ClientPricingCard 
          heading={"Medium App Complexity Price"} 
          subheading={"7-15 Screen / Dailog"} 
          price1={"$1500"}  
        />
        <ClientPricingCard 
          heading={"Low App Complexity Price"} 
          subheading={"1-10 Screen / Dailog"} 
          price1={"$1000"}  
        /> 
      </div>
    </div>
  );
};

export default connect(null, { setDashboardRoute })(ClientPricing);
