import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import { connect } from "react-redux";
import axios from "axios";
import { useHistory } from "react-router";

//import { convertMinutesToHours } from "../../../../../utils/getDateTime";
import {
  getClientSingleProject,
  getClientProjectStates,
  getClientOnGoingProjects,
  getClientCompletedProjects,
} from "../../../../../redux/ClientDashboard/actions";
import { putAdminDashboardMarkAsComplete } from "../../../../../redux/AdminDashboard/actions";
import { setAlert } from "../../../../../redux/Alerts/actions";

import baseDomain, { makeProjectActiveReq, makeProjectPauseReq, makeProjectCancelReq } from "../../../../../constants/apiRoutes";
import Loader from "../../../../Loader";
import BugsDetectedTable from "../../../../Common/DashboardTables/BugsDetectedTable/index";
import Piedata from "../../../../Common/Piedata/Piedata";
import EditProjectModal from "../../../../Modals/EditProject";
import { toggleEditClientModal } from "../../../../../redux/Routing/actions";

import { useAlertConfirmation } from "../../../../../Services/AlertService";
import settings from "../../../../../constants/settings";
import pause from "../../../../../assets/icons/pause.png";
import cancel from "../../../../../assets/icons/cancel.png";
import EditIcon from "../../../../../assets/icons/editIcon.svg";
import "./style.scss";

const ProjectDetails = ({
  getClientSingleProject,
  getClientProjectStates,
  currentProject,
  setAlert,
  isLoading,
  projectStates,
  showEditClientModal,
  toggleEditClientModal,
  putAdminDashboardMarkAsComplete,
  getClientOnGoingProjects,
  getClientCompletedProjects,
}) => {
  const { projectID } = useParams();

  const history = useHistory();

  const fallBackAlert = useAlertConfirmation();

  useEffect(() => {
    getClientSingleProject(projectID);
    getClientProjectStates(projectID);
    // eslint-disable-next-line
  }, []);

  const toogleClientModalEdit = () => {
    toggleEditClientModal();
  };

  const handlePausePlay = async () => {
    if (currentProject.status === "PUBLISH") {
      try {
        await axios.put(`${baseDomain}${makeProjectPauseReq}/${projectID}`);
        setAlert({
          message: "Project Paused Successfully",
          type: "SUCCESS",
        });
        await getClientSingleProject(projectID);
        await getClientProjectStates(projectID);
        history.push("/dashboard/home");
      } catch (error) {
        setAlert({
          message: "Error while pausing project",
          type: "ERROR",
        });
      }
    } else {
      try {
        await axios.put(`${baseDomain}${makeProjectActiveReq}/${projectID}`);
        setAlert({
          message: "Project Activated Successfully",
          type: "SUCCESS",
        });
        await getClientSingleProject(projectID);
        await getClientProjectStates(projectID);
      } catch (error) {
        setAlert({
          message: "Error while Activating project",
          type: "ERROR",
        });
      }
    }
  };

  const handleComplete = () => {
    putAdminDashboardMarkAsComplete(projectID);
    getClientOnGoingProjects();
    getClientCompletedProjects();
    history.push(`/dashboard/home`);
  };

  const handleCancel = async () => {
    try {
      await fallBackAlert({
        message: "Are you sure you want to cancel this project",
        type: "ERROR",
        successText: "Yes",
        cancelText: "No",
      });
      await axios.put(`${baseDomain}${makeProjectCancelReq}/${projectID}`);
      setAlert({
        message: "Project Canceled Successfully",
        type: "SUCCESS",
      });
      await getClientSingleProject(projectID);
      await getClientProjectStates(projectID);
      history.push(`/dashboard/home`);
    } catch (error) {
      setAlert({
        message: "Error while cancelling project",
        type: "ERROR",
      });
    }
  };

  return (
    <div className="client-side-home">
      {isLoading ? (
        <Loader />
      ) : (
        <>
          <EditProjectModal showModal={showEditClientModal} toggleModal={toogleClientModalEdit} data={currentProject || {}} />
          <div className="overall-header">
            <div className="client-home-header">
              <div className="heading-client-home">
                {currentProject && currentProject.name}&nbsp;
                <strong> {currentProject && currentProject.platform && currentProject.platform.name}</strong>
                {currentProject && currentProject.platform && currentProject.platform.icon && (
                  <div className="platform-logo">
                    <img src={currentProject.platform.icon.gray} alt="logo" />
                  </div>
                )}
              </div>
              <div className="project-status">
                <div className="label">Project Status</div>
                <div className={"project-button pointer"}>
                  <button className={`glb-btn btn ${currentProject && currentProject.status}`}>{currentProject && currentProject.status === "PUBLISH" ? "LIVE" : currentProject.status }</button>
                </div>
              </div>
            </div>
            <div className="project-actions">
              {currentProject && (
                <div className={"project-button"}>
                  <button className={"btn EDIT"} onClick={toogleClientModalEdit}>
                    <img src={EditIcon} alt="icon" className="btn-icon"></img>
                    Edit
                  </button>
                </div>
              )}

              {currentProject.status !== "COMPLETED" && (
                <>
                  <div className={"project-button"}>
                    <button className={"btn MARK c"} onClick={handleComplete}>
                      Mark as Complete
                    </button>
                  </div>
                  <div className={"project-button"}>
                    <button className={"btn PAUSED"} onClick={handlePausePlay}>
                      <img src={pause} alt="icon" className="btn-icon"></img>
                      {currentProject.status === "PUBLISH" ? "Pause" : "Resume"}
                    </button>
                  </div>

                  <div className={"project-button"}>
                    <button className={"btn CANCELED"} onClick={handleCancel}>
                      <img src={cancel} alt="icon" className="btn-icon"></img>
                      Cancel project
                    </button>
                  </div>
                </>
              )}
            </div>
          </div>
          <div className="client-home-pie-data">
            <div className="client-home-pie" style={{ gridArea: "pi-chart" }}>
              <Piedata
                data={[
                  projectStates.repeatedPer || 0,
                  projectStates.criticalPer || 0,
                  projectStates.minorBugPer || 0,
                  projectStates.majorBugPer || 0,
                  projectStates.crashPer || 0,
                  projectStates.lowPer || 0,
                ]}
                data2={[
                  `${settings.client_home.pie_data.label1} (${
                    isNaN(projectStates.repeatedPer) || projectStates.repeatedPer === 0 ? 0 : parseInt(projectStates.repeatedPer).toFixed(2)
                  }%)`,
                  `${settings.client_home.pie_data.label2} (${
                    isNaN(projectStates.criticalPer) || projectStates.criticalPer === 0 ? 0 : parseInt(projectStates.criticalPer).toFixed(2)
                  }%)`,
                  `${settings.client_home.pie_data.label3} (${
                    isNaN(projectStates.minorBugPer) || projectStates.minorBugPer === 0 ? 0 : parseInt(projectStates.minorBugPer).toFixed(2)
                  }%)`,
                  `${settings.client_home.pie_data.label4} (${
                    isNaN(projectStates.majorBugPer) || projectStates.majorBugPer === 0 ? 0 : parseInt(projectStates.majorBugPer).toFixed(2)
                  }%)`,
                  `${settings.client_home.pie_data.label5} (${
                    isNaN(projectStates.crashPer) || projectStates.crashPer === 0 ? 0 : parseInt(projectStates.crashPer).toFixed(2)
                  }%)`,
                  `Low Priority (${isNaN(projectStates.lowPer) || projectStates.lowPer === 0 ? 0 : parseInt(projectStates.lowPer).toFixed(2)}%)`,
                ]}
              />
            </div>
            {/* <div className="test-data" style={{ gridArea: "used-hours" }}>
              <div className="label">{settings.client_home.pie_data.label7}</div>
              <div className="data">{`${convertMinutesToHours(parseInt(currentProject.burnHours))} hours`}</div>
            </div>
            <div className="test-data" style={{ gridArea: "left-hours" }}>
              <div className="label">{settings.client_home.pie_data.label8}</div>
              <div className="data">{`${convertMinutesToHours(parseInt(currentProject.testingHours) - parseInt(currentProject.burnHours))} hours`}</div>
            </div> */}
            <div className="test-data" style={{ gridArea: "total-bugs" }}>
              <div className="label">{settings.client_home.pie_data.label9}</div>
              <div className="data">{currentProject.bugReported}</div>
            </div>
          </div>
          <div className="client-home-bug-table">
            <div className="client-home-bugs-heading">
              <div className="heading-client-home">
                {settings.client_home.heading2.light}&nbsp;
                <strong>{settings.client_home.heading2.bold}</strong>
              </div>
            </div>
            <BugsDetectedTable bugsdetectedcontent={projectStates.bugs} projectID={projectID} />
          </div>
        </>
      )}
    </div>
  );
};

const mapStateToProps = ({ ClientDashboard, routeingState }) => ({
  currentProject: ClientDashboard.currentProject,
  projectStates: ClientDashboard.projectStates,
  isLoading: ClientDashboard.isLoading,
  showEditClientModal: routeingState.showEditClientModal,
});

export default connect(mapStateToProps, {
  getClientSingleProject,
  getClientProjectStates,
  setAlert,
  toggleEditClientModal,
  putAdminDashboardMarkAsComplete,
  getClientOnGoingProjects,
  getClientCompletedProjects,
})(ProjectDetails);
