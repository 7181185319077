import React, { useState, useEffect } from "react";
import { useParams, useHistory } from "react-router-dom";
import { connect } from "react-redux";

import { reportNewBug, getTesterDevices } from "../../../../../redux/TesterDashboard/actions";

import DashboardHeading from "../../../../Common/DashboardHeading";
import Loader from "../../../../Loader";
import settings from "../../../../../constants/settings";

import "./style.scss";

const ReportBugForm = ({ reportNewBug, isLoading, selectedProject, getTesterDevices, devices }) => {
  const { projectID } = useParams();
  const history = useHistory();

  const [attachment, setAttachment] = useState(null);
  const [currentDevice, setCurrentDevice] = useState(devices);
  const [form, setForm] = useState({
    projectId: projectID,
    summary: "",
    component: "",
    priority: "LOW",
    device: {},
    deviceId: currentDevice,
    description: "",
  });
  const { summary, component, priority, description, deviceId } = form;

  useEffect(() => {
    getTesterDevices();
    // eslint-disable-next-line
  }, []);

  const setInput = e => {
    setCurrentDevice(devices.filter(item => item.id === e.target.value));
    setForm({
      ...form,
      [e.target.name]: e.target.value,
    });
  };

  const [disabledBtn, setDisabledBtn] = useState(false);
  const handleSubmit = e => {
    e.preventDefault();
    setDisabledBtn(true);
    reportNewBug({ ...form, device: devices.find(item => item.id === deviceId) }, attachment, history);
  };

  return (
    <div className="FormFillUp">
      <form onSubmit={handleSubmit}>
        <DashboardHeading heading={selectedProject.name} headingStrong={settings.report_bug_form.heading} showBtn={false} />
        <p className="FormFillUp-subheading">
          {settings.report_bug_form.reportbug.light} <span className="FormFillUp-subheading-textstyle1">{settings.report_bug_form.reportbug.bold}</span>
        </p>
        <button
          className="FormFillUp-backbtn"
          onClick={e => {
            e.preventDefault();
            history.push(`/dashboard/test-taken/projects/active/${projectID}`);
          }}
        >
          &laquo; {settings.report_bug_form.back_btn}
        </button>
        <div className="inputbox-wrapper">
          <p className="inputbox-heading">{settings.report_bug_form.Summary}</p>
          <input
            className="inputbox"
            type="text"
            placeholder={settings.report_bug_form.placeholder1}
            name="summary"
            value={summary}
            onChange={e => setInput(e)}
            required
          />
        </div>
        <div className="inputbox-wrapper">
          <p className="inputbox-heading">{settings.report_bug_form.Component}</p>
          <input
            className="inputbox"
            type="text"
            placeholder={settings.report_bug_form.placeholder2}
            name="component"
            value={component}
            onChange={e => setInput(e)}
            required
          />
        </div>

        <div className="dropbox-wrapper">
          <p className="dropbox-heading">{settings.report_bug_form.priority}</p>
          <select className="dropbox" name="priority" value={priority} onChange={e => setInput(e)} required>
            <option value="CRASH">{settings.report_bug_form.priority_option1}</option>
            <option value="BLOCKER">{settings.report_bug_form.priority_option2}</option>
            <option value="CRITICAL">{settings.report_bug_form.priority_option3}</option>
            <option value="MAJOR">{settings.report_bug_form.priority_option4}</option>
            <option value="MINOR">{settings.report_bug_form.priority_option5}</option>
            <option value="REPEATED">{settings.report_bug_form.priority_option6}</option>
            <option value="LOW">{settings.report_bug_form.priority_option7}</option>
          </select>
        </div>

        <div className="dropbox-wrapper">
          <p className="dropbox-heading">Device Used For Testing*</p>
          <select className="dropbox" id="deviceList" value={deviceId} onChange={e => setInput(e)} name="deviceId" disabled={devices.length === 0} required>
            <option value="">Choose Device</option>
            {devices &&
              devices.map((dev, i) => (
                <option value={dev.id} key={i}>
                  {dev.name}
                </option>
              ))}
          </select>
        </div>

        <div className="attach-file">
          <div className="label">{settings.report_bug_form.attach_file}</div>
          <label htmlFor="attach">{attachment ? attachment.name : settings.report_bug_form.screenshots}</label>
          <input type="file" name="attach" id="attach" onChange={e => setAttachment(e.target.files[0])} />
        </div>

        <div>
          <p className="inputbox-heading">{settings.report_bug_form.bug}</p>
          <textarea
            className="FormFillUp-textarea"
            row="6"
            col="50"
            required
            placeholder={settings.report_bug_form.placeholder3}
            value={description}
            name="description"
            onChange={e => setInput(e)}
          />
        </div>

        {isLoading ? (
          <Loader />
        ) : (
          <>
            <button
              type="submit"
              className="FormFillUp-submitbtn red"
              onClick={e => {
                e.preventDefault();
                history.push(`/dashboard/test-taken/projects/active/${projectID}`);
              }}
            >
              {"Cancel"}
            </button>
            <button className="FormFillUp-submitbtn green" type="submit" disabled={disabledBtn}>
              {settings.report_bug_form.btnText}
            </button>
          </>
        )}
      </form>
    </div>
  );
};
const mapStateToProps = ({ testerDashboard }) => ({
  selectedProject: testerDashboard.testerSelectedProject,
  isLoading: testerDashboard.isLoading,
  devices: testerDashboard.userDevices,
});

export default connect(mapStateToProps, { reportNewBug, getTesterDevices })(ReportBugForm);
