import React, {useEffect} from "react";
import { useParams, useHistory } from "react-router-dom";
import { connect } from "react-redux";
import { getProjectListedBugs, getTesterProjectByID, getProjectBugsExport } from "../../../../../redux/TesterDashboard/actions";

import Loader from "../../../../Loader";
import DashboardHeading from "../../../../Common/DashboardHeading";
import BugListTable from "../../../../Common/DashboardTables/BugListTable";
import settings from "../../../../../constants/settings";

import "./style.scss";

const BugList = ({ getProjectListedBugs, projectBugs, isLoading, selectedProject, getTesterProjectByID, role, getProjectBugsExport }) => {
  const { projectID } = useParams();
  const history = useHistory();
  useEffect(() => {
    getProjectListedBugs(projectID);
    getTesterProjectByID(projectID);
    getProjectBugsExport(projectID);
    // eslint-disable-next-line
  }, []);

  // const [pageNumber,setPageNumber]=useState(1)
  // const buttons = [];

  // const renderPagination = (i=1)=>{
  //   for(i;i<=projectBugs?.[0]?.count;i++){
  //       buttons.push(i);
  //   }
  //   }
  //   renderPagination()
  //   const handlePageChange = (i)=>{
  //     setPageNumber(i);
  //     getProjectListedBugs(projectID,i);
  //   }

  return (
    <div className="text-bug-list">
      {role === "TESTER" && (
        <DashboardHeading
          heading={selectedProject.name}
          headingStrong={settings.bug_list.heading2}
          showBtn={selectedProject.status === "PAUSED" || selectedProject.status === "COMPLETED" ? false : true}
          btnValue={settings.bug_list.btnText}
          btnSize={settings.bug_list.btnSize}
          btnOnClick={() => history.push(`/dashboard/test-taken/projects/active/${projectID}/reportbug`)}
        />
      )}
      {role === "CLIENT" && <DashboardHeading heading={settings.bug_list.heading1.light} headingStrong={settings.bug_list.heading1.bold} />}
      {/* <div className="BackBtnContainer FormFillUp-backbtn"
        onClick={e => {
          e.preventDefault();
          window.history.back()
        }}
      >
        &laquo; {settings.report_bug_form.back_btn}
      </div> */} 
      <div className="card">{isLoading ? <Loader /> : <BugListTable tableContentArray={projectBugs} projectID={projectID} role={role} />}</div>
      {/* <div className="pagination-container" >
      <div
            className="controller element"
            onClick={() => {
              handlePageChange(pageNumber - 1);
            }}
          >
            {"<"}
          </div>
      <div className="pagination">
          {buttons.map((item, idx) => {
            return (
              <p
                className={pageNumber === idx + 1 ? "controller element current" : "controller element"}
                onClick={() => {
                  handlePageChange(item);
                }}
              >
                {item}
              </p>
            );
          })}
        </div>
        <div
            className="controller element"
            onClick={() => {
              handlePageChange(pageNumber+1 <= projectBugs?.[0]?.count ?  pageNumber + 1: pageNumber );
            }}
          >
            {">"}
          </div>
      </div> */}
    </div>
  );
};
const mapStateToProps = ({ testerDashboard }) => ({
  projectBugs: testerDashboard.projectListedBugs,
  allProjectBugs:testerDashboard.allProjectBugs,
  selectedProject: testerDashboard.testerSelectedProject,
  isLoading: testerDashboard.isLoading,
});

export default connect(mapStateToProps, { getProjectListedBugs, getTesterProjectByID, getProjectBugsExport })(BugList);
