import React from "react";
import { useHistory } from "react-router-dom";
import settings from "../../../constants/settings";
import "./style.scss";
const ProjectCard = ({ img, project, platformToTest, testingOn, testingEnds, btnColor, btnText, btnKey, className, projectID }) => {
  const history = useHistory();


  const truncation = desc => {
    let description =  desc.length > 16 ? desc.slice(0, 16).concat("...") : desc;
    return description;
  };

  return (
    <div className={className}>
      <div className="project-img">
        <img src={img?.url || img} alt={project} />
      </div>
      <div className="project-detail">
        <h4>{settings.dashboardactivetests.project}</h4>
        <h2>
          <span>{truncation(project)}</span>
        </h2>
        <h4>{settings.dashboardactivetests.platform}</h4>
        <h2>{platformToTest}</h2>
      </div>
      {/* <div className="testing-detail">
        <h4>{settings.dashboardactivetests.testingon}</h4>
        <h2>{testingOn}</h2>
      </div>
      <div className="testing-detail">
        <h4>{settings.dashboardactivetests.testingends}</h4>
        <h2>{testingEnds}</h2>
      </div> */}
      {!btnKey ? (
        <button className={"glb-btn " + btnColor + "detail-btn"} onClick={() => history.push(`/dashboard/home/projects/${projectID}`)}>
          {btnText}
        </button>
      ) : (
        <button className={("glb-btn " + btnColor + "detail-btn")}>{btnKey}</button>
      )}
    </div>
  );
};

export default ProjectCard;
