import React from "react";
import HalfPageBackground from "../../components/Layout/HalfPageBackground";
import { withLayout } from "../../layouts";
import { Helmet } from "react-helmet";
import { LoginRoutes } from "../../routes";
import AutoLogin from "../../components/AutoLogin";

import { useRouteMatch, Route, Switch, Redirect } from "react-router-dom";

import "../../assets/sass/commonStyles.scss";

const Login = () => {
  const match = useRouteMatch();
  return (
    <div className="halfPageBackground-container">
      <Helmet>
        <meta name="description" content="Login to compete with fellow testers and collect bug bounty." />
      </Helmet>
      <AutoLogin />
      <HalfPageBackground />
      <Switch>
        <Redirect exact from={`${match.url}/`} to={`${match.url}/password`} />
        {LoginRoutes.map(({ path, Component, exact }, i) => (
          <Route path={`${match.url}${path}`} exact={exact} component={Component} key={i} />
        ))}
      </Switch>
    </div>
  );
};

export default withLayout(Login, true, false);
