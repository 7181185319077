import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import axios from "axios";
import { useHistory } from "react-router-dom";
import { setAlert } from "../../../../../redux/Alerts/actions";
import { getWalletEarning, deleteTesterAccount } from "../../../../../redux/TesterDashboard/actions";
import baseDomain, { getUserBanksReq, transferAmountStatusReq } from "../../../../../constants/apiRoutes";
import DashboardHeading from "../../../../Common/DashboardHeading";
import settings from "../../../../../constants/settings";
import InitilizedTransfer from "../../../../Common/DashboardTables/InitilizedTransfer";
import DashboardSubHeading from "../../../../Common/DashboardSubHeading";
import Loader from "../../../../Loader";
import TesterBankTable from "../../../../Common/DashboardTables/TesterBankTable";
//import MyEarningsAccordian from "../../../../Common/DashboardTables/MyEarningsAccordian/index";

import "./style.scss";
import RequestTransfer from "../../../../Modals/RequestTransferModal";

const TransferAmount = ({ totalWallet, getWalletEarning, setAlert, deleteTesterAccount, earnedWallet }) => {
  const history = useHistory();
  const [isLoading, setIsLoading] = useState(true);
  const [testerBanks, setTesterBanks] = useState([]);
  const [initializedTransfers, setInitializedTransfers] = useState([]);
  const [modal, setModal] = useState(false);

  useEffect(() => {
    getInitailData();
    getWalletEarning();
    // eslint-disable-next-line
  }, []);

  const getInitailData = async () => {
    setIsLoading(true);
    try {
      const { data } = await axios.get(`${baseDomain}${getUserBanksReq}`);
      const res = await axios.get(`${baseDomain}${transferAmountStatusReq}`);
      setInitializedTransfers(res.data.data);
      setTesterBanks(data.data);
      setIsLoading(false);
    } catch (error) {
      setAlert({ message: error.response.data.message || "Unable to transfer amount try later", type: "ERROR" });
      history.push("/dashboard/earning/total");
      setIsLoading(false);
    }
  };

  const transferAmount = () => {
    setModal(true);
  };

  return (
    <div className="transfer-amt">
      <div className="transfer-amt-heading">
        <DashboardHeading heading={settings.transferamount.heading1.light} headingStrong={settings.transferamount.heading1.bold} />
        {/* <div className="card">{isLoading 
        ? <Loader /> 
        : (
          <>
            {earnedWallet.map((item,i) => (
              <MyEarningsAccordian myearningscontent={item} key={i} showTable={false} showBtn={true} />
            ))}
          </>
        )}</div> */}
      </div>

      <div className="current-bln-container">
        <div className="current-bln-label">{settings.transferamount.currentbalance}</div>
        <div className="current-bln">
          <div className="transfer-amt">{totalWallet ? "$ " + totalWallet.toFixed(2) : "$ 0"}</div>
        </div>
        <div className="transfer-amount">
          <button className="glb-btn" onClick={transferAmount}>
            {settings.transferamount.transfer_amt}
          </button>
        </div>
      </div>
      {/* Modal */}
      {modal && earnedWallet.length !== 0 && (
        <RequestTransfer setModal={setModal} earnedWallet={earnedWallet} testerBanks={testerBanks} getInitailData={getInitailData} />
      )}

      {/* Bank details started */}
      <div className="tester-bank-table">
        <DashboardSubHeading heading={settings.transferamount.heading2.light} headingStrong={settings.transferamount.heading2.bold} showBtn={false} />
        {isLoading ? (
          <Loader />
        ) : (
          <div className="card">
            <TesterBankTable bankDetailsArray={testerBanks} getInitailData={getInitailData} deleteBankTable={deleteTesterAccount} showItem={true} />
          </div>
        )}
      </div>
      {/* Bank details Ended */}

      <div className="tester-initialized-transfer">
        <DashboardSubHeading heading={settings.transferamount.heading4.light} headingStrong={settings.transferamount.heading4.bold} showBtn={false} />
        {isLoading ? (
          <Loader />
        ) : (
          <div className="card">
            <InitilizedTransfer bankDetailsArray={testerBanks} transferStatusArray={initializedTransfers} />
          </div>
        )}
      </div>
    </div>
  );
};

const mapStateToProps = ({ testerDashboard }) => ({
  totalWallet: testerDashboard.totalWallet,
  earnedWallet: testerDashboard.earnedWallet,
  isLoading: testerDashboard.isLoading,
});

export default connect(mapStateToProps, { getWalletEarning, setAlert, deleteTesterAccount })(TransferAmount);
