import axios from "axios";
import React from "react";
import { connect } from "react-redux";
import { useHistory } from "react-router";
import baseDomain, { transferAmountReq } from "../../../constants/apiRoutes";
import settings from "../../../constants/settings";
import { setAlert } from "../../../redux/Alerts/actions";
//import MyEarningsAccordian from '../../Common/DashboardTables/MyEarningsAccordian';

import Loader from "../../Loader";

import "./style.scss";

const RequestTransfer = ({ setModal, earnedWallet, isLoading, testerBanks, setAlert, getInitailData }) => {
  const history = useHistory();

  const transferAmount = async projectId => {
    if (testerBanks.length !== 0) {
      try {
        await axios.put(`${baseDomain}${transferAmountReq}/${projectId}`);
        getInitailData();
        setAlert({ message: settings.transferamount.success_message, type: "SUCCESS" });
        history.push("/dashboard/earning/total");
      } catch (error) {
        setAlert({ message: error.response.data.message || settings.transferamount.error2, type: "ERROR" });
      }
    } else {
      setAlert({ message: "No Bank Account Added", type: "ERROR" });
    }
  };

  return (
    <div className="requestTransferModal">
      <div className="card">
        {isLoading ? (
          <Loader />
        ) : (
          <>
            <p
              className="closeTransferModal"
              onClick={() => {
                setModal(false);
              }}
            >
              X
            </p>
            <h2 style={{ textAlign: "center" }}>Earnings Breakdown</h2>
            <table>
              <thead>
                <tr>
                  <th>Project</th>
                  <th>Earning</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                {earnedWallet.map((item, i) => (
                  <tr key={i}>
                    <td>{item.projectName}</td>
                    <td>{item?.earning?.toFixed(2)}</td>
                    <td>
                      <button
                        onClick={() => {
                          transferAmount(item.projectId);
                        }}
                      >
                        Transfer Amount
                      </button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </>
        )}
      </div>
    </div>
  );
};

export default connect(null, { setAlert })(RequestTransfer);
