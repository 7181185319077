import {
  GET_TESTER_LEADERBOARD_PROJECTS,
  GET_TESTER_LEADERBOARD_PROJECTS_SUCCESS,
  GET_TESTER_LEADERBOARD_PROJECTS_ERROR,
  GET_TESTER_LEADERBOARD,
  GET_TESTER_LEADERBOARD_SUCCESS,
  GET_TESTER_LEADERBOARD_ERROR,
  GET_TESTER_LIVE_PROJECTS,
  GET_TESTER_LIVE_PROJECTS_SUCCESS,
  GET_TESTER_LIVE_PROJECTS_ERROR,
  GET_TESTER_DEVICES,
  GET_TESTER_DEVICES_SUCCESS,
  GET_TESTER_DEVICES_ERROR,
  CREATE_TESTER_DEVICES,
  CREATE_TESTER_DEVICES_SUCCESS,
  CREATE_TESTER_DEVICES_ERROR,
  GET_TESTER_NOTIFICATIONS,
  GET_TESTER_NOTIFICATIONS_SUCCESS,
  GET_TESTER_NOTIFICATIONS_ERROR,
  GET_TESTER_PAUSED_PROJECTS,
  GET_TESTER_PAUSED_PROJECTS_SUCCESS,
  GET_TESTER_PAUSED_PROJECTS_ERROR,
  GET_TESTER_COMPLETED_PROJECTS,
  GET_TESTER_COMPLETED_PROJECTS_SUCCESS,
  GET_TESTER_COMPLETED_PROJECTS_ERROR,
  GET_TESTER_PROJECT_BY_ID,
  GET_TESTER_PROJECT_BY_ID_SUCCESS,
  GET_TESTER_PROJECT_BY_ID_ERROR,
  GET_TESTER_LISTED_BUGS,
  GET_TESTER_LISTED_BUGS_SUCCESS,
  GET_TESTER_LISTED_BUGS_ERROR,
  GET_PROJECT_LISTED_BUGS,
  GET_PROJECT_LISTED_BUGS_SUCCESS,
  GET_PROJECT_LISTED_BUGS_ERROR,
  GET_PROJECT_BUGS_EXPORT,
  GET_PROJECT_BUGS_EXPORT_SUCCESS,
  GET_PROJECT_BUGS_EXPORT_ERROR,
  GET_COMMUNITY_STATES,
  GET_COMMUNITY_STATES_SUCCESS,
  GET_COMMUNITY_STATES_ERROR,
  CHANGE_PASSWORD,
  CHANGE_PASSWORD_SUCCESS,
  CHANGE_PASSWORD_ERROR,
  GET_WALLET_EARNING,
  GET_WALLET_EARNING_SUCCESS,
  GET_WALLET_EARNING_ERROR,
  GET_PAID_EARNING,
  GET_PAID_EARNING_SUCCESS,
  GET_PAID_EARNING_ERROR,
  GET_ALL_SKILLS,
  GET_ALL_SKILLS_SUCCESS,
  GET_ALL_SKILLS_ERROR,
  CREATE_TESTER_SKILL,
  CREATE_TESTER_SKILL_SUCCESS,
  CREATE_TESTER_SKILL_ERROR,
  GET_TESTER_SKILLS,
  GET_TESTER_SKILLS_SUCCESS,
  GET_TESTER_SKILLS_ERROR,
  GET_SINGLE_BUG_DATA,
  GET_SINGLE_BUG_DATA_SUCCESS,
  GET_SINGLE_BUG_DATA_ERROR,
  DELETE_TESTER_SKILL,
  DELETE_TESTER_SKILL_SUCCESS,
  DELETE_TESTER_SKILL_ERROR,
  GET_EARNING_GRAPH,
  GET_EARNING_GRAPH_SUCCESS,
  GET_EARNING_GRAPH_ERROR,
  DELETE_TESTER_DEVICE,
  DELETE_TESTER_DEVICE_SUCCESS,
  DELETE_TESTER_DEVICE_ERROR,
  DELETE_TESTER_ACCOUNT,
  DELETE_TESTER_ACCOUNT_SUCCESS,
  DELETE_TESTER_ACCOUNT_ERROR,
  UPDATE_PROFILE,
  UPDATE_PROFILE_SUCCESS,
  UPDATE_PROFILE_ERROR,
  UPDATE_PROFILE_WITHOUT_IMAGE,
  UPDATE_PROFILE_WITHOUT_IMAGE_SUCCESS,
  UPDATE_PROFILE_WITHOUT_IMAGE_ERROR,
  UPLOAD_FEEDBACK,
  UPLOAD_FEEDBACK_SUCCESS,
  UPLOAD_FEEDBACK_ERROR,
  UPLOAD_TESTIMONIAL,
  UPLOAD_TESTIMONIAL_SUCCESS,
  UPLOAD_TESTIMONIAL_ERROR,
  DELETE_PROFILE_PICTURE,
  DELETE_PROFILE_PICTURE_SUCCESS,
  DELETE_PROFILE_PICTURE_ERROR,
  GET_ASSIGNED_BUGS,
  GET_ASSIGNED_BUGS_SUCCESS,
  GET_ASSIGNED_BUGS_ERROR,
  ADD_COMMENT,
  ADD_COMMENT_SUCCESS,
  ADD_COMMENT_ERROR,
  GET_COMMENT,
  GET_COMMENT_SUCCESS,
  GET_COMMENT_ERROR
} from "../actions";

const initialState = {
  isError: false,
  isLoading: false,
  userDevices: [],
  testerNotifications: [],
  testerActiveProjects: [],
  testerCompletedProjects: [],
  testerSelectedProject: {},
  testerLeaderboard: [],
  testerPausedProjects: [],
  testerLiveProjects: [],
  testerListedBugs: [],
  projectListedBugs: [],
  allProjectBugs:[],
  communityStates: {},
  earnedWallet: [],
  earningPaid: [],
  allSkillsList: [],
  testerSkills: [],
  singleBugData: {},
  earningGraph: [],
  totalWallet: 0,
  deletebankaccount: [],
  assignedBugs:[],
  comments:[],
  isCommentLoaded:false
};

const fun = (state = initialState, action) => {
  switch (action.type) {
    // Tester Leadboard Projects
    case GET_TESTER_LEADERBOARD_PROJECTS:
      return {
        ...state,
        testerActiveProjects: [],
        isLoading: true,
        isError: false,
      };
    case GET_TESTER_LEADERBOARD_PROJECTS_SUCCESS:
      return {
        ...state,
        testerActiveProjects: action.payload,
        isLoading: false,
        isError: false,
      };
    case GET_TESTER_LEADERBOARD_PROJECTS_ERROR:
      return {
        ...state,
        isLoading: false,
        testerActiveProjects: [],
        isError: true,
      };

    // Tester Leadboard
    case GET_TESTER_LEADERBOARD:
      return {
        ...state,
        testerLeaderboard: [],
        isLoading: true,
        isError: false,
      };
    case GET_TESTER_LEADERBOARD_SUCCESS:
      return {
        ...state,
        testerLeaderboard: action.payload,
        isLoading: false,
        isError: false,
      };
    case GET_TESTER_LEADERBOARD_ERROR:
      return {
        ...state,
        isLoading: false,
        testerLeaderboard: [],
        isError: true,
      };

    // Tester Live Projects
    case GET_TESTER_LIVE_PROJECTS:
      return {
        ...state,
        testerLiveProjects: [],
        isLoading: true,
        isError: false,
      };
    case GET_TESTER_LIVE_PROJECTS_SUCCESS:
      return {
        ...state,
        testerLiveProjects: action.payload,
        isLoading: false,
        isError: false,
      };
    case GET_TESTER_LIVE_PROJECTS_ERROR:
      return {
        ...state,
        isLoading: false,
        testerLiveProjects: [],
        isError: true,
      };

    // Tester Paused Projects
    case GET_TESTER_PAUSED_PROJECTS:
      return {
        ...state,
        testerPausedProjects: [],
        isLoading: true,
        isError: false,
      };
    case GET_TESTER_PAUSED_PROJECTS_SUCCESS:
      return {
        ...state,
        testerPausedProjects: action.payload,
        isLoading: false,
        isError: false,
      };
    case GET_TESTER_PAUSED_PROJECTS_ERROR:
      return {
        ...state,
        isLoading: false,
        testerPausedProjects: [],
        isError: true,
      };

    // Tester Completed Projects
    case GET_TESTER_COMPLETED_PROJECTS:
      return {
        ...state,
        testerCompletedProjects: [],
        isLoading: true,
        isError: false,
      };
    case GET_TESTER_COMPLETED_PROJECTS_SUCCESS:
      return {
        ...state,
        testerCompletedProjects: action.payload,
        isLoading: false,
        isError: false,
      };
    case GET_TESTER_COMPLETED_PROJECTS_ERROR:
      return {
        ...state,
        isLoading: false,
        testerCompletedProjects: [],
        isError: true,
      };

    // Tester Completed Projects
    case GET_TESTER_PROJECT_BY_ID:
      return {
        ...state,
        testerSelectedProject: {},
        isLoading: true,
        isError: false,
      };
    case GET_TESTER_PROJECT_BY_ID_SUCCESS:
      return {
        ...state,
        testerSelectedProject: action.payload,
        isLoading: false,
        isError: false,
      };
    case GET_TESTER_PROJECT_BY_ID_ERROR:
      return {
        ...state,
        isLoading: false,
        testerSelectedProject: [],
        isError: true,
      };

    // Tester DEVICES
    case GET_TESTER_DEVICES:
      return {
        ...state,
        userDevices: [],
        isLoading: true,
        isError: false,
      };
    case GET_TESTER_DEVICES_SUCCESS:
      return {
        ...state,
        userDevices: action.payload,
        isLoading: false,
        isError: false,
      };
    case GET_TESTER_DEVICES_ERROR:
      return {
        ...state,
        isLoading: false,
        isError: true,
      };

    // Create DEVICES
    case CREATE_TESTER_DEVICES:
      return {
        ...state,
        isLoading: true,
        isError: false,
      };
    case CREATE_TESTER_DEVICES_SUCCESS:
      return {
        ...state,
        userDevices: action.payload.deviceID
          ? [...state.userDevices, action.payload].filter(device => device.id !== action.payload.deviceID)
          : [...state.userDevices, action.payload],
        isLoading: false,
        isError: false,
      };
    case CREATE_TESTER_DEVICES_ERROR:
      return {
        ...state,
        isLoading: false,
        isError: true,
      };

    // Delete Tester DEVICES
    case DELETE_TESTER_DEVICE:
      return {
        ...state,
        isLoading: true,
        isError: false,
      };
    case DELETE_TESTER_DEVICE_SUCCESS:
      return {
        ...state,
        userDevices: state.userDevices.filter(device => device.id !== action.deviceID),
        isLoading: false,
        isError: false,
      };
    case DELETE_TESTER_DEVICE_ERROR:
      return {
        ...state,
        isLoading: false,
        isError: true,
      };

    // Tester Notifications
    case GET_TESTER_NOTIFICATIONS:
      return {
        ...state,
        testerNotifications: [],
        isLoading: true,
        isError: false,
      };
    case GET_TESTER_NOTIFICATIONS_SUCCESS:
      return {
        ...state,
        testerNotifications: action.payload,
        isLoading: false,
        isError: false,
      };
    case GET_TESTER_NOTIFICATIONS_ERROR:
      return {
        ...state,
        testerNotifications: [],
        isLoading: false,
        isError: true,
      };

    // Tester Listed Bugs
    case GET_TESTER_LISTED_BUGS:
      return {
        ...state,
        testerListedBugs: [],
        isLoading: true,
        isError: false,
      };
    case GET_TESTER_LISTED_BUGS_SUCCESS:
      return {
        ...state,
        testerListedBugs: action.payload,
        isLoading: false,
        isError: false,
      };
    case GET_TESTER_LISTED_BUGS_ERROR:
      return {
        ...state,
        testerListedBugs: [],
        isLoading: false,
        isError: true,
      };

    // Project Listed Bugs
    case GET_PROJECT_LISTED_BUGS:
      return {
        ...state,
        projectListedBugs: [],
        isLoading: true,
        isError: false,
      };
    case GET_PROJECT_LISTED_BUGS_SUCCESS:
      return {
        ...state,
        projectListedBugs: action.payload,
        isLoading: false,
        isError: false,
      };
    case GET_PROJECT_LISTED_BUGS_ERROR:
      return {
        ...state,
        projectListedBugs: [],
        isLoading: false,
        isError: true,
      };

    case GET_PROJECT_BUGS_EXPORT:
      return {
        ...state,
        allProjectBugs:[],
        isLoading: true,
        isError: false,
      };
      
  case GET_PROJECT_BUGS_EXPORT_SUCCESS:
      return {
          ...state,
          allProjectBugs:action.payload,
          isLoading: false,
          isError: false,
        };
        
  case GET_PROJECT_BUGS_EXPORT_ERROR:
      return {
        ...state,
        allProjectBugs:[],
        isLoading: false,
        isError: true,
      }     

    // Project Listed Bugs
    case GET_COMMUNITY_STATES:
      return {
        ...state,
        communityStates: {},
        isLoading: true,
        isError: false,
      };
    case GET_COMMUNITY_STATES_SUCCESS:
      return {
        ...state,
        communityStates: action.payload,
        isLoading: false,
        isError: false,
      };
    case GET_COMMUNITY_STATES_ERROR:
      return {
        ...state,
        communityStates: {},
        isLoading: false,
        isError: true,
      };

    // Change Password
    case CHANGE_PASSWORD:
      return {
        ...state,
        isLoading: true,
        isError: false,
      };
    case CHANGE_PASSWORD_SUCCESS:
      return {
        ...state,
        isLoading: false,
        isError: false,
      };
    case CHANGE_PASSWORD_ERROR:
      return {
        ...state,
        isLoading: false,
        isError: true,
      };

    // Waller Earning
    case GET_WALLET_EARNING:
      return {
        ...state,
        isLoading: true,
        earnedWallet: [],
        totalWallet: 0,
        isError: false,
      };
    case GET_WALLET_EARNING_SUCCESS:
      return {
        ...state,
        earnedWallet: action.payload.transactions,
        totalWallet: action.payload.totalInWallet,
        isLoading: false,
        isError: false,
      };
    case GET_WALLET_EARNING_ERROR:
      return {
        ...state,
        earnedWallet: [],
        totalWallet: 0,
        isLoading: false,
        isError: true,
      };

    // Paid Earning
    case GET_PAID_EARNING:
      return {
        ...state,
        isLoading: true,
        earningPaid: [],
        isError: false,
      };
    case GET_PAID_EARNING_SUCCESS:
      return {
        ...state,
        earningPaid: action.payload,
        isLoading: false,
        isError: false,
      };
    case GET_PAID_EARNING_ERROR:
      return {
        ...state,
        earningPaid: [],
        isLoading: false,
        isError: true,
      };

    // Paid Earning
    case GET_ALL_SKILLS:
      return {
        ...state,
        isLoading: true,
        allSkillsList: [],
        isError: false,
      };
    case GET_ALL_SKILLS_SUCCESS:
      return {
        ...state,
        allSkillsList: action.payload,
        isLoading: false,
        isError: false,
      };
    case GET_ALL_SKILLS_ERROR:
      return {
        ...state,
        allSkillsList: [],
        isLoading: false,
        isError: true,
      };

    // Paid Earning
    case GET_TESTER_SKILLS:
      return {
        ...state,
        isLoading: true,
        testerSkills: [],
        isError: false,
      };
    case GET_TESTER_SKILLS_SUCCESS:
      return {
        ...state,
        testerSkills: action.payload,
        isLoading: false,
        isError: false,
      };
    case GET_TESTER_SKILLS_ERROR:
      return {
        ...state,
        testerSkills: [],
        isLoading: false,
        isError: true,
      };

    // Paid Earning
    case CREATE_TESTER_SKILL:
      return {
        ...state,
        isLoading: true,
        isError: false,
      };
    case CREATE_TESTER_SKILL_SUCCESS:
      return {
        ...state,
        testerSkills: [...state.testerSkills, action.payload],
        isLoading: false,
        isError: false,
      };
    case CREATE_TESTER_SKILL_ERROR:
      return {
        ...state,
        isLoading: false,
        isError: true,
      };

    // Paid Earning
    case GET_SINGLE_BUG_DATA:
      return {
        ...state,
        singleBugData: {},
        isLoading: true,
        isError: false,
      };
    case GET_SINGLE_BUG_DATA_SUCCESS:
      return {
        ...state,
        singleBugData: action.payload,
        isLoading: false,
        isError: false,
      };
    case GET_SINGLE_BUG_DATA_ERROR:
      return {
        ...state,
        singleBugData: {},
        isLoading: false,
        isError: true,
      };

    // Delete Skill
    case DELETE_TESTER_SKILL:
      return {
        ...state,
        isLoading: true,
        isError: false,
      };
    case DELETE_TESTER_SKILL_SUCCESS:
      return {
        ...state,
        testerSkills: state.testerSkills.filter(({ id }) => id !== action.payload),
        isLoading: false,
        isError: false,
      };
    case DELETE_TESTER_SKILL_ERROR:
      return {
        ...state,
        isLoading: false,
        isError: true,
      };

    // Paid Earning
    case GET_EARNING_GRAPH:
      return {
        ...state,
        earningGraph: [],
        isLoading: true,
        isError: false,
      };
    case GET_EARNING_GRAPH_SUCCESS:
      return {
        ...state,
        earningGraph: action.payload,
        isLoading: false,
        isError: false,
      };
    case GET_EARNING_GRAPH_ERROR:
      return {
        ...state,
        earningGraph: [],
        isLoading: false,
        isError: true,
      };

    // Delete Tester Bank Account Details
    case DELETE_TESTER_ACCOUNT:
      return {
        ...state,
        isLoading: true,
        isError: false,
      };
    case DELETE_TESTER_ACCOUNT_SUCCESS:
      return {
        ...state,
        deletebankaccount: action.payload,
        isLoading: false,
        isError: false,
      };
    case DELETE_TESTER_ACCOUNT_ERROR:
      return {
        ...state,
        isLoading: false,
        isError: true,
      };

    // Update Profile
    case UPDATE_PROFILE:
      return {
        ...state,
        isLoading: true,
        isError: false,
      };
    case UPDATE_PROFILE_SUCCESS:
      return {
        ...state,
        isLoading: false,
        isError: false,
      };
    case UPDATE_PROFILE_ERROR:
      return {
        ...state,
        isLoading: false,
        isError: true,
      };

    // Update Profile Without Image
    case UPDATE_PROFILE_WITHOUT_IMAGE:
      return {
        ...state,
        isLoading: true,
        isError: false,
      };
    case UPDATE_PROFILE_WITHOUT_IMAGE_SUCCESS:
      return {
        ...state,
        isLoading: false,
        isError: false,
      };
    case UPDATE_PROFILE_WITHOUT_IMAGE_ERROR:
      return {
        ...state,
        isLoading: false,
        isError: true,
      };

    // Feedback
    case UPLOAD_FEEDBACK:
      return {
        ...state,
        isLoading: true,
        isError: false,
      };
    case UPLOAD_FEEDBACK_SUCCESS:
      return {
        ...state,
        isLoading: false,
        isError: false,
      };
    case UPLOAD_FEEDBACK_ERROR:
      return {
        ...state,
        isLoading: false,
        isError: true,
      };

  //testimonial
  case UPLOAD_TESTIMONIAL:
    return {
      ...state,
      isLoading: true,
      isError: false,
    };
  case UPLOAD_TESTIMONIAL_SUCCESS:
    return {
      ...state,
      isLoading: false,
      isError: false,
    };
  case UPLOAD_TESTIMONIAL_ERROR:
    return {
      ...state,
      isLoading: false,
      isError: true,
    };
  case DELETE_PROFILE_PICTURE:
    return {
      ...state,
      isLoading:true,
      isError:false
    };  
  case DELETE_PROFILE_PICTURE_SUCCESS:
    return {
        ...state,
        isLoading:false,
        isError:false
    };
  case DELETE_PROFILE_PICTURE_ERROR:
    return {
        ...state,
        isLoading:false,
        isError:true
    };
  case GET_ASSIGNED_BUGS:
    return {
      ...state,
      isLoading:true,
      isError:false 
    };
  case GET_ASSIGNED_BUGS_SUCCESS:
    return {
      ...state,
      isLoading:false,
      assignedBugs:action.payload,
      isError:false
    };
  case GET_ASSIGNED_BUGS_ERROR:
    return {
      ...state,
      isLoading:false,
      assignedBugs:[],
      isError:true
    };
  case ADD_COMMENT:
    return {
      ...state,
      isLoading:true,
      isError:false
    };
  case ADD_COMMENT_SUCCESS:
    return {
      ...state,
      isLoading:false,
      isError:false
    };
  case ADD_COMMENT_ERROR:
    return {
      ...state,
      isLoading:false,
      isError:true
    };
  case GET_COMMENT:
    return {
      ...state,
     isCommentLoaded:true,
      comments:[],
      isError:false
    };
  case GET_COMMENT_SUCCESS:
    return {
      ...state,
      isCommentLoaded:false,
      comments:action.payload,
      isError:false
    };
  case GET_COMMENT_ERROR:
    return {
      ...state,
      isCommentLoaded:false,
      comments:[],
      isError:true
    }                   
    default:
      return { ...state };
  }
};

export default fun;
