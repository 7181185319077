import React, { useEffect } from "react";
import ServiceOfferings from "../Automation/ServiceOfferings";
import Benefits from "../Automation/Benefits";
import ToolsUsed from "../Automation/ToolsUsed";
import settings from "../../constants/settings";
import AutoLogin from "../../components/AutoLogin";
import { Helmet } from "react-helmet";
import { withLayout } from "../../layouts";

import "./style.scss";
const Automation = ({ staticSiteNavHandler }) => {
  useEffect(() => {
    staticSiteNavHandler("AUTOMATION");
    // eslint-disable-next-line
  }, []);

  return (
    <div className="home-automation">
      <Helmet>
        <meta name="description" content="Automation testing services for mobile, web and software testing " />
      </Helmet>
      <AutoLogin />
      <div className="automation-heading">
        <div className="automation-heading-content">
          <h4 className="automation-heading-highlight text-yellow">{settings.automation.heading}</h4>
          {settings.automation.description}
        </div>
      </div>

      <ServiceOfferings data={settings.automation.service_offerings} />
      <Benefits data={settings.automation.benefits} />
      <ToolsUsed data={settings.automation.tools_used} />
    </div>
  );
};

export default withLayout(Automation);
