import React, { useEffect, useState } from "react"; 
import { connect } from "react-redux";
import { CloseSign } from "../../../assets/icons/icons";

import { getAdminLiveProjects, inviteSelectedTesters, clearSelectedTestersList } from "../../../redux/AdminDashboard/actions";
import { setAlert } from "../../../redux/Alerts/actions";
import "./style.scss";

const InvitationModal = ({
  showModal,
  toggleModal,
  setAlert,
  getAdminLiveProjects,
  adminLiveProjects,
  sendInvitation,
  id,
  adminTestersListForParticularProject,
  inviteSelectedTesters,
  clearSelectedTestersList,
}) => {
  const [particularProjectId, setParticularProjectId] = useState("");
  const [attachedMessageToMail, setAttachedMessageToMail] = useState("");
  const [selectedTestersListArray, setSelectedTestersListArray] = useState([]);
  const [searchTesters, setSearchTesters] = useState([]);
  const [projectSearch,setProjectSearch]=useState([]);
  const [search, setSearch] = useState("");
  const [search1, setSearch1] = useState("");

  let sortedContent = [];
  let trimmed = [];
  
  trimmed = adminTestersListForParticularProject.map((item)=>{
    let name=item.name.trim()
    return(
      {...item,name:name}
    )
 }) 

 sortedContent = trimmed.sort((a, b) => a.name > b.name ? 1 : -1)


  useEffect(() => {
    getAdminLiveProjects();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    setSearchTesters(sortedContent);
    // eslint-disable-next-line
  }, [adminTestersListForParticularProject]);

  useEffect(() => {
    setProjectSearch(adminLiveProjects);
    // eslint-disable-next-line
  }, [adminLiveProjects]);

  const modalToggle = () => {
    toggleModal();
    clearSelectedTestersList();
    setSearch1("");
    setProjectSearch(adminLiveProjects)
  };
  const handleInputChange = e => {
    setAttachedMessageToMail(e.target.value);
  };

  const adminLiveProjectsRender = () => {
    return projectSearch.map(item => (
      <ul>
        <li>
          <div className="deivce-container">
            <div className="sevice-wrapper">
              <h3 className="device">{item.name}</h3>
            </div>
            <h1 className="mail-button" onClick={() => handleSendEmail(item.projectId)}>
              +
            </h1>
          </div>
        </li>
      </ul>
    ));
  };
  const particularTestersRender = () => {
    return (
      <ul>
        {searchTesters.map(
          item =>
            item.role[0] !== "ADMIN" &&
            item.role[0] !== "AGENCY" && (
              <li>
                <div className="testerList">
                  <h3>{item.name}</h3>
                  {
                    item?.isEmailVerified ?
                  (<input
                    type="checkbox"
                    className="checkboxes"
                    onChange={e => {
                      handleSelectedTesters(e, item.id);
                    }}
                  />):<input
                  type="checkbox"
                  className="checkboxes"
                  disabled
                />}
                </div>
              </li>
            )
        )}
      </ul>
    );
  };

  const handleSelectAll = () => {
    let arr = document.getElementsByClassName("checkboxes");
    const dummySelectedList = [];
    // loop start
    for (const s of arr) {
      s.checked = true;
    }
    // loop end
    // second loop for pushing all items in array list of testers
    adminTestersListForParticularProject.forEach(item => {
      dummySelectedList.push(item.id);
    });
    // second loop end
    // changing orignal selected list
    setSelectedTestersListArray([...dummySelectedList]);
  };
  const handleDeselectAll = () => {
    let arr = document.getElementsByClassName("checkboxes");
    for (const s of arr) {
      s.checked = false;
    }
    // changing orignal selected list
    setSelectedTestersListArray([]);
  };

  const handleSelectedTesters = (e, id) => {
    if (e.target.checked) {
      setSelectedTestersListArray([...selectedTestersListArray, id]);
      //  add that tester into   selected tester list
    } else {
      setSelectedTestersListArray(oldArray => oldArray.filter(item => item !== id));
      //  remove that tester from selected tester list
    }
  };

  const handleSearch = e => {
    setSearchTesters(sortedContent.filter(item => item.name.toLowerCase().includes(e.target.value.toLowerCase())));
    setProjectSearch(adminLiveProjects.filter(item => item.name.toLowerCase().includes(e.target.value.toLowerCase())));
    setSearch(e.target.value);
  };

  const handleSearch1 = e => {
    setProjectSearch(adminLiveProjects.filter(item => item.name.toLowerCase().includes(e.target.value.toLowerCase())));
    setSearch1(e.target.value);
  };

  const handleSendEmail = projectId => {
    setParticularProjectId(projectId);
    id ? sendInvitation({
          projectId: projectId,
          userId: id,
          })
          : sendInvitation(projectId);
      setSearch("");
      setSearch1("");
      setProjectSearch(adminLiveProjects)
  };

  const mailToSelectiveTesters = () => {
    if (selectedTestersListArray.length > 0) {
      const payload = {
        projectId: particularProjectId,
        userIds: selectedTestersListArray,
        customMessage: attachedMessageToMail,
      };
      inviteSelectedTesters(payload);
      setAttachedMessageToMail("");
      clearSelectedTestersList();
      setSelectedTestersListArray([]);
    } else {
      setAlert({ message: "Please Select  At least one tester", type: "Error" });
    }
  };

  return (
    <div className={showModal ? "declineBug-modal-container blur-bg" : "none"}>
      <div className="declineBug-modal">
        <div className="close-icon">
          <div className="close" onClick={modalToggle}>
            {CloseSign}
          </div>
        </div>
        <div className="heading">
          <h2>Send Invitation</h2>
        </div>
        <div className="body-container">
          <div className="body">
            <div className="declinePopUpContainer">
              <div className="declinePopUp">
                {adminTestersListForParticularProject.length > 0 && (
                  <div className="selectButtons ">
                    <input type="text" value={search} placeholder="Search by Name" className="search-testers" onChange={handleSearch}/>
                    <button className="glb-action-btn selectAll" onClick={handleSelectAll}>
                      Select all
                    </button>
                    <button className="glb-action-btn deSelectAll" onClick={handleDeselectAll}>
                      Deselect all
                    </button>
                  </div>
                )}
                {!adminTestersListForParticularProject.length > 0 && <input className="glb-input" type="search" value={search1} placeholder="Search" onChange={handleSearch1} />}
                {adminTestersListForParticularProject.length === 0 ? adminLiveProjectsRender() : particularTestersRender()}
                {adminTestersListForParticularProject.length > 0 && (
                  <input
                    className="attachedMessage"
                    type="text"
                    onChange={handleInputChange}
                    value={attachedMessageToMail}
                    placeholder="Attach any Custom Message While Inviting Tester"
                  />
                )}
                <div className="declinePopUpButtons">
                  {adminTestersListForParticularProject.length === 0 ? (
                    <button className={"glb-btn no"} onClick={modalToggle}>
                      Cancel
                    </button>
                  ) : (
                    <button className="glb-btn" onClick={mailToSelectiveTesters}>
                      Send Invite
                    </button>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
const mapStateToProps = ({ adminDashboard }) => ({
  adminLiveProjects: adminDashboard.adminLiveProjects,
  adminTestersListForParticularProject: adminDashboard.testerInvitesList,
});

export default connect(mapStateToProps, { setAlert, getAdminLiveProjects, inviteSelectedTesters, clearSelectedTestersList })(InvitationModal);
