import { all, call, fork, put, takeEvery } from "redux-saga/effects";

import {
  GET_TESTER_LEADERBOARD_PROJECTS,
  GET_TESTER_DEVICES,
  CREATE_TESTER_DEVICES,
  GET_TESTER_NOTIFICATIONS,
  GET_TESTER_LEADERBOARD,
  GET_TESTER_LIVE_PROJECTS,
  GET_TESTER_PAUSED_PROJECTS,
  GET_TESTER_COMPLETED_PROJECTS,
  GET_TESTER_PROJECT_BY_ID,
  GET_TESTER_LISTED_BUGS,
  GET_PROJECT_LISTED_BUGS,
  REPORT_NEW_BUG,
  GET_COMMUNITY_STATES,
  CHANGE_PASSWORD,
  GET_WALLET_EARNING,
  GET_PAID_EARNING,
  GET_ALL_SKILLS,
  CREATE_TESTER_SKILL,
  GET_TESTER_SKILLS,
  GET_SINGLE_BUG_DATA,
  DELETE_TESTER_SKILL,
  GET_EARNING_GRAPH,
  DELETE_TESTER_DEVICE,
  DELETE_TESTER_ACCOUNT,
  UPDATE_PROFILE,
  UPDATE_PROFILE_WITHOUT_IMAGE,
  UPLOAD_FEEDBACK,
  UPLOAD_TESTIMONIAL,
  DELETE_PROFILE_PICTURE,
  GET_PROJECT_BUGS_EXPORT,
  GET_ASSIGNED_BUGS,
  ADD_COMMENT,
  GET_COMMENT
} from "../actions";

import { setAlert } from "../Alerts/actions";
import { logoutUser } from "../auth/actions";

import { setIsDeviceHavingTrue } from "../auth/actions";

import {
  getTesterLeaderboardProjectsSuccess,
  getTesterLeaderboardProjectsError,
  getTesterLeaderboardSuccess,
  getTesterLeaderboardError,
  getTesterLiveProjectsSuccess,
  getTesterLiveProjectsError,
  getTesterDevicesSuccess,
  getTesterDevicesError,
  createTesterDevicesSuccess,
  createTesterDevicesError,
  deleteTesterDeviceSuccess,
  deleteTesterDeviceError,
  getTesterNotificationsSuccess,
  getTesterNotificationsError,
  getTesterCompletedProjectsSuccess,
  getTesterCompletedProjectsError,
  getTesterPausedProjectsSuccess,
  getTesterPausedProjectsError,
  getTesterProjectByIDSuccess,
  getTesterProjectByIDError,
  getTesterListedBugsSuccess,
  getTesterListedBugsError,
  getProjectListedBugsSuccess,
  getProjectListedBugsError,
  reportNewBugSuccess,
  reportNewBugError,
  getCommunityStatesSuccess,
  getCommunityStatesError,
  changePasswordSuccess,
  changePasswordError,
  getWalletEarningSuccess,
  getWalletEarningError,
  getPaidEarningSuccess,
  getPaidEarningError,
  getAllSkillsSuccess,
  getAllSkillsError,
  createTesterSkillSuccess,
  createTesterSkillError,
  getTesterSkillsSuccess,
  getTesterSkillsError,
  getSingleBugDataSuccess,
  getSingleBugDataError,
  deleteTesterSkillSuccess,
  deleteTesterSkillError,
  getEarningGraphSuccess,
  getEarningGraphError,
  deleteTesterAccountSuccess,
  deleteTesterAccountError,
  updateProfileSuccess,
  updateProfileError,
  updateProfileWithoutImageSuccess,
  updateProfileWithoutImageError,
  uploadFeedbackSuccess,
  uploadFeedbackError,
  uploadTestimonialSuccess,
  uploadTestimonialError,
  removeProfilePictureSuccess,
  removeProfilePictureError,
  getProjectBugsExportSuccess,
  getProjectBugsExportError,
  getAssignedBugsSuccess,
  getAssignedBugsError,
  addCommentSuccess,
  addCommentError,
  getCommentSuccess,
  getCommentError
} from "./actions";

import baseDomain, {
  getTesterLeaderboardProjectsReq,
  getTesterDevicesReq,
  createDeviceReq,
  deleteDeviceReq,
  getTesterNotificationReq,
  getTesterLeaderboardReq,
  getTesterLiveProjectsReq,
  getTesterCompletedProjectsReq,
  getTesterPausedProjectsReq,
  getTesterProjectByIDReq,
  getTesterListedBugsReq,
  getProjectListedBugsReq,
  reportNewBugReq,
  getCommunityStatesReq,
  changePassowrdReq,
  getWalletEarningReq,
  getPaidEarningReq,
  getAllSkillsReq,
  createTesterSkillReq,
  getTesterSkillsReq,
  fileUploadReq,
  getSingleBugDataReq,
  deleteTesterSkillReq,
  getEarningGraphReq,
  deleteAccountReq,
  updateProfileReq,
  uploadFeedbackReq,
  uploadTestimonialReq,
  deleteProfilePicture,
  getAssignedBugsReq,
  addCommentReq,
  getCommentReq
} from "../../constants/apiRoutes";

import axios from "axios";

const getTesterLeaderboardProjectsAPI = async () => {
  return axios.get(`${baseDomain}${getTesterLeaderboardProjectsReq}`);
};

const getTesterLeaderboardAPI = async () => {
  return axios.get(`${baseDomain}${getTesterLeaderboardReq}`);
};

const getTesterLiveProjectsAPI = async () => {
  return axios.get(`${baseDomain}${getTesterLiveProjectsReq}`);
};

const getTesterDevicesAPI = async () => {
  return axios.get(`${baseDomain}${getTesterDevicesReq}`);
};

const createTesterDevicesAPI = async data => {
  return axios.post(`${baseDomain}${createDeviceReq}`, data);
};

const deleteTesterDeviceAPI = async deviceID => {
  return axios.delete(`${baseDomain}${deleteDeviceReq}/${deviceID}`);
};

const getTesterNotificationsAPI = async () => {
  return axios.get(`${baseDomain}${getTesterNotificationReq}`);
};

const getTesterCompletedProjectsAPI = async () => {
  return axios.get(`${baseDomain}${getTesterCompletedProjectsReq}`);
};

const getTesterPausedProjectsAPI = async () => {
  return axios.get(`${baseDomain}${getTesterPausedProjectsReq}`);
};

const getTesterProjectByIDAPI = async data => {
  return axios.get(`${baseDomain}${getTesterProjectByIDReq}/${data}`);
};

const getTesterListedBugsAPI = async projectID => {
  return axios.get(`${baseDomain}${getTesterListedBugsReq}/${projectID}`);
};

const getProjectListedBugsAPI = async (projectID) => {
  return axios.get(`${baseDomain}${getProjectListedBugsReq}/${projectID}`);
};

const getProjectsBugsExportAPI = async (projectID) => {
  return axios.get(`${baseDomain}${getProjectListedBugsReq}/${projectID}`);
};

const getCommunityStatesAPI = async () => {
  return axios.get(`${baseDomain}${getCommunityStatesReq}`);
};

const reportNewBugAPI = async data => {
  return axios.post(`${baseDomain}${reportNewBugReq}`, data);
};

const changePasswordAPI = async data => {
  return axios.post(`${baseDomain}${changePassowrdReq}`, data);
};

const getWalletEarningAPI = async () => {
  return axios.get(`${baseDomain}${getWalletEarningReq}`);
};

const getPaidEarningAPI = async () => {
  return axios.get(`${baseDomain}${getPaidEarningReq}`);
};

const getAllSkillsAPI = async () => {
  return axios.get(`${baseDomain}${getAllSkillsReq}`);
};

const createTesterSkillAPI = async data => {
  return axios.put(`${baseDomain}${createTesterSkillReq}`, data);
};

const getTesterSkillsAPI = async () => {
  return axios.get(`${baseDomain}${getTesterSkillsReq}`);
};

const getSingleBugDataAPI = async bugID => {
  return await axios.get(`${baseDomain}${getSingleBugDataReq}/${bugID}`);
};

const deleteTesterSkillAPI = async skillID => {
  return axios.delete(`${baseDomain}${deleteTesterSkillReq}/${skillID}`);
};

const getEarningGraphAPI = async data => {
  return axios.post(`${baseDomain}${getEarningGraphReq}`, data);
};

const fileUploadAPI = async (file, type) => {
  return axios.post(`${baseDomain}${fileUploadReq}/${type}`, file);
};

const deleteTesterBankAccountAPI = async accountNo => {
  return axios.delete(`${baseDomain}${deleteAccountReq}/${accountNo}`);
};

const updateProfileAPI = async data => {
  return axios.put(`${baseDomain}${updateProfileReq}`, data);
};

const uploadFeedbackAPI = async data => {
  return axios.post(`${baseDomain}${uploadFeedbackReq}`, data);
};

const uploadTestimonialAPI = async data => {
  return axios.post(`${baseDomain}${uploadTestimonialReq}`, data);
};

const removeProfilePictureAPI = async (userId) => {
  return axios.put(`${baseDomain}${deleteProfilePicture}/${userId}`);
}

const getAssignedBugsAPI = async (userID,projectID) => {
  return axios.get(`${baseDomain}${getAssignedBugsReq}/${userID}/${projectID}`);
}

const addCommentAPI = async (payload)=>{
  return axios.post(`${baseDomain}${addCommentReq}`,payload);
}

const getCommentAPI = async (bugID)=>{
  return axios.get(`${baseDomain}${getCommentReq}/${bugID}`);
}

function* tokenErrorFun() {
  yield put(
    setAlert({
      message: "You are logged in from another device or session expired",
      type: "ERROR",
    })
  );
  yield put(logoutUser());
}

function* getTesterLeaderboardProjects() {
  try {
    const { data } = yield call(getTesterLeaderboardProjectsAPI);
    yield put(getTesterLeaderboardProjectsSuccess(data.data));
  } catch (error) {
    yield tokenErrorFun();
    yield put(getTesterLeaderboardProjectsError(error));
  }
}

function* getTesterLeaderboard() {
  try {
    const { data } = yield call(getTesterLeaderboardAPI);
    yield put(getTesterLeaderboardSuccess(data.data));
  } catch (error) {
    yield tokenErrorFun();
    yield put(getTesterLeaderboardError(error));
  }
}

function* getTesterLiveProjects() {
  try {
    const { data } = yield call(getTesterLiveProjectsAPI);
    yield put(getTesterLiveProjectsSuccess(data.data));
  } catch (error) {
    yield tokenErrorFun();
    yield put(getTesterLiveProjectsError(error));
  }
}

function* getTesterDevices() {
  try {
    const { data } = yield call(getTesterDevicesAPI);
    yield put(getTesterDevicesSuccess(data.data));
  } catch (error) {
    yield tokenErrorFun();
    yield put(getTesterDevicesError(error));
  }
}

function* createTesterDevices({ payload }) {
  const deviceID = payload.deviceID;
  try {
    if (deviceID) yield call(deleteTesterDeviceAPI, deviceID);
    const { data } = yield call(createTesterDevicesAPI, payload);
    yield put(
      setAlert({
        message: deviceID ? "Device Updates Successfully" : "Device Added successfully",
        type: "SUCCESS",
      })
    );
    yield put(setIsDeviceHavingTrue());
    yield put(createTesterDevicesSuccess({ ...data.data, deviceID }));
  } catch (error) {
    yield put(
      setAlert({
        message: deviceID ? "Device could not be updated." : "Device could not be added.",
        type: "ERROR",
      })
    );
    yield put(createTesterDevicesError(error));
  }
}

function* deleteTesterDevice({ deviceID }) {
  try {
    yield call(deleteTesterDeviceAPI, deviceID);
    yield put(
      setAlert({
        message: "Device deleted successfully",
        type: "SUCCESS",
      })
    );
    yield put(deleteTesterDeviceSuccess(deviceID));
  } catch (error) {
    yield tokenErrorFun();
    yield put(deleteTesterDeviceError(error));
  }
}

function* getTesterNotifications() {
  try {
    const { data } = yield call(getTesterNotificationsAPI);
    yield put(getTesterNotificationsSuccess(data.data));
  } catch (error) {
    yield tokenErrorFun();
    yield put(getTesterNotificationsError(error));
  }
}

function* getTesterCompletedProjects() {
  try {
    const { data } = yield call(getTesterCompletedProjectsAPI);
    yield put(getTesterCompletedProjectsSuccess(data.data));
  } catch (error) {
    yield tokenErrorFun();
    yield put(getTesterCompletedProjectsError(error));
  }
}

function* getTesterPausedProjects() {
  try {
    const { data } = yield call(getTesterPausedProjectsAPI);
    yield put(getTesterPausedProjectsSuccess(data.data));
  } catch (error) {
    yield tokenErrorFun();
    yield put(getTesterPausedProjectsError(error));
  }
}

function* getTesterProjectByID({ payload }) {
  try {
    const { data } = yield call(getTesterProjectByIDAPI, payload);
    yield put(getTesterProjectByIDSuccess(data.data));
  } catch (error) {
    yield tokenErrorFun();
    yield put(getTesterProjectByIDError(error));
  }
}

function* getTesterListedBugs({ payload }) {
  try {
    const { data } = yield call(getTesterListedBugsAPI, payload);
    yield put(getTesterListedBugsSuccess(data.data));
  } catch (error) {
    yield tokenErrorFun();
    yield put(getTesterListedBugsError(error));
  }
}

function* getProjectListedBugs(payload) {
  try {
    const { data } = yield call(getProjectListedBugsAPI, payload.projectID);
    yield put(getProjectListedBugsSuccess(data.data));
  } catch (error) {
    yield tokenErrorFun();
    yield put(getProjectListedBugsError(error));
  }
}

function* getProjectBugsExport({payload}){
  try{
    const { data } = yield call(getProjectsBugsExportAPI, payload);
    yield put(getProjectBugsExportSuccess(data.data));
  }catch(error){
    yield put(getProjectBugsExportError());
  }
}

function* getCommunityStates() {
  try {
    const { data } = yield call(getCommunityStatesAPI);
    yield put(getCommunityStatesSuccess(data.data));
  } catch (error) {
    yield tokenErrorFun();
    yield put(getCommunityStatesError(error));
  }
}

function* reportNewBug({ payload, attachment, history }) {
  try {
    let attach = [];
    if (attachment) {
      const fileData = new FormData();
      fileData.append("file", attachment);
      const res = yield call(fileUploadAPI, fileData, "OTHER");
      attach = [res.data.data];
    } else attach = [];
    delete payload.deviceId;
    const { data } = yield call(reportNewBugAPI, { ...payload, attachments: attach });
    yield put(setAlert({ message: "Bug Submitted", type: "SUCCESS" }));
    yield put(reportNewBugSuccess(data.data));
    history.push(`/dashboard/test-taken/projects/active/${payload.projectId}`);
  } catch (error) {
    yield put(reportNewBugError(error));
    yield put(setAlert({
      message: error.response.data.message, type:"ERROR"
    }))
  }
}

function* changePassword({ payload }) {
  try {
    yield call(changePasswordAPI, payload);
    yield put(setAlert({ message: "Password Changed Successfully", type: "SUCCESS" }));
    yield put(changePasswordSuccess());
  } catch (error) {
    yield put(setAlert({ message: error.response.data.message, type: "ERROR" }));
    yield put(changePasswordError(error));
  }
}

function* getWalletEarning() {
  try {
    const { data } = yield call(getWalletEarningAPI);
    yield put(getWalletEarningSuccess(data.data));
  } catch (error) {
    yield tokenErrorFun();
    yield put(getWalletEarningError(error));
  }
}

function* getPaidEarning() {
  try {
    const { data } = yield call(getPaidEarningAPI);
    yield put(getPaidEarningSuccess(data.data));
  } catch (error) {
    yield tokenErrorFun();
    yield put(getPaidEarningError(error));
  }
}

function* getAllSkills() {
  try {
    const { data } = yield call(getAllSkillsAPI);
    yield put(getAllSkillsSuccess(data.data));
  } catch (error) {
    yield tokenErrorFun();
    yield put(getAllSkillsError(error));
  }
}

function* createTesterSkill({ payload }) {
  try {
    const { data } = yield call(createTesterSkillAPI, payload);
    yield put(createTesterSkillSuccess(data.data));
  } catch (error) {
    yield tokenErrorFun();
    yield put(createTesterSkillError(error));
  }
}

function* getTesterSkills() {
  try {
    const { data } = yield call(getTesterSkillsAPI);
    yield put(getTesterSkillsSuccess(data.data));
  } catch (error) {
    yield tokenErrorFun();
    yield put(getTesterSkillsError(error));
  }
}

function* getSingleBugData({ payload }) {
  try {
    const { data } = yield call(getSingleBugDataAPI, payload);
    yield put(getSingleBugDataSuccess(data.data));
  } catch (error) {
    yield tokenErrorFun();
    yield put(getSingleBugDataError(error));
  }
}

function* deleteTesterSkill({ payload }) {
  try {
    yield call(deleteTesterSkillAPI, payload);
    yield put(deleteTesterSkillSuccess(payload));
  } catch (error) {
    yield tokenErrorFun();
    yield put(deleteTesterSkillError(error));
  }
}

function* getEarningGraph({ payload }) {
  try {
    const { data } = yield call(getEarningGraphAPI, payload);
    yield put(getEarningGraphSuccess(data.data));
  } catch (error) {
    yield tokenErrorFun();
    yield put(getEarningGraphError(error));
  }
}

function* deleteTesterBankAccount({ accountNo }) {
  try {
    yield call(deleteTesterBankAccountAPI, accountNo);
    yield put(
      setAlert({
        message: "Account deleted successfully",
        type: "SUCCESS",
      })
    );
    yield put(deleteTesterAccountSuccess(accountNo));
  } catch (error) {
    yield tokenErrorFun();
    yield put(deleteTesterAccountError(error));
  }
}

function* updateProfile({ payload, userImage }) {
  try {
    const fileData = new FormData();
    fileData.append("file", userImage);
    const { data } = yield call(fileUploadAPI, fileData, "PROJECT_ICON");
    yield call(updateProfileAPI, { ...payload, image: data.data });
    yield put(setAlert({ message: "Profile Updated Successfully", type: "SUCCESS" }));
    yield put(updateProfileSuccess());
    window.location.reload();
  } catch (error) {
    yield put(setAlert({ message: error.response.data.message, type: "ERROR" }));
    yield put(updateProfileError(error));
  }
}

function* updateProfileWithoutImage({ payload }) {
  try {
    yield call(updateProfileAPI, payload);
    yield put(setAlert({ message: "Profile Updated Successfully", type: "SUCCESS" }));
    yield put(updateProfileWithoutImageSuccess());
  } catch (error) {
    yield put(setAlert({ message: error.response.data.message, type: "ERROR" }));
    yield put(updateProfileWithoutImageError(error));
  }
}

function* uploadFeedback({ payload }) {
  try {
    yield call(uploadFeedbackAPI, payload);
    yield put(setAlert({ message: "Feedback Submitted Successfully", type: "SUCCESS" }));
    yield put(uploadFeedbackSuccess());
  } catch (error) {
    yield put(setAlert({ message: error.response.data.message, type: "ERROR" }));
    yield put(uploadFeedbackError(error));
  }
}

function* uploadTestimonial({ payload }) {
  try {
    yield call(uploadTestimonialAPI, payload);
    yield put(setAlert({ message: "Testimonial Submitted Successfully", type: "SUCCESS" }));
    yield put(uploadTestimonialSuccess());
  } catch (error) {
    yield put(setAlert({ message: error.response.data.message, type: "ERROR" }));
    yield put(uploadTestimonialError(error));
  }
}

function* workerRemoveProfilePicture({payload}) {
  try{
    yield call(removeProfilePictureAPI,payload);
    yield put(removeProfilePictureSuccess());
    setAlert({
      message:"Profile Picture Removed Successfully",
      type:"SUCCESS"
    })
  }
  catch(error){
   yield put(removeProfilePictureError());
   setAlert({
     message:"Profile Deletion Failed",
     type:"ERROR"
   })
  }
}

function* workerGetAssignedBugs({payload}){
  try{
     const {data} = yield call(getAssignedBugsAPI,payload.userID,payload.projectID);
     yield put(getAssignedBugsSuccess(data.data));
  }catch(error){
    yield put(getAssignedBugsError());
  }
}

function* workerAddComment({payload}){
  try{
    yield call(addCommentAPI,payload);
    yield put(addCommentSuccess());
    setAlert({message:"Comment Added",type:"SUCCESS"});
  }catch(error){
    yield put(addCommentError());
    setAlert({message:"Something went wrong!",type:"ERROR"});
  }
}

function* workerGetComment(payload){
  try{
    const {data} = yield call(getCommentAPI,payload.bugId);
    yield put(getCommentSuccess(data.data));
  }catch(err){
    yield put(getCommentError());
  }
}

export function* watchGetTesterLeaderboardProjects() {
  yield takeEvery(GET_TESTER_LEADERBOARD_PROJECTS, getTesterLeaderboardProjects);
}
export function* watchGetTesterLeaderboard() {
  yield takeEvery(GET_TESTER_LEADERBOARD, getTesterLeaderboard);
}
export function* watchGetTesterLiveProjects() {
  yield takeEvery(GET_TESTER_LIVE_PROJECTS, getTesterLiveProjects);
}
export function* watchGetTesterDevices() {
  yield takeEvery(GET_TESTER_DEVICES, getTesterDevices);
}
export function* watchCreateTesterDevices() {
  yield takeEvery(CREATE_TESTER_DEVICES, createTesterDevices);
}
export function* watchDeleteTesterDevice() {
  yield takeEvery(DELETE_TESTER_DEVICE, deleteTesterDevice);
}
export function* watchGetTesterNotifications() {
  yield takeEvery(GET_TESTER_NOTIFICATIONS, getTesterNotifications);
}
export function* watchGetTesterCompletedProjects() {
  yield takeEvery(GET_TESTER_COMPLETED_PROJECTS, getTesterCompletedProjects);
}
export function* watchGetTesterPausedProjects() {
  yield takeEvery(GET_TESTER_PAUSED_PROJECTS, getTesterPausedProjects);
}
export function* watchGetTesterProjectByID() {
  yield takeEvery(GET_TESTER_PROJECT_BY_ID, getTesterProjectByID);
}
export function* watchGetTesterListedBugs() {
  yield takeEvery(GET_TESTER_LISTED_BUGS, getTesterListedBugs);
}
export function* watchGetProjectListedBugs() {
  yield takeEvery(GET_PROJECT_LISTED_BUGS, getProjectListedBugs);
}
export function* wateGetProjectBugsExport(){
  yield takeEvery(GET_PROJECT_BUGS_EXPORT,getProjectBugsExport);
}
export function* watchGetCommunityStates() {
  yield takeEvery(GET_COMMUNITY_STATES, getCommunityStates);
}
export function* watchReportNewBug() {
  yield takeEvery(REPORT_NEW_BUG, reportNewBug);
}
export function* watchChangePassword() {
  yield takeEvery(CHANGE_PASSWORD, changePassword);
}
export function* watchGetWalletEarning() {
  yield takeEvery(GET_WALLET_EARNING, getWalletEarning);
}
export function* watchGetPaidEarning() {
  yield takeEvery(GET_PAID_EARNING, getPaidEarning);
}
export function* watchGetAllSkills() {
  yield takeEvery(GET_ALL_SKILLS, getAllSkills);
}
export function* watchCreateTesterSkill() {
  yield takeEvery(CREATE_TESTER_SKILL, createTesterSkill);
}
export function* watchGetTesterSkills() {
  yield takeEvery(GET_TESTER_SKILLS, getTesterSkills);
}
export function* watchGetSingleBugData() {
  yield takeEvery(GET_SINGLE_BUG_DATA, getSingleBugData);
}
export function* watchDeleteTesterSkill() {
  yield takeEvery(DELETE_TESTER_SKILL, deleteTesterSkill);
}
export function* watchGetEarningGraph() {
  yield takeEvery(GET_EARNING_GRAPH, getEarningGraph);
}
export function* watchupdateProfile() {
  yield takeEvery(UPDATE_PROFILE, updateProfile);
}
export function* watchupdateProfileWithoutImage() {
  yield takeEvery(UPDATE_PROFILE_WITHOUT_IMAGE, updateProfileWithoutImage);
}
export function* watchuploadFeedback() {
  yield takeEvery(UPLOAD_FEEDBACK, uploadFeedback);
}
export function* watchuploadTestimonial() {
  yield takeEvery(UPLOAD_TESTIMONIAL, uploadTestimonial);
}
export function* watchDeleteTesterBankAccount() {
  yield takeEvery(DELETE_TESTER_ACCOUNT, deleteTesterBankAccount);
}
export function* watchRemoveProfilePicture(){
  yield takeEvery(DELETE_PROFILE_PICTURE, workerRemoveProfilePicture);
}
export function* watchGetAssignedBugs(){
  yield takeEvery(GET_ASSIGNED_BUGS,workerGetAssignedBugs);
}
export function* watchAddComment(){
  yield takeEvery(ADD_COMMENT,workerAddComment);
}
export function* watchGetComment(){
  yield takeEvery(GET_COMMENT,workerGetComment);
}

export default function* rootSaga() {
  yield all([
    fork(watchGetTesterLeaderboardProjects),
    fork(watchGetTesterLeaderboard),
    fork(watchGetTesterLiveProjects),
    fork(watchGetTesterDevices),
    fork(watchCreateTesterDevices),
    fork(watchDeleteTesterDevice),
    fork(watchGetTesterNotifications),
    fork(watchGetTesterCompletedProjects),
    fork(watchGetTesterPausedProjects),
    fork(watchGetTesterProjectByID),
    fork(watchGetTesterListedBugs),
    fork(watchGetProjectListedBugs),
    fork(wateGetProjectBugsExport),
    fork(watchGetCommunityStates),
    fork(watchReportNewBug),
    fork(watchChangePassword),
    fork(watchGetWalletEarning),
    fork(watchGetPaidEarning),
    fork(watchGetAllSkills),
    fork(watchCreateTesterSkill),
    fork(watchGetTesterSkills),
    fork(watchGetSingleBugData),
    fork(watchDeleteTesterSkill),
    fork(watchGetEarningGraph),
    fork(watchDeleteTesterBankAccount),
    fork(watchupdateProfile),
    fork(watchupdateProfileWithoutImage),
    fork(watchuploadFeedback),
    fork(watchuploadTestimonial),
    fork(watchRemoveProfilePicture),
    fork(watchGetAssignedBugs),
    fork(watchAddComment),
    fork(watchGetComment)
  ]);
}
