import React, { useState } from "react";

import rightArrow from "../../assets/icons/rightAro.svg";
import leftArrow from "../../assets/icons/leftAro.svg";

const PlatformData = ({ platformData, setplatformData, iconBlue }) => {
  const [x, setX] = useState(0);
  const SetPlatFormData = e => {
    const { id, value } = e.target;
    const updatePlatfroms = platformData.map(item => {
      if (item.platform === id) {
        item.hours = value;
        item.price = value * 5;
      }
      return item;
    });
    setplatformData([...updatePlatfroms]);
  };

  const moveRightArrow = () => {
    x === -100 * (platformData.length - 1) ? setX(0) : setX(x - 100);
  };
  const moveLeftArrow = () => {
    x === 0 ? setX(0) : setX(x + 100);
  };
  return (
    <div style={{ display: "flex" }}>
      <button className="glb-btn" onClick={moveLeftArrow}>
        <img src={leftArrow} alt="" />
      </button>
      <div className="select-platform-time">
        {platformData.map((data, i) => (
          <div className="select-platform-time-container" key={i} style={{ transform: `translateX(${x}%)` }}>
            <div className="input-fields">
              <label>Testing Man Hours</label>
              <input type="number" id={data.platform} min={0} onChange={SetPlatFormData} value={data.hours} />
            </div>
            <div className="logo">
              <img src={iconBlue[data.platform]} alt="" />
            </div>
            <div className="input-fields">
              <label>Final Price</label>
              <input type="text" value={` $ ${data.hours * 5}`} />
            </div>
          </div>
        ))}
      </div>
      <button className="glb-btn" onClick={moveRightArrow}>
        <img src={rightArrow} alt="" />
      </button>
    </div>
  );
};

export default PlatformData;
