import React from "react";
import { connect } from "react-redux";
import { CloseSign } from "../../../../assets/icons/icons";

import axios from "axios";
import baseDomain, { setBankDefaultReq, deleteAccountReq } from "../../../../constants/apiRoutes";
import { setAlert } from "../../../../redux/Alerts/actions";
import settings from "../../../../constants/settings";
import { useAlertConfirmation } from "../../../../Services/AlertService";

import NoDataMessage from "../../NoDataMessage";

import "../style.scss";

const TesterBankTable = ({ bankDetailsArray, getInitailData, showItem, setAlert }) => {
  const setDefault = async (isDefault, bankID) => {
    if (!isDefault) {
      await axios.put(`${baseDomain}${setBankDefaultReq}/${bankID}`);
      await getInitailData();
    }
  };

  const fallBackAlert = useAlertConfirmation();

  const handleDelete = async accountNo => {
      await fallBackAlert({
        message: "Are you sure ? you want to delete the Account",
        type: "ERROR",
        successText: "Yes",
        cancelText: "No",
      });
    // deleteTesterAccount(accountNo);
    await axios.delete(`${baseDomain}${deleteAccountReq}/${accountNo}`);
    setAlert({ message: "Bank Deleted", type: "SUCCESS" });
    await getInitailData();
  };
  return (
    <div className="dashboard-table tester-bank-table">
      {bankDetailsArray.length === 0 ? (
        <NoDataMessage message={settings.tester_bank_table.message} />
      ) : (
        <table>
          <tr>
            {settings.tester_bank_table.tableHeadingArray.map((head, i) => (
              <th key={i}>{head}</th>
            ))}
            {showItem && (
              <th>Remove</th>
            )}
          </tr>
          {bankDetailsArray.map((item, i) => (
            <tr key={i}>
              <td>{item.name}</td>
              <td>{item.accountNumber}</td>
              <td>{item.ifsc}</td> 
              <td>{item.bankDetail && item.bankDetail.BANK}</td>
              <td>{item.bankDetail && item.bankDetail.BRANCH}</td>
              <td className={item.isDefault ? "approved" : "declined cursor"} onClick={() => showItem ? setDefault(item.isDefault, item.id) : null }>
                {item.isDefault ? "Default" : "Set Default"}
              </td>
              {showItem && (
                <td className="remove-device" onClick={() => handleDelete(item.id)}>
                  {CloseSign}
                </td>
              )}
            </tr>
          ))}
        </table>
      )}
    </div>
  );
}; 

export default connect(null, { setAlert })(TesterBankTable);
