import React, { useEffect } from "react";
import { connect } from "react-redux";

import { CloseSign } from "../../assets/icons/icons";
import { removeAlert } from "../../redux/Alerts/actions";
import settings from "../../constants/settings";

import "./style.scss";
const Alerts = ({ showAlert, message, type, removeAlert, isFallBackAlert, successText, cancelText, successCallBack, cancelCallBack }) => {
  useEffect(() => {
    let alertTimeout;
    if (!isFallBackAlert)
      alertTimeout = setTimeout(() => {
        removeAlert();
      }, 3000);
    return () => clearTimeout(alertTimeout);
    // eslint-disable-next-line
  }, [showAlert]);

  const handleCloseAlert = () => {
    if (!isFallBackAlert) removeAlert();
    else cancelCallBack();
  };

  const handleSuccess = () => {
    if (isFallBackAlert) successCallBack();
  };

  return (
    showAlert && (
      <div className="app-alerts">
        <div className="app-alert-head">
          <div className="icon" type={type || "ERROR"} />
          <div className="message">{message || settings.alerts.message}</div>
          <div className="close-alert" onClick={handleCloseAlert}>
            {CloseSign}
          </div>
        </div>
        {isFallBackAlert && (
          <div className="app-alert-callback">
            <div className="btn success-btn" onClick={handleSuccess}>
              {successText || "Yes"}
            </div>
            <div className="btn cancel-btn" onClick={handleCloseAlert}>
              {cancelText || "No"}
            </div>
          </div>
        )}
      </div>
    )
  );
};

const mapStateToProps = ({ alert }) => ({
  message: alert.message,
  type: alert.alertType,
  showAlert: alert.showAlert,
});

export default connect(mapStateToProps, { removeAlert })(Alerts);
