import React from "react";
import { useHistory } from "react-router-dom";
import "./style.scss";
const MultiplierCard = ({ heading, listArray, showBtn = true, btnValue, btnOnClick, img, alt }) => {
  const history = useHistory();
  return (
    <div className="multiplier-card">
      <div className="heading">{heading}</div>
      <div className="img">
        <img src={img} alt={alt} />
      </div>

      <div className="list">
        {listArray.map((item, i) => (
          <ul key={i}>
            {!item.link && <li>{item.name}</li>}
            {item.link && (
              <li onClick={() => history.push(item.link)} className="pointer">
                {item.name}
              </li>
            )}
          </ul>
        ))}
      </div>
      {showBtn && (
        <button className="glb-btn" onClick={btnOnClick}>
          {btnValue}
        </button>
      )}
    </div>
  );
};

export default MultiplierCard;
