import React, { useEffect } from "react";
import { connect } from "react-redux";

import { setDashboardRoute } from "../../../redux/Routing/actions";
import { getTesterNotifications } from "../../../redux/TesterDashboard/actions";
import { setIsNotification } from "../../../redux/auth/actions"; 

import Loader from "../../Loader";

import DashboardHeading from "../../Common/DashboardHeading";
import NotificationTable from "../../Common/DashboardTables/NotificationTable";
import settings from "../../../constants/settings";

import "./style.scss";

const Notifications = ({ setDashboardRoute, componentID, testerNotifications, getTesterNotifications, isLoading, setIsNotification }) => {
  useEffect(() => {
    setDashboardRoute(componentID);
    getTesterNotifications();
    setIsNotification(); 
    // eslint-disable-next-line
  }, []);
  return (
    <div className="client-dash-notifications">
      <div className="notifications">
        <DashboardHeading heading={settings.notifications.heading1.light} headingStrong={settings.notifications.heading1.bold} showBtn={false} type="notifications" />
        {isLoading ? (
          <Loader />
        ) : (
          <div className="cards">
            <NotificationTable tableContentArray={testerNotifications} />
          </div>
        )}
      </div>
    </div>
  );
};

const mapStateToProps = ({ testerDashboard }) => ({
  testerNotifications: testerDashboard.testerNotifications,
  isLoading: testerDashboard.isLoading,
});

export default connect(mapStateToProps, { setDashboardRoute, getTesterNotifications, setIsNotification })(Notifications);
