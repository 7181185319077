import React, { useEffect } from "react";
import { connect } from "react-redux";

import { setDashboardRoute } from "../../../redux/Routing/actions";

import AllProjectsList from "./Components/AllProjectsList";
import BugList from "./Components/BugList";
import CompletedProjectBug from "../../DashboardTester/TestTaken/Components/SubmittedBugDetails";

import { useRouteMatch, Route, Switch, Redirect } from "react-router-dom";

const ManageBugs = ({ setDashboardRoute, componentID }) => {
  const match = useRouteMatch();
  useEffect(() => {
    setDashboardRoute(componentID);
    // eslint-disable-next-line
  }, []);
  return (
    <Switch>
      <Redirect exact from={`${match.url}/`} to={`${match.url}/projectlist`} />
      <Route path={`${match.url}/projectlist`} exact={true} component={AllProjectsList} />
      <Route path={`${match.url}/projectlist/:projectID/bugs`} exact={true} component={BugList} />
      <Route path={`${match.url}/projectlist/:projectID/bugs/:bugID/details`} exact={true} component={CompletedProjectBug} />
      <Redirect to={`/dashboard/home`} />
    </Switch>
  );
};

export default connect(null, { setDashboardRoute })(ManageBugs);