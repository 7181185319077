import { SET_ALERT, REMOVE_ALERT } from "../actions";

export const setAlert = payload => ({
  type: SET_ALERT,
  payload,
});

export const removeAlert = () => ({
  type: REMOVE_ALERT,
});
