import React from "react";
import axios from "axios";

import baseDomain, { submitBankDetailsReq } from "../../../../../../constants/apiRoutes";
import { CloseSign } from "../../../../../../assets/icons/icons";
import settings from "../../../../../../constants/settings";
import "./style.scss";
const Modal = ({ showModal, ifscData, closeModal, userDetails, setAlert }) => {
  const modalClose = () => {
    closeModal();
  };

  const submitBankDetails = async () => {
    try {
      await axios.post(`${baseDomain}${submitBankDetailsReq}`, userDetails);
      setAlert({
        message: settings.transfer_amount_modals.added_successfully,
        type: "SUCCESS",
      });
      closeModal();
    } catch (error) {
      setAlert({
        message: error.response.data.message || "Bank Already Exist",
        type: "WARNING",
      });
      closeModal();
    }
  };
  return (
    <div className={showModal ? "transfer-amount-modal" : "none"}>
      <div className="transfer-amount-modal-container">
        <div className="heading">
          <div className="text">{settings.transfer_amount_modals.heading1}</div>
          <div className="close-icon" onClick={modalClose}>
            {CloseSign}
          </div>
        </div>
        <div className="body">
          <div className="display-data">
            <div className="label">{settings.transfer_amount_modals.name}</div>
            <div className="data">{userDetails.name}</div>
          </div>
          <div className="display-data">
            <div className="label">{settings.transfer_amount_modals.account}</div>
            <div className="data">{userDetails.accountNumber}</div>
          </div>
          <div className="display-data">
            <div className="label">{settings.transfer_amount_modals.ifsc}</div>
            <div className="data">{userDetails.ifsc}</div>
          </div>
          <div className="display-data">
            <div className="label">{settings.transfer_amount_modals.bank}</div>
            <div className="data">{ifscData.BANK}</div>
          </div>
          <div className="display-data">
            <div className="label">{settings.transfer_amount_modals.branch}</div>
            <div className="data">{ifscData.BRANCH}</div>
          </div>
        </div>
        <div className="submit">
          <button className="glb-btn" onClick={submitBankDetails}>
            {settings.transfer_amount_modals.btnText}
          </button>
        </div>
      </div>
    </div>
  );
};

export default Modal;
