import React from "react";

import PlatformPicker from "../../../../Common/PlatformPicker";
import DashboardSubHeading from "../../../../Common/DashboardSubHeading";
import settings from "../../../../../constants/settings";

import "./style.scss";

const Step1 = ({ formDataStepOne, setFormDataStepOne, setCartData, cartData, setPlatformFilter, platformFilter }) => {
  const { title, platform } = formDataStepOne;
  return (
    <div className="addtest-step1">
      <div className="project-title">
        <DashboardSubHeading
          heading={settings.client_add_tests_step1.heading1.light}
          headingStrong={settings.client_add_tests_step1.heading1.bold}
          showBtn={false}
        />
        <div className="card">
          <input
            type="text"
            className="addtest-input"
            placeholder={settings.client_add_tests_step1.placeholder1}
            value={title}
            name="title"
            onChange={e =>
              setFormDataStepOne({
                ...formDataStepOne,
                title: e.target.value,
              })
            }
          />
        </div>
      </div>

      <div className="choose-platfrom">
        <DashboardSubHeading
          heading={settings.client_add_tests_step1.heading2.light}
          headingStrong={settings.client_add_tests_step1.heading2.bold}
          showBtn={false}
        />
        <div className="card">
          <PlatformPicker
            platformFilter={platformFilter}
            setPlatformFilter={plt => {
              setPlatformFilter(plt);
              setCartData({
                ...cartData,
                platformName: plt.name,
              });
              setFormDataStepOne({
                ...formDataStepOne,
                platform: plt.id,
              });
            }}
            platformId={platform}
            setPlatform={(platformId, name) => {
              setFormDataStepOne({
                ...formDataStepOne,
                platform: platformId,
              });
              setCartData({
                ...cartData,
                platformName: name,
              });
            }}
          />
        </div>
      </div>
    </div>
  );
};

export default Step1;
