import React,{useState} from 'react';
import { Link } from 'react-router-dom';
import "./style.scss";

const NavDropdown = ({MenuItems}) => {

    const [click, setClick] = useState(false);

    const handleClick = () => setClick(!click);

    return (
        <>
        <ul
        style={{display:"block"}}
          onClick={handleClick}
          className="dropdownItems"
        >
          {MenuItems.map((item, index) => {
            return (
              <li key={index}>
                <Link
                  className={item.cName}
                  to={item.path}
                  onClick={() => setClick(false)}
                >
                  {item.title}
                </Link>
              </li>
            );
          })}
        </ul>
      </>
    )
}

export default NavDropdown;

