import React, { Fragment } from "react";
import PricingComponent from "../../components/Pricing";
import { withData, withLayout } from "../../layouts";
import AutoLogin from "../../components/AutoLogin";
import HalfPageBackground from "../../components/Layout/HalfPageBackground"

import "./style.scss";
const Pricing = ({ settings }) => {
  return (
    <Fragment>
      <AutoLogin />
      <HalfPageBackground />
      <PricingComponent data={settings.pricing} />
      
    </Fragment>
  );
};
export default withData(withLayout(Pricing, true, true));
