import {
  GET_ADMIN_ALL_CLIENTS,
  GET_ADMIN_ALL_CLIENTS_SUCCESS,
  GET_ADMIN_ALL_CLIENTS_ERROR,
  GET_ADMIN_ACTIVE_CLIENTS,
  GET_ADMIN_ACTIVE_CLIENTS_SUCCESS,
  GET_ADMIN_ACTIVE_CLIENTS_ERROR,
  GET_ADMIN_ALL_TESTER,
  GET_ADMIN_ALL_TESTER_SUCCESS,
  GET_ADMIN_ALL_TESTER_ERROR,
  GET_ADMIN_ACTIVE_TESTER,
  GET_ADMIN_ACTIVE_TESTER_SUCCESS,
  GET_ADMIN_ACTIVE_TESTER_ERROR,
  GET_ADMIN_DASHBOARD_STATS,
  GET_ADMIN_DASHBOARD_STATS_SUCCESS,
  GET_ADMIN_DASHBOARD_STATS_ERROR,
  GET_ADMIN_DASHBOARD_SEED,
  GET_ADMIN_DASHBOARD_SEED_SUCCESS,
  GET_ADMIN_DASHBOARD_SEED_ERROR,
  PUT_AGENCY_ACTIVATE,
  PUT_AGENCY_ACTIVATE_SUCCESS,
  PUT_AGENCY_ACTIVATE_ERROR,
  PUT_AGENCY_DEACTIVATE,
  PUT_AGENCY_DEACTIVATE_SUCCESS,
  PUT_AGENCY_DEACTIVATE_ERROR,
  PUT_TESTER_ACTIVATE,
  PUT_TESTER_ACTIVATE_SUCCESS,
  PUT_TESTER_ACTIVATE_ERROR,
  PUT_TESTER_DEACTIVATE,
  PUT_TESTER_DEACTIVATE_SUCCESS,
  PUT_TESTER_DEACTIVATE_ERROR,
  GET_TESTER_ALL_PROJECTS,
  GET_TESTER_ALL_PROJECTS_SUCCESS,
  GET_TESTER_ALL_PROJECTS_ERROR,
  PUT_TESTER_MAKE_REVIEWER,
  PUT_TESTER_MAKE_REVIEWER_SUCCESS,
  PUT_TESTER_MAKE_REVIEWER_ERROR,
  PUT_TESTER_MAKE_TESTER,
  PUT_TESTER_MAKE_TESTER_SUCCESS,
  PUT_TESTER_MAKE_TESTER_ERROR,
  GET_TESTER_SINGLE_PROJECTS,
  GET_TESTER_SINGLE_PROJECTS_SUCCESS,
  GET_TESTER_SINGLE_PROJECTS_ERROR,
  GET_FEEDBACKS,
  GET_FEEDBACKS_SUCCESS,
  GET_FEEDBACKS_ERROR,
  GET_ADMIN_LIVE_PROJECTS,
  GET_ADMIN_LIVE_PROJECTS_SUCCESS,
  GET_ADMIN_LIVE_PROJECTS_ERROR,
  GET_ADMIN_PAUSED_PROJECTS,
  GET_ADMIN_PAUSED_PROJECTS_SUCCESS,
  GET_ADMIN_PAUSED_PROJECTS_ERROR,
  GET_ADMIN_COMPLETED_PROJECTS,
  GET_ADMIN_COMPLETED_PROJECTS_SUCCESS,
  GET_ADMIN_COMPLETED_PROJECTS_ERROR,
  GET_TESTER_BANK_DETAILS,
  GET_TESTER_BANK_DETAILS_SUCCESS,
  GET_TESTER_BANK_DETAILS_ERROR,
  GET_TESTER_DEVICE_DETAILS,
  GET_TESTER_DEVICE_DETAILS_SUCCESS,
  GET_TESTER_DEVICE_DETAILS_ERROR,
  GET_TESTER_SKILLSET_DETAILS,
  GET_TESTER_SKILLSET_DETAILS_SUCCESS,
  GET_TESTER_SKILLSET_DETAILS_ERROR,
  INVITE_TESTER_ADMIN,
  INVITE_TESTER_ADMIN_SUCCESS,
  INVITE_TESTER_ADMIN_ERROR,
  INVITE_PARTICULAR_TESTER_ADMIN,
  INVITE_PARTICULAR_TESTER_ADMIN_SUCCESS,
  INVITE_PARTICULAR_TESTER_ADMIN_ERROR,
  INVITE_SELECTED_TESTER_ADMIN,
  GET_ADMIN_ALL_EARNING,
  GET_ADMIN_ALL_EARNING_SUCCESS,
  GET_ADMIN_ALL_EARNING_ERROR,
  PUT_ADMIN_DASHBOARD_MARK_AS_COMPLETE,
  PUT_ADMIN_DASHBOARD_MARK_AS_COMPLETE_SUCCESS,
  PUT_ADMIN_DASHBOARD_MARK_AS_COMPLETE_ERROR,
  CREATE_BADGE,
  CREATE_BADGE_SUCCESS,
  CREATE_BADGE_ERROR,
  GET_BADGES,
  GET_BADGES_SUCCESS,
  GET_BADGES_ERROR,
  CREATE_EVENT_BADGE,
  CREATE_EVENT_BADGE_SUCCESS,
  CREATE_EVENT_BADGE_ERROR,
  GET_EVENT_BADGES,
  GET_EVENT_BADGES_SUCCESS,
  GET_EVENT_BADGES_ERROR,
  DELETE_BADGE,
  DELETE_BADGE_SUCCESS,
  DELETE_BADGE_ERROR,
  EDIT_BADGE,
  EDIT_BADGE_SUCCESS,
  EDIT_BADGE_ERROR,
  DELETE_EVENT,
  DELETE_EVENT_SUCCESS,
  DELETE_EVENT_ERROR,
  EDIT_EVENT,
  EDIT_EVENT_SUCCESS,
  EDIT_EVENT_ERROR,
  CLEAR_SELECTED_TESTER_ADMIN,
  GET_TESTIMONIALS,
  GET_TESTIMONIALS_SUCCESS,
  GET_TESTIMONIALS_ERROR,
  APPROVE_TESTIMONIAL,
  APPROVE_TESTIMONIAL_SUCCESS,
  APPROVE_TESTIMONIAL_ERROR,
  REJECT_TESTIMONIAL,
  REJECT_TESTIMONIAL_SUCCESS,
  REJECT_TESTIMONIAL_ERROR,
  UPDATE_TESTIMONIAL,
  UPDATE_TESTIMONIAL_SUCCESS,
  UPDATE_TESTIMONIAL_ERROR,
  GET_ADMIN_TESTERS_EXPORT,
  GET_ADMIN_TESTERS_EXPORT_SUCCESS,
  GET_ADMIN_TESTERS_EXPORT_ERROR,
  GET_FINANCE_REPORT,
  GET_FINANCE_REPORT_SUCCESS,
  GET_FINANCE_REPORT_ERROR,
  GET_REVIEWER,
  GET_REVIEWER_SUCCESS,
  GET_REVIEWER_ERROR,
  ASSIGN_REVIEWER_BUG,
  ASSIGN_REVIEWER_BUG_SUCCESS,
  ASSIGN_REVIEWER_BUG_ERROR,
  UPDATE_REVIEWER,
  UPDATE_REVIEWER_SUCCESS,
  UPDATE_REVIEWER_ERROR
} from "../actions";

// Admin All Clients
export const getAdminAllClients = () => ({
  type: GET_ADMIN_ALL_CLIENTS,
});
export const getAdminAllClientsSuccess = payload => ({
  type: GET_ADMIN_ALL_CLIENTS_SUCCESS,
  payload,
});
export const getAdminAllClientsError = () => ({
  type: GET_ADMIN_ALL_CLIENTS_ERROR,
});

// Admin Active Clients
export const getAdminActiveClients = () => ({
  type: GET_ADMIN_ACTIVE_CLIENTS,
});
export const getAdminActiveClientsSuccess = payload => ({
  type: GET_ADMIN_ACTIVE_CLIENTS_SUCCESS,
  payload,
});
export const getAdminActiveClientsError = () => ({
  type: GET_ADMIN_ACTIVE_CLIENTS_ERROR,
});

// Admin All Testers
export const getAdminAllTesters = (payload) => ({
  type: GET_ADMIN_ALL_TESTER,
  payload
});
export const getAdminAllTestersSuccess = payload => ({
  type: GET_ADMIN_ALL_TESTER_SUCCESS,
  payload,
});
export const getAdminAllTestersError = () => ({
  type: GET_ADMIN_ALL_TESTER_ERROR,
});

// Admin Tester Export
export const getAdminTesterExport = () =>({
  type: GET_ADMIN_TESTERS_EXPORT,
});

export const getAdminTesterExportSuccess = (payload) =>({
  type: GET_ADMIN_TESTERS_EXPORT_SUCCESS,
  payload
});

export const getAdminTesterExportError = () =>({
  type: GET_ADMIN_TESTERS_EXPORT_ERROR,
});

// Admin All Earnings
export const getAdminAllEarnings = () => ({ 
  type: GET_ADMIN_ALL_EARNING,
}); 
export const getAdminAllEarningsSuccess = payload => ({
  type: GET_ADMIN_ALL_EARNING_SUCCESS,
  payload, 
});
export const getAdminAllEarningsError = () => ({
  type: GET_ADMIN_ALL_EARNING_ERROR,
}); 

// Admin Active Testers
export const getAdminActiveTesters = () => {
  return {
  type: GET_ADMIN_ACTIVE_TESTER,
}
};
export const getAdminActiveTestersSuccess = payload => ({
  type: GET_ADMIN_ACTIVE_TESTER_SUCCESS,
  payload,
});
export const getAdminActiveTestersError = () => ({
  type: GET_ADMIN_ACTIVE_TESTER_ERROR,
});

// Admin Dashboard Stats
// Seed Dashboard
export const getAdminSeedDashboard = () => ({
  type: GET_ADMIN_DASHBOARD_SEED,
});
export const getAdminSeedDashboardSuccess = payload => ({
  type: GET_ADMIN_DASHBOARD_SEED_SUCCESS,
  payload,
});
export const getAdminSeedDashboardError = () => ({
  type: GET_ADMIN_DASHBOARD_SEED_ERROR,
});

// Dashboard Stats
export const getAdminDashboardStats = () => ({
  type: GET_ADMIN_DASHBOARD_STATS,
});
export const getAdminDashboardStatsSuccess = payload => ({
  type: GET_ADMIN_DASHBOARD_STATS_SUCCESS,
  payload,
});
export const getAdminDashboardStatsError = () => ({
  type: GET_ADMIN_DASHBOARD_STATS_ERROR,
});

// ACTIVATE AGENCY
export const agencyActivate = payload => ({
  type: PUT_AGENCY_ACTIVATE,
  payload,
});
export const agencyActivateSuccess = payload => ({
  type: PUT_AGENCY_ACTIVATE_SUCCESS,
  payload,
});
export const agencyActivateError = () => ({
  type: PUT_AGENCY_ACTIVATE_ERROR,
});

// DEACTIVATE AGENCY
export const agencyDeactivate = payload => ({
  type: PUT_AGENCY_DEACTIVATE,
  payload,
});
export const agencyDeactivateSuccess = payload => ({
  type: PUT_AGENCY_DEACTIVATE_SUCCESS,
  payload,
});
export const agencyDeactivateError = () => ({
  type: PUT_AGENCY_DEACTIVATE_ERROR,
});

// ACTIVATE TESTER
export const testerActivate = payload => ({
  type: PUT_TESTER_ACTIVATE,
  payload,
});
export const testerActivateSuccess = payload => ({
  type: PUT_TESTER_ACTIVATE_SUCCESS,
  payload,
});
export const testerActivateError = () => ({
  type: PUT_TESTER_ACTIVATE_ERROR,
});

// DEACTIVATE TESTER
export const testerDeactivate = payload => ({
  type: PUT_TESTER_DEACTIVATE,
  payload,
});
export const testerDeactivateSuccess = payload => ({
  type: PUT_TESTER_DEACTIVATE_SUCCESS,
  payload,
});
export const testerDeactivateError = () => ({
  type: PUT_TESTER_DEACTIVATE_ERROR,
});

// TESTER ALL PROJECTS
export const getTesterAllProjects = payload => ({
  type: GET_TESTER_ALL_PROJECTS,
  payload,
});
export const getTesterAllProjectsSuccess = payload => ({
  type: GET_TESTER_ALL_PROJECTS_SUCCESS,
  payload,
});
export const getTesterAllProjectsError = () => ({
  type: GET_TESTER_ALL_PROJECTS_ERROR,
});
// MARK_AS_COMPLETE
export const putAdminDashboardMarkAsComplete = payload => ({
  type: PUT_ADMIN_DASHBOARD_MARK_AS_COMPLETE,
  payload,
})
export const putAdminDashboardMarkAsCompleteSuccess = payload => ({
  type: PUT_ADMIN_DASHBOARD_MARK_AS_COMPLETE_SUCCESS,
  payload,
})
export const putAdminDashboardMarkAsCompleteError = () => ({
  type: PUT_ADMIN_DASHBOARD_MARK_AS_COMPLETE_ERROR,
})

// TESTER SINGLE PROJECT DETAILS
export const getTesterSingleProject = payload => ({
  type: GET_TESTER_SINGLE_PROJECTS,
  payload,
});
export const getTesterSingleProjectSuccess = payload => ({
  type: GET_TESTER_SINGLE_PROJECTS_SUCCESS,
  payload,
});
export const getTesterSingleProjectError = () => ({
  type: GET_TESTER_SINGLE_PROJECTS_ERROR,
});

// MAKE REVIEWER
export const putTesterMakeReviewer = payload => ({
  type: PUT_TESTER_MAKE_REVIEWER,
  payload,
});
export const putTesterMakeReviewerSuccess = payload => ({
  type: PUT_TESTER_MAKE_REVIEWER_SUCCESS,
  payload,
});
export const putTesterMakeReviewerError = () => ({
  type: PUT_TESTER_MAKE_REVIEWER_ERROR,
});

// MAKE TESTER
export const putTesterMakeTester = payload => ({
  type: PUT_TESTER_MAKE_TESTER,
  payload,
});
export const putTesterMakeTesterSuccess = payload => ({
  type: PUT_TESTER_MAKE_TESTER_SUCCESS,
  payload,
});
export const putTesterMakeTesterError = () => ({
  type: PUT_TESTER_MAKE_TESTER_ERROR,
});

// GET FEEDBACK
export const getFeedbacks = payload => ({
  type: GET_FEEDBACKS,
  payload,
});
export const getFeedbacksSuccess = payload => ({
  type: GET_FEEDBACKS_SUCCESS,
  payload,
});
export const getFeedbacksError = () => ({
  type: GET_FEEDBACKS_ERROR,
});

// Admin Live Projects
export const getAdminLiveProjects = payload => ({
  type: GET_ADMIN_LIVE_PROJECTS,
  payload,
});
export const getAdminLiveProjectsSuccess = payload => ({
  type: GET_ADMIN_LIVE_PROJECTS_SUCCESS,
  payload,
});
export const getAdminLiveProjectsError = () => ({
  type: GET_ADMIN_LIVE_PROJECTS_ERROR,
});

// Admin Paused Projects
export const getAdminPausedProjects = payload => ({
  type: GET_ADMIN_PAUSED_PROJECTS,
  payload,
});
export const getAdminPausedProjectsSuccess = payload => ({
  type: GET_ADMIN_PAUSED_PROJECTS_SUCCESS,
  payload,
});
export const getAdminPausedProjectsError = () => ({
  type: GET_ADMIN_PAUSED_PROJECTS_ERROR,
});

// Admin Completed Projects
export const getAdminCompletedProjects = payload => ({
  type: GET_ADMIN_COMPLETED_PROJECTS,
  payload,
});
export const getAdminCompletedProjectsSuccess = payload => ({
  type: GET_ADMIN_COMPLETED_PROJECTS_SUCCESS,
  payload,
});
export const getAdminCompletedProjectsError = () => ({
  type: GET_ADMIN_COMPLETED_PROJECTS_ERROR,
});

// Tester Bank Details
export const getTesterBankDetails = payload => ({
  type: GET_TESTER_BANK_DETAILS,
  payload,
});
export const getTesterBankDetailsSuccess = payload => ({
  type: GET_TESTER_BANK_DETAILS_SUCCESS,
  payload,
});
export const getTesterBankDetailsError = () => ({
  type: GET_TESTER_BANK_DETAILS_ERROR,
});

// Tester Device Details
export const getTesterDeviceDetails = payload => ({
  type: GET_TESTER_DEVICE_DETAILS,
  payload,
});
export const getTesterDeviceDetailsSuccess = payload => ({
  type: GET_TESTER_DEVICE_DETAILS_SUCCESS,
  payload,
});
export const getTesterDeviceDetailsError = () => ({
  type: GET_TESTER_DEVICE_DETAILS_ERROR,
}); 

// Tester Skillset Details
export const getTesterSkillsetDetails = payload => ({
  type: GET_TESTER_SKILLSET_DETAILS,
  payload,
});
export const getTesterSkillsetDetailsSuccess = payload => ({
  type: GET_TESTER_SKILLSET_DETAILS_SUCCESS,
  payload,
});
export const getTesterSkillsetDetailsError = () => ({
  type: GET_TESTER_SKILLSET_DETAILS_ERROR,
}); 

// Invite Tester
export const inviteTester = payload => {
  return{
  type: INVITE_TESTER_ADMIN,
  payload,
}
}

export const inviteTesterSuccess = payload => ({
  type: INVITE_TESTER_ADMIN_SUCCESS,
  payload,
});
export const inviteTesterError = () => ({
  type: INVITE_TESTER_ADMIN_ERROR,
});


// Invite Particular Tester
export const inviteParticularTester = payload=> {
  return {
  type: INVITE_PARTICULAR_TESTER_ADMIN,
  payload,

}
};
export const inviteParticularTesterSuccess = (payload) => ({
  type: INVITE_PARTICULAR_TESTER_ADMIN_SUCCESS,
  payload,
});
export const inviteParticularTesterError = () => ({
  type: INVITE_PARTICULAR_TESTER_ADMIN_ERROR,
});

export const createBadge = (payload,badgeIcon) => {
  return{
    type:CREATE_BADGE,
    payload,
    badgeIcon
  }
}
export const createBadgeSuccess = (payload) => {
  return{
    type:CREATE_BADGE_SUCCESS,
    payload,
  }
}
export const createBadgeError = () => {
  return{
    type:CREATE_BADGE_ERROR,
  }
}
export const createEventBadge = (payload) => {
  return{
    type:CREATE_EVENT_BADGE,
    payload,
  }
}
export const createEventBadgeSuccess = (payload) => {
  return{
    type:CREATE_EVENT_BADGE_SUCCESS,
    payload,
  }
}
export const createEventBadgeError = () => {
  return{
    type:CREATE_EVENT_BADGE_ERROR,
  }
}

export const getBadges = () => {
  return{
    type:GET_BADGES
  }
}

export const getBadgesSuccess = (payload) => {
  return{
    type:GET_BADGES_SUCCESS,
    payload
  }
}
export const getBadgesError = () => {
  return{
    type:GET_BADGES_ERROR
  }
}
export const getEventBadges = () => {
  return{
    type:GET_EVENT_BADGES
  }
}

export const getEventBadgesSuccess = (payload) => {
  return{
    type:GET_EVENT_BADGES_SUCCESS,
    payload
  }
}
export const getEventBadgesError = () => {
  return{
    type:GET_EVENT_BADGES_ERROR
  }
}

export const deleteBadge = (payload) => {
  return{
    type:DELETE_BADGE,
    payload,
  }
}
export const deleteBadgeSuccess = () => {
  return{
    type:DELETE_BADGE_SUCCESS,
  }
}
export const deleteBadgeError = () => {
  return{
    type:DELETE_BADGE_ERROR,
  }
}

export const deleteEvent = (payload) => {
  return{
    type:DELETE_EVENT,
    payload,
  }
}
export const deleteEventSuccess = () => {
  return{
    type:DELETE_EVENT_SUCCESS,
  }
}
export const deleteEventError = () => {
  return{
    type:DELETE_EVENT_ERROR,
  }
}
export const editBadge = (formData,badgeIcon) => {
  return{
    type:EDIT_BADGE,
    formData,
    badgeIcon
  }
}
export const editBadgeSuccess = () => {
  return{
    type:EDIT_BADGE_SUCCESS,
  }
}
export const editBadgeError = () => {
  return{
    type:EDIT_BADGE_ERROR,
  }
}
export const editEvent = (formData) => {
  return{
    type:EDIT_EVENT,
    formData,
  }
}
export const editEventSuccess = () => {
  return{
    type:EDIT_EVENT_SUCCESS,
  }
}
export const editEventError = () => {
  return{
    type:EDIT_EVENT_ERROR,
  }
}
// Invite Selected Testers
export const inviteSelectedTesters = (payload,history) => {
  return{
    type: INVITE_SELECTED_TESTER_ADMIN,
    payload,
    history
  }
 
};

export const clearSelectedTestersList = () => (
  {
  type: CLEAR_SELECTED_TESTER_ADMIN
}
);

//testimonials
export const getTestimonials = ()=>({
  type: GET_TESTIMONIALS
})

export const getTestimonialsSuccess = (payload)=>({
  type: GET_TESTIMONIALS_SUCCESS,
  payload,
})

export const getTestimonialsError = ()=>({
  type: GET_TESTIMONIALS_ERROR
})

export const approveTestimonial = (payload)=>({
  type: APPROVE_TESTIMONIAL,
  payload
})

export const approveTestimonialSuccess = ()=>({
  type: APPROVE_TESTIMONIAL_SUCCESS,
})

export const approveTestimonialError = ()=>({
  type: APPROVE_TESTIMONIAL_ERROR,
})

export const rejectTestimonial = (payload)=>{
  return {
    type: REJECT_TESTIMONIAL,
    payload
  }
}

export const rejectTestimonialSuccess = ()=>({
  type: REJECT_TESTIMONIAL_SUCCESS
})

export const rejectTestimonialError = ()=>({
  type: REJECT_TESTIMONIAL_ERROR
})

export const updateTestimonial = (id,input)=>{
  return {
    type: UPDATE_TESTIMONIAL,
    id:id,
    input:input,
  }
}

export const updateTestimonialSuccess = ()=>({
  type: UPDATE_TESTIMONIAL_SUCCESS
})

export const updateTestimonialError = ()=>({
  type: UPDATE_TESTIMONIAL_ERROR
})
export const getFinanceReport = ()=>{
  return{
    type:GET_FINANCE_REPORT
  }
}

export const getFinanceReportSuccess = (payload)=>{
 return{
  type:GET_FINANCE_REPORT_SUCCESS,
  payload:[...payload]
 }
}

export const getFinanceReportError = ()=>({
  type:GET_FINANCE_REPORT_ERROR
})

//process automation
export const getReviewer = () =>{
  return {
    type:GET_REVIEWER
  }
}

export const getReviewerSuccess = (payload) =>{
  return {
    type:GET_REVIEWER_SUCCESS,
    payload
  }
}

export const getReviewerError = () =>{
  return {
    type:GET_REVIEWER_ERROR
  }
}

export const assignReviewer = (payload) => {
  return {
    type: ASSIGN_REVIEWER_BUG,
    payload
  }
}

export const assignReviewerSuccess = () => {
  return {
    type: ASSIGN_REVIEWER_BUG_SUCCESS,
  }
}

export const assignReviewerError = () => {
  return {
    type: ASSIGN_REVIEWER_BUG_ERROR,
  }
}

export const updateReviewer = (payload) => {
  return {
    type: UPDATE_REVIEWER,
    payload
  }
}

export const updateReviewerSuccess = () => {
  return {
    type: UPDATE_REVIEWER_SUCCESS
  }
}

export const updateReviewerError = () => {
  return {
    type: UPDATE_REVIEWER_ERROR
  }
}
