import React from "react";
import { Link } from "react-router-dom";

//import { convertMinutesToHours } from "../../../../utils/getDateTime";
import NoDataMessage from "../../NoDataMessage";
import settings from "../../../../constants/settings";

import "../style.scss";

// const tableHeadingArray = ["Project Name", "Ongoing Hours", "Remaining Hours", "Status"];

const ActiveTestTable = ({ tableContentArray }) => {
  return (
    <div className="dashboard-table active-test-table">
      {tableContentArray.length === 0 ? (
        <NoDataMessage message={settings.active_test_table.message} />
      ) : (
        <table>
          <tr>
            {settings.active_test_table.tableHeadingArray.map((head, i) => (
              <th key={i}>{head}</th>
            ))}
          </tr>

          {tableContentArray &&
            tableContentArray.map(({ name, totalHours, burnedHours, projectId, logo }, i) => (
              <tr key={i}>
                <td>
                  <img className="project-img" src={ logo?.url || logo} alt={name} /> {name}
                </td>
                {/* <td className="remove">{`${convertMinutesToHours(parseInt(burnedHours))} hours`} </td>
                <td className="remove">{`${convertMinutesToHours(parseInt(totalHours) - parseInt(burnedHours))} hours`} </td> */}
                <td>
                  <Link to={`/dashboard/test-taken/projects/active/${projectId}/buglist`} className="blue-color">
                    {settings.active_test_table.view}
                  </Link>
                </td>
              </tr>
            ))}
        </table>
      )}
    </div>
  );
};

export default ActiveTestTable;
