import React from "react";
import Count from "./Count";
import settings from "../../../constants/settings";

import "./style.scss";
const CounterComponent = () => {
  return (
    <div className="counter-component bg-highlight">
      {settings.counter_component.counts.map(count => (
        <Count key={count.id} data={count} />
      ))}
      <div className="counter">
        <div className="number">{settings.counter_component.number}</div>
        <div className="title">{settings.counter_component.bugs_encountered}</div>
      </div>
    </div>
  );
};

export default CounterComponent;
