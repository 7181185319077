import React, { useState } from "react";

import settings from "../../../constants/settings"
import "./style.scss";

const FAQ = () => {
  const [showDetails1, setShowDetails1] = useState(false);
  const [showDetails2, setShowDetails2] = useState(false);
  const [showDetails3, setShowDetails3] = useState(false);
  const [showDetails4, setShowDetails4] = useState(false);

  const UpArrow = ({ color }) => (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill={color} width="36px" height="36px">
      <path d="M0 0h24v24H0z" fill="none" />
      <path d="M12 8l-6 6 1.41 1.41L12 10.83l4.59 4.58L18 14z" />
    </svg>
  );

  const DownArrow = ({ color }) => (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill={color} width="36px" height="36px">
      <path d="M0 0h24v24H0z" fill="none" />
      <path d="M16.59 8.59L12 13.17 7.41 8.59 6 10l6 6 6-6z" />
    </svg>
  );
  return (
    <div className="faq_container bg-light">
      <h4 className="heading">{settings.testerFAQ.heading}</h4>
      <div className="faq">
        

        <div className="list-item" id="accordin1">
          <div className="list-item-title"               onClick={() => {
                setShowDetails1(!showDetails1);
                document.getElementById("accordin1").classList.value === "list-item" ? document.getElementById("accordin1").classList.add("active") : document.getElementById("accordin1").classList.value = "list-item";
              }} >
            {settings.testerFAQ.accordian1.title}{" "}
            <span
            >
              {showDetails1 ? <UpArrow color="#123dfb" /> : <DownArrow color="#123dfb" />}
            </span>
          </div>
          {showDetails1 && (
            <p>
              {settings.testerFAQ.accordian1.description}
            </p>
          )}
        </div>


        <div className="list-item" id="accordin2">
          <div className="list-item-title">
            {settings.testerFAQ.accordian2.title}{" "}
            <span
              onClick={() => {
                setShowDetails2(!showDetails2);
                document.getElementById("accordin2").classList.value === "list-item" ? document.getElementById("accordin2").classList.add("active") : document.getElementById("accordin2").classList.value = "list-item";
              }}
            >
              {showDetails2 ? <UpArrow color="#123dfb" /> : <DownArrow color="#123dfb" />}
            </span>
          </div>
          {showDetails2 && (
            <p>
              {settings.testerFAQ.accordian2.description}
            </p>
          )}
        </div>


        <div className="list-item" id="accordin3">
          <div className="list-item-title">
            {settings.testerFAQ.accordian3.title}{" "}
            <span
              onClick={() => {
                setShowDetails3(!showDetails3);
                document.getElementById("accordin3").classList.value === "list-item" ? document.getElementById("accordin3").classList.add("active") : document.getElementById("accordin3").classList.value = "list-item";
              }}
            >
              {showDetails3 ? <UpArrow color="#123dfb" /> : <DownArrow color="#123dfb" />}
            </span>
          </div>
          {showDetails3 && (
            <p>
              {settings.testerFAQ.accordian3.description}
            </p>
          )}
        </div>


        <div className="list-item" id="accordin4">
          <div className="list-item-title">
            {settings.testerFAQ.accordian4.title}{" "}
            <span
              onClick={() => {
                setShowDetails4(!showDetails4);
                document.getElementById("accordin4").classList.value === "list-item" ? document.getElementById("accordin4").classList.add("active") : document.getElementById("accordin4").classList.value = "list-item";
              }}
            >
              {showDetails4 ? <UpArrow color="#123dfb" /> : <DownArrow color="#123dfb" />}
            </span>
          </div>
          {showDetails4 && (
            <p>
              {settings.testerFAQ.accordian4.description}
            </p>
          )}
        </div>
        
          
      </div>
    </div>
  );
}

export default FAQ;
