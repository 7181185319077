import React from "react";
import { Link } from "react-router-dom";
//import { convertMinutesToHours } from "../../../../utils/getDateTime";
import NoDataMessage from "../../NoDataMessage";
import settings from "../../../../constants/settings";
import './style.scss'

import "../style.scss";

// const tableHeadingArray = ["Project Name", "Ongoing Hours", "Remaining Hours", "Earnings", "Bugs Reported", "Bugs Approved", "Bug list"];

const LiveProjects = ({ tableContentArray }) => {
  return (
    <div className="dashboard-table live-projects">
      {tableContentArray.length === 0 ? (
        <NoDataMessage message={settings.live_projects.message} />
      ) : (
        <table>
          <tr>
            {settings.live_projects.tableHeadingArray.map((head, i) => (
              <th key={i}>{head}</th>
            ))}
          </tr>

          {tableContentArray.map((item, i) => (
            <tr key={i}>
              <td>{item.name}</td>
              <td>{item.platform}</td>
              {/* <td>{`${convertMinutesToHours(item.burnedHours)} hours`} </td> */}
              {/* <td>{convertMinutesToHours(parseInt(item.totalHours) - parseInt(item.burnedHours)) + " hours"} </td> */}
              <td>{item?.earned ? item.earned.toFixed(2) : 0}</td>
              <td>{item.bugReported}</td>
              <td>{item.bugApproval}</td>
              <td className="completed-view-details">
                <Link to={`/dashboard/test-taken/projects/active/${item.projectId}/buglist`} className="inherit">
                  {settings.live_projects.view}
                </Link>
              </td>
            </tr>
          ))}
        </table>
      )}
    </div>
  );
};

export default LiveProjects;
