export const getFullDateTime = date => {
  const d = new Date(date);
  return `${d.getDate()}/${d.getMonth() + 1}/${d.getFullYear()}, ${d.getHours()}:${d.getMinutes()}`;
};

export const getmonthYearTime = date => {
  const d = new Date(date);
  if (d.getMonth < 9) return `${d.getFullYear()}-${d.getMonth() + 1}`;
  else return `${d.getFullYear()}-0${d.getMonth() + 1}`;
};

export const convertMinutesToHours = minutes => {
  const hours = Math.floor(minutes / 60);
  let calcMinutes = minutes % 60;
  if (calcMinutes < 10) calcMinutes = `0${calcMinutes}`;
  return `${hours}:${calcMinutes}`;
};
