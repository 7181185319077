import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import DashboardHeading from "../../../../Common/DashboardHeading";
import axios from "axios";

import baseDomain, { testerSingleProjectsReq } from "../../../../../constants/apiRoutes";
import AnalysisTable from "../../.././../Common/DashboardTables/AnalysisTable";

const TesterBugDetail = () => {
  const { id, testerId } = useParams();
  const [mydata, setMydata] = useState();
  useEffect(() => {
    TesterSingleProjectBugs(id, testerId);
    // eslint-disable-next-line
  }, []);

  const TesterSingleProjectBugs = async (id, testerId) => {
    try {
      const { data } = await axios.get(`${baseDomain}${testerSingleProjectsReq}/${id}/${testerId}`);
      setMydata(data.data);
    } catch (error) {}
  };

  return (
    <div className="tester-details-all-bugs">
      <DashboardHeading headingStrong={"Reported Bugs"} showBtn={false} />
      {mydata && <AnalysisTable tableContentArray={mydata} projectID={id} role={"ADMIN"} />}
    </div>
  );
};

export default TesterBugDetail;
