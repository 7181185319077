import {
  SIGNUP_TESTER,
  SIGNUP_TESTER_SUCCESS,
  SIGNUP_TESTER_ERROR,
  SIGNUP_CLIENT,
  SIGNUP_CLIENT_SUCCESS,
  SIGNUP_CLIENT_ERROR,
  LOGIN_USER,
  LOGIN_USER_SUCCESS,
  LOGIN_USER_ERROR,
  LOGOUT_USER,
  LOGOUT_USER_SUCCESS,
  LOGOUT_USER_ERROR,
  USER_INFO,
  USER_INFO_SUCCESS,
  USER_INFO_ERROR,
  SET_IS_DEVICE_HAVING_TRUE,
  SET_IS_NOTIFICATION,
  SET_IS_DEVICE_HAVING_FALSE,
  GENERATE_REFERRAL_CODE_SUCCESS,
  GENERATE_REFERRAL_CODE_ERROR,
  SOCIAL_SIGNUP,
  SOCIAL_SIGNUP_SUCCESS,
  SOCIAL_SIGNUP_ERROR,
  SOCIAL_SIGNUP_CODE_SEND,
  SOCIAL_SIGNUP_CODE_SEND_SUCCESS,
  SOCIAL_SIGNUP_CODE_SEND_ERROR,
  SOCIAL_SIGNUP_SUBMIT,
  SOCIAL_SIGNUP_SUBMIT_SUCCESS,
  SOCIAL_SIGNUP_SUBMIT_ERROR,
  SOCIAL_SIGNIN_GET_URL,
  SOCIAL_SIGNIN_GET_URL_SUCCESS,
  SOCIAL_SIGNIN_GET_URL_ERROR,
  SOCIAL_SIGNIN_CODE_SUBMIT_ERROR,
  SOCIAL_SIGNIN_CODE_SUBMIT_SUCCESS,
  SOCIAL_SIGNIN_CODE_SUBMIT,
  SIGNUP_TESTER_PROCEED,
} from "../actions";

import { getLocal } from "../../utils/localStorage";

const initialState = {
  token: "",
  error: false,
  loading: false,
  proceed: null,
  isLoadingDashboard: true,
  isAuth: getLocal("token") ? true : false,
  user: {},
  redirectUrl:"",
  // role: getLocal("role") ? getLocal("role") : "",
  role: "",
  preFill:{},
};

// eslint-disable-next-line
export default (state = initialState, action) => {
  switch (action.type) {
    // Login User
    case LOGIN_USER:
    case USER_INFO:
      return {
        ...state,
        loading: true,
        isLoadingDashboard: true,
        user: {},
        role: "",
        token: "",
        error: false,
      };

    case LOGIN_USER_SUCCESS:
    case USER_INFO_SUCCESS:
      return {
        ...state,
        token: action.payload.token,
        role: action.payload.role[0],
        user: action.payload,
        isAuth: true,
        loading: false,
        isLoadingDashboard: false,
        error: false,
      };
    case LOGIN_USER_ERROR:
    case USER_INFO_ERROR:
      return {
        ...state,
        token: "",
        user: {},
        role: "",
        loading: false,
        isLoadingDashboard: false,
        isAuth: false,
        error: true,
      };
    case LOGOUT_USER:
      return {
        ...state,
        token: "",
        error: false,
        loading: true,
        isAuth: false,
        role: "tester",
      };

    case LOGOUT_USER_SUCCESS:
      return {
        ...state,
        token: action.payload,
        isAuth: false,
        loading: false,
        error: false,
      };
    case LOGOUT_USER_ERROR:
      return {
        ...state,
        token: "",
        loading: false,
        isAuth: false,
        error: true,
      };

    // SignUp Tester
    case SIGNUP_TESTER:
      return {
        ...state,
        loading: true,
        token: "",
        error: false,
      };

    case SIGNUP_TESTER_SUCCESS:
      return {
        ...state,
        loading: false,
        error: false,
        proceed: true,
      };
    case SIGNUP_TESTER_ERROR:
      return {
        ...state,
        token: "",
        loading: false,
        error: true,
        proceed: false,
      };
    case SIGNUP_TESTER_PROCEED:
      return {
        ...state,
        proceed: null,
      };
    // SignUp Client
    case SIGNUP_CLIENT:
      return {
        ...state,
        loading: true,
        token: "",
        error: false,
      };
    case SIGNUP_CLIENT_SUCCESS:
      return {
        ...state,
        loading: false,
        error: false,
      };
    case SIGNUP_CLIENT_ERROR:
      return {
        ...state,
        token: "",
        loading: false,
        error: true,
      };
    case SET_IS_DEVICE_HAVING_TRUE:
      return {
        ...state,
        user: {
          ...state.user,
          isHaveDevice: true,
        },
        loading: false,
        error: true,
      };
    case SET_IS_NOTIFICATION:
      return {
        ...state,
        user: {
          ...state.user,
          pendingNotification: 0,
        },
        loading: false,
        error: true,
      }; 
      case SET_IS_DEVICE_HAVING_FALSE:
      return {
        ...state,
        user: {
          ...state.user,
          isHaveDevice: false,
        },
        loading: false,
        error: true,
      }; 
      case GENERATE_REFERRAL_CODE_SUCCESS:
        return {
          ...state,
          user:{
            ...state.user,
            refferalCode:action.payload.data
          }
        }
      case GENERATE_REFERRAL_CODE_ERROR:
        return {
          ...state,
        }
        case SOCIAL_SIGNUP:
          return{
            ...state,
           loading:true
          }
        case SOCIAL_SIGNUP_SUCCESS:
          return{
            ...state,
           loading:false,
           redirectUrl:action.payload
          }
        case SOCIAL_SIGNUP_ERROR:
          return{
            ...state,
           loading:false
          }
          case SOCIAL_SIGNUP_CODE_SEND:
            return{
              ...state,
              loading:true,
            }
          case SOCIAL_SIGNUP_CODE_SEND_SUCCESS:
            return{
              ...state,
              loading:false,
              preFill:{
                ...state.preFill,
                email:action.payload.email,
                name:action.payload.name,
                lastName:action.payload.lastName,
                profilePic:action.payload.profilePic,
                linkedInId:action.payload.linkedInId,
                userId:action.payload.userId,
              }
            }
          case SOCIAL_SIGNUP_CODE_SEND_ERROR:
            return{
              ...state,
              loading:false,
            }

            case SOCIAL_SIGNUP_SUBMIT:
              return{
                ...state,
                loading:true
              }
            case SOCIAL_SIGNUP_SUBMIT_SUCCESS:
              return{
                ...state,
                loading:false
              }
            case SOCIAL_SIGNUP_SUBMIT_ERROR:
              return{
                ...state,
                loading:false
              }
            case SOCIAL_SIGNIN_GET_URL:
              return{
                ...state,
                loading:true
              }
            case SOCIAL_SIGNIN_GET_URL_SUCCESS:
              return{
                ...state,
                loading:false,
                redirectUrl:action.payload
              }
            case SOCIAL_SIGNIN_GET_URL_ERROR:
              return{
                ...state,
                loading:false
              }
              case SOCIAL_SIGNIN_CODE_SUBMIT:
                return {
                  ...state,
                  loading: true,
                  isLoadingDashboard: true,
                  user: {},
                  role: "",
                  token: "",
                  error: false,
                };
                case SOCIAL_SIGNIN_CODE_SUBMIT_SUCCESS :
                return {
                  ...state,
                  token: action.payload.token,
                  role: action.payload.role[0],
                  user: action.payload,
                  isAuth: true,
                  loading: false,
                  isLoadingDashboard: false,
                  error: false,
                };

                case SOCIAL_SIGNIN_CODE_SUBMIT_ERROR : 
                return {
                  ...state,
                  token: "",
                  user: {},
                  role: "",
                  loading: false,
                  isLoadingDashboard: false,
                  isAuth: false,
                  error: true,
                };
                
      
    default:
      return { ...state };
  }
};
