import React from "react";

import { Link } from "react-router-dom";
import settings from "../../../constants/settings";
import "../style.scss";

const LoginSSO = () => {
  return (
    <div className="login-component">
      <h1 className="login-heading">{settings.login_component.login_sso.sso}</h1>
      <form className="login-form">
        <div className="form-group">
          <label>{settings.login_component.login_sso.email}</label>
          <input type="email" required />
        </div>

        <button>{settings.login_component.login_sso.btnText}</button>
        <h3 className="form-or">{settings.login_component.login_sso.or}</h3>
        <Link className="option-btn" to="/login/password">
          {settings.login_component.login_sso.username}
        </Link>
      </form>
    </div>
  );
};

export default LoginSSO;
