import React from "react";
import "./style.scss";
import item7 from "../../../assets/icons/1612618.png";
import item8 from "../../../assets/icons/896530.png";
import item9 from "../../../assets/icons/4205637.png";
import item10 from "../../../assets/icons/782711.png";
import item11 from "../../../assets/icons/684809.png";
import item12 from "../../../assets/icons/748151.png";

function UserTesting({ data = {} }) {
  return (
    <div className="user-testing">
      <h4 className="user-testing-heading">{data.heading2}</h4>

      <div className="user-testing-content">{data.content2}</div>
      <div className="user-testing-grid">
        <div className="user-testing-box">
          <img src={item7} alt="icon" />
          <h4 className="box-heading">{data.item2a}</h4>
          <p className="box-content">{data.content2a}</p>
        </div>

        <div className="user-testing-box">
          <img src={item8} alt="icon" />
          <h4 className="box-heading">{data.item2b}</h4>
          <p className="box-content">{data.content2b}</p>
        </div>

        <div className="user-testing-box">
          <img src={item9} alt="icon" />
          <h4 className="box-heading">{data.item2c}</h4>
          <p className="box-content">{data.content2c}</p>
        </div>

        <div className="user-testing-box">
          <img src={item10} alt="icon" />
          <h4 className="box-heading">{data.item2d}</h4>
          <p className="box-content">{data.content2d}</p>
        </div>

        <div className="user-testing-box">
          <img src={item11} alt="icon" />
          <h4 className="box-heading">{data.item2f}</h4>
          <p className="box-content">{data.content2e}</p>
        </div>

        <div className="user-testing-box">
          <img src={item12} alt="icon" />
          <h4 className="box-heading">{data.item2e}</h4>
          <p className="box-content">{data.content2f}</p>
        </div>
      </div>
    </div>
  );
}

export default UserTesting;
