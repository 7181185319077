import React, { useEffect } from "react";
import { connect } from "react-redux";
import "./style.scss";

import { setDashboardRoute } from "../../../redux/Routing/actions";
import { getTesterLeaderboard, getCommunityStates } from "../../../redux/TesterDashboard/actions";

import UserTestingLeaderboard from "../../Common/UserTestingLeaderboard";
import DashboardHeading from "../../Common/DashboardHeading"; 
import Loader from "../../Loader";
import settings from "../../../constants/settings";

const Community = ({ setDashboardRoute, componentID, getTesterLeaderboard, getCommunityStates, leaderBoard, community, isLoading }) => {
  useEffect(() => {
    setDashboardRoute(componentID);
    getTesterLeaderboard();
    getCommunityStates();
    // eslint-disable-next-line
  }, []);

  return (
    <>
      <div className="community-leaderboard-heading">
        <DashboardHeading heading={settings.community.heading.light} headingStrong={settings.community.heading.bold} />
      </div>
      <div className="leaderboard-stats">
        {isLoading ? <Loader /> : <UserTestingLeaderboard leaderBoard={leaderBoard} />}
        <div className="leaderboard-stats-color-tiles">
          {isLoading ? (
            <Loader />
          ) : (
            <>
              <div className="leaderboard-stats-color-tiles-card bg1">
                <div className="content">
                  <p className="title">{settings.community.applicationtested}</p>
                  <h1 className="value">{community.totalProject}</h1>
                </div>
                <div className="background"></div>
              </div>
              <div className="leaderboard-stats-color-tiles-card bg2">
                <div className="content">
                  <p className="title">{settings.community.bugsfound}</p>
                  <h1 className="value">{community.totalBug}</h1>
                </div>
                <div className="background"></div>
              </div>
              <div className="leaderboard-stats-color-tiles-card bg3">
                <div className="content">
                  <p className="title">{settings.community.testersavailable}</p>
                  <h1 className="value">{community.totalTester}</h1>
                </div>
                <div className="background"></div>
              </div>
              <div className="leaderboard-stats-color-tiles-card bg4">
                <div className="content">
                  <p className="title">{settings.community.devicefarm}</p>
                  <h1 className="value">{community.totalDevice}</h1>
                </div>
                <div className="background"></div>
              </div>
            </>
          )}
        </div> 
      </div>
    </>
  );
};

const mapStateToProps = ({ testerDashboard }) => ({
  leaderBoard: testerDashboard.testerLeaderboard,
  community: testerDashboard.communityStates,
  isLoading: testerDashboard.isLoading,
});

export default connect(mapStateToProps, { setDashboardRoute, getTesterLeaderboard, getCommunityStates })(Community);
