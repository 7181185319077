import React, { useState } from "react";
import settings from "../../../../../constants/settings";

import "./style.scss";

const Step2 = ({ isDisable, setPlatformLogo, handleChangeStepTwo, formDataStepTwo, appLogo, packages, setCartData, cartData, logo }) => {

  const { appComplexity, description } = formDataStepTwo; 

  const logoPlatform = e => {
    setPlatformLogo(e.target.files[0]); 
  } 

  const [disableSelect, setDisableSelect] = useState(false);

  const handleStepTwo = e => {
    handleChangeStepTwo(e); 
  } 
  
  return (
    <div className="addtest-step2">
      <div className="form">
        <div className="form-heading">{settings.client_add_tests_step2.heading}</div>

        <div className="logo-uploader">
          {logo === "logo" && (
            <>
              <p className="addtest-input-label">{settings.client_add_tests_step2.label1}</p>
              <label htmlFor="testlogo">{appLogo ? appLogo.name : "Logo Here"}</label>
              <input type="file" name="testlogo" id="testlogo" accept="image/*" onChange={logoPlatform} /> 
            </>
          )}
          {logo === "url" && (
            <>
              <p className="addtest-input-label">{"App Logo"}</p>
              <img src={appLogo} className="appLogo" alt="logo" />
            </>
          )}
        </div>

        <div className="addtest-dropdown">
          <label>{settings.client_add_tests_step2.label2}</label>
          <select
            id="complexity"
            value={appComplexity}
            name="appComplexity"
            onChange={e => {
              handleChangeStepTwo(e);
              if (e.target.value !== "null")
                setCartData({
                  ...cartData,
                  packageName: packages.filter(pka => pka.appComplexity === e.target.value)[0].packageName || "-",
                  basePrice: packages.filter(pka => pka.appComplexity === e.target.value)[0].basePrice || "-",
                });
              else
                setCartData({
                  ...cartData,
                  packageName: "",
                  basePrice: "",
                  deviceCount: "35",
                  multiplier: "1",
                });
            }}
            onClick={() => setDisableSelect(true)}
            disabled={packages.length === 0}
          >
            {/* <option defaultValue={"null"} selected>
              {settings.client_add_tests_step2.option}
            </option> */}
            <option value="" disabled={disableSelect} >{settings.client_add_tests_step2.option}</option>
            {packages &&
              packages.map((pkg, i) => (
                <option value={pkg.appComplexity} key={i}>
                  {pkg.packageName}
                </option>
              ))}
          </select>
        </div>

        <div className="addtest-dropdown">
          <label>{settings.client_add_tests_step2.label3}</label>
          <div className="tooltip">
            35 Devices
            <div className="tooltip-text">Your application can be validated on maximum 35 different Devices</div>
          </div>
         
        </div>
        <div className="addtest-description">
          <label>{settings.client_add_tests_step2.label4}</label>
          <textarea id="description" value={description} name="description" onChange={handleStepTwo} disabled={isDisable} ></textarea> 
        </div>
      </div>
    </div>
  );
};

export default Step2;
