import React from "react";
import { connect } from "react-redux";
import { setAlert } from "../../../../redux/Alerts/actions";
import "./style.scss";

const FormBadgeEvent = ({ showModal, setModal, createBadgeEvent, form, setForm, setAlert }) => {
  const { badgeEvent, score, weightage } = form;

  const handleSubmit = e => {
    e.preventDefault();
    if (badgeEvent.length < 4) {
      return setAlert({ message: "Enter Badge Event Name with at least 5 characters", type: "ERROR" });
    } else if (!score || score.length === 0) {
      return setAlert({ message: "Please Enter Valid Score", type: "ERROR" });
    } else {
      createBadgeEvent(form);
      setModal();
    }
  };

  const setInput = e => {
    setForm({
      ...form,
      [e.target.name]: e.target.value,
    });
  };

  return (
    <div className="formModal" style={showModal ? { display: "flex" } : { display: "none" }}>
      <div className="formChildContainer">
        <button className="glb-btn" id="close-popUp" onClick={setModal}>
          &#215;
        </button>
        <h2 className="heading">Create Badge Event</h2>
        <form className="badges-form" onSubmit={handleSubmit}>
          <div className="inputFieldBox">
            <label>Badge Event Name</label>
            <br />
            <input value={badgeEvent} type="text" name="badgeEvent" onChange={setInput} />
          </div>
          <div className="inputFieldBox">
            <label>Event Score</label>
            <br />
            <input value={score} type="number" name="score" onChange={setInput} />
          </div>
          <div className="inputFieldBox">
            <label>Event Multiplier</label>
            <br />
            <input value={weightage} type="number" name="weightage" onChange={setInput} />
          </div>

          <div style={{ textAlign: "center", width: "100%" }}>
            <button className="glb-btn" type="submit" id="submit-badge">
              Submit
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default connect(null, { setAlert })(FormBadgeEvent);
