import React from "react";
import cart from "../../assets/icons/cart.svg";

const ShopingCart = ({ people }) => {
  return (
    <div className="cart">
      <div className="cart-details">
        <div className="summary-card">
          <div className="cart-icon">
            <img src={cart} alt="cart" />
          </div>
          <h2>Card Summary</h2>
          <div className="section">
            <h3>Platform Selected</h3>
            <div className="items">
              <div className="item">
                <div className="desc">1x Android Testing</div>
                <div className="price">$ 100</div>
              </div>
              <div className="item">
                <div className="desc">1x iOS Testing</div>
                <div className="price">$ 120</div>
              </div>
            </div>
          </div>
          <div className="section">
            <h3>Duration of test</h3>
            <div className="items">
              <div className="item">
                <div className="desc">21 Days</div>
                <div className="price">$ 150</div>
              </div>
            </div>
          </div>
          <div className="total">
            <div className="item">
              <div className="desc">Sub Total</div>
              <div className="price">$ {people * 5}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ShopingCart;
