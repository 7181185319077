import React from "react";
import cart from "../../assets/icons/cart.svg";

const SubTotal = ({ setStep, people }) => {
  return (
    <div className="sub-total" onClick={() => setStep(3)}>
      <div className="sub-total-inner">
        <div className="icon">
          {" "}
          <img src={cart} alt="" />
        </div>
        <div className="details">$ {people * 5}</div>
      </div>
    </div>
  );
};

export default SubTotal;
