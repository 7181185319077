import React from "react";
import settings from "../../constants/settings";

const Error = () => {
  return (
    <div>
      <h1>{settings.error.heading}</h1>
      <h2>
        Redirect back to{" "}
        <a style={{ color: "blue", textDecoration: "underline" }} href="https://www.qantily.com/">
          Home
        </a>
      </h2>
    </div>
  );
};

export default Error;
