import "../style.scss";
import { CloseSign } from "../../../assets/icons/icons";
import NoDataMessage from "../../Common/NoDataMessage";
import settings from "../../../constants/settings";

const TesterTransferModal = ({ showModal, toggleModal, particularTesterDetail, manageEarnings, handleToggle }) => {
  const tableHeadingArray = ["Name of tranferee", "Request Date", "Transfer Date", "Transaction", "Status"];

  const name = particularTesterDetail?.name;

  return (
    <div className={showModal ? "initiateTransfer-modal-container blur-bg" : "none"}>
      <div className="initiateTransfer-modal">
        <div className="close-icon">
          <div className="close" onClick={toggleModal}>
            {CloseSign}
          </div>
        </div>
        <div className="heading">
          <h2>Tester Transaction Details</h2>
        </div>
        <div className="body-container">
          <div className="body">
            {particularTesterDetail?.testersRequests?.length > 0 ? (
              <table>
                <thead>
                  <tr>
                    {tableHeadingArray.map((head, i) => (
                      <th key={i}>{head}</th>
                    ))}
                  </tr>
                </thead>
                <tbody>
                  {particularTesterDetail?.testersRequests?.map((item, i) => (
                    <tr key={i}>
                      <td className="name">
                        <div className="link">{name}</div>
                      </td>
                      <td>{`${item.requestDate ? item.requestDate.split("T")[0] : "-"}`}</td>
                      <td>{`${item.initiatedDate ? item.initiatedDate.split("T")[0] : "-"}`}</td>
                      <td>{`${item.amount ? "$ " + item.amount.toFixed(2) : "0"}`}</td>
                      <td className={item.paymentStatus === "MARKED_FOR_TRANSFER" ? "Pending" : item.paymentStatus}>
                        <button className="glb-btn" onClick={() => handleToggle(item.id, item.paymentStatus)}>
                          {item.paymentStatus === "MARKED_FOR_TRANSFER" ? "PENDING" : item.paymentStatus}
                        </button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            ) : (
              <NoDataMessage message={settings.my_earnings_table.message} />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default TesterTransferModal;
