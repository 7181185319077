import React from "react";

import "./style.scss";
const DashboardHeading = ({
  heading,
  headingStrong,
  showBtn = false,
  btnValue,
  btnOnClick,
  btnSize = "",
  showSecBtn = false,
  secBtnVal,
  secBtnOnClick,
  secBtnSize = "",
}) => {
  return (
    <div className="dashboard-heading-container">
      <div className="dashboard-heading">
        <div className="dashboard-heading-inner">
          <h2>
            {heading} <strong>{headingStrong}</strong>
          </h2>
          {showBtn && (
            <button className={`glb-btn ${btnSize}`} onClick={btnOnClick}>
              {btnValue}
            </button>
          )}
        </div>
        <div className="dashboard-heading-inner">
          {showSecBtn && (
            <button className={`glb-btn sec-btn ${secBtnSize}`} onClick={secBtnOnClick}>
              {secBtnVal}
            </button>
          )}
        </div>
      </div>
    </div>
  );
};

export default DashboardHeading;
