import React, { useEffect } from "react";
import { connect } from "react-redux";

import { setDashboardRoute } from "../../../redux/Routing/actions";

import AllProjects from "./Components/AllProjects";
import ActiveProject from "./Components/ActiveProject";
import ReportBugForm from "./Components/ReportBugForm"; 
import SubmittedBugDetails from "./Components/SubmittedBugDetails";
import BugList from "./Components/BugList";

import { useRouteMatch, Route, Switch, Redirect } from "react-router-dom";

const TestTaken = ({ setDashboardRoute, componentID }) => {
  const match = useRouteMatch();
  useEffect(() => {
    setDashboardRoute(componentID);
    // eslint-disable-next-line
  }, []);
  return (
    <Switch>
      <Redirect exact from={`${match.url}/`} to={`${match.url}/projects`} />
      <Route path={`${match.url}/projects`} exact={true} component={AllProjects} />
      <Route path={`${match.url}/projects/active/:projectID`} exact={true} component={ActiveProject} />
      <Route path={`${match.url}/projects/pause/:projectID`} exact={true} component={ActiveProject} />
      <Route path={`${match.url}/projects/active/:projectID/reportbug`} exact={true} component={ReportBugForm} />
      <Route path={`${match.url}/projects/active/:projectID/buglist`} exact={true} render={() => <BugList role="TESTER" />} />
      <Route path={`${match.url}/projects/active/:projectID/bug/:bugID`} exact={true} component={SubmittedBugDetails} />
      <Route path={`${match.url}/projects/completed/:projectID`} exact={true} render={() => <BugList role="CLIENT" />} />
      <Route path={`${match.url}/projects/completed/:projectID/bug/:bugID`} exact={true} component={SubmittedBugDetails} />
      <Redirect to={`/dashboard/test-taken`} />
    </Switch>
  );
};

export default connect(null, { setDashboardRoute })(TestTaken);
