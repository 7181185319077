import {
  SIGNUP_TESTER,
  SIGNUP_TESTER_SUCCESS,
  SIGNUP_TESTER_ERROR,
  SIGNUP_TESTER_PROCEED,
  SIGNUP_CLIENT,
  SIGNUP_CLIENT_SUCCESS,
  SIGNUP_CLIENT_ERROR,
  LOGIN_USER,
  LOGIN_USER_SUCCESS,
  LOGIN_USER_ERROR,
  LOGOUT_USER,
  LOGOUT_USER_SUCCESS,
  LOGOUT_USER_ERROR,
  USER_INFO,
  USER_INFO_SUCCESS,
  USER_INFO_ERROR,
  SET_IS_DEVICE_HAVING_TRUE,
  SET_IS_NOTIFICATION,
  SET_IS_DEVICE_HAVING_FALSE,
  GENERATE_REFERRAL_CODE,
  GENERATE_REFERRAL_CODE_SUCCESS,
  GENERATE_REFERRAL_CODE_ERROR,
  SOCIAL_SIGNUP,
  SOCIAL_SIGNUP_SUCCESS,
  SOCIAL_SIGNUP_ERROR,
  SOCIAL_SIGNUP_CODE_SEND,
  SOCIAL_SIGNUP_CODE_SEND_SUCCESS,
  SOCIAL_SIGNUP_CODE_SEND_ERROR,
  SOCIAL_SIGNUP_SUBMIT,
  SOCIAL_SIGNUP_SUBMIT_SUCCESS,
  SOCIAL_SIGNUP_SUBMIT_ERROR,
  SOCIAL_SIGNIN_GET_URL,
  SOCIAL_SIGNIN_GET_URL_SUCCESS,
  SOCIAL_SIGNIN_GET_URL_ERROR,
  SOCIAL_SIGNIN_CODE_SUBMIT,
  SOCIAL_SIGNIN_CODE_SUBMIT_SUCCESS,
  SOCIAL_SIGNIN_CODE_SUBMIT_ERROR
} from "../actions";

export const loginUser = (payload, history) => ({
  type: LOGIN_USER,
  payload,
  history,
});

export const loginUserSuccess = payload => ({
  type: LOGIN_USER_SUCCESS,
  payload,
});

export const loginUserError = payload => ({
  type: LOGIN_USER_ERROR,
  payload,
});

export const userInfo = history => ({
  type: USER_INFO,
  history,
});

export const userInfoSuccess = payload => ({
  type: USER_INFO_SUCCESS,
  payload,
});

export const userInfoError = payload => ({
  type: USER_INFO_ERROR,
  payload,
});

export const logoutUser = (token) => {
  return {
  type: LOGOUT_USER,
  token,
}
};

export const logoutUserSuccess = () => ({
  type: LOGOUT_USER_SUCCESS,
});

export const logoutUserError = () => ({
  type: LOGOUT_USER_ERROR,
});

export const signUpTester = (payload,profilePic, history) => ({
  type: SIGNUP_TESTER,
  payload,
  profilePic,
  history,
});

export const signUpTesterSuccess = payload => ({
  type: SIGNUP_TESTER_SUCCESS,
  payload,
});

export const signUpTesterError = payload => ({
  type: SIGNUP_TESTER_ERROR,
  payload,
});

export const signUpTesterProceed = payload => ({
  type: SIGNUP_TESTER_PROCEED,
  payload,
});

export const signUpClient = (payload, profilePic, history) => ({
  type: SIGNUP_CLIENT,
  payload,
  profilePic,
  history,
});

export const signUpClientSuccess = (payload, history) => ({
  type: SIGNUP_CLIENT_SUCCESS,
  payload,
  history,
});

export const signUpClientError = payload => ({
  type: SIGNUP_CLIENT_ERROR,
  payload,
});

export const setIsDeviceHavingTrue = () => ({
  type: SET_IS_DEVICE_HAVING_TRUE,
});

export const setIsNotification = () => {
  return {type: SET_IS_NOTIFICATION}
}; 
export const setIsDeviceHavingFalse = () => ({
  type: SET_IS_DEVICE_HAVING_FALSE,
});
export const generateReferralCode = (payload) => {
  return{
    type:GENERATE_REFERRAL_CODE,
    payload
  }
}
export const generateReferralCodeSuccess=(payload)=>{
  return{
    type:GENERATE_REFERRAL_CODE_SUCCESS,
    payload
  }
}
export const generateReferralCodeError=(payload)=>{
  return{
    type:GENERATE_REFERRAL_CODE_ERROR,
  }
}

export const socialSignup = (identity,provider)=>{
  return{
    type:SOCIAL_SIGNUP,
    identity,
    provider,
  }
}

export const socialSignupSuccess = (payload) => {
  return{
    type:SOCIAL_SIGNUP_SUCCESS,
    payload
  }
}
export const socialSignupError = () => {
  return{
    type:SOCIAL_SIGNUP_ERROR
  }
}
export const socialSignupCodeSend = (code,state,history)=>{
 
  return{
    type:SOCIAL_SIGNUP_CODE_SEND,
    code,
    state,
    history
  }
}

export const socialSignupCodeSendSuccess = (payload) => {
  return{
    type:SOCIAL_SIGNUP_CODE_SEND_SUCCESS,
    payload
  }
}
export const socialSignupCodeSendError = () => {
  return{
    type:SOCIAL_SIGNUP_CODE_SEND_ERROR
  }
}
export const socialSignupSubmit = (form,userId , history)=>{
  let completeData =  {...form,userId}
  return{
    type:SOCIAL_SIGNUP_SUBMIT,
    form:completeData,
    history
  }
}

export const socialSignupSubmitSuccess = (payload) => {
  return{
    type:SOCIAL_SIGNUP_SUBMIT_SUCCESS,
  }
}
export const socialSignupSubmitError = () => {
  return{
    type:SOCIAL_SIGNUP_SUBMIT_ERROR
  }
}
export const socialSigninGetUrl = (provider)=>{
  return{
    type:SOCIAL_SIGNIN_GET_URL,
    provider,
  }
}

export const socialSigninGetUrlSuccess = (payload) => {
  return{
    type:SOCIAL_SIGNIN_GET_URL_SUCCESS,
    payload,
  }
}
export const socialSigninGetUrlError = () => {
  return{
    type:SOCIAL_SIGNIN_GET_URL_ERROR
  }
}

export const socialSignInCodeSubmit = (code,state,history) =>{
  return{
    type:SOCIAL_SIGNIN_CODE_SUBMIT,
    code,
    state,
    history
  }
}
export const socialSignInCodeSubmitSuccess = (payload) =>{
  return{
    type:SOCIAL_SIGNIN_CODE_SUBMIT_SUCCESS,
    payload
  }
}
export const socialSignInCodeSubmitError = () =>{
  return{
    type:SOCIAL_SIGNIN_CODE_SUBMIT_ERROR,
  }
}
