import { all, call, fork, put, takeEvery } from "redux-saga/effects";
import axios from "axios";

import { setAlert } from "../Alerts/actions";

import baseDomain, {
  getClientOnGoingProjectsReq,
  getClientPausedProjectsReq,
  getClientCompletedProjectsReq,
  getClientSingleProjectReq,
  getClientProjectStatesReq,
  appHintReq,
  appDetailsReq,
  putEditProjectReq,
  updateClientProfileReq,
  fileUploadReq,
  saveDraftProjectReq,
} from "../../constants/apiRoutes";

import {
  GET_CLIENT_ONGOING_PROJECTS,
  GET_CLIENT_PAUSED_PROJECTS,
  GET_CLIENT_COMPLETED_PROJECTS,
  GET_CLIENT_SINGLE_PROJECT,
  GET_CLIENT_PROJECT_STATES,
  APP_HINT,
  APP_DETAILS,
  UPDATE_PROJECT_DETAILS,
  UPDATE_CLIENT_PROFILE,
  UPDATE_CLIENT_PROFILE_WITHOUT_IMAGE,
  DRAFT_PROJECT_CLIENT,
} from "../actions";

import {
  getClientOnGoingProjectsSuccess,
  getClientOnGoingProjectsError,
  getClientPausedProjectsSuccess,
  getClientPausedProjectsError,
  getClientCompletedProjectsSuccess,
  getClientCompletedProjectsError,
  getClientSingleProjectSuccess,
  getClientSingleProjectError,
  getClientProjectStatesSuccess,
  getClientProjectStatesError,
  appHintSuccess,
  appHintError,
  appDetailsSuccess,
  appDetailsError,
  updateProjectDetailsSuccess,
  updateProjectDetailsError,
  updateClientProfileSuccess,
  updateClientProfileError,
  updateClientProfileWithoutImageSuccess,
  updateClientProfileWithoutImageError,
  draftClientProjectsSuccess,
  draftClientProjectsError,
} from "./actions";

const getClientOnGoingProjectsAPI = async () => {
  return axios.get(`${baseDomain}${getClientOnGoingProjectsReq}`);
};

const getClientPausedProjectsAPI = async () => {
  return axios.get(`${baseDomain}${getClientPausedProjectsReq}`);
};

const getClientCompletedProjectsAPI = async () => {
  return axios.get(`${baseDomain}${getClientCompletedProjectsReq}`);
};

const getClientSingleProjectAPI = async projectID => {
  return axios.get(`${baseDomain}${getClientSingleProjectReq}/${projectID}`);
};

const getClientProjectStatesAPI = async projectID => {
  return axios.get(`${baseDomain}${getClientProjectStatesReq}/${projectID}`);
};

const appHintAPI = async data => {
  return axios.post(`${baseDomain}${appHintReq}`, data);
};

const appDetailsAPI = async data => {
  return axios.post(`${baseDomain}${appDetailsReq}`, data);
};

const updateProjectDetailsAPI = async data => {
  return axios.put(`${baseDomain}${putEditProjectReq}`, data);
};

const fileUploadAPI = async (file, type) => {
  return axios.post(`${baseDomain}${fileUploadReq}/${type}`, file);
};

const updateClientProfileAPI = async data => {
  return axios.put(`${baseDomain}${updateClientProfileReq}`, data);
};

const getClientDraftProjectAPI = async => {
  return axios.get(`${baseDomain}${saveDraftProjectReq}`);
};

function* getClientOnGoingProjects() {
  try {
    const { data } = yield call(getClientOnGoingProjectsAPI);
    yield put(getClientOnGoingProjectsSuccess(data.data));
  } catch (error) {
    yield put(getClientOnGoingProjectsError(error));
    yield put(setAlert({ message: error.response.data.message, type: "ERROR" }));
  }
}

function* getClientPausedProjects() {
  try {
    const { data } = yield call(getClientPausedProjectsAPI);
    yield put(getClientPausedProjectsSuccess(data.data));
  } catch (error) {
    yield put(getClientPausedProjectsError(error));
    yield put(setAlert({ message: error.response.data.message, type: "ERROR" }));
  }
}

function* getClientCompletedProjects() {
  try {
    const { data } = yield call(getClientCompletedProjectsAPI);
    yield put(getClientCompletedProjectsSuccess(data.data));
    yield call(getClientOnGoingProjectsAPI);
  } catch (error) {
    yield put(getClientCompletedProjectsError(error));
    yield put(setAlert({ message: error.response.data.message, type: "ERROR" }));
    yield call(getClientOnGoingProjectsAPI);
  }
}

function* getClientSingleProject({ payload }) {
  try {
    const { data } = yield call(getClientSingleProjectAPI, payload);
    yield put(getClientSingleProjectSuccess(data.data));
  } catch (error) {
    yield put(getClientSingleProjectError(error));
    yield put(setAlert({ message: error.response.data.message, type: "ERROR" }));
  }
}

function* getClientProjectStates({ payload }) {
  try {
    const { data } = yield call(getClientProjectStatesAPI, payload);
    yield put(getClientProjectStatesSuccess(data.data));
  } catch (error) {
    yield put(getClientProjectStatesError(error));
    yield put(setAlert({ message: error.response.data.message, type: "ERROR" }));
  }
}

function* appHint({ payload }) {
  try {
    const { data } = yield call(appHintAPI, payload);
    yield put(appHintSuccess(data.data));
  } catch (error) {
    yield put(appHintError(error));
    yield put(setAlert({ message: "Enter Something to Search", type: "ERROR" }));
  }
}

function* appDetails({ payload }) {
  try {
    const { data } = yield call(appDetailsAPI, payload);
    yield put(appDetailsSuccess(data.data));
  } catch (error) {
    yield put(appDetailsError(error));
    yield put(setAlert({ message: error.response.data.message, type: "ERROR" }));
  }
}

function* updateProjectDetails({ payload }) {
  try {
    yield call(updateProjectDetailsAPI, payload);
    yield put(updateProjectDetailsSuccess(payload));
    yield put(setAlert({ message: "Project Details Updated.", type: "SUCCESS" }));
  } catch (error) {
    yield put(updateProjectDetailsError(error));
    yield put(setAlert({ message: error.response.data.message, type: "ERROR" }));
  }
}

function* updateClientProfile({ payload, userImage }) {
  try {
    const fileData = new FormData();
    fileData.append("file", userImage);
    const { data } = yield call(fileUploadAPI, fileData, "PROFILE_IMAGE_AGENCY");
    yield call(updateClientProfileAPI, { ...payload, image: data.data });
    yield put(setAlert({ message: "Profile Updated Successfully", type: "SUCCESS" }));
    yield put(updateClientProfileSuccess());
  } catch (error) {
    yield put(setAlert({ message: error.response.data.message, type: "ERROR" }));
    yield put(updateClientProfileError(error));
  }
}

function* updateClientProfileWithoutImage({ payload }) {
  try {
    yield call(updateClientProfileAPI, payload);
    yield put(setAlert({ message: "Profile Updated Successfully", type: "SUCCESS" }));
    yield put(updateClientProfileWithoutImageSuccess());
  } catch (error) {
    yield put(setAlert({ message: error.response.data.message, type: "ERROR" }));
    yield put(updateClientProfileWithoutImageError(error));
  }
}

function* getClientDraftProjects() {
  try {
    const res = yield call(getClientDraftProjectAPI);
    const data = res?.data?.data;
    yield put(draftClientProjectsSuccess(data));
  } catch (error) {
    yield put(setAlert({ message: error.response.data.message, type: "ERROR" }));
    yield put(draftClientProjectsError(error));
  }
}

export function* watchGetClientOnGoingProjects() {
  yield takeEvery(GET_CLIENT_ONGOING_PROJECTS, getClientOnGoingProjects);
}
export function* watchGetClientPausedProjects() {
  yield takeEvery(GET_CLIENT_PAUSED_PROJECTS, getClientPausedProjects);
}
export function* watchGetClientCompletedProjects() {
  yield takeEvery(GET_CLIENT_COMPLETED_PROJECTS, getClientCompletedProjects);
}
export function* watchGetClientSingleProject() {
  yield takeEvery(GET_CLIENT_SINGLE_PROJECT, getClientSingleProject);
}
export function* watchGetClientProjectStates() {
  yield takeEvery(GET_CLIENT_PROJECT_STATES, getClientProjectStates);
}
export function* watchAppHint() {
  yield takeEvery(APP_HINT, appHint);
}
export function* watchAppDetails() {
  yield takeEvery(APP_DETAILS, appDetails);
}
export function* watchUpdateProjectDetails() {
  yield takeEvery(UPDATE_PROJECT_DETAILS, updateProjectDetails);
}
export function* watchupdateClientProfile() {
  yield takeEvery(UPDATE_CLIENT_PROFILE, updateClientProfile);
}
export function* watchupdateClientProfileWithoutImage() {
  yield takeEvery(UPDATE_CLIENT_PROFILE_WITHOUT_IMAGE, updateClientProfileWithoutImage);
}
export function* watchGetClientDraftProjects() {
  yield takeEvery(DRAFT_PROJECT_CLIENT, getClientDraftProjects);
}

export default function* rootSaga() {
  yield all([
    fork(watchGetClientOnGoingProjects),
    fork(watchGetClientPausedProjects),
    fork(watchGetClientCompletedProjects),
    fork(watchGetClientSingleProject),
    fork(watchGetClientProjectStates),
    fork(watchAppHint),
    fork(watchAppDetails),
    fork(watchUpdateProjectDetails),
    fork(watchupdateClientProfile),
    fork(watchupdateClientProfileWithoutImage),
    fork(watchGetClientDraftProjects),
  ]);
}
