import React, { Fragment, useEffect } from "react";
import CardsGrid01 from "../../components/Home/CardsGrid01";
import CardsGrid02 from "../../components/Home/CardsGrid02";
//import CardsGrid03 from "../../components/Home/CardsGrid03"
import Banner from "../../components/Home/Banner";
//import CounterComponent from "../../components/Home/CounterComponent"; 
import AdditionalInfoLanding from "../../components/Home/AdditinalInfoLanding";
//import NewInfoLanding from "../../components/Home/NewInfoLanding";
import NewInfoLanding01 from "../../components/Home/NewInfoLanding01";
//import NewInfoLanding02 from "../../components/Home/NewInfoLanding02";
import AutoLogin from "../../components/AutoLogin";
import TestimonialLanding from "../../components/Home/TestimonialLanding";
import ClientList from "../../components/Home/ClientsBanner";
import { withData, withLayout } from "../../layouts";

import "./style.scss";
//import HackathonBanner from "../../components/Home/HackathonBanner";
const Home = ({ settings, staticSiteNavHandler }) => {
  useEffect(() => {
    staticSiteNavHandler("HOME");
    // eslint-disable-next-line
  }, []);
  return (
    <Fragment>
      <AutoLogin />
      {/* <HackathonBanner/> */}
      <Banner data={settings.banner01} />
      <ClientList data={settings.data8} />
      <CardsGrid01 settings={{ greyBg: false, isDevicesComponent: true }} data={settings.data3} />
      <CardsGrid01 settings={{ greyBg: true, floatingCards: true }} data={settings.data1} />
      <CardsGrid02 data={settings.data2} />
      {/* <CardsGrid03 data={settings.data9} /> */}
      {/* <NewInfoLanding data={settings.data4} /> */}
      {/* <CounterComponent /> */}
      {/* <ClientList data={settings.data8} /> */}
      <NewInfoLanding01 data={settings.data5} />
      {/* <NewInfoLanding02 data={settings.data6} /> */}
      <AdditionalInfoLanding data={settings.data7} /> 
      <TestimonialLanding /> 
    </Fragment>
  );
};
export default withData(withLayout(Home));
