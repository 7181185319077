import React, { useState } from "react";
import { useHistory } from "react-router";
import { connect } from "react-redux";
// import { useParams, useHistory } from "react-router-dom";
import { CloseSign } from "../../../assets/icons/icons";
import axios from "axios";

import baseDomain, { initiateTransferReq } from "../../../constants/apiRoutes";
import { setAlert } from "../../../redux/Alerts/actions";
import "./style.scss";
import { getAdminAllEarnings } from "../../../redux/AdminDashboard/actions";

const TransferInitiatedModal = ({ showModal, toggleModal, setAlert, paymentId, getAdminAllEarnings }) => {
  const [isDeclinedLoading, setIsDeclinedLoading] = useState(false);

  const history = useHistory();

  const modalToggle = () => {
    toggleModal();
  };

  const handleInitiateTransfer = async () => {
    setIsDeclinedLoading(true);
    try {
      await axios.put(`${baseDomain}${initiateTransferReq}/${paymentId}`);
      setAlert({
        message: "Transfer Initiated Successfully",
        type: "SUCCESS",
      });
      setIsDeclinedLoading(false);
      toggleModal();
      getAdminAllEarnings();
    } catch (error) {
      setAlert({
        message: error.response.data.message || "Error while initiating transfer",
        type: "ERROR",
      });
      setIsDeclinedLoading(false);
      history.push("/dashboard/earnings");
    }
  };

  return (
    <div className={showModal ? "initiateTransfer-modal-container blur-bg" : "none"}>
      <div className="initiateTransfer-modal">
        <div className="close-icon">
          <div className="close" onClick={modalToggle}>
            {CloseSign}
          </div>
        </div>
        <div className="heading">
          <h2>Mark For Transfer</h2>
        </div>
        <div className="body-container">
          <div className="body">
            <div className="initiateTransferPopUpContainer">
              <div className="initiateTransferPopUp">
                <div className="initiateTransferPopUpButtons">
                  <button className={"glb-btn no"} onClick={modalToggle}>
                    Cancel
                  </button>
                  <button className={"glb-btn yes"} disabled={isDeclinedLoading} onClick={handleInitiateTransfer}>
                    Proceed
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = ({ routeingState }) => ({
  paymentId: routeingState.paymentId,
});

export default connect(mapStateToProps, { setAlert, getAdminAllEarnings })(TransferInitiatedModal);
