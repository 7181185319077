import React, { Fragment, useState } from "react";
import StepperThreeSteps from "../Common/StepperThreeSteps";
import AccountSetupHeading from "../Common/AccountSetupHeading";
import SubTotal from "./SubTotal";
import Platforms from "./Platforms";
import Checkout from "./Checkout";
import "./style.scss";

const ChoosePlatformComp = () => {
  const [step, setStep] = useState(1);
  const [people, setPeople] = useState(1);
  return (
    <Fragment>
      <AccountSetupHeading />
      {step === 2 && <SubTotal setStep={setStep} people={people} />}
      <div className="choose-platform-container">
        <StepperThreeSteps currentStep={step} setStep={setStep} />
        {(step === 1 || step === 2) && <Platforms setStep={setStep} currentStep={step} people={people} setPeople={setPeople} />}
        {step === 3 && <Checkout people={people} />}
      </div>
    </Fragment>
  );
};

export default ChoosePlatformComp;
