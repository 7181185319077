import React from "react";

import NoDataMessage from "../../NoDataMessage";

import "../style.scss";

const AllFeadbacksTable = ({ mnageEarnings }) => {
  const tableHeadingArray = ["Feedbacks", "Name", "Email", "Role"];
  return (
    <div className="dashboard-table manage-all-earnings-table">
      {mnageEarnings.length === 0 ? (
        <NoDataMessage message={"No Feedbacks"} />
      ) : (
        <table>
          <tr>
            {tableHeadingArray.map((head, i) => (
              <th key={i}>{head}</th>
            ))}
          </tr>

          {mnageEarnings.map((item, i) => (
            <tr key={i}>
              <td>{item.feedBack}</td>
              <td>{item.name}</td>
              <td style={{textTransform:"initial"}} >{item.email}</td>
              <td>{item.role}</td>
            </tr>
          ))}
        </table>
      )}
    </div>
  );
};

export default AllFeadbacksTable;