import {
  GET_TESTER_LEADERBOARD_PROJECTS,
  GET_TESTER_LEADERBOARD_PROJECTS_SUCCESS,
  GET_TESTER_LEADERBOARD_PROJECTS_ERROR,
  GET_TESTER_LEADERBOARD,
  GET_TESTER_LEADERBOARD_SUCCESS,
  GET_TESTER_LEADERBOARD_ERROR,
  GET_TESTER_LIVE_PROJECTS,
  GET_TESTER_LIVE_PROJECTS_SUCCESS,
  GET_TESTER_LIVE_PROJECTS_ERROR,
  GET_TESTER_NOTIFICATIONS,
  GET_TESTER_NOTIFICATIONS_SUCCESS,
  GET_TESTER_NOTIFICATIONS_ERROR,
  GET_TESTER_DEVICES,
  GET_TESTER_DEVICES_SUCCESS,
  GET_TESTER_DEVICES_ERROR,
  CREATE_TESTER_DEVICES,
  CREATE_TESTER_DEVICES_SUCCESS,
  CREATE_TESTER_DEVICES_ERROR,
  GET_TESTER_PAUSED_PROJECTS,
  GET_TESTER_PAUSED_PROJECTS_SUCCESS,
  GET_TESTER_PAUSED_PROJECTS_ERROR,
  GET_TESTER_COMPLETED_PROJECTS,
  GET_TESTER_COMPLETED_PROJECTS_SUCCESS,
  GET_TESTER_COMPLETED_PROJECTS_ERROR,
  GET_TESTER_PROJECT_BY_ID,
  GET_TESTER_PROJECT_BY_ID_SUCCESS,
  GET_TESTER_PROJECT_BY_ID_ERROR,
  GET_TESTER_LISTED_BUGS,
  GET_TESTER_LISTED_BUGS_SUCCESS,
  GET_TESTER_LISTED_BUGS_ERROR,
  GET_PROJECT_LISTED_BUGS,
  GET_PROJECT_LISTED_BUGS_SUCCESS,
  GET_PROJECT_LISTED_BUGS_ERROR,
  REPORT_NEW_BUG,
  REPORT_NEW_BUG_SUCCESS,
  REPORT_NEW_BUG_ERROR,
  GET_COMMUNITY_STATES,
  GET_COMMUNITY_STATES_SUCCESS,
  GET_COMMUNITY_STATES_ERROR,
  GET_WALLET_EARNING,
  GET_WALLET_EARNING_SUCCESS,
  GET_WALLET_EARNING_ERROR,
  GET_PAID_EARNING,
  GET_PAID_EARNING_SUCCESS,
  GET_PAID_EARNING_ERROR,
  CHANGE_PASSWORD,
  CHANGE_PASSWORD_SUCCESS,
  CHANGE_PASSWORD_ERROR,
  GET_ALL_SKILLS,
  GET_ALL_SKILLS_SUCCESS,
  GET_ALL_SKILLS_ERROR,
  CREATE_TESTER_SKILL,
  CREATE_TESTER_SKILL_SUCCESS,
  CREATE_TESTER_SKILL_ERROR,
  GET_TESTER_SKILLS,
  GET_TESTER_SKILLS_SUCCESS,
  GET_TESTER_SKILLS_ERROR,
  GET_SINGLE_BUG_DATA,
  GET_SINGLE_BUG_DATA_SUCCESS,
  GET_SINGLE_BUG_DATA_ERROR,
  DELETE_TESTER_SKILL,
  DELETE_TESTER_SKILL_SUCCESS,
  DELETE_TESTER_SKILL_ERROR,
  GET_EARNING_GRAPH,
  GET_EARNING_GRAPH_SUCCESS,
  GET_EARNING_GRAPH_ERROR,
  DELETE_TESTER_DEVICE,
  DELETE_TESTER_DEVICE_SUCCESS,
  DELETE_TESTER_DEVICE_ERROR,
  DELETE_TESTER_ACCOUNT,
  DELETE_TESTER_ACCOUNT_SUCCESS,
  DELETE_TESTER_ACCOUNT_ERROR,
  UPDATE_PROFILE,
  UPDATE_PROFILE_SUCCESS,
  UPDATE_PROFILE_ERROR,
  UPDATE_PROFILE_WITHOUT_IMAGE,
  UPDATE_PROFILE_WITHOUT_IMAGE_SUCCESS,
  UPDATE_PROFILE_WITHOUT_IMAGE_ERROR,
  UPLOAD_FEEDBACK,
  UPLOAD_FEEDBACK_SUCCESS,
  UPLOAD_FEEDBACK_ERROR,
  UPLOAD_TESTIMONIAL,
  UPLOAD_TESTIMONIAL_SUCCESS,
  UPLOAD_TESTIMONIAL_ERROR,
  DELETE_PROFILE_PICTURE,
  DELETE_PROFILE_PICTURE_SUCCESS,
  DELETE_PROFILE_PICTURE_ERROR,
  GET_PROJECT_BUGS_EXPORT,
  GET_PROJECT_BUGS_EXPORT_SUCCESS,
  GET_PROJECT_BUGS_EXPORT_ERROR,
  GET_ASSIGNED_BUGS,
  GET_ASSIGNED_BUGS_SUCCESS,
  GET_ASSIGNED_BUGS_ERROR,
  ADD_COMMENT,
  ADD_COMMENT_SUCCESS,
  ADD_COMMENT_ERROR,
  GET_COMMENT,
  GET_COMMENT_SUCCESS,
  GET_COMMENT_ERROR
} from "../actions";

// Tester Leadboard Projects
export const getTesterLeaderboardProjects = () => ({
  type: GET_TESTER_LEADERBOARD_PROJECTS,
});
export const getTesterLeaderboardProjectsSuccess = payload => ({
  type: GET_TESTER_LEADERBOARD_PROJECTS_SUCCESS,
  payload,
});
export const getTesterLeaderboardProjectsError = () => ({
  type: GET_TESTER_LEADERBOARD_PROJECTS_ERROR,
});

// Tester Leadboard
export const getTesterLeaderboard = () => ({
  type: GET_TESTER_LEADERBOARD,
});
export const getTesterLeaderboardSuccess = payload => ({
  type: GET_TESTER_LEADERBOARD_SUCCESS,
  payload,
});
export const getTesterLeaderboardError = () => ({
  type: GET_TESTER_LEADERBOARD_ERROR,
});

// Live Projects
export const getTesterLiveProjects = () => ({
  type: GET_TESTER_LIVE_PROJECTS,
});
export const getTesterLiveProjectsSuccess = payload => ({
  type: GET_TESTER_LIVE_PROJECTS_SUCCESS,
  payload,
});
export const getTesterLiveProjectsError = () => ({
  type: GET_TESTER_LIVE_PROJECTS_ERROR,
});

// Get Tester Notifications
export const getTesterNotifications = () => ({
  type: GET_TESTER_NOTIFICATIONS,
});
export const getTesterNotificationsSuccess = payload => ({
  type: GET_TESTER_NOTIFICATIONS_SUCCESS,
  payload,
});
export const getTesterNotificationsError = () => ({
  type: GET_TESTER_NOTIFICATIONS_ERROR,
});

// Get Tester Devices
export const getTesterDevices = () => ({
  type: GET_TESTER_DEVICES,
});
export const getTesterDevicesSuccess = payload => ({
  type: GET_TESTER_DEVICES_SUCCESS,
  payload,
});
export const getTesterDevicesError = () => ({
  type: GET_TESTER_DEVICES_ERROR,
});

// Create Tester Devices
export const createTesterDevices = payload => ({
  type: CREATE_TESTER_DEVICES,
  payload,
});
export const createTesterDevicesSuccess = payload => ({
  type: CREATE_TESTER_DEVICES_SUCCESS,
  payload,
});
export const createTesterDevicesError = () => ({
  type: CREATE_TESTER_DEVICES_ERROR,
});

// Create Tester Devices
export const deleteTesterDevice = deviceID => ({
  type: DELETE_TESTER_DEVICE,
  deviceID,
});
export const deleteTesterDeviceSuccess = deviceID => ({
  type: DELETE_TESTER_DEVICE_SUCCESS,
  deviceID,
});
export const deleteTesterDeviceError = () => ({
  type: DELETE_TESTER_DEVICE_ERROR,
});

// DELETE TESTER ACCOUNT DETAILS
export const deleteTesterAccount = deviceID => ({
  type: DELETE_TESTER_ACCOUNT,
  deviceID,
});
export const deleteTesterAccountSuccess = payload => ({
  type: DELETE_TESTER_ACCOUNT_SUCCESS,
  payload,
});
export const deleteTesterAccountError = () => ({
  type: DELETE_TESTER_ACCOUNT_ERROR,
});

// Tester Paused Projects
export const getTesterPausedProjects = projectID => ({
  type: GET_TESTER_PAUSED_PROJECTS,
  payload: projectID,
});
export const getTesterPausedProjectsSuccess = payload => ({
  type: GET_TESTER_PAUSED_PROJECTS_SUCCESS,
  payload,
});
export const getTesterPausedProjectsError = () => ({
  type: GET_TESTER_PAUSED_PROJECTS_ERROR,
});
// Tester Completed Projects
export const getTesterCompletedProjects = projectID => ({
  type: GET_TESTER_COMPLETED_PROJECTS,
  payload: projectID,
});
export const getTesterCompletedProjectsSuccess = payload => ({
  type: GET_TESTER_COMPLETED_PROJECTS_SUCCESS,
  payload,
});
export const getTesterCompletedProjectsError = () => ({
  type: GET_TESTER_COMPLETED_PROJECTS_ERROR,
});

// Tester Project by ID
export const getTesterProjectByID = projectID => ({
  type: GET_TESTER_PROJECT_BY_ID,
  payload: projectID,
});
export const getTesterProjectByIDSuccess = payload => ({
  type: GET_TESTER_PROJECT_BY_ID_SUCCESS,
  payload,
});
export const getTesterProjectByIDError = () => ({
  type: GET_TESTER_PROJECT_BY_ID_ERROR,
});

// TEster Listed Bugs
export const getTesterListedBugs = projectID => ({
  type: GET_TESTER_LISTED_BUGS,
  payload: projectID,
});
export const getTesterListedBugsSuccess = payload => ({
  type: GET_TESTER_LISTED_BUGS_SUCCESS,
  payload,
});
export const getTesterListedBugsError = () => ({
  type: GET_TESTER_LISTED_BUGS_ERROR,
});

// Project Bugs Listed
export const getProjectListedBugs = (projectID) => {
  return{
  type: GET_PROJECT_LISTED_BUGS,
  projectID:projectID,
}
};
export const getProjectListedBugsSuccess = payload => ({
  type: GET_PROJECT_LISTED_BUGS_SUCCESS,
  payload,
});
export const getProjectListedBugsError = () => ({
  type: GET_PROJECT_LISTED_BUGS_ERROR,
});

export const getProjectBugsExport = (payload) => ({
  type: GET_PROJECT_BUGS_EXPORT,
  payload
});

export const getProjectBugsExportSuccess = (payload) => ({
  type: GET_PROJECT_BUGS_EXPORT_SUCCESS,
  payload
});

export const getProjectBugsExportError = () => ({
  type: GET_PROJECT_BUGS_EXPORT_ERROR
});


// Project Bugs Listed
export const getCommunityStates = () => ({
  type: GET_COMMUNITY_STATES,
});
export const getCommunityStatesSuccess = payload => ({
  type: GET_COMMUNITY_STATES_SUCCESS,
  payload,
});
export const getCommunityStatesError = () => ({
  type: GET_COMMUNITY_STATES_ERROR,
});

// Report New Bug
export const reportNewBug = (formData, attachment, history) => ({
  type: REPORT_NEW_BUG,
  payload: formData,
  attachment,
  history,
});
export const reportNewBugSuccess = payload => ({
  type: REPORT_NEW_BUG_SUCCESS,
  payload,
});
export const reportNewBugError = () => ({
  type: REPORT_NEW_BUG_ERROR,
});

// cHANGE pASSWORD
export const changePassword = formData => ({
  type: CHANGE_PASSWORD,
  payload: formData,
});
export const changePasswordSuccess = payload => ({
  type: CHANGE_PASSWORD_SUCCESS,
});
export const changePasswordError = () => ({
  type: CHANGE_PASSWORD_ERROR,
});

// Report New Bug
export const getWalletEarning = () => ({
  type: GET_WALLET_EARNING,
});
export const getWalletEarningSuccess = payload => ({
  type: GET_WALLET_EARNING_SUCCESS,
  payload,
});
export const getWalletEarningError = () => ({
  type: GET_WALLET_EARNING_ERROR,
});

// Report New Bug
export const getPaidEarning = () => ({
  type: GET_PAID_EARNING,
});
export const getPaidEarningSuccess = payload => ({
  type: GET_PAID_EARNING_SUCCESS,
  payload,
});
export const getPaidEarningError = () => ({
  type: GET_PAID_EARNING_ERROR,
});

// GET ALL SKILLS
export const getSingleBugData = payload => ({
  type: GET_SINGLE_BUG_DATA,
  payload,
});
export const getSingleBugDataSuccess = payload => ({
  type: GET_SINGLE_BUG_DATA_SUCCESS,
  payload,
});
export const getSingleBugDataError = () => ({
  type: GET_SINGLE_BUG_DATA_ERROR,
});

// GET ALL SKILLS
export const getAllSkills = () => ({
  type: GET_ALL_SKILLS,
});
export const getAllSkillsSuccess = payload => ({
  type: GET_ALL_SKILLS_SUCCESS,
  payload,
});
export const getAllSkillsError = () => ({
  type: GET_ALL_SKILLS_ERROR,
});

// create Tester Skill
export const createTesterSkill = payload => ({
  type: CREATE_TESTER_SKILL,
  payload,
});
export const createTesterSkillSuccess = payload => ({
  type: CREATE_TESTER_SKILL_SUCCESS,
  payload,
});
export const createTesterSkillError = () => ({
  type: CREATE_TESTER_SKILL_ERROR,
});

// create Tester Skill
export const getTesterSkills = payload => ({
  type: GET_TESTER_SKILLS,
  payload,
});
export const getTesterSkillsSuccess = payload => ({
  type: GET_TESTER_SKILLS_SUCCESS,
  payload,
});
export const getTesterSkillsError = () => ({
  type: GET_TESTER_SKILLS_ERROR,
});

// DELETE Tester Skill
export const deleteTesterSkill = payload => ({
  type: DELETE_TESTER_SKILL,
  payload,
});
export const deleteTesterSkillSuccess = payload => ({
  type: DELETE_TESTER_SKILL_SUCCESS,
  payload,
});
export const deleteTesterSkillError = () => ({
  type: DELETE_TESTER_SKILL_ERROR,
});

// GET_EARNING_GRAPH
export const getEarningGraph = payload => ({
  type: GET_EARNING_GRAPH,
  payload,
});
export const getEarningGraphSuccess = payload => ({
  type: GET_EARNING_GRAPH_SUCCESS,
  payload,
});
export const getEarningGraphError = () => ({
  type: GET_EARNING_GRAPH_ERROR,
});

// UPDATE PROFILE
export const updateProfile = (formData , userImage) => ({
  type: UPDATE_PROFILE,
  payload: formData,
  userImage
});
export const updateProfileSuccess = payload => ({
  type: UPDATE_PROFILE_SUCCESS,
  payload,
});
export const updateProfileError = () => ({
  type: UPDATE_PROFILE_ERROR,
});

// UPDATE PROFILE WITHOUT IMAGE
export const updateProfileWithoutImage = (formData) => ({
  type: UPDATE_PROFILE_WITHOUT_IMAGE,
  payload: formData,
});
export const updateProfileWithoutImageSuccess = payload => ({
  type: UPDATE_PROFILE_WITHOUT_IMAGE_SUCCESS,
  payload,
});
export const updateProfileWithoutImageError = () => ({
  type: UPDATE_PROFILE_WITHOUT_IMAGE_ERROR,
});

// FEEDBACK
export const uploadFeedback = formData => ({
  type: UPLOAD_FEEDBACK,
  payload: formData,
});
export const uploadFeedbackSuccess = payload => ({
  type: UPLOAD_FEEDBACK_SUCCESS,
});
export const uploadFeedbackError = () => ({
  type: UPLOAD_FEEDBACK_ERROR,
});

//Testimonial
export const uploadTestimonial = formData =>{
 return{
  type: UPLOAD_TESTIMONIAL,
  payload:formData,
 }
}

export const uploadTestimonialSuccess = payload => ({
  type: UPLOAD_TESTIMONIAL_SUCCESS,
});
export const uploadTestimonialError = () => ({
  type: UPLOAD_TESTIMONIAL_ERROR,
});

//Remove Profile Picture

export const removeProfilePicture = (payload) => {
  return {
    type: DELETE_PROFILE_PICTURE,
    payload
  }
};

export const removeProfilePictureSuccess = () => {
  return {
    type: DELETE_PROFILE_PICTURE_SUCCESS,
  }
};

export const removeProfilePictureError = () => {
  return {
    type: DELETE_PROFILE_PICTURE_ERROR,
  }
};

export const getAssignedBugs = (payload)=>{
  return {
    type: GET_ASSIGNED_BUGS,
    payload
  }
}

export const getAssignedBugsSuccess = (payload)=>{
  return {
    type: GET_ASSIGNED_BUGS_SUCCESS,
    payload
  }
}

export const getAssignedBugsError = ()=>{
  return {
    type: GET_ASSIGNED_BUGS_ERROR,
  }
}

export const addComment = (payload)=>{
  return {
    type: ADD_COMMENT,
    payload:payload
  }
}

export const addCommentSuccess = ()=>{
  return {
    type: ADD_COMMENT_SUCCESS
  }
}

export const addCommentError = ()=>{
  return {
    type: ADD_COMMENT_ERROR
  }
}

export const getComment = (bugId)=>{
  return {
    type: GET_COMMENT,
    bugId:bugId
  }
}

export const getCommentSuccess = (payload)=>{
  return {
    type: GET_COMMENT_SUCCESS,
    payload
  }
}

export const getCommentError = ()=>{
  return {
    type: GET_COMMENT_ERROR
  }
}

