import React from "react";
import { Link } from "react-router-dom";
import "./style.scss";
import nexgenLogo from "./../../../assets/images/quantlyBanner.png";
import fbLogo from "./../../../assets/icons/Group 10224.svg";
import linkedinLogo from "./../../../assets/icons/linkedIn-Icon.svg";
import twitterLogo from "./../../../assets/icons/icons8-twitter.svg";
import instaLogo from "./../../../assets/icons/Group 10222.svg";
//import discordLogo from "./../../../assets/icons/discordIcon.svg";
import settings from "../../../constants/settings";

const Footer = ({ isShow }) => {
  return (
    <div className={isShow ? "footer-main-container" : "display-none"}>
      <div className="footer-navigation">
        <div className="footer-logo-container">
          <div className="footer-logo">
            <a href="https://nexgeniots.com/" target="_blank" rel="noreferrer opener" className="inherit">
              <img src={nexgenLogo} alt="NexGen Logo" width="80%" /> 
            </a> 
          </div>
        </div>
        <div className="footer-navitems-container">
          <ul>
            <li>
              <Link className="inherit blue-color" to={`/crowdsourcedtesting/services`}>
                {settings.layout_footer.services}
              </Link>
              <ul style={{display:"block"}} >
                <li style={{height:'10px',paddingLeft:"0px"}} ></li>
                <li style={{fontSize:"12px",marginBottom:"10px",paddingLeft:"0px"}} ><Link to="/crowdsourcedtesting/crowd-testing" >Crowdsourced Testing</Link></li>
                <li style={{fontSize:"12px",marginBottom:"10px",paddingLeft:"0px"}} ><Link to="/crowdsourcedtesting/automation" >Automation Testing</Link></li>
              </ul>
            </li>
            <li>
            <Link className="inherit" to="/crowdsourcedtesting/tester">
            BECOME A TESTER
            </Link>
            </li>
            <li>
            <Link className="inherit" to="/crowdsourcedtesting/aboutus">
              <span >ABOUT US</span>
            </Link>
            </li>
            <li>
            <Link className="inherit" to="/crowdsourcedtesting/contactus">
              <span >CONTACT US</span>
            </Link>
            </li>
            {/* <li>
              <Link className="inherit blue-color" to={`/automation`}>
                {settings.layout_footer.automation}
              </Link>
            </li>
            <li>
              <Link className="inherit blue-color" to={`/crowdsourcedtesting/crowd-testing`}>
                {settings.layout_footer.crowdtest}
              </Link>
            </li>
            <li>
              <Link className="inherit blue-color" to={`/crowdsourcedtesting/devicehub`}>
                {settings.layout_footer.device}
              </Link>
            </li> */}
          </ul>
        </div>
        <div className="footer-social-container">
          <ul>
            <li>
              <a href="https://www.facebook.com/Qantily-107658081392006/" target="_blank" rel="noreferrer opener" className="inherit">
                <img src={fbLogo} className="socialMediaIcon" alt="Facebook" />
              </a>
            </li>
            <li>
              <a href="https://www.instagram.com/qantily/" target="_blank" rel="noreferrer opener" className="inherit">
                <img src={instaLogo} className="socialMediaIcon" alt="Instagram" /> 
              </a> 
            </li> 
            <li>
              <a href="https://twitter.com/qantily" target="_blank" rel="noreferrer opener" className="inherit">
                <img src={twitterLogo} className="socialMediaIcon" alt="Twitter" /> 
              </a>
            </li>
            <li>
              <a href="https://www.linkedin.com/company/qantily/" target="_blank" rel="noreferrer opener" className="inherit">
                <img src={linkedinLogo} className="socialMediaIcon" alt="LinkedIn" /> 
              </a>
            </li>
           {/* <li>
           <a href="https://discord.gg/tDXkYEtcSv" target="_blank" rel="noreferrer opener" className="inherit">
                <img src={discordLogo} className="socialMediaIcon" alt="Discord" /> 
              </a>
           </li> */}
          </ul>
        </div>
      </div>
      <div className="footer-otherdetails-container">
        <div className="copyright-details">
          <div className="divider2"></div>
          <div className="other-text">{settings.layout_footer.text1}</div>
          <div className="divider2"></div>

          {/* <div className="new-text">+91-98765643210</div> */}
          <div className="new-text">
            <a href="mailto:info@qantily.com" className="inherit">
              {settings.layout_footer.text3}
            </a>
          </div>
          <div className="other-text terms-privacy"><div><a href="/terms" style={{borderBottom:"1px solid grey"}}>TERMS &amp; CONDITIONS &nbsp;</a></div> <div><a href="/privacy" style={{borderBottom:"1px solid grey"}} >&nbsp;PRIVACY POLICY</a></div></div>   
          <div className="other-text" >{settings.layout_footer.text2}</div>  
        </div>
        <div className="company-connection">
          <div className="other-text">
            <a href="mailto:info@qantily.com" className="inherit">
              {settings.layout_footer.text3}
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;
