import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { useParams, useHistory } from "react-router-dom";
import axios from "axios";

import baseDomain, { approveBugReq, updateBugReq } from "../../../../../constants/apiRoutes";
import DashboardHeading from "../../../../Common/DashboardHeading";
import Loader from "../../../../Loader";
import { getSingleBugData } from "../../../../../redux/TesterDashboard/actions";
import { setAlert } from "../../../../../redux/Alerts/actions";
import settings from "../../../../../constants/settings";
import DeclineBugModal from "../../../../Modals/DeclineBugModal";
import "./style.scss";
import { toggleDeclineBugModal } from "../../../../../redux/Routing/actions";

const CompletedProjectBug = ({ getSingleBugData, singleBug, isLoading, role, setAlert, toggleDeclineBugModal, showPopUp }) => {
  const { bugID, projectID } = useParams();
  const history = useHistory();
  const [isApprovedLoading, setIsApprovedLoading] = useState(false);
  const [formData, setFormData] = useState({
    summary: "",
    component: "",
    priority: "",
    description: "",
    bugId: "",
  });

  useEffect(() => {
    setFormData({
      summary: singleBug.summary,
      component: singleBug.component,
      priority: singleBug.priority,
      description: singleBug.description,
      bugId: bugID,
    });
    // eslint-disable-next-line
  }, [singleBug]);

  useEffect(() => {
    getSingleBugData(bugID);
    // eslint-disable-next-line
  }, []);

  const handleDeclineBugModal = () => {
    toggleDeclineBugModal();
  };

  const handleApprove = async () => {
    setIsApprovedLoading(true);
    try {
      await axios.put(`${baseDomain}${approveBugReq}/${bugID}`);
      setAlert({
        message: "Bug Approved Successfully",
        type: "SUCCESS",
      });
      setIsApprovedLoading(false);
      if (role === "TESTER" || role === "REVIEWER") {
        history.push(`/dashboard/test-taken/projects/active/${projectID}`);
      } else if (role === "ADMIN") {
        history.push(`/dashboard/projects/projectlist/${projectID}/bugs`);
      }
    } catch (error) {
      setAlert({
        message: error.response.data.message || "Error while approving bug",
        type: "ERROR",
      });
      setIsApprovedLoading(false);
    }
  };

  const updateBug = async () => {
    try {
      await axios.put(`${baseDomain}${updateBugReq}`, formData);
      setAlert({
        message: "Bug Updated Successfully",
        type: "SUCCESS",
      });
      setIsApprovedLoading(false);
    } catch (error) {
      setAlert({
        message: error.response.data.message || "Error while updating bug",
        type: "ERROR",
      });
      setIsApprovedLoading(false);
    }
  };

  return (
    <div className="tester-completed-project-bug">
      {isLoading ? (
        <Loader />
      ) : (
        <>
          <div className="heading">
            <DashboardHeading heading={singleBug.project} headingStrong={settings.submitted_bug_details.heading} />
          </div>
          {/* <div className="BackBtnContainer FormFillUp-backbtn"
              onClick={e => {
                e.preventDefault();
                if (role === "TESTER" || role === "REVIEWER") {
                  history.push(`/dashboard/test-taken/projects/active/${projectID}/buglist`);
                } else if (role === "ADMIN") {
                  history.push(`/dashboard/projects/projectlist/${projectID}/bugs`);
                }
              }}
            >
              &laquo; {settings.report_bug_form.back_btn}
          </div> */}
          <div className="status_container">
            <div className="label">{settings.submitted_bug_details.status}</div>
            <div className={`tester-completed-test-status ${singleBug.status}`}>{singleBug.status}</div>
          </div>
          {/* {role === "REVIEWER" && ( */}
          {role === "TESTER" && (
            <>
              <div className="label">{settings.submitted_bug_details.summary}</div>
              <div className="tester-completed-test-summary">{singleBug.summary}</div>
              <div className="label">{settings.submitted_bug_details.component}</div>
              <div className="tester-completed-test-severity">{singleBug.component}</div>
              <div className="label">{settings.submitted_bug_details.priority}</div>
              <div className="tester-completed-test-oem">{singleBug.priority}</div>
              <div className="label">Device Used For Testing</div>
              <div className="tester-completed-test-oem">{singleBug.device?.name}</div>
              <div className="label">{settings.submitted_bug_details.attachments}</div>
              <button className="glb-btn" onClick={() => window.open(singleBug.attachments[0], "_blank")}>
                {singleBug.attachments && singleBug.attachments.length === 0 ? "No file attached" : "View Attachment"}
              </button>
              <div className="label">{settings.submitted_bug_details.Describe}</div>
              <div className="tester-completed-test-description">{singleBug.description}</div>

              {`${singleBug.status}` === "REJECTED" && (
                <>
                  <div className="label">Rejection Reason</div>
                  <div className="tester-completed-test-description">{singleBug.reason}</div>
                </>
              )}
            </>
          )}

          {/* {role === "TESTER" && ( */}
          {(role === "REVIEWER" || role === "ADMIN") && (
            <>
              <div className="label">{settings.submitted_bug_details.summary}</div>
              <input
                type="text"
                className="reviewer-summary"
                name="summary"
                value={formData.summary}
                onChange={e => setFormData({ ...formData, summary: e.target.value })}
              />
              <div className="label">{settings.submitted_bug_details.component}</div>
              <input
                type="text"
                className="reviewer-component"
                name="component"
                value={formData.component}
                onChange={e => setFormData({ ...formData, component: e.target.value })}
              />
              <div className="label">{settings.submitted_bug_details.priority}</div>
              <select
                className="reviewer-dropbox"
                name="priority"
                value={formData.priority}
                onChange={e => setFormData({ ...formData, priority: e.target.value })}
              >
                <option value="CRASH">{settings.report_bug_form.priority_option1}</option>
                <option value="BLOCKER">{settings.report_bug_form.priority_option2}</option>
                <option value="CRITICAL">{settings.report_bug_form.priority_option3}</option>
                <option value="MAJOR">{settings.report_bug_form.priority_option4}</option>
                <option value="MINOR">{settings.report_bug_form.priority_option5}</option>
                <option value="REPEATED">{settings.report_bug_form.priority_option6}</option>
                <option value="LOW">{settings.report_bug_form.priority_option7}</option>
              </select>
              <div className="label">Device Used For Testing</div>
              <div className="reviewer-summary">{singleBug.device?.name}</div>
              <div className="label">{settings.submitted_bug_details.attachments}</div>
              <button className="glb-btn" onClick={() => window.open(singleBug.attachments[0], "_blank")}>
                {singleBug.attachments && singleBug.attachments.length === 0 ? "No file attached" : "View Attachment"}
              </button>

              <div className="label">{settings.submitted_bug_details.Describe}</div>
              <textarea
                className="reviewer-textarea"
                row="6"
                col="50"
                required
                value={formData.description}
                name="description"
                onChange={e => setFormData({ ...formData, description: e.target.value })}
              />
              <div className="reviewer-update-btn" onClick={updateBug}>
                Update Bug
              </div>

              {`${singleBug.status}` === "REJECTED" && (
                <>
                  <div className="label">Rejection Reason</div>
                  <div className="tester-completed-test-description">{singleBug.reason}</div>
                </>
              )}
            </>
          )}

          {((role === "REVIEWER" && singleBug.status === "PENDING") || role === "ADMIN") && (
            <>
              <div className="reviewer-action">
                <button className={`glb-btn reviewer-btn no`} onClick={handleDeclineBugModal}>
                  {settings.submitted_bug_details.forReviewerNo}
                </button>
                <button className={`glb-btn reviewer-btn yes`} disabled={isApprovedLoading} onClick={handleApprove}>
                  {isApprovedLoading ? "Approving Bug" : settings.submitted_bug_details.forReviewerYes}
                </button>
              </div>
            </>
          )}
          <DeclineBugModal showModal={showPopUp} toggleModal={handleDeclineBugModal} />
        </>
      )}
    </div>
  );
};

const mapStateToProps = ({ testerDashboard, auth, routeingState }) => ({
  singleBug: testerDashboard.singleBugData,
  isLoading: testerDashboard.isLoading,
  role: auth.role,
  showPopUp: routeingState.showPopUp,
});

export default connect(mapStateToProps, { getSingleBugData, setAlert, toggleDeclineBugModal })(CompletedProjectBug);
