import React, { Fragment, useState,useEffect } from "react";
import { connect } from "react-redux";
import settings from "../../constants/settings";
import { eyeSign, googleLogo, linkedInLogoBlue } from "../../assets/icons/icons";
import Step1 from "../../assets/images/ProgressForm1.png";
import { PasswordViewer } from "../../utils/passwordVisibilityToggle";
import { signUpTesterProceed } from "../../redux/auth/actions";

const Form1 = ({
  data,
  signUp,
  form,
  setForm,
  countriesList,
  getStaticStates,
  statesList,
  isLoading,
  setAlert,
  title,
  setCounter,
  socialSignUpCheck,
  handleSocialSignUp,
  signUpTesterProceed
}) => {
  const { name, email, password, passwordRep } = form;
  const [currentState, setCurrentState] = useState({});
  const [isPasswordMatch, setIsPasswordMatch] = useState(true);
  const [isEmail, setIsEmail] = useState(true);
  const [showpasswordtester, setShowPasswordtester] = useState(false);
  
  // const [testerImage,setTesterImage] = useState(null);

  useEffect(()=>{
    signUpTesterProceed();
    // eslint-disable-next-line
  },[])

  const nextForm = e => {
    e.preventDefault();
    if (password !== passwordRep && !socialSignUpCheck) setAlert({ message: settings.signuptester.warningPassword, type: "WARNING" });
    else if (password.length < 8 && !socialSignUpCheck) setAlert({ message: "Password must have atleast 8 characters  ", type: "WARNING" });
    else if (!/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(email))
      setAlert({ message: settings.signuptester.warningEmail, type: "WARNING" });
    // else signUp(form,testerImage);
    else setCounter(1);
  };
  const setInput = e => {
    let value = e.target.type === "checkbox" ? e.target.checked : e.target.value;
    const name = e.target.name;
    if (name === "name" && value.length === 1 && value === " ") return;
    if ((name === "password" || name === "passwordRep") && value === " ") return setAlert({ message: "Password can't have space", type: "WARNING" });
    if (name === "name" || name === "domain") {
      value = value.replace(/[^A-Za-z ]/gi, "");
    }
    if (name === "city") {
      setForm({
        ...form,
        [name]: currentState.cities.filter(cty => cty.name === e.target.value)[0],
      });
    } else {
      setForm({
        ...form,
        [name]: value,
      });
    }
  };
  console.log(setCurrentState);

  return (
    <Fragment>
      <form onSubmit={nextForm}>
        <div className="social-logins">
          <button
            type="button"
            onClick={e => {
              handleSocialSignUp(e, "LINKEDIN");
            }}
            className="LinkedIn"
          >
            {linkedInLogoBlue} Sign-up With LinkedIn
          </button>
          <button
            type="button"
            onClick={e => {
              handleSocialSignUp(e, "GOOGLE");
            }}
            className="Google"
          >
            {googleLogo} Sign-up With Google
          </button>
        </div>

        <div className="progress-bar">
          <img src={Step1} alt="Step 1" width="60%" />
        </div>

        <div className="tester-details-form">
          <div className="details-input-fields">
            <label>{settings.signuptester.name}</label>
            <input type="text" className="input-text" placeholder={settings.signuptester.placeholder1} required name="name" value={name} onChange={setInput} />
          </div>
          <div className="details-input-fields">
            <label>{settings.signuptester.email}</label>
            <input
              type="text"
              className="input-text"
              placeholder={settings.signuptester.placeholder2}
              required
              name="email"
              value={email}
              onChange={setInput}
              onBlur={e => {
                // eslint-disable-next-line
                if (/^([A-Za-z0-9_\-\.]+)@([A-Za-z0-9_\-\.]+)\.([a-zA-Z]){2,15}$/.test(e.target.value)) setIsEmail(true);
                else setIsEmail(false);
              }}
            />
            <small className={!isEmail ? "show-error" : "hide"}>{settings.signuptester.valid_email}</small>
          </div>
          {!socialSignUpCheck && (
            <div className="details-input-fields">
              <label>{settings.signuptester.password}</label>
              <input
                type="password"
                className="input-text"
                required
                minLength="8"
                name="password"
                value={password}
                onChange={setInput}
                onBlur={() => {
                  if (passwordRep && passwordRep.length !== 0) {
                    if (password !== passwordRep) setIsPasswordMatch(false);
                    else setIsPasswordMatch(true);
                  }
                }}
              />
              <small className={!isPasswordMatch ? "show-error" : "hide"}>{settings.signuptester.message}</small>
            </div>
          )}
          {!socialSignUpCheck && (
            <div className="details-input-fields">
              <label>{settings.signuptester.repeatpassword}</label>
              <input
                type={showpasswordtester ? "text" : "password"}
                className="input-text"
                required
                name="passwordRep"
                value={passwordRep}
                onChange={setInput}
                onBlur={() => {
                  if (password !== passwordRep) setIsPasswordMatch(false);
                  else setIsPasswordMatch(true);
                }}
              />
              <svg
                onMouseOver={() => {
                  PasswordViewer(showpasswordtester, setShowPasswordtester);
                }}
                onMouseOut={() => {
                  PasswordViewer(showpasswordtester, setShowPasswordtester);
                }}
                onTouchStart={() => {
                  PasswordViewer(showpasswordtester, setShowPasswordtester);
                }}
                onTouchEnd={() => {
                  PasswordViewer(showpasswordtester, setShowPasswordtester);
                }}
                className="password-show-hide"
                fill={showpasswordtester ? "#646464" : "#bbbbbb"}
                width="24"
                height="24"
                style={{ marginRight: "5px" }}
                xmlns="http://www.w3.org/2000/svg"
                fill-rule="evenodd"
              >
                {eyeSign}
              </svg>
            </div>
          )}
        </div>
        <div className="prev-next" id="prev-next-1">
          <button className="glb-btn submit-form" type="submit">
            Next
          </button>
        </div>
      </form>
    </Fragment>
  );
};

export default connect(null,{signUpTesterProceed})(Form1);
