import React, { Fragment } from "react";
import { Route, Switch, BrowserRouter as Router, Redirect } from "react-router-dom";
import { connect } from "react-redux";

import { staticSiteNavHandler } from "./redux/Routing/actions";
import { MainRoutes } from "./routes";
import { toggleRegisterModal } from "./redux/Routing/actions";
import HomescreenModal from "./components/Modals/HomescreenModal";
import AlertService from "./Services/AlertService";

const App = ({ toggleRegisterModal, showRegisterModal, staticSiteNavHandler }) => {
  return (
    <Fragment>
      <Router>
        <AlertService>
          <HomescreenModal showModal={showRegisterModal} toggleModal={() => toggleRegisterModal()} />
          <Switch>
            <Route exact path="/">
              <Redirect to="/crowdsourcedtesting" />
            </Route>
            {MainRoutes.map(({ Component, path, exact = false }) => {
              return <Route key={path} component={() => <Component staticSiteNavHandler={staticSiteNavHandler} />} path={path} exact={exact} />;
            })}
            <Redirect to="/error" />
          </Switch>
        </AlertService>
      </Router>
    </Fragment>
  );
};

const mapStateToProps = ({ routeingState }) => ({
  showRegisterModal: routeingState.showRegisterModal,
});

export default connect(mapStateToProps, { toggleRegisterModal, staticSiteNavHandler })(App);
