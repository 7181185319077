import {
  GET_CLIENT_ONGOING_PROJECTS,
  GET_CLIENT_ONGOING_PROJECTS_SUCCESS,
  GET_CLIENT_ONGOING_PROJECTS_ERROR,
  GET_CLIENT_PAUSED_PROJECTS,
  GET_CLIENT_PAUSED_PROJECTS_SUCCESS,
  GET_CLIENT_PAUSED_PROJECTS_ERROR,
  GET_CLIENT_COMPLETED_PROJECTS,
  GET_CLIENT_COMPLETED_PROJECTS_SUCCESS,
  GET_CLIENT_COMPLETED_PROJECTS_ERROR,
  GET_CLIENT_SINGLE_PROJECT,
  GET_CLIENT_SINGLE_PROJECT_SUCCESS,
  GET_CLIENT_SINGLE_PROJECT_ERROR,
  GET_CLIENT_PROJECT_STATES,
  GET_CLIENT_PROJECT_STATES_SUCCESS,
  GET_CLIENT_PROJECT_STATES_ERROR,
  APP_DETAILS,
  APP_DETAILS_SUCCESS,
  APP_DETAILS_ERROR,
  APP_HINT,
  APP_HINT_SUCCESS,
  APP_HINT_ERROR,
  UPDATE_PROJECT_DETAILS,
  UPDATE_PROJECT_DETAILS_SUCCESS,
  UPDATE_PROJECT_DETAILS_ERROR,
  UPDATE_CLIENT_PROFILE,
  UPDATE_CLIENT_PROFILE_SUCCESS,
  UPDATE_CLIENT_PROFILE_ERROR,
  UPDATE_CLIENT_PROFILE_WITHOUT_IMAGE,
  UPDATE_CLIENT_PROFILE_WITHOUT_IMAGE_SUCCESS,
  UPDATE_CLIENT_PROFILE_WITHOUT_IMAGE_ERROR,
  DRAFT_PROJECT_CLIENT,
  DRAFT_PROJECT_CLIENT_SUCCESS,
  DRAFT_PROJECT_CLIENT_ERROR
} from "../actions";

// Client ongoing projects
export const getClientOnGoingProjects = () => ({
  type: GET_CLIENT_ONGOING_PROJECTS,
});
export const getClientOnGoingProjectsSuccess = payload => ({
  type: GET_CLIENT_ONGOING_PROJECTS_SUCCESS,
  payload,
});
export const getClientOnGoingProjectsError = payload => ({
  type: GET_CLIENT_ONGOING_PROJECTS_ERROR,
  payload,
});

// Client paused projects
export const getClientPausedProjects = () => ({
  type: GET_CLIENT_PAUSED_PROJECTS,
});
export const getClientPausedProjectsSuccess = payload => ({
  type: GET_CLIENT_PAUSED_PROJECTS_SUCCESS,
  payload,
});
export const getClientPausedProjectsError = payload => ({
  type: GET_CLIENT_PAUSED_PROJECTS_ERROR,
  payload,
});

// Client Completed projects
export const getClientCompletedProjects = () => ({
  type: GET_CLIENT_COMPLETED_PROJECTS,
});
export const getClientCompletedProjectsSuccess = payload => ({
  type: GET_CLIENT_COMPLETED_PROJECTS_SUCCESS,
  payload,
});
export const getClientCompletedProjectsError = payload => ({
  type: GET_CLIENT_COMPLETED_PROJECTS_ERROR,
  payload,
});

// Client Single projects
export const getClientSingleProject = payload => ({
  type: GET_CLIENT_SINGLE_PROJECT,
  payload,
});
export const getClientSingleProjectSuccess = payload => ({
  type: GET_CLIENT_SINGLE_PROJECT_SUCCESS,
  payload,
});
export const getClientSingleProjectError = payload => ({
  type: GET_CLIENT_SINGLE_PROJECT_ERROR,
  payload,
});

// Client Single projects
export const getClientProjectStates = payload => ({
  type: GET_CLIENT_PROJECT_STATES,
  payload,
});
export const getClientProjectStatesSuccess = payload => ({
  type: GET_CLIENT_PROJECT_STATES_SUCCESS,
  payload,
});
export const getClientProjectStatesError = payload => ({
  type: GET_CLIENT_PROJECT_STATES_ERROR,
  payload,
});

// App Hint
export const appHint = payload => ({
  type: APP_HINT,
  payload,
});
export const appHintSuccess = payload => ({
  type: APP_HINT_SUCCESS,
  payload,
});
export const appHintError = payload => ({
  type: APP_HINT_ERROR,
  payload,
});


// App Details
export const appDetails = payload => ({
  type: APP_DETAILS,
  payload,
});
export const appDetailsSuccess = payload => ({
  type: APP_DETAILS_SUCCESS,
  payload,
});
export const appDetailsError = payload => ({
  type: APP_DETAILS_ERROR,
  payload,
});

// Update Project Details
export const updateProjectDetails = payload => ({
  type: UPDATE_PROJECT_DETAILS,
  payload,
});
export const updateProjectDetailsSuccess = payload => ({
  type: UPDATE_PROJECT_DETAILS_SUCCESS,
  payload,
});
export const updateProjectDetailsError = payload => ({
  type: UPDATE_PROJECT_DETAILS_ERROR,
  payload,
});

// UPDATE CLIENT PROFILE
export const updateClientProfile = (formData , userImage) => ({
  type: UPDATE_CLIENT_PROFILE,
  payload: formData,
  userImage
});
export const updateClientProfileSuccess = payload => ({
  type: UPDATE_CLIENT_PROFILE_SUCCESS,
  payload,
});
export const updateClientProfileError = () => ({
  type: UPDATE_CLIENT_PROFILE_ERROR,
});

// UPDATE PROFILE WITHOUT IMAGE
export const updateClientProfileWithoutImage = (formData) => ({
  type: UPDATE_CLIENT_PROFILE_WITHOUT_IMAGE,
  payload: formData,
});
export const updateClientProfileWithoutImageSuccess = payload => ({
  type: UPDATE_CLIENT_PROFILE_WITHOUT_IMAGE_SUCCESS,
  payload,
});
export const updateClientProfileWithoutImageError = () => ({
  type: UPDATE_CLIENT_PROFILE_WITHOUT_IMAGE_ERROR,
});

//  SAVE CLIENT DRAFT PROJECT
export const draftClientProjects = () => {
  return {
    type: DRAFT_PROJECT_CLIENT,
  }
};
export const draftClientProjectsSuccess = payload => {
  return{
    type: DRAFT_PROJECT_CLIENT_SUCCESS,
    payload,
}
};
export const draftClientProjectsError = () => ({
  type: DRAFT_PROJECT_CLIENT_ERROR,
});