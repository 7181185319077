import React, { Fragment } from "react";

import "./style.scss";

const CardsGrid01 = ({ data = {}, settings = {}, fontWeight = false }) => {
  return (
    <Fragment>
      <div className={`flexboxgrid cards-grid-01 ${settings.greyBg}`}>
        <div className="custom-container center-xs">
          {data.heading && <h4>{data.heading}</h4>}
          {data.description && (
            <div className="row center-xs">
              <div className="description-container col-lg-8">
                <span>{data.description}</span>
              </div>
            </div>
          )}
          <div className={settings.isDevicesComponent ? "row custom-row-001" : "row custom-row-01"}>
            {data.items &&
              [...data.items].map(({ urlToImage, description, heading }, index) => (
                <Fragment key={index}>
                  <div className="tester_item--container col-xs">
                    
                    {urlToImage && (
                      <div className="image-container">
                        <div className={settings.floatingCards ? "tester_floating-cards" : ""}>
                          <img src={urlToImage} alt="icon" />
                        </div>
                      </div>
                    )}
                    {heading && <span className="whyQantily_heading">{heading}</span>}
                    {description && <span className="whyQantily_description">{description}</span>}
                  </div>
                </Fragment>
              ))}
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default CardsGrid01;
