import React, { useState, useEffect } from "react";
//import { Link } from "react-router-dom";
import { withData } from "../../../layouts";

import "./style.scss";
const words = ["Website", "Android App", "iOS App", "Console Game", "OTT Platform","Misc"];

const Banner = ({ data = {} }) => {
  const [index, setIndex] = useState(0);
  const [subIndex, setSubIndex] = useState(0);
  const [blink, setBlink] = useState(true);
  const [reverse, setReverse] = useState(false);

  useEffect(() => {
    if (index === words.length - 1) {
      setIndex(0);
      return;
    }
    if (subIndex === words[index].length + 1 && index !== words.length - 1 && !reverse) {
      setReverse(true);
      return;
    }
    if (subIndex === 0 && reverse) {
      setReverse(false);
      setIndex(prev => prev + 1);
      return;
    }
    const timeout = setTimeout(() => {
      setSubIndex(prev => prev + (reverse ? -1 : 1));
    }, 150);

    return () => clearTimeout(timeout);
  }, [subIndex, index, reverse]);

  useEffect(() => {
    const timeout2 = setTimeout(() => {
      setBlink(prev => !prev);
    }, 500);
    return () => clearTimeout(timeout2);
  }, [blink]);

  return (
    <div className="banner-main-container bg-light">
      <div className="banner-details-container">
        <div className="banner-details">
          <div className="banner-text">{data.heading}</div>
          <div className="banner-text div-yellow">{`${words[index].substring(0, subIndex)}${blink ? "|" : ""}`}</div>
          <span>{data.description}</span>
          {/* <div className="banner-button-flex">
            <Link to="/tester" className="glb-btn glb-btn-blue banner-button-tester">
              Become a Tester
            </Link>
            <Link to="/signup/setup" className="glb-btn glb-btn-blue banner-button-client">
              {data.buttonTextSecondary}
            </Link>
          </div> */}
        </div>
        <div className="banner-image-container">
          <img src={data.urlToImage} alt="Crowd Testing Illustration" />
        </div>
      </div>
    </div>
  );
};

export default withData(Banner);
