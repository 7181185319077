import React, { useState } from "react";
import { connect } from "react-redux";
import NoDataMessage from "../../NoDataMessage";
import settings from "../../../../constants/settings";
import { toggleTransferInitiatedModal } from "../../../../redux/Routing/actions";
import TransferInitiatedModal from "../../../Modals/TransferInitiatedModal";
import TesterTransferModal from "../../../Modals/TesterTransactionModal";

import "../style.scss";

const ManageAllEarningsTable = ({ manageEarnings, toggleTransferInitiatedModal, showPopUp }) => {
  const [showModal, setShowModal] = useState(false);
  const [particularTesterDetail, setParticularTesterDetails] = useState();
  const tableHeadingArray = ["Name of tranferee", "Total Amount", "Requested Money"];
  const handleSetShowModal = item => {
    setShowModal(true);
    setParticularTesterDetails(item);
  };
 
  const handlecheck = () => {
    setShowModal(!showModal);
    setParticularTesterDetails({});
  };

  const handleToggle = (paymentId, status) => {
    if (status !== "PAID") {
      setShowModal(false);
      toggleTransferInitiatedModal(paymentId);
    }
  };
  return (
    <div className="dashboard-table manage-all-earnings-table">
      {!manageEarnings && manageEarnings?.length === 0 ? (
        <NoDataMessage message={settings.my_earnings_table.message} />
      ) : (
        <>
          <table>
            <tr>
              {tableHeadingArray.map((head, i) => (
                <th key={i}>{head}</th>
              ))}
            </tr>

            {manageEarnings?.map((item, i) => {
              if (item && item.testersRequests && item.testersRequests.length > 0) {
                return (
                  <tr key={i}>
                    <td className="name">
                      <div className="link testerName" onClick={() => handleSetShowModal(item)}>
                        {item.name}
                      </div>
                    </td>
                    <td>{`${item.totalEarning && item.totalEarning !==0 ? "$" + item.totalEarning.toFixed(2) : "$ 0"}`}</td> 
                    <td>{`${item.pendingRequests && item.pendingRequests !== 0 ? "$" + item.pendingRequests.toFixed(2) : "$ 0"}`}</td> 
                  </tr>
                );
              } else return null;
            })}
          </table>
          <TransferInitiatedModal showModal={showPopUp} toggleModal={() => toggleTransferInitiatedModal()} />
          <TesterTransferModal
            showModal={showModal}
            handleToggle={handleToggle}
            manageEarnings={manageEarnings}
            particularTesterDetail={particularTesterDetail}
            toggleModal={handlecheck}
          />
        </>
      )}
    </div>
  );
};

const mapStateToProps = ({ routeingState }) => ({
  showPopUp: routeingState.showTransferInitiatedModal,
});

// export default ManageAllEarningsTable;
export default connect(mapStateToProps, { toggleTransferInitiatedModal })(ManageAllEarningsTable);
