import React from "react";
import { useHistory } from "react-router-dom";
import settings from "../../../constants/settings";
import { CloseSign } from "../../../assets/icons/icons";
import "./style.scss";

import client from "../../../assets/images/client.png";
import tester from "../../../assets/images/Alpha.png";

const HomescreenModal = ({ showModal, toggleModal }) => {
  const history = useHistory();
  const modalToggle = () => {
    toggleModal();
  };
  return (
    <div className={showModal ? "homescreen-modal-container blur-bg" : "none"}>
      <div className="homescreen-modal">
        <div className="close-icon">
          <div className="close" onClick={modalToggle}>
            {CloseSign}
          </div>
        </div>
        <div className="heading">
          <h2>{settings.homescreenModal.heading}</h2>
          <h4>{settings.homescreenModal.subHeading}</h4>
        </div>
        <div className="body-container">
          <div className="body">
            <div className="client">
              <div className="section-heading">{settings.homescreenModal.client_heading}</div>
              <div className="section-image">
                <img src={client} alt="Client Side" />
              </div>
              <div className="section-button">
                <div
                  onClick={() => {
                    history.push("/signup/setup");
                    toggleModal();
                  }}
                  className="banner-button-tester"
                >
                  <button>{settings.homescreenModal.client_button}</button>
                </div>
              </div>
            </div>
            <div className="tester">
              <div className="section-heading">{settings.homescreenModal.tester_heading}</div>
              <div className="section-image">
                <img src={tester} alt="Tester Side" />
              </div>
              <div className="section-button">
                <div
                  onClick={() => {
                    history.push("/signup/tester");
                    toggleModal();
                  }}
                  className="banner-button-tester"
                >
                  <button>{settings.homescreenModal.tester_button}</button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HomescreenModal;
