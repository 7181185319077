import React, { useState } from "react";
import "./style.scss";
const TestResourceList = () => {
  const [showDetails1, setShowDetails1] = useState(false);
  const [showDetails2, setShowDetails2] = useState(false);
  const [showDetails3, setShowDetails3] = useState(false);
  const [showDetails4, setShowDetails4] = useState(false);
  const [showDetails5, setShowDetails5] = useState(false);
  const [showDetails6, setShowDetails6] = useState(false);
  const [showDetails7, setShowDetails7] = useState(false);
  const UpArrow = ({ color }) => (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill={color} width="36px" height="36px">
      <path d="M0 0h24v24H0z" fill="none" />
      <path d="M12 8l-6 6 1.41 1.41L12 10.83l4.59 4.58L18 14z" />
    </svg>
  );

  const DownArrow = ({ color }) => (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill={color} width="36px" height="36px">
      <path d="M0 0h24v24H0z" fill="none" />
      <path d="M16.59 8.59L12 13.17 7.41 8.59 6 10l6 6 6-6z" />
    </svg>
  );

  return (
    <div className="test-resources-list-main-container">
      <div className="test-resources-list-item">
        <div className="test-resources-list-item-title">
          Android x 10 Hours{" "}
          <span
            onClick={() => {
              setShowDetails1(!showDetails1);
            }}
          >
            {showDetails1 ? <UpArrow color="#123dfb" /> : <DownArrow color="#123dfb" />}
          </span>
        </div>
        {showDetails1 && (
          <table className="test-resources-list-item-table">
            <tr className="table-row">
              <th className="table-header">For Known Bugs</th>
              <th className="table-header">Upload</th>
              <th className="table-header">Provide Web Link</th>
            </tr>
            <tr className="table-row">
              <td className="table-data">
                <div className="button green">Upload Excel</div>
              </td>
              <td className="table-data">
                <div className="button lg-blue">Upload APK</div>
              </td>
              <td className="table-data">
                <input className="glb-input" type="text" placeholder="www.abc.xyz" />
              </td>
            </tr>
          </table>
        )}
      </div>
      <div className="test-resources-list-item">
        <div className="test-resources-list-item-title">
          Android x 10 Hours{" "}
          <span
            onClick={() => {
              setShowDetails2(!showDetails2);
            }}
          >
            {showDetails2 ? <UpArrow color="#123dfb" /> : <DownArrow color="#123dfb" />}
          </span>
        </div>
        {showDetails2 && (
          <table className="test-resources-list-item-table">
            <tr className="table-row">
              <th className="table-header">For Known Bugs</th>
              <th className="table-header">Upload</th>
              <th className="table-header">Provide Web Link</th>
            </tr>
            <tr className="table-row">
              <td className="table-data">
                <div className="button green">Upload Excel</div>
              </td>
              <td className="table-data">
                <div className="button lg-blue">Upload APK</div>
              </td>
              <td className="table-data">
                <input className="glb-input" type="text" placeholder="www.abc.xyz" />
              </td>
            </tr>
          </table>
        )}
      </div>
      <div className="test-resources-list-item">
        <div className="test-resources-list-item-title">
          Android x 10 Hours{" "}
          <span
            onClick={() => {
              setShowDetails3(!showDetails3);
            }}
          >
            {showDetails3 ? <UpArrow color="#123dfb" /> : <DownArrow color="#123dfb" />}
          </span>
        </div>
        {showDetails3 && (
          <table className="test-resources-list-item-table">
            <tr className="table-row">
              <th className="table-header">For Known Bugs</th>
              <th className="table-header">Upload</th>
              <th className="table-header">Provide Web Link</th>
            </tr>
            <tr className="table-row">
              <td className="table-data">
                <div className="button green">Upload Excel</div>
              </td>
              <td className="table-data">
                <div className="button lg-blue">Upload APK</div>
              </td>
              <td className="table-data">
                <input className="glb-input" type="text" placeholder="www.abc.xyz" />
              </td>
            </tr>
          </table>
        )}
      </div>
      <div className="test-resources-list-item">
        <div className="test-resources-list-item-title">
          Android x 10 Hours{" "}
          <span
            onClick={() => {
              setShowDetails4(!showDetails4);
            }}
          >
            {showDetails4 ? <UpArrow color="#123dfb" /> : <DownArrow color="#123dfb" />}
          </span>
        </div>
        {showDetails4 && (
          <table className="test-resources-list-item-table">
            <tr className="table-row">
              <th className="table-header">For Known Bugs</th>
              <th className="table-header">Upload</th>
              <th className="table-header">Provide Web Link</th>
            </tr>
            <tr className="table-row">
              <td className="table-data">
                <div className="button green">Upload Excel</div>
              </td>
              <td className="table-data">
                <div className="button lg-blue">Upload APK</div>
              </td>
              <td className="table-data">
                <input className="glb-input" type="text" placeholder="www.abc.xyz" />
              </td>
            </tr>
          </table>
        )}
      </div>
      <div className="test-resources-list-item">
        <div className="test-resources-list-item-title">
          Android x 10 Hours{" "}
          <span
            onClick={() => {
              setShowDetails5(!showDetails5);
            }}
          >
            {showDetails5 ? <UpArrow color="#123dfb" /> : <DownArrow color="#123dfb" />}
          </span>
        </div>
        {showDetails5 && (
          <table className="test-resources-list-item-table">
            <tr className="table-row">
              <th className="table-header">For Known Bugs</th>
              <th className="table-header">Upload</th>
              <th className="table-header">Provide Web Link</th>
            </tr>
            <tr className="table-row">
              <td className="table-data">
                <div className="button green">Upload Excel</div>
              </td>
              <td className="table-data">
                <div className="button lg-blue">Upload APK</div>
              </td>
              <td className="table-data">
                <input className="glb-input" type="text" placeholder="www.abc.xyz" />
              </td>
            </tr>
          </table>
        )}
      </div>
      <div className="test-resources-list-item">
        <div className="test-resources-list-item-title">
          Android x 10 Hours{" "}
          <span
            onClick={() => {
              setShowDetails6(!showDetails6);
            }}
          >
            {showDetails6 ? <UpArrow color="#123dfb" /> : <DownArrow color="#123dfb" />}
          </span>
        </div>
        {showDetails6 && (
          <table className="test-resources-list-item-table">
            <tr className="table-row">
              <th className="table-header">For Known Bugs</th>
              <th className="table-header">Upload</th>
              <th className="table-header">Provide Web Link</th>
            </tr>
            <tr className="table-row">
              <td className="table-data">
                <div className="button green">Upload Excel</div>
              </td>
              <td className="table-data">
                <div className="button lg-blue">Upload APK</div>
              </td>
              <td className="table-data">
                <input className="glb-input" type="text" placeholder="www.abc.xyz" />
              </td>
            </tr>
          </table>
        )}
      </div>
      <div className="test-resources-list-item">
        <div className="test-resources-list-item-title">
          Android x 10 Hours{" "}
          <span
            onClick={() => {
              setShowDetails7(!showDetails7);
            }}
          >
            {showDetails7 ? <UpArrow color="#123dfb" /> : <DownArrow color="#123dfb" />}
          </span>
        </div>
        {showDetails7 && (
          <table className="test-resources-list-item-table">
            <tr className="table-row">
              <th className="table-header">For Known Bugs</th>
              <th className="table-header">Upload</th>
              <th className="table-header">Provide Web Link</th>
            </tr>
            <tr className="table-row">
              <td className="table-data">
                <div className="button green">Upload Excel</div>
              </td>
              <td className="table-data">
                <div className="button lg-blue">Upload APK</div>
              </td>
              <td className="table-data">
                <input className="glb-input" type="text" placeholder="www.abc.xyz" />
              </td>
            </tr>
          </table>
        )}
      </div>
    </div>
  );
};

export default TestResourceList;
