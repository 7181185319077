import React, { useEffect } from 'react'
import { connect } from 'react-redux';
import { setDashboardRoute } from '../../../redux/Routing/actions';
import { useRouteMatch, Route, Switch, Redirect } from "react-router-dom";
import AssignedProjects from './Components/AllProjects/index';
import BugsAssigned from './Components/BugList';

const AssignedBugs = ({setDashboardRoute,componentID}) => {

  useEffect(()=>{
    setDashboardRoute(componentID)
  // eslint-disable-next-line
  },[])

  const match = useRouteMatch();
    return (
      <Switch>
      <Redirect exact from={`${match.url}/`} to={`${match.url}/projectList`} />
      <Route path={`${match.url}/projectList`} exact={true} component={AssignedProjects} />
      <Route path={`${match.url}/projectList/:projectId/buglist`} exact={true} component={BugsAssigned} />
      <Redirect to={`/dashboard/home`} />
      </Switch>
  )
}

export default connect(null,{setDashboardRoute})(AssignedBugs);
