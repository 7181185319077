import React, { useEffect } from "react";
import { connect } from "react-redux";
import { useRouteMatch, Route, Switch, Redirect } from "react-router-dom";

import { setDashboardRoute } from "../../../redux/Routing/actions";

import NewTests from "./Components/NewTests";
import AddTests from "./Components";
import AppController from "./Components/AppController";

const NewProject = ({ setDashboardRoute, componentID }) => {
  const match = useRouteMatch();
  useEffect(() => {
    setDashboardRoute(componentID);
    // eslint-disable-next-line
  }, []);

  return (
    <div className="earnings">
      <Switch>
        <Redirect exact from={`${match.url}/`} to={`${match.url}/addtests`} />
        <Route path={`${match.url}/addtests`} exact={true} component={NewTests} />
        <Route path={`${match.url}/addtests/manualCard`} exact={true} component={AddTests} />
        <Route path={`${match.url}/addtests/:store`} exact={true} component={AppController} />
        <Redirect to={`/dashboard/tester/`} />
      </Switch>
    </div>
  );
};

export default connect(null, { setDashboardRoute })(NewProject);
