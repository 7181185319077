import { SET_TESTER_ROUTE, TOGGLE_SIDENAV, TOGGLE_REGISTER_MODAL, TOGGLE_DECLINE_BUG_MODAL, TOGGLE_RESET_PASSWORD_MODAL, TOGGLE_EDIT_CLIENT_MODAL, TOOGLE_INVITE_MODAL, STATIC_SITE_NAV_HANDLER , TOGGLE_TRANSFER_INITIATED_MODAL, ADD_COMMENT_MODAL } from "../actions";

const initialState = {
  dashboardCurrentRoute: "",
  showSideNav: false,
  showRegisterModal: false,
  showPopUp: false,
  showResetPassModal: false,
  showEditClientModal: false,
  showInviteModal: false, 
  currentStaticPage: "HOME",
  showTransferInitiatedModal: false, 
  paymentId: [],
  showCommentModal:false
};

const fun = (state = initialState, action) => {
  switch (action.type) {
    case SET_TESTER_ROUTE:
      return {
        ...state,
        dashboardCurrentRoute: action.payload,
      };
    case TOGGLE_SIDENAV:
      return {
        ...state,
        showSideNav: !state.showSideNav,
      };
    case TOGGLE_REGISTER_MODAL:
      return {
        ...state,
        showRegisterModal: !state.showRegisterModal,
      };
    case TOGGLE_DECLINE_BUG_MODAL:
      return {
        ...state,
        showPopUp: !state.showPopUp,
      };
    case TOGGLE_RESET_PASSWORD_MODAL:
      return {
        ...state,
        showResetPassModal: !state.showResetPassModal,
      };
    case TOGGLE_EDIT_CLIENT_MODAL:
      return {
        ...state,
        showEditClientModal: !state.showEditClientModal,
      };

    case TOOGLE_INVITE_MODAL:
      return {
        ...state,
        showInviteModal: !state.showInviteModal,
      };

    case TOGGLE_TRANSFER_INITIATED_MODAL:
      return {
        ...state,
        showTransferInitiatedModal: !state.showTransferInitiatedModal, 
        paymentId: action.payload,
      };
    case STATIC_SITE_NAV_HANDLER:
      return {
        ...state,
        currentStaticPage: action.payload,
      };
    case ADD_COMMENT_MODAL:
        return {
          ...state,
          showCommentModal: !state.showCommentModal,
        };
    default:
      return { ...state };
  }
};

export default fun;
