import React, { useEffect } from "react";
import { withLayout } from "../../layouts";
import AutoLogin from "../../components/AutoLogin";
import "./style.scss";
import { Helmet } from "react-helmet";
import AboutUsComp from "../../components/AboutUs";

import "./style.scss";
const AboutUs = ({ staticSiteNavHandler }) => {
  useEffect(() => {
    staticSiteNavHandler("ABOUTUS");
    // eslint-disable-next-line
  }, []);

  return (
    <div className="AboutUsView">
      <Helmet>
        <meta name="description" content="Who we are" />
      </Helmet>
      <AutoLogin />
      <AboutUsComp />
    </div>
  );
};

export default withLayout(AboutUs);
