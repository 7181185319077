import { all } from "redux-saga/effects";
import authSaga from "./auth/sagas";
import staticDataSaga from "./staticData/sagas";
import testerDashboardSaga from "./TesterDashboard/sagas";
import ClientAddTestSaga from "./ClientAddTest/sagas";
import ClientDashboard from "./ClientDashboard/sagas";
import AdminDashboard from "./AdminDashboard/sagas";

export default function* rootSaga(getState) {
  yield all([authSaga(), staticDataSaga(), testerDashboardSaga(), ClientAddTestSaga(), ClientDashboard(), AdminDashboard()]);
}
