import React, { useState } from "react";
import { connect } from "react-redux";
import { approveTestimonial, rejectTestimonial, updateTestimonial } from "../../../redux/AdminDashboard/actions";
import { setAlert } from "../../../redux/Alerts/actions";
import emptyProfile from "../../../assets/images/profile-icon-png-898.png";
import "./style.scss";

const ApproveTestimonials = ({ name, profilePic, testimonial, setView, testimonialId, approveTestimonial, rejectTestimonial, updateTestimonial, setAlert }) => {
  const [input, setInput] = useState({
    testimonial: testimonial || "",
  });

  const handleUpdate = () => {
    if (input.testimonial.length <= 0) {
      setAlert({
        message: "Please enter some review.",
        type: "ERROR",
      });
    } else {
      updateTestimonial(testimonialId, input);
      setView(true);
    }
  };

  const handleApprove = () => {
    approveTestimonial(testimonialId);
    setView(false);
  };

  const handleReject = () => {
    rejectTestimonial(testimonialId);
    setView(false);
  };

  return (
    <div className="testimonialApproval">
      <div className="testimonialApprovalModal">
        <div className="modal-header">
          <div
            onClick={() => {
              setView(false);
            }}
            style={{ cursor: "pointer", fontWeight: "bold", float: "right" }}
          >
            X
          </div>
          <div>
            <img width="100px" src={profilePic ? profilePic : emptyProfile} alt="profilePic" />
            <h3>{name}</h3>
          </div>
        </div>
        <textarea
          className="edit-testimonial"
          maxLength="250"
          type="name"
          value={input.testimonial ? input.testimonial : " "}
          onChange={e => setInput({ ...input, testimonial: e.target.value })}
        />
        <div className="approve-reject">
          <button className="glb-btn update" onClick={handleUpdate}>
            Save Changes
          </button>
          <button className="glb-btn approve" onClick={handleApprove}>
            Approve
          </button>
          <button className="glb-btn reject" onClick={handleReject}>
            Reject
          </button>
        </div>
      </div>
    </div>
  );
};

export default connect(null, { approveTestimonial, rejectTestimonial, updateTestimonial, setAlert })(ApproveTestimonials);
