import React, { useState, useEffect } from "react";
import { connect } from "react-redux";

import { signUpTester ,socialSignup , socialSignupCodeSend ,socialSignupSubmit} from "../../redux/auth/actions";
import { getStaticCountries, getStaticDevices, getStaticStates } from "../../redux/staticData/actions";
import { setAlert } from "../../redux/Alerts/actions";

import { useHistory} from "react-router-dom";

import Form1 from "./Form1";
import Form2 from "./Form2";
import Form3 from "./Form3";
import WelcomePage from "./WelcomePage";

import "./style.scss";
import Loader from "../Loader";
import { clearLocal } from "../../utils/localStorage";


const TesterSignUp = ({
  data = {},
  loading,
  error,
  proceed,
  signUpTester,
  getStaticCountries,
  getStaticDevices,
  getStaticStates,
  countriesList,
  statesList,
  devicesList,
  setAlert,
  socialSignup,
  redirectUrl,
  socialSignupCodeSend,
  preFill, 
  socialSignupSubmit,
  isLoading
}) => {
  const history = useHistory();
  const [form, setForm] = useState({
    name: "",
    email: "",
    password: "",
    phone: "",
    domain: "",
    country: "",
    state: "",
    passwordRep: "",
    city: {},
    declaration: false,
    accept: false,
    subscribe: false, 
    refferalCode:"",
    age:"",
    gender:""
  }); 
  const [socialSignUpCheck,setSocialSignUpCheck] = useState(false)

  const [counter, setCounter] = useState(0);

  const signUp = (formData,testerImage) => {
   if(socialSignUpCheck){
    socialSignupSubmit(formData, preFill.userId ,history);
   }
   else{
     signUpTester(formData,testerImage, history);
    }
  };

  useEffect(() => {
    clearLocal();
    getStaticCountries();
    getStaticDevices();
    const queryParams = new URLSearchParams(window.location.search);
    const code = queryParams.get('code')?.replace("/","%2F");
    const state = queryParams.get('state')  
    if(code && state ){
      socialSignupCodeSend(code,state,history)
    }
    // eslint-disable-next-line
  }, []);

  useEffect(()=>{
    if(redirectUrl.length !==0) window.location.href=`${redirectUrl}`
  },[redirectUrl])

  useEffect(()=>{
    if(Object.keys(preFill).length !== 0){
      setForm({...form,
      name:preFill?.name+" " +preFill?.lastName ,
      email:preFill?.email 
      })
      setSocialSignUpCheck(true);
    }
   // eslint-disable-next-line   
  },[preFill])

  const handleSocialSignUp = (e,prov)=>{
    e.preventDefault()
    const provider= prov
    const identity= "TESTER"
  socialSignup(identity,provider);
  }



  return (    
    <div className="tester-signup-main-container">
      <div className="tester-signup-image-section">
        <div className="tester-signup-image-container">
          <img src={data.urlToImage} alt={data.urlToImage}></img>
        </div>
      </div>
      <div className="tester-signup-content-section">
        {counter !== 3 && <div className="tester-signup-content-heading">Hey Buddy, <br/>Welcome To Qantily!</div> } 
        {counter === 0 && (
          isLoading ? <Loader/> :
          <Form1
            data={data}
            signUp={signUp}
            form={form}
            setForm={setForm}
            countriesList={countriesList}
            getStaticStates={getStaticStates}
            statesList={statesList} 
            setAlert={setAlert}
            title="form1"
            setCounter={setCounter}
            handleSocialSignUp={handleSocialSignUp}
            socialSignUpCheck={socialSignUpCheck}
          /> )
        }
        {counter === 1 &&
          ( isLoading? <Loader/> : 
          <Form2
            data={data}
            signUp={signUp}
            form={form}
            setForm={setForm}
            countriesList={countriesList}
            getStaticStates={getStaticStates}
            statesList={statesList} 
            setAlert={setAlert}
            title="form2"
            setCounter={setCounter}
            handleSocialSignUp={handleSocialSignUp}
          />)  
        }
        {counter === 2 &&
          ( isLoading ? <Loader/> : 
          <Form3
            data={data}
            signUp={signUp}
            form={form}
            setForm={setForm}
            countriesList={countriesList}
            getStaticStates={getStaticStates}
            statesList={statesList} 
            setAlert={setAlert}
            title="form2"
            setCounter={setCounter}
            error={error}
            proceed={proceed}
            handleSocialSignUp={handleSocialSignUp}
          />) 
        }
        {counter === 3 && 
         <WelcomePage 
         form={form}
         />
        } 
      </div>
    </div>
  );
};

const mapStateToProps = ({ auth, staticData }) => ({
  loading: auth.loading,
  error: auth.error,
  proceed: auth.proceed,
  token: auth.token,
  redirectUrl:auth.redirectUrl,
  countriesList: staticData.countries,
  statesList: staticData.states,
  devicesList: staticData.devices,
  preFill :auth.preFill,
  isLoading:auth.loading,
});

export default connect(mapStateToProps, { signUpTester, getStaticCountries, getStaticDevices, getStaticStates, setAlert ,socialSignup , socialSignupCodeSend , socialSignupSubmit})(TesterSignUp);
