import React from "react";
import settings from "../../../constants/settings";

import { Link } from "react-router-dom";
//import { convertMinutesToHours } from "../../../utils/getDateTime";
import profilePic from "../../../assets/images/profilePic.jpg";

import "./style.scss";
const DashbaordActiveTests = ({ onlineProjects }) => {
  return (
    <div className="dashboard-active-tests">
      <div className="comp-logo">
        <img src={onlineProjects?.icon?.url || onlineProjects?.icon} alt="" />
      </div>
      <div className="position">
        <small>{settings.dashboardactivetests.name}</small>
        <h4 className="name">{onlineProjects.name}</h4>
      </div>
      <div className="platform">
        <small>{settings.dashboardactivetests.platform}</small>
        <div className="onlineprojects-platform-container">
          {onlineProjects.platformIcon && (
            <div className="platform-icons">
              <img src={onlineProjects.platformIcon} alt="" />
            </div>
          )}
          <div className="platform-name">{onlineProjects.platformName}</div>
        </div>
      </div>
      {/* <div className="timings">
        <div className="time-disc">
          <small className="desc">{settings.dashboardactivetests.testingon}</small>
          <h4 className="time">
            {convertMinutesToHours(onlineProjects.testingHoursTotal)} {settings.dashboardactivetests.hours}
          </h4>
        </div>
        <div className="time-disc">
          <small className="desc">{settings.dashboardactivetests.testingends}</small>
          <h4 className="time">{`${convertMinutesToHours(onlineProjects.testingRemainingHours)} ${settings.dashboardactivetests.hours}`} </h4>
        </div>
      </div> */}

      <div className="leaderboard">
        <small>{settings.dashboardactivetests.leaderboard}</small>
        <div className="leaderboard-leaders">
          {onlineProjects.leaderBoard.length === 0 ? (
            <small>{settings.dashboardactivetests.noleaderboard}</small>
          ) : (
            onlineProjects.leaderBoard.slice(0, 3).map((user, i) => (
              <div className="leader" key={i}>
                <div className="image">
                  <img src={user.icon || profilePic} alt="" />
                </div>
                <div className="name">{user.name}</div>
                <div className="amount">$ {user?.earned ? user.earned.toFixed(2) : 0}</div>
                <div className="earned">{settings.dashboardactivetests.earned}</div>
              </div>
            ))
          )}
        </div>
      </div>
      <div className="begin-testing">
        <Link to={`/dashboard/test-taken/projects/active/${onlineProjects.projectId}`}>
          <button>{settings.dashboardactivetests.btnText1}</button>
        </Link>
      </div>
      <div className="active-test-bug-list">
        <Link to={`/dashboard/test-taken/projects/active/${onlineProjects.projectId}/buglist`}>
          <button>{settings.dashboardactivetests.btnText2}</button>
        </Link>
      </div>
    </div>
  );
};

export default DashbaordActiveTests;
