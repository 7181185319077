import React from "react";
import { Link } from "react-router-dom";

import NoDataMessage from "../../NoDataMessage";

import "../style.scss";

const AllProjectsListTable = ({ tableContentArray, message, reportBugs }) => { 
  return (
    <div className="dashboard-table all-projects-table">
      {tableContentArray.length === 0 ? (
        <NoDataMessage message={message} />
      ) : (
        <table className={`${reportBugs ? "true" : "false"}`}>
          <tr>
            <th>Project Name</th>
            {reportBugs && (
              <>
                <th>Bugs Reported</th>
                <th>Pending Bugs</th>
              </>
            )}
          </tr>
          
          {tableContentArray &&
            tableContentArray.map((item, i) => (
              <tr key={i}>
                <td className="projectName">
                <Link to={`/dashboard/projects/projectlist/${reportBugs ? item.projectId : item.id }/bugs`} className="blue-color">
                  {item.name}
                </Link>
                </td>
                {reportBugs && (
                  <>
                    <td>{item.bugReported}</td>
                    <td>{item.pending}</td>
                  </>
                )}
                {/* <td>
                  <Link to={`/dashboard/projects/projectlist/${reportBugs ? item.projectId : item.id }/bugs`} className="blue-color">
                    View Bugs
                  </Link>
                </td> */}
              </tr>
            ))}
        </table>
      )}
    </div>
  );
};

export default AllProjectsListTable;
