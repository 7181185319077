import React from "react";

import NoDataMessage from "../../NoDataMessage";
import settings from "../../../../constants/settings";

import "../style.scss";

// const tableHeadingArray = ["Payment Status", "Amount", "Transaction Code"];

const InitilizedTransfer = ({ transferStatusArray }) => {
  return (
    <div className="dashboard-table initilized-transfer-table">
      {transferStatusArray.length === 0 ? (
        <NoDataMessage message={settings.initialized_transfer_table.message} />
      ) : (
        <table>
          <tr>
            {settings.initialized_transfer_table.tableHeadingArray.map((head, i) => (
              <th key={i}>{head}</th>
            ))}
          </tr>

          {/*  */}
          {transferStatusArray.map((item, i) => (
            <tr key={i}>
              <td>{item.transactionCode}</td>
              <td>{item.amount ? item.amount.toFixed(2) : "-"}</td>
              <td>{item.createAt ? item.createAt.split("T")[0] : "-"}</td>
              <td className={`${item.paymentStatus}`}>{item.paymentStatus}</td>
            </tr>
          ))}
        </table>
      )}
    </div>
  );
};

export default InitilizedTransfer;
