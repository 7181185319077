import React, { useEffect } from "react";
import { connect } from "react-redux";
import { useRouteMatch, Route, Switch, Redirect } from "react-router-dom";

import { setDashboardRoute } from "../../../redux/Routing/actions";

import TesterDetails from "./Component/TesterDetails";
import TesterList from "./Component/TesterList";
import TesterBugDetail from "./Component/TesterBugDetail";

const ManageTesters = ({ setDashboardRoute, componentID }) => {
  const match = useRouteMatch();
  useEffect(() => {
    setDashboardRoute(componentID);
    // eslint-disable-next-line
  }, []);

  return (
    <div className="earnings">
      <Switch>
        <Redirect exact from={`${match.url}/`} to={`${match.url}/testerlist`} />
        <Route path={`${match.url}/testerlist`} exact={true} component={TesterList} />
        <Route path={`${match.url}/:id`} exact={true} component={TesterDetails} />
        <Route path={`${match.url}/:id/bug/:testerId`} exact={true} component={TesterBugDetail} />
        <Redirect to={`/dashboard/tester/`} />
      </Switch>
    </div>
  );
};

export default connect(null, { setDashboardRoute })(ManageTesters);
