import React, { Fragment, useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { connect } from "react-redux";

import { signUpClient, socialSignup, socialSignupCodeSend, socialSignupSubmit } from "../../redux/auth/actions";
import { getStaticCountries } from "../../redux/staticData/actions";
import { setAlert } from "../../redux/Alerts/actions";

import { isURLValidator } from "../../utils/validation";

import AccountSetupHeading from "../Common/AccountSetupHeading";
import settings from "../../constants/settings";
import { PasswordViewer } from "../../utils/passwordVisibilityToggle";
import { eyeSign, googleLogo, linkedInLogoBlue } from "../../assets/icons/icons";
import Step1 from "../../assets/images/ProgressBarStep1.png";
import Step2 from "../../assets/images/ProgressBarStep2.png";
import Step3 from "../../assets/images/ProgressBarStep3.png";

import "./style.scss";
import Loader from "../Loader";

import { clearLocal } from "../../utils/localStorage";
const SignupForm = ({
  loading,
  error,
  signUpClient,
  getStaticCountries,
  countriesList,
  setAlert,
  socialSignup,
  preFill,
  socialSignupCodeSend,
  socialSignupSubmit,
  redirectUrl,
}) => {
  const [form, setForm] = useState({
    name: "",
    email: "",
    password: "",
    passwordRep: "",
    position: "",
    phone: "",
    country: "",
    employees: "",
    website: "",
    decision: false,
    declaration: false,
    accept: false,
  });

  const [count, setCount] = useState(0);

  const [isURL, setIsURL] = useState(true);
  const [userImage, setUserImage] = useState(null);
  const [isEmail, setIsEmail] = useState(true);
  const [isPhoneNumber, setIsPhoneNumber] = useState(true);
  const [isValidDomain, setIsValidDomain] = useState(true);
  const [isPasswordMatch, setIsPasswordMatch] = useState(true);
  const [showpasswordclient, setShowPasswordclient] = useState(false);
  const [showcnfpasswordclient, setShowCnfPasswordclient] = useState(false);
  // const [isPhoneCorrect, setIsPhoneCorrect] = useState(true);
  const [isEmployees, setIsEmployees] = useState(true);
  const { name, email, password, position, phone, country, employees, website, decision, declaration, accept, passwordRep } = form;
  const history = useHistory();

  useEffect(() => {
    clearLocal();
    getStaticCountries();
    // eslint-disable-next-line
    const queryParams = new URLSearchParams(window.location.search);
    const code = queryParams.get("code")?.replace("/", "%2F");
    const state = queryParams.get("state");
    if (code && state) {
      socialSignupCodeSend(code, state, history);
    }
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (redirectUrl.length !== 0) window.location.href = `${redirectUrl}`;
  }, [redirectUrl]);

  useEffect(() => {
    if (Object.keys(preFill).length !== 0) {
      setForm({ ...form, name: preFill?.name + " " + preFill?.lastName, email: preFill?.email });
      setSocialSignUpCheck(true);
    }
    // eslint-disable-next-line
  }, [preFill]);

  const [socialSignUpCheck, setSocialSignUpCheck] = useState(false);

  const submitForm = e => {
    e.preventDefault();
    if (password !== passwordRep && !socialSignUpCheck) setAlert({ message: settings.signuptester.warningPassword, type: "WARNING" });
    else if (!/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(email))
      setAlert({ message: settings.signuptester.warningEmail, type: "WARNING" });
    else if (password.length < 8 && !socialSignUpCheck) setAlert({ message: "Password must have atleast 8 characters  ", type: "WARNING" });
    else if (phone.length !== 10) setAlert({ message: "Enter valid phone number", type: "WARNING" });
    else if (position.length < 3) setAlert({ message: "Enter valid Position", type: "WARNING" });
    else if (!isURLValidator(website)) setAlert({ message: "Enter Valid URL", type: "WARNING" });
    else if (socialSignUpCheck) {
      socialSignupSubmit(form, preFill.userId, history);
    } else {
      signUpClient(form, userImage, history);
    }
  };

  const setInput = e => {
    let value = e.target.type === "checkbox" ? e.target.checked : e.target.value;
    const name = e.target.name;
    if (name === "phone" && value.length > 10) return setAlert({ message: "Please enter number of 10 digit only", type: "WARNING" });
    if (name === "name" && value.length === 1 && value === " ") return;
    if (name === "phone" && value === "0") return setAlert({ message: "Please Enter a Valid Digit", type: "WARNING" });
    if ((name === "password" || name === "passwordRep") && !socialSignUpCheck && value === " ")
      return setAlert({ message: "Password can't have space", type: "WARNING" });
    if (name === "name" || name === "position") {
      value = value.replace(/[^A-Za-z ]/gi, "");
    }
    setForm({
      ...form,
      [name]: value,
    });
  };

  const handleSocialSignUp = (e, prov) => {
    e.preventDefault();
    const provider = prov;
    const identity = "CLIENT";
    socialSignup(identity, provider);
  };

  const validateStep1 = () => {
    if ((name === "" || email === "" || password === "" || passwordRep === "") && !socialSignUpCheck) {
      setAlert({ message: "Please Fill all Details", type: "WARNING" });
    } else {
      if (password.length < 8 && !socialSignUpCheck) {
        setAlert({ message: "Password must have atleast 8 characters  ", type: "WARNING" });
      } else if (password !== passwordRep && !socialSignUpCheck) {
        setAlert({ message: settings.signuptester.warningPassword, type: "WARNING" });
      } else if (!/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(email)) {
        setAlert({ message: "Please Enter Valid Email", type: "WARNING" });
      } else {
        setCount(1);
      }
    }
  };

  const validateStep2 = () => {
    if (phone === "" || position === "") {
      setAlert({ message: "Please Fill all Details", type: "WARNING" });
    } else {
      if (phone.length !== 10) {
        setAlert({ message: "Enter valid phone number", type: "WARNING" });
      } else {
        setCount(2);
      }
    }
  };

  return (
    <Fragment>
      <AccountSetupHeading />
      <div className="signup-form">
        <h2 className="form-heading">{settings.signupclient.heading}</h2>
        <div className="form-submit" style={{ justifyContent: "space-around" }}>
          <button
            onClick={e => {
              handleSocialSignUp(e, "LINKEDIN");
            }}
            id="linkedin"
            className="submit-form"
            style={{ marginTop: "10px", display: "block" }}
          >
            {linkedInLogoBlue} Sign up with LinkedIn
          </button>

          <button
            onClick={e => {
              handleSocialSignUp(e, "GOOGLE");
            }}
            id="google"
            className="submit-form"
            style={{ marginTop: "10px", display: "block" }}
          >
            {googleLogo} Sign up with Google
          </button>
        </div>
        <br />
        <br />
        <div className="progress-bar" style={{ textAlign: "center" }}>
          {count === 0 && <img src={Step1} alt="Step 1" width="60%" />}
          {count === 1 && <img src={Step2} alt="Step 2" width="60%" />}
          {count === 2 && <img src={Step3} alt="Step 3" width="60%" />}
        </div>
        <br />
        {loading ? (
          <Loader />
        ) : (
          <form onSubmit={submitForm}>
            <div className="form-inputs">
              {count === 0 && (
                <>
                  <div className="form-group">
                    <label>{settings.signupclient.name}</label>
                    <input type="text" placeholder={settings.signupclient.placeholder1} required name="name" value={name} onChange={setInput} />
                  </div>
                  <div className="form-group">
                    <label>{settings.signupclient.email}</label>
                    <input
                      type="email"
                      placeholder={settings.signupclient.placeholder2}
                      required
                      name="email"
                      value={email}
                      onChange={setInput}
                      onBlur={e => {
                        // eslint-disable-next-line
                        if (/^([A-Za-z0-9_\-\.]+)@([A-Za-z0-9_\-\.]+)\.([a-zA-Z]){2,15}$/.test(e.target.value)) setIsEmail(true);
                        else setIsEmail(false);
                      }}
                    />
                    <small className={!isEmail ? "show-error" : "hide"}>{settings.signuptester.valid_email}</small>
                  </div>
                  {!socialSignUpCheck && (
                    <div className="form-group">
                      <label>{settings.signupclient.password}</label>
                      <input
                        type={showcnfpasswordclient ? "text":"password"}
                        required
                        minLength="8"
                        name="password"
                        placeholder={settings.signupclient.placeholder7}
                        value={password}
                        onChange={setInput}
                        onBlur={() => {
                          if (passwordRep && passwordRep.length !== 0) {
                            if (password !== passwordRep) setIsPasswordMatch(false);
                            else setIsPasswordMatch(true);
                          }
                        }}
                      />
                                            <svg
                        onMouseOver={() => {
                          PasswordViewer(showcnfpasswordclient, setShowCnfPasswordclient);
                        }}
                        onMouseOut={() => {
                          PasswordViewer(showcnfpasswordclient, setShowCnfPasswordclient);
                        }}
                        onTouchStart={() => {
                          PasswordViewer(showcnfpasswordclient, setShowCnfPasswordclient);
                        }}
                        onTouchEnd={() => {
                          PasswordViewer(showcnfpasswordclient, setShowCnfPasswordclient);
                        }}
                        className="password-show-hide"
                        fill={showcnfpasswordclient ? "#646464" : "#bbbbbb"}
                        width="24"
                        height="24"
                        xmlns="http://www.w3.org/2000/svg"
                        fill-rule="evenodd"
                      >
                        {eyeSign}
                      </svg>
                      <small className={!isPasswordMatch ? "show-error" : "hide"}>{settings.signuptester.message}</small>
                    </div>
                  )}
                  {!socialSignUpCheck && (
                    <div className="form-group">
                      <label>{settings.signupclient.confirmPassword}</label>
                      <input
                        type={showpasswordclient ? "text" : "password"}
                        required
                        minLength="8"
                        name="passwordRep"
                        value={passwordRep}
                        placeholder={settings.signupclient.placeholder8}
                        onChange={setInput}
                        onBlur={() => {
                          if (password !== passwordRep) setIsPasswordMatch(false);
                          else setIsPasswordMatch(true);
                        }}
                      />
                      <svg
                        onMouseOver={() => {
                          PasswordViewer(showpasswordclient, setShowPasswordclient);
                        }}
                        onMouseOut={() => {
                          PasswordViewer(showpasswordclient, setShowPasswordclient);
                        }}
                        onTouchStart={() => {
                          PasswordViewer(showpasswordclient, setShowPasswordclient);
                        }}
                        onTouchEnd={() => {
                          PasswordViewer(showpasswordclient, setShowPasswordclient);
                        }}
                        className="password-show-hide"
                        fill={showpasswordclient ? "#646464" : "#bbbbbb"}
                        width="24"
                        height="24"
                        xmlns="http://www.w3.org/2000/svg"
                        fill-rule="evenodd"
                      >
                        {eyeSign}
                      </svg>
                    </div>
                  )}
                  <div></div>
                  <div className="form-submit">
                    <button className="glb-btn" onClick={validateStep1} type="submit" style={{ float: "right" }}>
                      Next
                    </button>
                  </div>
                </>
              )}

              {count === 1 && (
                <>
                  <div className="form-group">
                    <label>{settings.signupclient.contactinfo}</label>
                    <input
                      type="number"
                      placeholder={settings.signupclient.placeholder4}
                      name="phone"
                      value={phone}
                      required
                      pattern="[0-9]{10}"
                      onChange={setInput}
                      onBlur={() => {
                        if (phone.length !== 10) setIsPhoneNumber(false);
                        else setIsPhoneNumber(true);
                      }}
                    />
                    <small className={!isPhoneNumber ? "show-error" : "hide"}>Enter a valid phone number</small>

                    <small className={"hide"}>{settings.signupclient.message1}</small>
                    {/* <small className={!isPhoneCorrect ? "show-error" : "hide"}>Invalid Contact Information</small> */}
                  </div>
                  <div className="form-group">
                    <label>{settings.signupclient.position}</label>
                    <input
                      type="text"
                      minLength="3"
                      placeholder={settings.signupclient.placeholder3}
                      required
                      name="position"
                      value={position}
                      onChange={setInput}
                      onBlur={() => {
                        if (position.length < 3) setIsValidDomain(false);
                        else setIsValidDomain(true);
                      }}
                    />
                    <small className={!isValidDomain ? "show-error" : "hide"}>Enter a valid Position</small>
                  </div>
                  <button
                    className="glb-btn previouButton"
                    type="button"
                    onClick={() => {
                      setCount(0);
                    }}
                  >
                    &#8592;
                  </button>
                  <div className="form-submit" style={{ float: "right" }}>
                    <button className="glb-btn" type="submit" onClick={validateStep2}>
                      Next
                    </button>
                  </div>
                </>
              )}

              {count === 2 && (
                <>
                  <div className="form-group">
                    <label>{settings.signupclient.headquarters}</label>
                    <select
                      className="input-select"
                      id="countryList"
                      value={country}
                      onChange={setInput}
                      name="country"
                      disabled={countriesList.length === 0}
                      required
                    >
                      <option value="">Choose Country</option>
                      {countriesList &&
                        countriesList.map((cont, i) => (
                          <option value={cont.id} key={i}>
                            {cont.name} ({cont.phone_code})
                          </option>
                        ))}
                    </select>
                  </div>
                  <div className="form-group">
                    <label>{settings.signupclient.employees}</label>
                    <input
                      type="number"
                      placeholder={settings.signupclient.placeholder5}
                      name="employees"
                      min="1"
                      value={employees}
                      onChange={setInput}
                      required
                      onBlur={() => {
                        if (employees <= 0) setIsEmployees(false);
                        else setIsEmployees(true);
                      }}
                    />
                    <small className={!isEmployees ? "show-error" : "hide"}>{settings.signupclient.message2}</small>
                  </div>

                  <div className="form-group web-field">
                    <label>{settings.signupclient.website}</label>
                    <input
                      type="text"
                      placeholder={settings.signupclient.placeholder6}
                      required
                      name="website"
                      value={website}
                      onChange={setInput}
                      onBlur={e => {
                        // eslint-disable-next-line+
                        if (isURLValidator(e.target.value)) setIsURL(true);
                        else setIsURL(false);
                      }}
                    />
                    <small className={!isURL ? "show-error" : "hide"}>{settings.signupclient.message3}</small>
                  </div>

                  <div className="form-group img-field">
                    <label>{settings.signupclient.profileimg}</label>
                    <label htmlFor="user-image" className="user-image-label">
                      {userImage ? userImage.name : "Add Profile image"}
                    </label>
                    <input type="file" id="user-image" className="image-input" onChange={e => setUserImage(e.target.files[0])} accept="image/*" name="file" />
                    <small className={"hide"}>h</small>
                  </div>

                  <button
                    className="glb-btn previouButton"
                    type="button"
                    onClick={() => {
                      setCount(1);
                    }}
                  >
                    &#8592;
                  </button>
                  <div className="form-check-box">
                    <div className="form-group">
                      <input type="checkbox" name="decision" required checked={decision} onChange={setInput} />
                      <span> {settings.signupclient.decision}</span>
                    </div>
                    <div className="form-group">
                      <input type="checkbox" name="declaration" required checked={declaration} onChange={setInput} />
                      <span>{settings.signupclient.declaration}</span>
                    </div>
                    <div className="form-group">
                      <input type="checkbox" name="accept" required checked={accept} onChange={setInput} />
                      <span>
                        By Signing up you agree to our{" "}
                        <u>
                          <a href="/terms" className="form-link">
                            Terms and Conditions
                          </a>
                        </u>{" "}
                        and{" "}
                        <u>
                          <a href="/privacy" className="form-link">
                            Privacy Policy
                          </a>
                        </u>
                        .*
                      </span>
                    </div>
                  </div>
                  <div className="form-submit" style={{ float: "right" }}>
                    <button className="glb-btn" type="submit" disabled={loading}>
                      {settings.signupclient.submit_btn}
                    </button>
                  </div>
                </>
              )}
            </div>
          </form>
        )}
      </div>
    </Fragment>
  );
};

const mapStateToProps = ({ auth, staticData }) => ({
  loading: auth.loading,
  error: auth.error,
  countriesList: staticData.countries,
  preFill: auth.preFill,
  redirectUrl: auth.redirectUrl,
});

export default connect(mapStateToProps, { signUpClient, getStaticCountries, setAlert, socialSignup, socialSignupCodeSend, socialSignupSubmit })(SignupForm);
