import React from "react";
import service1 from "../../../assets/icons/1147212.png";
import service2 from "../../../assets/icons/3463339.png";
import service3 from "../../../assets/icons/126481.png";
import service4 from "../../../assets/icons/1930814.png";
import service5 from "../../../assets/icons/481270.png";

import "./style.scss";

function ServiceOfferings({ data = {} }) {
  return (
    <div className="service-offerings">
      <h4 className="service-offerings-heading">{data.heading1}</h4>

      <div className="service-offerings-icons">
        <div className="service-box">
          <img src={service1} alt="Automation Working" />
          <h4 className="service-box-heading">{data.item1a}</h4>
        </div>

        <div className="service-box">
          <img src={service2} alt="Automation Working" />
          <h4 className="service-box-heading">{data.item1b}</h4>
        </div>

        <div className="service-box">
          <img src={service3} alt="Automation Working" />
          <h4 className="service-box-heading">{data.item1c}</h4>
        </div>

        <div className="service-box">
          <img src={service4} alt="Automation Working" />
          <h4 className="service-box-heading">{data.item1d}</h4>
        </div>

        <div className="service-box">
          <img src={service5} alt="Automation Working" />
          <h4 className="service-box-heading">{data.item1e}</h4>
        </div>
      </div>
    </div>
  );
}

export default ServiceOfferings;
