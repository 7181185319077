import React from "react";

import "./style.scss";

function PricingComponent({ data = {} }) {

  return (
    <div className="pricing">
      <div className="pricing-heading">{data.heading}</div>
      <div className="pricing-subHeading">{data.subHeading}</div>
      
    </div>
  );
}

export default PricingComponent;
