import {
  GET_STATIC_COUNTRIES,
  GET_STATIC_COUNTRIES_SUCCESS,
  GET_STATIC_COUNTRIES_ERROR,
  GET_STATIC_STATES,
  GET_STATIC_STATES_SUCCESS,
  GET_STATIC_STATES_ERROR,
  GET_STATIC_DEVICES,
  GET_STATIC_DEVICES_SUCCESS,
  GET_STATIC_DEVICES_ERROR,
  GET_STATIC_PLATFORMS,
  GET_STATIC_PLATFORMS_SUCCESS,
  GET_STATIC_PLATFORMS_ERROR,
} from "../actions";

// Countries
export const getStaticCountries = () => ({
  type: GET_STATIC_COUNTRIES,
});
export const getStaticCountriesSuccess = payload => ({
  type: GET_STATIC_COUNTRIES_SUCCESS,
  payload,
});
export const getStaticCountriesError = payload => ({
  type: GET_STATIC_COUNTRIES_ERROR,
  payload,
});

// States
export const getStaticStates = payload => ({
  type: GET_STATIC_STATES,
  payload,
});
export const getStaticStatesSuccess = payload => ({
  type: GET_STATIC_STATES_SUCCESS,
  payload,
});
export const getStaticStatesError = payload => ({
  type: GET_STATIC_STATES_ERROR,
  payload,
});

// Devices
export const getStaticDevices = () => ({
  type: GET_STATIC_DEVICES,
});
export const getStaticDevicesSuccess = payload => ({
  type: GET_STATIC_DEVICES_SUCCESS,
  payload,
});
export const getStaticDevicesError = payload => ({
  type: GET_STATIC_DEVICES_ERROR,
  payload,
});

// Platforms
export const getStaticPlatforms = () => ({
  type: GET_STATIC_PLATFORMS,
});
export const getStaticPlatformsSuccess = payload => ({
  type: GET_STATIC_PLATFORMS_SUCCESS,
  payload,
});
export const getStaticPlatformsError = payload => ({
  type: GET_STATIC_PLATFORMS_ERROR,
  payload,
});
