import React, { useEffect } from "react";
import { connect } from "react-redux";

import { getAdminLiveProjects, getAdminPausedProjects, getAdminCompletedProjects } from "../../../../../redux/AdminDashboard/actions";

import DashboardHeading from "../../../../Common/DashboardHeading";
import AllProjectsListTable from "../../../../Common/DashboardTables/AllProjectsListTable";
import Loader from "../../../../Loader";
import settings from "../../../../../constants/settings";

import "./style.scss";

const AllProjectsList = ({ getAdminLiveProjects, getAdminPausedProjects, getAdminCompletedProjects, activeProjects, pausedProjects, completedProjects, isLoading }) => {
  useEffect(() => {
    getAdminLiveProjects();
    getAdminPausedProjects();
    getAdminCompletedProjects();
    // eslint-disable-next-line
  }, []);
  return (
    <div className="manage-bugs-all-projects">
      <div className="active-projects">
        <DashboardHeading heading={settings.all_projects.heading1.light} headingStrong={settings.all_projects.heading1.bold} />
        <div className="cards">{isLoading ? <Loader /> : <AllProjectsListTable tableContentArray={activeProjects} message={"No Active Projects"} reportBugs={true} />}</div>
      </div>
      {(pausedProjects.length !== 0) && (
        <div className="paused-projects">          
          <DashboardHeading headingStrong={"Paused Projects"} />
          <div className="cards">{isLoading ? <Loader /> : <AllProjectsListTable tableContentArray={pausedProjects} message={"No Paused Projects"} reportBugs={true} />}</div>       
        </div>
      )}
      {(completedProjects.length !== 0) && (
        <div className="completed-projects">
        <DashboardHeading heading={settings.all_projects.heading2.light} headingStrong={settings.all_projects.heading2.bold} />
        <div className="cards">{isLoading ? <Loader /> : <AllProjectsListTable tableContentArray={completedProjects} message={"No Completed Projects"} reportBugs={true} />}</div>
      </div>
      )}
      
    </div>
  );
};

const mapStateToProps = ({ adminDashboard }) => ({
  activeProjects: adminDashboard.adminLiveProjects,
  pausedProjects: adminDashboard.adminPausedProjects,
  completedProjects: adminDashboard.adminCompletedProjects,
  isLoading: adminDashboard.isLoading,  
});

export default connect(mapStateToProps, { getAdminLiveProjects, getAdminPausedProjects, getAdminCompletedProjects })(AllProjectsList);