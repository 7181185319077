import React from "react";
import { Link } from "react-router-dom";

import NoDataMessage from "../../NoDataMessage";
import settings from "../../../../constants/settings";

import "../style.scss";

// const tableHeadingArray = ["Project Name", "Earnings", "Date", "Status"];

const LifetimeEarningsTable = ({ lifetimeearningscontent }) => {
  return (
    <div className="dashboard-table lifetime-earning-table">
      {lifetimeearningscontent.length === 0 ? (
        <NoDataMessage message={settings.lifetime_earning_table.message} />
      ) : (
        <table>
          <tr>
            {settings.lifetime_earning_table.tableHeadingArray.map((head, i) => (
              <th key={i}>{head}</th>
            ))}
          </tr>

          {lifetimeearningscontent.map((item, i) => (
            <tr key={i}>
              <td>
                {" "}
                <Link className="link">{item.projectName}</Link>
              </td>
              <td>{item.earning ? item.earning.toFixed(2) : "-"}</td>
              <td>{item.date}</td>
              <td>{item.status}</td>
            </tr>
          ))}
        </table>
      )}
    </div>
  );
};

export default LifetimeEarningsTable;
