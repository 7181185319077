import React, { useState, useEffect } from "react";
import { connect } from "react-redux";

import { setDashboardRoute } from "../../../redux/Routing/actions";
import { changePassword } from "../../../redux/TesterDashboard/actions";
import { updateClientProfile, updateClientProfileWithoutImage } from "../../../redux/ClientDashboard/actions";
import { getStaticCountries } from "../../../redux/staticData/actions";

import DashboardHeading from "../../Common/DashboardHeading";
import DashboardSubHeading from "../../Common/DashboardSubHeading";
import Dropdown from "../../Common/Dropdown";
import settings from "../../../constants/settings";
import ProfilePic from "../../../assets/images/profilePic.jpg";
import { PasswordViewer } from "../../../utils/passwordVisibilityToggle";

import { setAlert } from "../../../redux/Alerts/actions";
import "./style.scss";
import "../../SignupClient/style.scss";
import { eyeSign } from "../../../assets/icons/icons";
// const LanguageList = [{ name: "English" }, { name: "Hindi" }, { name: "French" }, { name: "German" }];

// const TimezoneList = [{ name: "Kolkata" }, { name: "Germany" }];

const SettingsClient = ({
  setDashboardRoute,
  componentID,
  changePassword,
  getStaticCountries,
  countriesList,
  updateClientProfile,
  updateClientProfileWithoutImage,
  user,
  setAlert,
}) => {
  const [passwordReset, setPasswordReset] = useState({
    oldPassword: "",
    newPassword: "",
    newDupPassword: "",
  });
  const [userImage, setUserImage] = useState(user.profilePic || ProfilePic);
  const [imageChanged, setImageChanged] = useState(false);
  const [currentUserImg, setCurrentUserImg] = useState(userImage);
  const [form, setForm] = useState({
    // image: userImage,
    name: user.name,
    position: user.position,
    country: user.country.id,
    website: user.website,
    employees: user.employee,
    email: user.email,
    phone: user.phone,
    // city: "",
  });
  const [showpassword, setShowPassword] = useState(false);
  const [shownewpassword, setShowNewPassword] = useState(false);
  const { oldPassword, newPassword, newDupPassword } = passwordReset;

  useEffect(() => {
    setDashboardRoute(componentID);
    getStaticCountries();
    // eslint-disable-next-line
  }, []);

  const handelChangePassword = e => {
    e.preventDefault();
    if (newPassword !== newDupPassword) {
      setAlert({ message: "New Password and Confirm Password did not match.", type: "WARNING" });
    } else if (newPassword.length < 8) {
      setAlert({ message: "Password must have atleast 8 characters.", type: "WARNING" });
    } else {
      changePassword(passwordReset);
    }
  };

  const handelUpdateProfile = e => {
    e.preventDefault();
    if (form.country === "") {
      setAlert({ message: "Please select a country.", type: "WARNING" });
    } else if (form.name === "") {
      setAlert({ message: "Please enter a Name.", type: "WARNING" });
    } else if (form.position === "") {
      setAlert({ message: "Please enter a Position.", type: "WARNING" });
    } else if (form.website === "") {
      setAlert({ message: "Please enter a Website.", type: "WARNING" });
    } else if (form.employees === "") {
      setAlert({ message: "Please enter Number of Employees.", type: "WARNING" });
    } else if (form.email === "") {
      setAlert({ message: "Please enter a Email Id.", type: "WARNING" });
    } else if (form.phone === "") {
      setAlert({ message: "Please enter a Mobile No.", type: "WARNING" });
    } else {
      imageChanged ? updateClientProfile(form, userImage) : updateClientProfileWithoutImage(form);
    }
  };

  const handlePasswordChange = e => {
    setPasswordReset({
      ...passwordReset,
      [e.target.name]: e.target.value,
    });
  };

  const myfunc = e => {
    setForm({ ...form, country: e.target.value });
    // getStaticStates(e.target.value)
  };

  const handleImg = e => {
    const reader = new FileReader();
    reader.onload = () => {
      if (reader.readyState === 2) {
        setCurrentUserImg(reader.result);
        setUserImage(e.target.files[0]);
        setImageChanged(true);
      }
    };
    reader.readAsDataURL(e.target.files[0]);
  };

  return (
    <div className="client-dash-settings">
      <div className="settings-leaderboard">
        <DashboardHeading heading={settings.testersettings.heading1.light} headingStrong={settings.testersettings.heading1.bold} showBtn={false} />
      </div>

      {/* Profile Settings  */}

      <div className="settings-leaderboard">
        <DashboardHeading heading={settings.testersettings.heading5.bold} showBtn={false} />
      </div>
      <form onSubmit={handelUpdateProfile}>
        <div className="change-password-container">
          <label>{settings.testersettings.profileimg}</label>
          <div className="form-group img-field">
            <div className="img-circle">
              <img alt="user" src={currentUserImg} width="100px" height="100px" style={{ borderRadius: "50%" }} />
            </div>
            <label htmlFor="user-image" className="user-image-label">
              {userImage ? user.name : "Add Profile image"}
            </label>
            <input type="file" id="user-image" className="image-input" onChange={handleImg} accept="image/*" name="file" />
            <small className={"hide"}></small>
          </div>

          <div className="field1">
            <div className="input-field">
              <label>Your Name</label>
              <input type="name" width="big" value={form.name} onChange={e => setForm({ ...form, name: e.target.value })} />
            </div>
          </div>

          <div className="field1">
            <div className="input-field">
              <label>Email Id</label>
              <input type="name" width="big" value={form.email} onChange={e => setForm({ ...form, email: e.target.value })} />
            </div>
          </div>

          <div className="field1">
            <div className="input-field">
              <label>Mobile No.</label>
              <input type="number" width="big" value={form.phone} onChange={e => setForm({ ...form, phone: e.target.value })} />
            </div>
          </div>

          <div className="field1">
            <div className="input-field">
              <label>Position</label>
              <input type="text" width="big" value={form.position} onChange={e => setForm({ ...form, position: e.target.value })} />
            </div>
          </div>

          <div className="field1">
            <div className="input-field">
              <label>Website</label>
              <input type="text" width="big" value={form.website} onChange={e => setForm({ ...form, website: e.target.value })} />
            </div>
          </div>

          <div className="field1">
            <div className="input-field">
              <label>No. of Employees</label>
              <input type="number" width="big" value={form.employees} onChange={e => setForm({ ...form, employees: parseInt(e.target.value) })} />
            </div>
          </div>

          <div className="same-row">
            <div className="country-list field1">
              <div className="input-field">
                <label>Country</label>
                <select
                  className="input-select"
                  id="countryList"
                  value={form.country}
                  onChange={(e, country) => myfunc(e, country)}
                  name="country"
                  disabled={countriesList.length === 0}
                >
                  {user.country ? <option value={user.country.id}>{user.country.name}</option> : <option value="">Choose Country</option>}
                  {countriesList &&
                    countriesList.map((cont, i) => (
                      <option value={cont.id} key={i}>
                        {cont.name}
                      </option>
                    ))}
                </select>
              </div>
            </div>
          </div>
          <button className="glb-btn submit-btn" type="submit">
            Save Profile
          </button>
        </div>
      </form>

      {/* Password Change Settings */}

      <div className="settings-leaderboard-1">
        <DashboardSubHeading heading={settings.testersettings.change_password.heading2} showBtn={false} />
      </div>
      <form onSubmit={handelChangePassword}>
        <div className="change-password-container">
          <div className="settings-change-password">
            <div className="input-field" id="old-password">
              <label>{settings.testersettings.change_password.currpassword}</label>
              <input
                type={showpassword ? "text" : "password"}
                width="big"
                required
                value={oldPassword}
                name="oldPassword"
                onChange={e => handlePasswordChange(e)}
              />
              <svg
                onMouseOver={() => {
                  PasswordViewer(showpassword, setShowPassword);
                }}
                onMouseOut={() => {
                  PasswordViewer(showpassword, setShowPassword);
                }}
                onTouchStart={() => {
                  PasswordViewer(showpassword, setShowPassword);
                }}
                onTouchEnd={() => {
                  PasswordViewer(showpassword, setShowPassword);
                }}
                className="password-show-hide"
                fill={showpassword ? "#646464" : "#bbbbbb"}
                width="24"
                height="24"
                xmlns="http://www.w3.org/2000/svg"
                fill-rule="evenodd"
              >
                {eyeSign}
              </svg>
            </div>
          </div>
          <div className="same-row">
            <div className="field1">
              <div className="input-field">
                <label>{settings.testersettings.change_password.newpassword}</label>
                <input type="password" width="small" required value={newPassword} name="newPassword" onChange={handlePasswordChange} />
              </div>
            </div>
            <div className="field2">
              <div className="input-field">
                <label>{settings.testersettings.change_password.repeatpassword}</label>
                <input
                  type={shownewpassword ? "text" : "password"}
                  width="small"
                  required
                  value={newDupPassword}
                  name="newDupPassword"
                  onChange={e => handlePasswordChange(e)}
                />
                <svg
                  onMouseOver={() => {
                    PasswordViewer(shownewpassword, setShowNewPassword);
                  }}
                  onMouseOut={() => {
                    PasswordViewer(shownewpassword, setShowNewPassword);
                  }}
                  onTouchStart={() => {
                    PasswordViewer(shownewpassword, setShowNewPassword);
                  }}
                  onTouchEnd={() => {
                    PasswordViewer(shownewpassword, setShowNewPassword);
                  }}
                  className="password-show-hide-1"
                  fill={shownewpassword ? "#646464" : "#bbbbbb"}
                  width="24"
                  height="24"
                  xmlns="http://www.w3.org/2000/svg"
                  fill-rule="evenodd"
                >
                  {eyeSign}
                </svg>
              </div>
            </div>
          </div>

          <button className="glb-btn submit-btn" type="submit">
            {settings.testersettings.change_password.btnText1}
          </button>
        </div>
      </form>
      <div className="LangNTimezone">
        <div className="same-row-1">
          <DashboardSubHeading heading={settings.testersettings.heading3} showBtn={false} />
          <Dropdown placeholder={settings.testersettings.placeholder} OptionListArray={settings.testersettings.LanguageList} />
        </div>
        <br />
        <div className="same-row-1">
          <DashboardSubHeading heading={settings.testersettings.heading4} showBtn={false} />
          <Dropdown placeholder={settings.testersettings.placeholder} OptionListArray={settings.testersettings.TimezoneList} />
        </div>
      </div>

      <div className="savaBtn">
        <button className="glb-btn" type="submit">
          {settings.testersettings.btnText2}
        </button>
      </div>
    </div>
  );
};

const mapStateToProps = ({ testerDashboard, staticData, auth }) => ({
  isLoading: testerDashboard.isLoading,
  countriesList: staticData.countries,
  user: auth.user,
});

export default connect(mapStateToProps, {
  setDashboardRoute,
  changePassword,
  updateClientProfile,
  updateClientProfileWithoutImage,
  getStaticCountries,
  setAlert,
})(SettingsClient);
