import React, { Fragment, useEffect, useState } from "react";
import settings from "../../constants/settings";
import backspace from "../../assets/icons/backspace.svg";
import Step3 from "../../assets/images/ProgressForm3.png";
import { googleLogo, linkedInLogoBlue } from "../../assets/icons/icons";

const Form1 = ({
  data,
  signUp,
  form,
  setForm,
  countriesList,
  getStaticStates,
  statesList,
  isLoading,
  setAlert,
  title,
  setCounter,
  error,
  proceed,
  handleSocialSignUp,
}) => {
  const { country, state, city, declaration, subscribe, accept } = form;
  const [currentState, setCurrentState] = useState({});
  const [testerImage, setTesterImage] = useState(null);
  const nextForm = e => {
    e.preventDefault();

    signUp(form, testerImage);
  };

  if (proceed === true && !error) {
    setCounter(3);
  } else if (proceed === false && error) {
    setCounter(0);
  }

  const prevForm = () => {
    setCounter(1);
  };

  useEffect(() => {
    getStaticStates(country);
    // eslint-disable-next-line
  }, [country]);

  const setInput = e => {
    let value = e.target.type === "checkbox" ? e.target.checked : e.target.value;
    const name = e.target.name;
    if (name === "city") {
      setForm({
        ...form,
        [name]: currentState.cities.filter(cty => cty.name === e.target.value)[0],
      });
    } else {
      setForm({
        ...form,
        [name]: value,
      });
      if (name === "state") {
        setCurrentState(statesList.filter(sta => sta.id === e.target.value)[0]);
      }
    }
  };

  return (
    <Fragment>
      <form onSubmit={nextForm}>
        <div className="social-logins">
          <button
            type="button"
            onClick={e => {
              handleSocialSignUp(e, "LINKEDIN");
            }}
            className="LinkedIn"
          >
            {linkedInLogoBlue} Sign-up With LinkedIn
          </button>
          <button
            type="button"
            onClick={e => {
              handleSocialSignUp(e, "GOOGLE");
            }}
            className="Google"
          >
            {googleLogo} Sign-up With Google
          </button>
        </div>

        <div className="progress-bar">
          <img src={Step3} alt="Step 1" width="60%" />
        </div>

        <div className="tester-details-form">
          {/*<div className="details-input-fields">
            <div className="label">{settings.signuptester.img}</div>
            <label htmlFor="user-image" className="user-image-label">
              {userImage ? userImage.name : "Add Profile image"}
            </label>
            <input type="file" id="user-image" className="image-input" onChange={e => setUserImage(e.target.files[0])} accept="image/*" name="file" />
            <small className={"hide"}>h</small>
          </div>*/}
          {/* <div className="details-input-fields">
          </div>  */}

          <div className="details-input-fields">
            <label>{settings.signuptester.country}</label>
            <select className="input-select" value={country} onChange={setInput} name="country" disabled={countriesList.length === 0} required>
              <option value={null} selected></option>
              {countriesList &&
                countriesList.map((cont, i) => (
                  <option value={cont.id} key={i}>
                    {cont.name} ({cont.phone_code})
                  </option>
                ))}
            </select>
            <small className={countriesList && countriesList.length === 0 ? "loading-data" : "hide"}>{settings.signuptester.loading}</small>
          </div>
          <div className="details-input-multifields">
            <div className="details-input-fields" style={{ marginBottom: 0 }}>
              <label>{settings.signuptester.state}</label>
              <select className="input-select" value={state} onChange={setInput} name="state" disabled={statesList.length === 0} required>
                <option value={null} selected></option>
                {statesList &&
                  statesList.map((cont, i) => (
                    <option value={cont.id} key={i}>
                      {cont.name}
                    </option>
                  ))}
              </select>
              <small className={statesList && statesList.length === 0 ? "loading-data" : "hide"}>{settings.signuptester.loading}</small>
            </div>
            <div className="details-input-fields" style={{ margin: 0 }}>
              <label>{settings.signuptester.city}</label>
              <select className="input-select" value={city.name} onChange={setInput} name="city" disabled={statesList.length === 0}>
                <option value={null} selected></option>
                {currentState.cities &&
                  currentState.cities.map((cont, i) => (
                    <option value={cont.name} key={i}>
                      {cont.name}
                    </option>
                  ))}
              </select>
              <small className={statesList.length === 0 ? "loading-data" : "hide"}>Select state first</small>
            </div>
          </div>
          <div className="details-input-fields img-field">
            <label>{settings.signuptester.profileimg}</label>
            <label htmlFor="user-image" className="user-image-label">
              {testerImage ? testerImage.name : "Add Profile image"}
            </label>
            <input type="file" id="user-image" className="image-input" onChange={e => setTesterImage(e.target.files[0])} accept="image/*" name="file" />
            <small className={"hide"}>h</small>
          </div>
          <div className="details-input-fields ">
            <input placeholder="Enter Your Referral Code" onChange={setInput} name="refferalCode" />
          </div>
        </div>
        <div className="user-consents">
          <label>
            <input type="checkbox" required name="declaration" checked={declaration} onChange={setInput} /> {data.consentMessageOne}*
          </label>
          <label>
            <input type="checkbox" required name="subscribe" checked={subscribe} onChange={setInput} /> {data.consentMessageTwo}*
          </label>
          <label>
            <input type="checkbox" required name="accept" checked={accept} onChange={setInput} />
            By Signing up you agree to our{" "}
            <u>
              <a href="/terms" style={{ color: "#247cd3" }}>
                Terms and Conditions
              </a>
            </u>{" "}
            and{" "}
            <u>
              <a href="/privacy" style={{ color: "#247cd3" }}>
                Privacy Policy
              </a>
            </u>
            .*
          </label>
        </div>
        <div className="prev-next">
          <div onClick={prevForm} style={{ cursor: "pointer" }}>
            <img src={backspace} alt="<-" width="40px" />
          </div>
          <button className="glb-btn submit-form" type="submit">
            Sign-up
          </button>
        </div>
      </form>
    </Fragment>
  );
};

export default Form1;
