import React, { useEffect, useState } from "react";
import axios from "axios";

import { getLocal } from "../../../utils/localStorage";
import baseDomain, { successPaymentStatusReq } from "../../../constants/apiRoutes";
import { useParams, useHistory } from "react-router-dom";
import { withLayout } from "../../../layouts";

import Success from "../../../assets/icons/successPayment.svg";
import settings from "../../../constants/settings";

import "../style.scss";
import Loader from "../../../components/Loader";
const PaymentSuccess = () => {
  const [isLoading, setIsLoading] = useState(true);
  const history = useHistory();
  const { transactionID } = useParams();
  useEffect(() => {
    verifyPayment();
    // eslint-disable-next-line
  }, []);
  const verifyPayment = async () => {
    try {
      await axios.get(`${baseDomain}${successPaymentStatusReq}/${transactionID}`, {
        headers: {
          token: getLocal("token"),
        },
      });
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      history.push(`/payment/failed/${transactionID}`);
    }
  };
  return (
    <div className="payment-container">
      <div className="payment-status">
        {isLoading ? (
          <Loader />
        ) : (
          <>
            <div className="img">
              <img src={Success} alt="Success" />
            </div>
            <div className="message">{settings.payment_status.payment_success.message}</div>
            <div className="continew-btn success" onClick={() => history.push("/dashboard")}>
              {settings.payment_status.payment_success.btnText}
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default withLayout(PaymentSuccess, true, false);
