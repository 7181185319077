import React, { useEffect } from "react";
import { connect } from "react-redux";
import { useHistory } from "react-router-dom";

import MyEarningsAccordian from "../../../../Common/DashboardTables/MyEarningsAccordian/index";
import LifetimeEarningsTable from "../../../../Common/DashboardTables/LifetimeEarningsTable/index";
import DashboardHeading from "../../../../Common/DashboardHeading";
import Loader from "../../../../Loader";
import settings from "../../../../../constants/settings";
import { getPaidEarning, getEarningGraph, getWalletEarning } from "../../../../../redux/TesterDashboard/actions";

import "./style.scss";
import TesterPricingTable from "../../../../Common/DashboardTables/TesterPricing";

const TotalEarning = ({ getPaidEarning, getWalletEarning, isLoading, earnedWallet, earningPaid, getEarningGraph, earningGraph, totalWallet }) => {
  const history = useHistory();
  const tableContentArray= [
    { bugType: "Crash", reward: "$5 - $15 " },
    { bugType: "Blocker", reward: "$4.5 - $13 " },
    { bugType: "Critical", reward: "$4 - $11 " },
    { bugType: "Major", reward: "$3.75 - $7.5 " },
    { bugType: "Minor", reward: "$2.8 - $5.6 " },
    { bugType: "Low", reward: "$1.75 - $3.75 " },
  ];
  useEffect(() => {
    getPaidEarning();
    getWalletEarning();
    // eslint-disable-next-line
  }, []);
  return (
    <>
    <div className="my_earnings">
      <div className="my-earnings">
        <DashboardHeading heading={settings.totalearnings.heading1.light} headingStrong={settings.totalearnings.heading1.bold} /> 
        <div className="card">{isLoading 
        ? <Loader /> 
        : (
          <>
            {earnedWallet.map((item,i) => (
              <MyEarningsAccordian myearningscontent={item} key={i} showTable={true} showBtn={false}/>
            ))}
          </>
        )}</div>
      </div> 


      <div className="current-balance-container">
        <div className="current-balance-label">{settings.totalearnings.totalBalance}</div>
        <div className="current-balance">
          <div className="transfer-amount">{totalWallet ? "$ " + totalWallet.toFixed(2) : "$ 0"}</div>  
          <div
            className="request-transfer"
            onClick={() =>
              history.push({
                pathname: "/dashboard/earning/transfer",
                state: totalWallet,
              })
            }
          >
            {settings.totalearnings.btnText}
          </div>
        </div>
      </div>

      <div className="life-time-earning">
        <DashboardHeading headingStrong={settings.totalearnings.heading2 + " " + settings.totalearnings.heading1.bold} />
        <div className="card">{isLoading ? <Loader /> : <LifetimeEarningsTable lifetimeearningscontent={earningPaid} />}</div>
      </div>
    </div>
    <div className="tester-pricing">
      <DashboardHeading headingStrong={"Rates"} showBtn={false} />
      <TesterPricingTable tableContentArray={tableContentArray} />
      <div className="terms-and-conditions">
        <p> * Please note that all earnings are in US-Dollar ($)  to be converted in calculated as per the country origin of the tester at the time of payout.<br/> Disclaimer: Actual Payouts Per Bug may differ from the submitted one. Bugs validity and Severity will be finalized by Internal Quality Team, based on that payout will happen.</p>
        <h6><a href="/terms" target="_blank">Terms &amp; Conditions</a> Apply *</h6>
      </div>
    </div></>
  );
};

const mapStateToProps = ({ testerDashboard }) => ({
  earnedWallet: testerDashboard.earnedWallet,
  earningPaid: testerDashboard.earningPaid,
  earningGraph: testerDashboard.earningGraph,
  totalWallet: testerDashboard.totalWallet,
  isLoading: testerDashboard.isLoading,
});

export default connect(mapStateToProps, { getPaidEarning, getEarningGraph, getWalletEarning })(TotalEarning);
