import React, { Fragment } from "react";
import "./style.scss";

const CardsGrid02 = ({ data = {} }) => {
  return (
    <Fragment>
      <div className={`flexboxgrid cards-grid-02 bg-light`}>
        <div className="custom-container center-xs">
          {data.heading && <h4 className="glb-header">{data.heading}</h4>}
          <div className="row custom-row-01">
            {data.items &&
              [...data.items].map(({ urlToImage, prefixText, suffixText }, index) => (
                <Fragment key={index}>
                  <div className="item--container col-xs">
                    {urlToImage && (
                      <div className="image-container">
                        <img src={urlToImage} alt="Product Lifecycle testing stages" />
                      </div>
                    )}
                    {prefixText && <span className="text-yellow text-bold">{prefixText}&nbsp;</span>}
                    {suffixText && <span className="text-white">{suffixText}</span>}
                  </div>
                </Fragment>
              ))}
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default CardsGrid02;
