import React from "react";

import "./style.scss";
const DashboardSubHeading = ({ heading, headingStrong, showBtn = true, btnValue, btnOnClick }) => {
  return (
    <div className="dashboard-sub-heading">
      <h2>
        {heading} <strong>{headingStrong}</strong>
      </h2>
      {showBtn && (
        <button className="glb-btn" onClick={btnOnClick}>
          {btnValue}
        </button>
      )}
    </div>
  );
};

export default DashboardSubHeading;
