import React, { useState } from "react";
import ApproveTestimonials from "../../../Modals/ApproveTestimonial";
import ProfilePic from "../../../../assets/images/profilePic.jpg";
import "./style.scss";

const Testimonial = ({ name, profilePic, testimonial, testimonialId, approved, rejected }) => {
  const [view, setView] = useState(false);
  const [truncated, setTruncated] = useState(true);

  const truncation = desc => {
    let description = truncated && desc.length > 25 ? desc.slice(0, 25).concat("...") : desc;
    return description;
  };

  const testimonialStatus = () => {
    if (approved === false && rejected === false) {
      return "#E78301";
    } else if (approved === true && rejected === false) {
      return "green";
    } else if (approved === false && rejected === true) {
      return "red";
    }
  };

  return (
    <div className="singleTestimonial">
      <div className="testimonial-header">
        <div>
          <p className="testerName">
            <img className="testimonialTesterImage" width="50px" height="50px" src={profilePic ? profilePic : ProfilePic} alt={name} />
            &ensp;{name}
          </p>
        </div>
        <div className="testimonial-status" style={{ backgroundColor: `${testimonialStatus()}` }}></div>
      </div>
      <div className="testimonial-div">
        <span className="testimonial-text">{truncation(testimonial)}</span>
        {testimonial.length > 25 && (
          <button
            onClick={() => {
              setTruncated(!truncated);
            }}
            className="more-less-btn"
          >
            {truncated ? "Read More" : "Read Less "}
          </button>
        )}
      </div>
      {approved === false && rejected === false && (
        <div className="glb-btn" style={{padding:"10px 0px"}} >
          <span
            onClick={() => {
              setView(true);
            }}
          >
            Proceed
          </span>
        </div>
      )}
      {view && <ApproveTestimonials testimonialId={testimonialId} name={name} profilePic={profilePic} testimonial={testimonial} setView={setView} />}
    </div>
  );
};

export default Testimonial;
