import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { connect } from "react-redux";
import { getProjectListedBugs, getTesterProjectByID, getProjectBugsExport } from "../../../../../redux/TesterDashboard/actions";
import exportFromJSON from "export-from-json";

import Loader from "../../../../Loader";
import DashboardHeading from "../../../../Common/DashboardHeading";
import BugListTable from "../../../../Common/DashboardTables/BugListTable";
import settings from "../../../../../constants/settings";

import "./style.scss";
import { getReviewer, assignReviewer, updateReviewer } from "../../../../../redux/AdminDashboard/actions";

const BugList = ({ getProjectListedBugs, projectBugs, isLoading, selectedProject, getTesterProjectByID, getProjectBugsExport, allProjectBugs, getReviewer, allReviewer,assignReviewer, updateReviewer }) => {
  const { projectID } = useParams();
  useEffect(() => {
    getProjectListedBugs(projectID);
    getTesterProjectByID(projectID);
    getProjectBugsExport(projectID);
    getReviewer()
    // eslint-disable-next-line
  }, []);

  const [search1, setSearch1] = useState("");
  const [search2, setSearch2] = useState("");
  const [currentAllBugs, setCurrentAllBugs] = useState([]);
  //const [pageNumber,setPageNumber]=useState(1);

  useEffect(() => {
    setCurrentAllBugs(projectBugs);
  }, [projectBugs]);

  const handleChange1 = e => {
    setCurrentAllBugs(projectBugs);
    setCurrentAllBugs(oldArray => oldArray.filter(item => item.screenName.toLowerCase().startsWith(e.target.value.toLowerCase())));
    setSearch1(e.target.value);
  };

  const handleChange2 = e => {
    setCurrentAllBugs(projectBugs);
    setCurrentAllBugs(oldArray => oldArray.filter(item => item.id.toLowerCase().startsWith(e.target.value.toLowerCase())));
    setSearch2(e.target.value);
  };

  
  // const buttons = [];

  // const renderPagination = (i=1)=>{
  //   for(i;i<=currentAllBugs?.[0]?.count;i++){
  //       buttons.push(i);
  //   }
  //   }
  //   renderPagination()
  //   const handlePageChange = (i)=>{
  //     setPageNumber(i);
  //     getProjectListedBugs(projectID,i);
  //   }

  const myList = allProjectBugs;
  const myNewList = [];

  myList.map(item => {
    const osIndex = item?.device?.extra?.findIndex(item => item.key === "os");
    //const manufacturerIndex = item?.device?.[0]?.extra?.findIndex(item => item.key==="manufacturer")
    return myNewList.push({
      "Bug Title": item.summary,
      "Bug Module": item.component,
      Severity: item.priority,
      Description: item.description,
      Attachments: item.attachments,
      Status: item.status,
      "Reporter Name": item.reporterName,
      Project: item.project,
      Earning: item.earning,
      "Approved Date": item.approvedDate,
      "Bug ID": item.id,
      //"Device Type": item.device ? item.device.type : "NA",
      "Device Name": item?.device?.name,
      "Device version": item?.device?.extra?.[osIndex]?.value,
      Platform: item.platform.name,
      Email: item.email,
      "Phone No.": item?.phone,
      "Tester Id": item?.testerId,
      City: item?.city?.[0]?.city?.name,
    });
  });

  const fileName = `${selectedProject.name}_Bugs`;
  const exportType = "xls";
  const data = myNewList;
  const ExportToExcel = () => {
    exportFromJSON({ data, fileName, exportType });
  };

  return (
    <div className="text-bug-list">
      <DashboardHeading heading={selectedProject.name} headingStrong={settings.bug_list.heading2} showBtn={false} />
      <div className="buttons-container">
        <input type="text" value={search1} placeholder="Search by Name" className="search-tester" onChange={handleChange1} />
        <input type="text" value={search2} placeholder="Search by Id" className="search-tester" onChange={handleChange2} />
        <button className="glb-btn glb-action-btn" onClick={ExportToExcel}>
          Export
        </button>
      </div>
      <div className="card">{isLoading ? <Loader/>:<BugListTable tableContentArray={currentAllBugs} projectID={projectID} role={"ADMIN"} reviewer={allReviewer} assignReviewer={assignReviewer} updateReviewer={updateReviewer} />}</div>
      {/* <div className="pagination-container" >
      <div
            className="controller element"
            onClick={() => {
              handlePageChange(pageNumber - 1);
            }}
          >
            {"<"}
          </div>
      <div className="pagination">
          {buttons.map((item, idx) => {
            return (
              <p
                className={pageNumber === idx + 1 ? "controller element current" : "controller element"}
                onClick={() => {
                  handlePageChange(item);
                }}
              >
                {item}
              </p>
            );
          })}
        </div>
        <div
            className="controller element"
            onClick={() => {
              handlePageChange(pageNumber+1 <= currentAllBugs?.[0]?.count ?  pageNumber + 1: pageNumber);
            }}
          >
            {">"}
          </div>
      </div> */}
    </div>
  );
};
const mapStateToProps = ({ testerDashboard, adminDashboard }) => ({
  projectBugs: testerDashboard.projectListedBugs,
  allProjectBugs:testerDashboard.allProjectBugs,
  selectedProject: testerDashboard.testerSelectedProject,
  allReviewer: adminDashboard.allReviewer,
  isLoading: testerDashboard.isLoading,
});

export default connect(mapStateToProps, { getProjectListedBugs, getTesterProjectByID, getProjectBugsExport, getReviewer, assignReviewer, updateReviewer })(BugList);
