import React, { useEffect } from "react";
import { connect } from "react-redux";

import DashboardHeading from "../../Common/DashboardHeading";
import LiveProjects from "../../Common/DashboardTables/LiveProjects";
import TesterDetailCard from "../../Common/TesterDetailCard";
import DashbaordActiveTests from "../../Common/DashboardActiveTests";
import NoDataMessage from "../../Common/NoDataMessage";
import { setDashboardRoute } from "../../../redux/Routing/actions";
import { getTesterLeaderboardProjects, getTesterLeaderboard, getTesterLiveProjects } from "../../../redux/TesterDashboard/actions";
import settings from "../../../constants/settings";

import PricingBanner from "../../../assets/images/testerDashboardBanner.jpg";

import Loader from "../../Loader";
// import { SearchIcon } from "../../../assets/icons/icons";

import "./style.scss";

const Home = ({
  setDashboardRoute,
  componentID,
  getTesterLeaderboardProjects,
  getTesterLeaderboard,
  getTesterLiveProjects,
  activeProjects,
  leaderboard,
  liveProjects,
  isLoading,
}) => {
  // const [search, setSearch] = useState("");
  // const [searchResults, setSearchResults] = useState([]);

  // const handleChange = e => {
  //   setSearchResults(activeProjects);
  //   setSearchResults(oldArray => oldArray.filter(item => item.name.toLowerCase().startsWith(e.target.value.toLowerCase())));
  //   setSearch(e.target.value);
  //   e.target.value.length === 0 && setSearchResults([]);
  // };
  // const clearSearch = e => {
  //   setSearch("");
  //   setSearchResults([]);
  // };

  useEffect(() => {
    setDashboardRoute(componentID);
    getTesterLeaderboardProjects();
    getTesterLeaderboard();
    getTesterLiveProjects();
    // eslint-disable-next-line
  }, []);

  return (
    <div className="tester-dash-home">
      <div className="dash-live-project-card searchResultsContainer">
        {/* <div className="card" id="searchbar">
              <div className="img">
                <SearchIcon color="#828282" size="17px" />
              </div>
              <input
                type="text"
                value={search}
                onChange={e => handleChange(e)}
              />
              {search.length > 0 && <button className="glb-btn" onClick={clearSearch}>Clear</button>}
            </div> */}
        <div
          className="dash-live-project-card"
          style={{ display: "none" }}
          // style={searchResults.length === 0 ? {display:"none"}: {}}
        >
          <DashboardHeading headingStrong="Search Results" showBtn={false} />
          <div className="dash-live-project-card-cards">
            <div className="live-project-card-cards">
              {/* {searchResults && searchResults.length === 0 ? (
                <NoDataMessage message={`${search && "No Search Results" }`} />
              ) : (
                searchResults.map((project, i) => <DashbaordActiveTests onlineProjects={project} key={i} />)
              )} */}
            </div>
          </div>
        </div>
      </div>
      <div className="dash-live-project-card">
        <DashboardHeading heading={settings.testerhome.heading1.light} headingStrong={settings.testerhome.heading1.bold} showBtn={false} />
        {isLoading ? (
          <Loader />
        ) : (
          <>
            <div className="dash-live-project-card-cards">
              <div className="live-project-card-cards">
                {activeProjects && activeProjects.length === 0 ? (
                  <NoDataMessage message={settings.testerhome.message1} />
                ) : (
                  activeProjects.map((project, i) => <DashbaordActiveTests onlineProjects={project} key={i} />)
                )}
              </div>
            </div>
          </>
        )}
      </div>

      <div className="tester-dashbaord-banner">
        <img src={PricingBanner} alt="Banner" />
        <small className={`text-grey`} >*Disclaimer: Actual Payouts Per Bug may differ from the submitted one. Bugs validity and Severity will be finalized by Internal Quality Team, based on that payout will happen.</small>
      </div>

      <div className="dash-home-leaderboard">
        <DashboardHeading heading={settings.testerhome.heading2.light} headingStrong={settings.testerhome.heading2.bold} showBtn={false} />
        {isLoading ? (
          <Loader />
        ) : (
          <div className="dash-home-leaderboard-cards">
            {leaderboard && leaderboard.length === 0 ? (
              <NoDataMessage message={settings.testerhome.message2} />
            ) : (
              leaderboard?.map((lead, i) => {
                if (i > 3) return null;
                else
                  return (
                    <TesterDetailCard
                      key={i}
                      amount={lead.earned || 0}
                      totalTests={lead.totalTests}
                      testSuccess={lead.validTests}
                      bugsMajor={lead.majorBugs}
                      firstName={lead.name}
                      profilePic={lead.profilePic}
                      position={lead.position}
                    />
                  );
              })
            )}
          </div>
        )}
      </div>

      <div className="live-projects">
        <DashboardHeading
          heading={settings.testerhome.heading3.light}
          headingStrong={settings.testerhome.heading3.bold}
          btnValue={settings.testerhome.btnText}
          btnOnClick={null}
        />
        <div className="cards">{isLoading ? <Loader /> : <LiveProjects tableContentArray={liveProjects} />}</div>
      </div>
    </div>
  );
};

const mapStateToProps = ({ testerDashboard }) => ({
  activeProjects: testerDashboard.testerActiveProjects,
  leaderboard: testerDashboard.testerLeaderboard,
  liveProjects: testerDashboard.testerLiveProjects,
  isLoading: testerDashboard.isLoading,
});

export default connect(mapStateToProps, { setDashboardRoute, getTesterLeaderboardProjects, getTesterLeaderboard, getTesterLiveProjects })(Home);
