import React from "react";
import { useHistory } from "react-router";
import "./style.scss";
import EarnMoney from "../../../assets/icons/money.png";
import TimeFlexibility from "../../../assets/icons/XMLID_1031_.png";
import LatestFeatures from "../../../assets/icons/new-product.png"
//import newInfoImg from "../../../assets/testerHowItWorks.png";

function NewInfoLanding({ data = {} }) {
  const history = useHistory();
  return (
    <div className="tester_newinfo bg-light">
      
      <h4 className="tester_new_info_heading">{data.heading}</h4>
      <div className="tester_newinfocontent">{data.description}</div>
      <div className="tester_icons_and_description">
      <div className="home_additionalinfo_two_div">
          <h4>{data.headingB}</h4>
          <div className="howItWorks" >
            <div className="howItWorks-item" >
              <div>
                <img width="70px" height="70px" src={EarnMoney} alt="Upload your app" />
              </div>
              <div>
                <h4>Earn Good Money</h4>
                <p>Every approved bug can fetch you upto $13. Get paid by referring your friends and family.</p>
              </div>
            </div>
            <div className="howItWorks-item" >
              <div>
                <img width="70px" height="70px" src={TimeFlexibility} alt="Upload your app" />
              </div>
              <div>
                <h4>Flexible Working</h4>
                <p>Work From Home work from your posh office or from a diner, work in the wee hours of the night or early hours of the morning. What suits you suits us too.</p>
              </div>
            </div>
            <div className="howItWorks-item" >
              <div>
                <img width="70px" height="70px" src={LatestFeatures} alt="Upload your app" />
              </div>
              <div>
                <h4>Test The Latest Apps</h4>
                <p>Join the cool community and always be the first to check out the latest apps/digital products.</p>
              </div>
            </div>
          </div>
          <br/>
          <div className="testerGetStarted glb-btn" onClick={() => history.push("/signup/tester")}>
            Get Started
          </div>
        </div>
      </div>
      {/* <div className="tester_icons_and_description_img">
        <img src={newInfoImg} alt="" />
      </div> */}
    </div>
  );
}

export default NewInfoLanding;
